@import "../../../../../../styles/variables";
.assembly-list-tree
{
    table
    {
        tbody
        {
            tr
            {
               td
                {
                    &:first-child
                    {
                        .text-block
                        {
                            overflow: inherit;
                        }
                        .tree-child-assembly
                        {
                            .ui-link.link
                            {
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                width: 100%;
                                height: 18px;
                                display: inline-block;
                                padding-right: 30px;
                                &.not-variant
                                {
                                    padding-right: 0;
                                }
                            }
                        }
                    }
                    &.instance-input
                    {
                        .text-block
                        {
                            height: 35px;
                            padding: 0px;
                        }
                    }
                }
            }
        }
    }
}
.text-block
{
    position: inherit;
}
.tree-child-assembly
{
    display: flex;
    > span{
        margin-right: 5px;
    }
    .cpn-link
    {
        color: #3CD1BF;
    }
    .ui-icon {
        width: 10px;
        height: 16px;
        margin-right: 10px;
        position: absolute;
        left: -15px;
        &.open {
            -webkit-transform: rotate(0);
            -ms-transform: rotate(0);
            transform: rotate(0);
        }
        &.close {
            -webkit-transform: rotate(270deg);
            -ms-transform: rotate(270deg);
            transform: rotate(270deg);
        }
    }
}

.rolled-up-modified-header
{
    .ui-icon
    {
        width: 20px;
        padding-left: 3px;
        svg
        {
            width: 13px;
            padding-top: 4px;
        }
    }
}
