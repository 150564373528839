@import "../../../../styles/variables";

.documents-list, .documents-table-view
{
  .regenerate-button
  {
    cursor: pointer;
  }
  .regenerate-button-spinner
  {
    .spinner
    {
      .spinner-wrapper-table
      {
        cursor: default;
      }
    }
  }
}
.document-regenerate-tooltip {
  .rc-tooltip-arrow {
    border-top-color: $BLACK_ERROR;
    .rc-tooltip-arrow-inner {
      border-top-color: $BLACK_ERROR;
    }
  }
  .rc-tooltip-inner {
    background-color: $BLACK_ERROR;
    border-color: $BLACK_ERROR;
    color: $BG_WHITE;
  }
}
