.info-block
{
	.custom-checkbox
	{
		.gray-checkbox
		{
			background-color: #B3B3B3!important;
			border-color: #B3B3B3!important;
			&:before,
			&:after
			{
				background-color: #000!important;
			}
		}
	}
}