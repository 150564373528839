.content-block {
    .sub-heading {
        h2 {
            font-size: 16px;
        }
    }
}
.company-config-toggle {
    border-bottom : 1px solid grey;
    padding: 20px 0;
    display: flex;
    align-items: flex-start;
    .toggle-block-first {
        flex-basis: 40%;
    }
    .toggle-block-second {
        flex-basis: 60%;
        display: flex;
        align-items: center;
    }
    .block-heading {
        display: flex;
        .toggle-lock-icon {
            width: 25px;
            margin-left: -25px;
        }
        .toggle-heading {
            padding-right: 5px;
            padding-bottom: 10px;
            color: white;
            font-size: 14px;
        }
    }
    .toggle-description {
        max-width: 350px;
        color: grey;
    }
    .toggel-value {
        margin-left: 10px;
        font-size: 14px;
        display: inline-block;
        &.disabled {
            color: #888888;
        }
    }
    .ui-toggle-btn {
        width: 36px;
        height: 16px;
        background: #888888;
        div {
            width: 12px;
            height: 12px;
            margin-left: 2px;
        }
        &.selected {
            background: #3CD1BF;
            div {
                margin-left: 22px;
            }
        }
    }
}
