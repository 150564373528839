@import "../../../../styles/variables";

.save-template-modal
{
    .modal-content
    {
        .modal-heading
        {
            padding: 30px 45px 20px 45px;
            h2{
                font-size: 24px;
            }
        }
        .modal-body
        {
            padding: 0 45px 0 45px;
            .existing-templates
            {
                margin-bottom: 10px;
                h3
                {
                    padding-bottom: 4px;
                }
                .select-template
                {
                   max-width: 284px;
                }
            }
            .template-divider{
                color: #888888;
                font-style:italic;
            }
            .new-templates{
                margin-top: 12px;
                h3{
                    padding-bottom: 4px;
                }
                input{
                    height: auto;
                    min-height: 30px;
                    color:#888888;
                    max-width: 284px;
                    height:30px;
                }
            }
            .checkbox-container{
                display: flex;
                border-bottom: 1px solid #575759;
                padding-bottom: 20px;
                margin-bottom: 26px;
                .checkbox-holder{
                    margin-right: 10px;
                    label{
                        border-color:#888888;
                    }
                }
                .checkbox-text{
                    color:#888888;
                }
                .info{
                    padding-left: 4px;
                    padding-top: 1px;
                    svg{
                        g{
                            path{
                                fill: #888;
                            }
                        }
                    }
                }
            }
            .btn-holder{
                display: block;
                .btn-container{
                    button{
                        min-width: 139px;
                        padding: 6px 12px;
                        &.dark{
                            margin-right: 10px;
                        }
                    }
                    .save-template-modal-spinner
                    {
                        .spinner-holder
                        {
                            justify-content: center;
                            .spinner
                            {
                                position: absolute;
                                left: 0px;
                            }
                        }
                    }
                }
            }
        }
    }
    .ui-modal-box
    {
        margin-top: 90px;
        .background
        {
            background: transparent;
        }
        .modal
        {
            padding:0;
            background: #18171d;
            width: 100%;
            height: auto;
            max-width: 564px;
        }
    }
}

.new-templates {
    .error {
        border: 2px solid #F54A4F !important;
        background: #F54A4F;
    }
}