@import "../../../../../styles/variables";

.icon-show-columns {
  width: 16px;
  height: 16px
}
#table-settings-menu {
    .rolled-up-modified-header
    {
        .ui-icon
        {
            display: none;
        }
    }
    position: absolute;
    right: 0;
    top: 35px;
    .modal
    {
        font-size: $FONT_SIZE_DEFAULT;
        pointer-events: all;
        position: absolute;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
        background: #17171F;
        margin-bottom: 35px;
        display: none;
        z-index: 5000;
        width: 158px;
        height: max-content;
        right: -2px;
        padding: 18px;

      > div
      {
          padding    : 30px 25px;
          text-align : left;
      }

      ul {
          li:first-child
          {
            span
            {
              color: #FFF;
            }
          }
        li {
          list-style-type: none;
          padding: 8px 0;
          > span{
            margin-left: 15px;
          }
        }
      }

      .header-block
      {
          padding       : 15px 25px;
          border-bottom : 1px solid $BG_LIGHT;
      }

      &.open
      {
          display: inline-table;;
      }
    }
}
