@import "../../../../../styles/variables";

.valispace-modal{
    .components-list, .review-screen{
        .modal {
            top: 10%;
            width: 78.5%;
            height: 75%;
            .modal-content
            {
                height: 100%;
                padding: 30px 60px;
                .modal-heading {
                    padding-bottom: 6px;
                    h1 {
                        font-size: 23px;
                        margin-bottom: 6px;
                    }
                }
                .tree-child-assembly {
                    .ui-icon {
                        padding: 4px 0px !important;
                        svg {
                            height: 10px !important;
                        }
                    }
                }
                .enlarge-icon-holder {
                    .ui-icon {
                        padding: 0px 0px !important;
                        svg {
                            height: 10px !important;
                        }
                    }
                }
                .ui-modal-box {
                    .modal-content {
                        .ui-icon {
                            display: unset;
                            padding: 0px 0px !important;
                            svg {
                                width: auto;
                                height: auto;
                            }
                        }
                    }
                }
            }
        }
        .valispace-components-wrapper
        {
            height: 100%;
            .valispace-components-table, .valispace-selected-cmps
            {
                height: 100%;
                .valispace-components
                {
                    height: 82%;
                    text-align: right;
                    #extended-table
                    {
                        .table-icons-section
                        {
                            .table-settings-wrapper
                            {
                                #table-settings-menu
                                {
                                    .modal
                                    {
                                        min-width: auto;
                                        ul
                                        {
                                            text-align: left;
                                        }
                                    }
                                }
                            }
                        }
                        .disabeld-row
                        {
                            td
                            {
                                color: $GREY_LIGHT3;
                            }
                        }
                    }
                }
                .rc-tooltip
                {
                    width: fit-content;
                    .rc-tooltip-content
                    {
                        .rc-tooltip-inner
                        {
                            background-color: $FONT_BLACK!important;
                            border-color: $FONT_BLACK!important;
                            color: $FONT_COLOR!important;
                            .ui-link
                            {
                                .existing-cmp-cpn
                                {
                                    color: $GREEN;
                                    &:hover
                                    {
                                        color: $FONT_COLOR;
                                        .open-link-icon
                                        {
                                            g
                                            {
                                                fill: $FONT_COLOR;
                                            }
                                        }
                                    }
                                    .open-link-icon
                                    {
                                        g
                                        {
                                            fill: $GREEN;
                                        }
                                    }
                                }
                            }
                        }
                        .rc-tooltip-arrow
                        {
                            border-top-color: $FONT_BLACK!important;
                        }
                    }
                }
            }
        }
        .content-wrapper
            {
                height: 100%;
                .details-wrapper
                {
                    height: 100%;
                    .table-wrapper
                    {
                        height: 81%;
                        .valispace-components
                        {
                            height: 100%;
                        }
                    }
                }
            }
        .valispace-selected-cmps
            {
                height: 84%;
                text-align: right;
                .modal-heading {
                    text-align: left;
                }
                .valispace-error-icon
                {
                    .rc-tooltip-arrow
                    {
                        border-right-color: transparent!important;
                        left: 50%;
                    }
                }
            }
    }
    .custom-modal
    {
        .modal
        {
            top: 15% !important;
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
            padding: 0;
            .modal-content
            {
                padding: 0 !important;
                .content-wrapper
                {
                    padding: 30px;
                    .details-wrapper
                    {
                        border-radius: 8px;
                        background-color: #353540;
                        .details-heading
                        {
                            text-align: left;
                            background-color: #41414A;
                            padding: 10px 25px;
                            border-top-left-radius: 8px;
                            border-top-right-radius: 8px;
                            h3
                            {
                                color: #888888;
                                font-weight: bold;
                                span
                                {
                                    font-weight: normal;
                                }
                            }
                        }
                        .list-table
                        {
                            padding: 15px 0;
                        }
                        .list-area
                        {
                            padding: 10px 0;
                            h3
                            {
                                text-align: left;
                                padding-left: 25px;
                                padding-bottom: 10px;
                                span
                                {
                                    color: #888888;
                                }
                            }
                            .table-wrapper-scroll
                            {
                                max-height: 40vh !important;
                            }
                        }
                        .table-wrapper
                        {
                            padding: 0 15px 15px;
                            .heading-holder
                            {
                                display: flex;
                                padding: 10px;
                                padding-bottom: 0;
                                h3
                                {
                                    text-align: left;
                                    padding: 0 25px 5px 0;
                                    span
                                    {
                                        color: #888888;
                                    }
                                }
                            }
                            .extended-table.have-table-icons
                            {
                                margin-top: 40px;
                            }
                            .valispace-components-view, .valispace-review-view
                            {
                                margin-top: 36px;
                                padding-right: 12px;
                                .indexes
                                {
                                    svg
                                    {
                                        width: 15px;
                                        height: 15px;
                                        cursor: pointer;
                                    }
                                }
                                #extended-data-table-wrapper
                                {
                                    th
                                    {
                                        span
                                        {
                                            display: inline;
                                        }
                                    }
                                    tr
                                    {
                                        cursor: default;
                                        .inline-image-viewer
                                        {
                                            .default-img-icon
                                            {
                                                cursor: default;
                                            }
                                        }
                                    }
                                }
                            }
                            .valispace-review-view
                            {
                                padding-right: 12px;
                                margin-top: 0;
                            }
                            .scrollbar-container
                            {
                                .ps__rail-y
                                {
                                    margin-top: 40px;
                                }
                            }
                        }
                    }
                }
            }
            .modal-close
            {
                .ui-icon
                {
                    display: block !important;
                }
            }
        }
    }
}

body[data-modal='valispace-component-bd open']
{
    .scrollbar-container.custom-scroll
    {
        position: unset;
        height: unset;
    }
}

.rc-tooltip
{
    .rc-tooltip-content
    {
        .rc-tooltip-inner
        {
            .ui-link 
            {
                .existing-cmp-cpn
                {
                    color: $GREEN;
                    &:hover
                    {
                        color: $FONT_COLOR;
                        .open-link-icon
                        {
                            g
                            {
                                fill: $FONT_COLOR;
                            }
                        }
                    }
                    .open-link-icon
                    {
                        g
                        {
                            fill: $GREEN;
                        }
                    }
                }
            }
        }
    }
}
