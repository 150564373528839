@import "../../../../../styles/variables";

.export-side-area
{
    .scrollbar-container.vertical {
        display:none;
    }
    &:hover {
        .scrollbar-container.vertical {
            display:block;
        }
    }


    flex       : 0 0 200px;
    text-align : right;
    position: initial;
    overflow: hidden;

    .trigger
    {
        cursor : pointer;

        *
        {
            pointer-events: none;
        }

        .ui-icon
        {
            width       : 9px;
            height      : 9px;
            margin-left : 8px;
            transform: rotate(90deg);

            svg
            {
                fill: $GREEN;
            }
        }
        &.open
        {
            .ui-icon
            {
                padding-bottom: 14px;
            }
        }
    }

    .modal
    {
        position      : absolute;
        // box-shadow    : 0px 0px 10px 0px rgba(0,0,0,0.5);
        z-index       : 1;
        top           : 0px;
        height        : 100vh;
        overflow      : inherit;
        width         : 0px;
        display       : block;
        font-size     : 14px;
        background    : #353540;
        > div
        {
            padding    : 0px 0 26px;
            text-align : left;
            h3 {
                font-size: 14px;
            }
        }
        .scrollarea {
            overflow: inherit;
            .scrollarea-content {
                overflow: initial;
                .export-content-holder {
                    padding-top: 11px;
                }
            }
        }

        .header-block
        {
            padding-top    : 31px;
            padding-bottom : 14px;
            padding-right  : 15px;
            padding-left   : 30px;
            border-bottom  : 1px solid #000;
            .close-icon{
                position: absolute;
                right: 30px;
                top: 5px;

                .ui-icon{
                    width: 10px;
                    height: 10px;
                }
            }
            h2{
                padding-bottom: 33px;
                font-size: 19px;
                font-weight: normal;
                width: 135px;
                height: 18px;
            }
            .export-button-wrapper{
                background: #41414B;
                align-items: center;
                height: 35px;
                width: 120%;
                margin-top: 29px;
                left: -30px;    
                button {
                    margin-left: 154px;
                    margin-top: 4.5px;
                    padding: 1px 0 0;
                    min-width: 100px;
                    min-height: 24px;
                }
            }
            .select-template {
                display: flex;
                align-items: center;
                margin-bottom: 20px;
                .ui-icon {
                    height: 16px;
                    width: 15px;
                    margin-left: 10px;
                    svg {
                        g {
                            fill: #3CD1BF;
                        }
                    }
                }
                .template {
                    width: 100%;
                    max-width: 193px;
                }
            }
            .save-export-template {
                display: flex;
                justify-content: space-between;
                max-width: 193px;
                align-items: center;
            }
        }

        label
        {
            font-size : 14px;
            color     : $GREY;
        }

        input
        {
            margin-bottom: 10px;
            line-height: 10px;
            padding: 6px 10px 7px;
        }

        &.open
        {
            width         : 270px !important;
            transition    : 0.1s;
            right         : 0;
            border-right  : 1px solid $BG_DARK;
            border-left   : 1px solid $BG_DARK;
            border-bottom : 1px solid $BG_DARK;
        }
        .components-fields {
            .required-fields-list {
                padding: 25px 0;
                list-style: none;
                li {
                    margin-bottom: 5px;
                }
            }
        }
        .custom-muted-text {
            color: $FONT_LIGHT;
        }
        .note {
            margin-bottom: 50px;
        }
        .cutom-checkbox{
            padding-top: 20px;
            padding-bottom: 10px;
            display: flex;
            span{
                margin-left: 10px;
            }
            label.middle:after {
                content: "__";
                width: 100%;
                margin: 0 auto;
                color: #3CD1BF;
                position: absolute;
                left: 4%;
                bottom: 3px;
            }
        }
    }
    .export-depth,
    .export-block{
        padding: 11px 30px 8px;
        .add-emails {
            display: flex;
            align-items: flex-end;
            .ui-icon {
                height: 16px;
                width: 20px;
                margin-right: 10px;
                svg {
                    g {
                        fill: #3CD1BF;
                    }
                }
            }
        }
    }
    .file-to-include {
        /*border-top: 1px solid #000;*/
        /*padding: 11px 30px 15px;*/
        padding: 0px 30px 15px;
        h3 {
            margin-bottom: 15px;
        }
    }
    .revision-to-include {
        padding: 0 30px 15px;
    }
    .is-mode-revision {
        border-top: none;
        padding-top: 0;
    }
    .customize-fields {
        display: flex;
        align-items: flex-end;
        .ui-icon {
            height: 16px;
            width: 15px;
            margin-right: 10px;
            svg {
                g {
                    fill: #3CD1BF;
                }
            }
        }
        &.disabled {
            .ui-icon {
                cursor: default;
                svg {
                    g {
                        fill: #979797;
                    }
                }
            }
            span {
                color: #979797;
            }
        }
    }
    .documents-to-include{
        padding: 11px 30px 15px;
        border-top: 1px solid #000;
        .cutom-checkbox {
            padding-top: 15px;
            padding-bottom: 5px;
        }
    }
    .export-block,.export-depth{
        border-top:1px solid #000;
    }
    .mail-block {
        overflow: hidden;
        padding-bottom: 20px;
        textarea {
            background: transparent;
            border: 1px solid #979797;
            resize: none;
            margin-bottom: 20px;
            color: #979797;
            height: auto;
            min-height: 70px;
            font-size: $FONT_SIZE_DEFAULT;
            &:focus.invalid
            {
                border-color: $ERRORS;
                background: $ERRORS;
                color: $FONT_COLOR;
            }
            &:focus
            {
                background: $BG_WHITE;
                color: #000;
                outline:none
            }

        }
        // button {
        //     float: right;
        //     padding: 6px;
        // }
        .cc-block {
            input[type="checkbox"]:not(old) + label {
                width: 15px;
                height: 15px;
            }
            label {
                margin-right: 7px;
            }
            span {
                &.disabled
                {
                    color: #979797;
                }
                vertical-align: text-bottom;
            }
        }
    }

    .export-depth {
        &.sourcing-block {
            .cutom-checkbox {
                padding: 0px;
            }
            h3 {
                font-weight: bold;
                margin-bottom: 13px;
            }
            .disabled {
                &.cutom-checkbox {
                    span {
                        color: #979797;
                    }
                    input[type="checkbox"]:not(old) + label {
                        border: 1px solid #979797;
                    }
                }
            }
        }
    }
    .export-block {
        span
        {
            display: block;
        }
        .cc-span
        {
            color: #979797;
            margin-bottom: 6px;
            display: block;
        }
        .export-block-heading
        {
            margin: 10px 0;
            span {
                display: block;
            }
        }
    }
    .custom-radio {
        padding : 10px 0 0 0px;
        .disabled
        {
            label, span
            {
                color: #979797;
            }
        }
        .large
        {
            display: flex;
        }
        .pl-22
        {
            padding-left: 22px;
        }
        .ml-10
        {
            margin-left: 10px;
        }

    }

    .disableccEmails{
        .export-block-heading, .cc-span, .mail-block, textarea{
            color: #979797;
            pointer-events:none;
            &::placeholder{
                color: #979797;
            }
        }
    }

    .custom-dropdown{
        margin: 0 30px 20px;
        .dropbtn {
            background-color: $BG_LIGHT2;
            padding: 5px 10px 3px;
            font-size: $FONT_SIZE_DEFAULT;
            line-height: 20px;
            cursor: pointer;
            text-align: left;
            width: 100%;
            display: block;
            border-radius: 0;
            text-transform: capitalize;
            border: 1px solid $GREY_LIGHT3;
            color: $FONT_COLOR;
            span {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 92%;
                display: inline-block;
            }
            .ui-icon{
                width: 10px;
                height: 10px;
                float: right;
                svg {
                    fill: $FONT_COLOR!important;
                }
            }
        }

        .dropbtn:focus {
            background-color: $FONT_COLOR;
            color: #000;
            .ui-icon{
                width: 10px;
                height: 10px;
                float: right;
                svg {
                    fill: #000 !important;
                }
            }
        }

        .dropdown {
            position: relative;
            display: block;
        }

        .dropdown-content {
            display: none;
            width: 100%;
            position: absolute;
            background-color: $FONT_COLOR;
            min-width: 160px;
            overflow: auto;
            box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
            z-index: 1;
            border-top: 0px;
            border-right: 1px solid $GREY_LIGHT3;
            border-left: 1px solid $GREY_LIGHT3;
            border-bottom: 1px solid $GREY_LIGHT3;
            max-height: 30vh;
            overflow-y:scroll;
            &.open{
                display: block;
            }
        }

        .dropdown-content a {
            color: #000;
            padding: 10px 15px 10px 30px;
            text-decoration: none;
            display: block;
            border:0;
            background-color: $FONT_COLOR;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 99%;
            &:hover {
                color:$GREEN;
            }
        }
    }
        .export-progress-modal, .email-sent-modal
        {
            .background{
                background: $BG_LIGHT3;
                opacity: 0.9;
            }
            .modal {
                height: auto;
                min-height: 400px;
                top: 15%;
                .modal-content {
                    padding-top: 90px;
                    .progress-bar-holder{
                        .progressbar-container{
                            margin: 0 auto 35px;
                        }

                    }
                }
                .progress-bar-text-holder{
                    h2{
                        font-weight: normal;
                    }
                }
            }

            .email-sent-block {
                text-align: center;
                .ui-icon {
                    width: 80px;
                    height: 68px;
                    margin-bottom: 30px;
                }
                h1 {
                    margin-bottom: 15px;
                }
                .email-list-scroll {
                    height: 103px;
                    overflow:hidden;
                    text-align: left;
                    .scrollbar-container.vertical {
                        display:none;
                    }
                    &:hover {
                        .scrollbar-container.vertical {
                            display:block;
                        }
                    }
                    .email-list-content
                    {
                        margin: 0px auto;
                        margin-left: auto !important;
                        margin-right: auto !important;
                        width: max-content;
                    }
                }
                .email-info{
                    max-width: 447px;
                    margin: 0 auto;
                    font-size: $FONT_SIZE_DEFAULT;
                    line-height: 17px;
                    .modal-text {
                        width: 100%;
                        display: inline-block;
                        .notification-time-section{
                            display: flex;
                            justify-content: center;
                            .notification-loader
                            {
                                margin-left: 5px;
                                @keyframes rotate
                                {
                                    from {transform: rotate(0deg);}
                                    to {transform: rotate(360deg);}
                                }
                                width                     : 16px;
                                height                    : 16px;
                                animation-name            : rotate;
                                animation-duration        : 0.4s;
                                animation-iteration-count : infinite;
                                animation-timing-function : linear;
                                .ui-icon
                                {
                                    width  : 16px;
                                    height : 16px;
                                    svg
                                    {
                                        height: 16px;
                                        width: 16px;
                                        fill: #3CD1BF;
                                    }
                                }
                            }
                        }
                        .content{
                            margin-bottom: 5px;
                        }
                    }
                }
                .modal-btn{
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    padding-top: 28px;
                    padding-right: 30px;
                    button{
                        height: 30px;
                        width: 138px;
                        padding: 0;
                        background: $GREEN;
                        color: $FONT_LIGHT;
                    }
                }
            }
        }
        .email-sent-modal {
            h1{
                font-size: $FONT_SIZE_23;
            }
            p{
            }
            .modal {
                min-width: 506px;
                min-height: 374px;
                padding-bottom: 0;
            }
    }

}
