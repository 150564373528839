@import "../../../../styles/variables";

body
{
    .vendor-index-element
    {
        width: 22px;
        height: 22px;
        border-radius: 100%;
        background-color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        background: $BLUE_TOOL_TIP_COLOR;
        .vendor-index-element-icon
        {
            height: 15px;
            width: 15px;
            .vendor-label-icon
            {
                width: 17px;
                height: 14px;
            }
        }
    }
}
