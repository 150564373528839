@import "../../../styles/variables";

.ui-image-viewer
{
    user-select : none;
    position    : relative;
    width       : 185px;

    .remove-btn
    {
        cursor        : pointer;
        position      : absolute;
        left          : -13px;
        top           : -13px;
        width         : 25px;
        height        : 25px;
        background    : $GREEN;
        border-radius : 100%;
        z-index       : 2;
        display       : none;

        .ui-icon
        {
            width       : 9px;
            margin-left : 0px;
            margin-top  : 0px;

            svg
            {
                width  : 26px;
                height : 27px;
            }
        }
    }

    .images
    {
        position   : relative;
        width      : 185px;
        height     : 145px;
        overflow   : hidden;

        .image
        {
            opacity             : 0;
            position            : absolute;
            left                : 0;
            top                 : 0;
            width               : 100%;
            height              : 100%;
            background-size     : contain;
            background-position : center;
            background-repeat   : no-repeat;
            background-color    : white;

            .image-copy
            {
                display: none;
            }

            &.slide-from-left
            {
                transition: left 0s;
                left : -100%;
            }

            &.slide-from-right
            {
                transition: left 0s;
                left : 100%;
            }

            &.selected
            {
                transition : left 0.07s ease-out;
                left       : 0;
                opacity    : 1;
            }
        }
    }

    .controls
    {
        display         : flex;
        justify-content : space-between;
        height          : 40px;
        overflow        : hidden;
        background      : white;

        div
        {
            display: table-cell;
        }

        .arrow
        {
            width        : 40px;
            height       : 40px;
            padding-left : 11px;
            padding-top  : 12px;
            cursor       : pointer;

            .ui-icon
            {
                width: 17px;

                path, polygon
                {
                    fill: $BG_LIGHT;
                }
            }

            &:hover
            {
                background: $GREY_LIGHT;
            }

            &.previous
            {
                .ui-icon
                {
                    transform: scaleX(-1);
                }
            }
            &.next
            {

            }

        }

        .count
        {
            padding-top : 15px;
            width       : 100px;
            text-align  : center;
            color       : $FONT_LIGHT;

            .of
            {
                font-size : $FONT_SIZE_12;
                padding   : 0 5px;
            }
        }
    }

    .primary-block
    {
        display    : none;
        margin-top : 10px;

        > span
        {
            margin-left: 10px;
            font-style: italic;
            color: $GREY;
        }
    }

    .drop-zone
    {
        display    : none;
        cursor     : pointer;
        overflow   : hidden;
        height     : 40px;
        border     : 1px dashed white;
        margin-top : 15px;

        > div:first-child
        {
            display         : flex;
            padding-left    : 12px;
            padding-right   : 12px;
            font-size       : 12px;
            align-items     : center;
            height          : 100%;
            justify-content : space-between;

            > div:first-child
            {
                span:nth-child(2)
                {
                    margin-left     : 10px;
                    font-size       : 14px;
                    color           : $GREY;
                    text-decoration : underline;
                }
            }
        }

        .modal
        {
            display : none;
            height  : 100%;
            width   : 100%;
        }

        &.accept-files
        {
            padding: 5px;

            > div:first-child
            {
                display: none;
            }

            .modal
            {
                display    : block;
                background : $GREEN;
            }
        }

        &.reject-files
        {
            padding: 5px;

            > div:first-child
            {
                display: none;
            }

            .modal
            {
                display    : block;
                background : $ERRORS;
            }
        }
    }

    &.edit
    {
        .remove-btn
        {
            display: block;
        }

        .primary-block
        {
            display: block;
        }

        .drop-zone
        {
            display: block;
        }
    }

    &.single
    {
        .controls
        {
            display: none;
        }

        .primary-block
        {
            display: none;
        }
    }

    &.empty
    {
        .remove-btn
        {
            display: none;
        }

        .controls
        {
            display: none;
        }

        .primary-block
        {
            display: none;
        }

        .images
        {
            background      : none;
            border          : 1px solid white;
            display         : flex;
            align-items     : center;
            justify-content : center;

            .image-copy
            {
                display: block;
            }
        }
    }
}
