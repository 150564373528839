@import "../../../../../styles/variables";

.groups-settings-route .group-list
{
  padding-bottom: 38px;
  font-size: 14px;
  .group-header{
    display: inline-block;
    border-bottom: 1.5px solid #979797;
    padding-bottom: 15px;
    width: 100%;

    .title{
      display: inline-block;
      font-size: $FONT_SIZE_19;
      color: $GREY_LIGHT3;
      font-weight: bold;
      text-transform: initial;
    }

    .add-btn-holder{
      display: inline-block;
      float: right;
      color: $GREEN;
      cursor: pointer;
    }
  }

  table {
    color: $GREY_LIGHT3;
    td{
      padding: 10px 20px 11px 0;
      border-bottom: 1px solid #979797;
      &:nth-child(1){
        color: $FONT_COLOR;
        width: 180px;
      }

      &:nth-child(3){
        text-align: right;
      }
      &:last-child{
        padding-right: 0;
      }
      &.no-users{
        color: $GREY_LIGHT3;
        text-align: left;
      }
      &.remove-col {
        width: 30px;
        .ui-icon{
          width: 22px;
          height: 23px;
          svg{
            width: 22px;
            height: 23px;
          }
        }
      }

    }
  }
  .ui-modal-box.remove-user{

    .modal {
      max-width: 506px;
      max-height: 252px;
      height: auto;
      padding-bottom: 0;
      .modal-content {
        padding: 47px 42px 30px;
        h1{
          margin-bottom: 10px;
        }
        h3{
          font-size: 14px;
          margin-bottom: 10px;
        }
        .info{
          max-width: 357px;
          p{
            color: $FONT_LIGHT;
            font-style: italic;
            font-size: 14px;
          }
        }
        .buttons.modal-footer {
          text-align: right;
          margin-top: 20px;

          button {
            padding: 6px 0;
            width: 125px;
            margin: 0 5px;
          }
        }
      }
    }
  }
}
