@import "../../../../../styles/variables";

.groups-settings-route .add-users-modal
{
  .ui-modal-box.existing-users-modal
  {
    align-items: baseline;

    color: $FONT_COLOR;
    .modal
    {
      max-width: 353px;
      margin: 0 auto;
      position: relative;
      padding: 0;
      height: auto;
      min-height: 279px;
      max-height: 527px;
      top: 130px;
      .modal-content
      {
        padding: 32px 44px;
        height: 100%;
        position: initial;
        h1{
          font-size: $FONT_SIZE_23;
          margin-bottom: 0px;
        }
        hr{
           margin: 15px 0;
           border-color: #979797;
        }
        .group-name{
          text-transform: capitalize;
          padding-bottom: 10px;
        }
        .ui-message {
          height: auto;
        }
        .button-section{
          padding-bottom: 25px;
          button, input[type="submit"] {
            padding: 6px 0;
            width: 125px;
          }
          input[type="submit"]{
            float: right;
          }
        }

        span{
          &.green{
            display: inline-block;
            cursor: pointer;
          }
        }
        .user-list{
          &.large{
            max-height: 250px;
            overflow: auto;
            margin-bottom: 10px;
          }
          .list{
            border-bottom: 1px solid #979797;
            padding: 10px;

            &:first-child{
              padding-top: 0 !important;
            }
            .title-area{
              max-width: 230px;
              display: inline-block;
            }
            .selector{
              display: inline-block;
              float: right;
              top: 10px;
            }
          }
        }
      }
    }
  }
}
