@import "../../../../../../../styles/variables";
.new-component-file-route{
    .app-row-table{
        &.extended-table{
            display: initial;
            table{
                position: inherit;
            }
        }
    }

    .review-screen
    {
        display: flex;
        flex-direction: column;
        overflow: hidden;

        .hidden
        {
            display: none !important;
        }
        .app-row.app-row-block {
            padding-top: 50px;
        }
        .app-row-block {
            padding-top: 75px;
            padding-bottom: 50px;
        }
        .app-row
        {
            .app-row-heading {
                h1 {
                    margin-bottom: 15px;
                }
                p {
                    margin-bottom: 15px;
                }
                .bulk-category{
                    margin-bottom: 25px;
                    width: 248px;
                    margin-left: 110px;
                }
            }
        }
        .required-field-text{
            display: inline-block;
        }
        .pagination-area{
            display: inline-block;
            float: right;
        }

        table{
            // width: 100%;
            th, td{
                // width: 100%;
                // width: 175px;
            }
        }
        .inner{
            margin-left: 99px;
            position: initial;
        }

        .table-holder
        {
            padding     : 10px;
            // th, td{
            //     width: 175px;
            // }
            .inner{
                margin-left: 100px;
                flex-shrink: 0;
            }
        }
        .inner.no-checkbox{
            margin-left: 75px;
        }
        .inner.table-content {
            overflow-x:auto;
            overflow-x: auto;
            overflow-y: hidden;
            padding-bottom: 20px;
        }
        .table-content-wrapper{
            margin-top: -1px;
            overflow-x: hidden;
            overflow-y: auto;
            .mpn-input{
                display: flex;
                background-color: $FONT_COLOR;
                input{
                    outline: none;
                }
                .ui-icon {
                    background-color: $FONT_COLOR;
                    padding-top: 10px;
                    padding-right: 5px;
                    width: 21px;;
                    height: auto;
                    &.disabled
                    {
                        opacity: 1;
                        pointer-events: none;
                        background: #4A4A54;
                        &::before{
                            opacity: .5;
                        }
                    }
                    &.disabled.selected-from-library
                    {
                        background: #bfbfc6;
                    }
                }
            }
        }
        .table-headings{
            overflow: hidden;
            th{
                &.first-child{
                    top: 0;
                }
                &.second-child{
                    border-bottom: 0;
                }
            }
        }
    }

    .hidden
    {
        display: none;
    }

    .col-move:hover{
        border-right: 1px solid $GREEN;
    }
}
.review-screen-modal
{
    .ui-modal-box
    {
        align-items     : center;
    }

    .modal {
        height: 85%;
        width: 93.7%;
        top: auto;
        display: flex;
        flex-direction: column;

        .modal-content {
            display: flex;
            flex-direction: column;
            overflow: hidden;
        }

        .app-row.app-row-block {
            padding: 25px 18px !important;
            display: flex;
            flex-direction: column;
            overflow: hidden;
            width: 100%;
            .app-row-table {
                margin: 0;
                padding: 0;
                display: flex;
                flex-direction: column;
                overflow: hidden;
            }
        }
        .buttons.modal-close {
            top:-24px;
            right:-19px;
        }
        .header-heading{
            .spinner,
            .ui-icon,
            .ui-icon svg
            {
                width  : 20px;
                height : 20px;
            }
        }
        .app-row-heading {
            overflow: hidden;
            padding: 0 20px;
            flex-shrink: 0;
            h1{
                float: left;
                margin-bottom: 0 !important;
            }
            .required-field-text {
                float: right;
                margin-bottom: 0 !important;
                font-size: $FONT_SIZE_DEFAULT;
                text-transform: capitalize;
            }
        }
        .bulk-action-block {
            overflow: hidden;
            flex-shrink: 0;
            margin: 15px 20px;
            .check-holder{
                margin: 0 12px 0 ;
            }
            .bulk-category {
                width: 140px;
                height: 24px;
                font-size: $FONT_SIZE_DEFAULT;
                line-height: 14px;
                padding: 0 10px;
                border: 1px solid $GREY_LIGHT3 !important;
                margin-left: 78px;
                display: inline-block;
            }
        }
        .import-table-icons
        {
            left: 65px;
        }
        .import-table-icons-with-use-existing
        {
            left: 75px;
        }
        .bulk-category.select-disable {
            background-image    : url("../../../../../../../assets/icons/arrow-select-white.svg") !important;
            background-size: 10px !important;
            background-position: right 8px center !important;
            background-repeat: no-repeat !important;
            color: $FONT_COLOR !important;
            background-color: $BG_LIGHT2 !important;
        }
        .review-screen-content{
            padding-right: 10px;
        }
        table {
            border: 0;
            border-collapse: inherit;
            tr {
                border: 0;

                th, td{
                    border-right: 1px solid $GREY_LIGHT3;
                    border-top: 1px solid $GREY_LIGHT3;
                    border-left: 0;
                    border-bottom: 0px;
                    &:nth-child(2){
                        border-left: 1px solid $GREY_LIGHT3;
                    }
                    > div.icon-holder{
                        height: 100%;
                        input{
                            height: 100%;
                            overflow-x: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }
                    }
                    &.first-child{
                        height: 36px;
                        padding-top: 9px;
                        position:absolute;
                        left:0;
                        width:60px;
                        border: 0px !important;
                        background: none;
                        .ui-icon  {
                            width: 20px;
                            height: 13px;
                            display: inline-block;
                            margin-top: 3px;
                            float: left;
                        }
                        .ui-toggle-btn{
                            float: right;
                        }
                        .error-circle {
                            border-radius: 100%;
                            background: #F54A54;
                            width: 10px;
                            height: 10px;
                            display: inline-block;
                            margin-top: 3px;
                        }
                    }
                    &.second-child {
                        height: 36px;
                        position:absolute;
                        padding-top: 9px;
                        left:65px;
                        width:35px;
                        text-align: center;
                        .ui-icon  {
                            &:hover{
                                svg{
                                    g
                                    {
                                        g{
                                            stroke: $GREEN;
                                        }

                                    }
                                }
                            }
                            display: inline;
                            vertical-align: -webkit-baseline-middle;
                        }
                        &.selected {
                            .index-number {
                                display: none;
                            }
                            .index-checkbox.hidden {
                                display: block !important;
                            }
                        }
                    }

                    .valid{
                        border: none;
                    }
                    .text-inputs,
                    select {
                        height: 100%;
                        min-height: 36px;
                        padding: 0px 20px 0 10px !important;
                        &.invalid {
                            background-color: $ERRORS;
                            color: $GREY;
                            option{
                                background: rgba(255,255,255,0.9);

                            }
                        }
                    }
                    .hidden-text-value
                    {
                        visibility: hidden;
                    }
                }

                &:hover {
                    .second-child {
                        .index-number {
                            display: none;
                        }
                        .index-checkbox.hidden {
                            display: block !important;
                        }
                    }
                    td
                    {
                        border-top: 1px solid $GREEN;
                        &.first-child,
                        &.second-child {
                            border-top: 1px solid $GREY_LIGHT3;
                            &:after {
                                opacity: 0;
                            }
                        }
                        &:after {
                            opacity: 9;
                        }
                    }
                }
                td
                {
                    position: relative;
                    &:after {
                        opacity: 0;
                        content: "";
                        border-bottom: 1px solid $GREEN;
                        position: absolute;
                        bottom: -1px;
                        left: 0;
                        right: 0;
                        width: 100%;
                        height: 1px;
                        z-index: 1;
                    }
                }
                &:last-child{
                    th,td{
                        border-bottom: 1px solid $GREY_LIGHT3;
                    }
                }

                th{
                    padding-left: 10px;
                    &.second-child{
                        top: 0;
                        padding-left: 0;
                    }
                    @-moz-document url-prefix() {
                        &.second-child{
                            top:13px;
                        }
                    }
                    @-moz-document url-prefix() {
                        &.first-child{
                            .ui-toggle-btn {
                                margin-top: 15px;
                            }
                        }
                    }
                }
            }
        }
    }

    .new-warning-modal
    {
        .ui-modal-box
        {
            z-index: 1000000;
            .modal
            {
                height: auto;
                top: -15%;
            }
        }
    }

    .ui-toggle-btn{
        width: 36px;
        > div {
            height: 12px;
            width: 13px;
            margin-left: 1px;
        }
        &.selected{
            > div {
                margin-left: 22px;
            }
        }
    }

}
body{
    .dark-tooltip{
        .rc-tooltip-content
        {
            font-size: 14px;
            font-family: $FONT_FAMILY;
            .rc-tooltip-arrow
            {
                border-top-color: $BG_LIGHT3 !important
            }
            .rc-tooltip-inner
            {
                color:  $FONT_COLOR !important;
                background-color: $BG_LIGHT3 !important;
                border: 1px solid $BG_LIGHT3 !important
            }
        }
    }
    .not-revision-managed
    {
        .rc-tooltip-content
        {
            .rc-tooltip-inner
            {
                white-space: pre-wrap;
            }
        }
    }

    .span-text-content
    {
        font-size: 11px;
    }
}

