@import "../../../../../styles/variables";
.integrations-table
{
    .ui-modal-box
    {
        .modal
        {
            .modal-content
            {
                .mcmaster-form-block
                {
                    p{
                        color: #F54A4F;
                        text-align: center;
                        margin-bottom: 10px;
                        margin-top: 10px;
                        font-size: 16px;
                    }

                    input{
                        margin-bottom: 18px;
                        font-size: 16px;
                    }
                    .field-block
                    {
                        .upload-metadata-block
                        {
                            margin-top: 10px;
                            .drop-zone {
                                border: 1px dashed $FONT_LIGHT;
                                display: flex;
                                align-items: center;
                                height: auto;
                                padding: 6px 15px;
                                .modal
                                {
                                    display: none;
                                    height: 100%;
                                    width: 100%;
                                }
                            }
                            .collapsed-block
                            {
                                display: flex;
                                align-items: center;
                                padding: 0;
                                .ui-icon
                                {
                                    width: 20px;
                                    height: 20px;
                                    margin:0 10px 0 0;
                                    svg
                                    {
                                        fill: $FONT_LIGHT;
                                    }
                                }
                                a{
                                    margin-left: 5px;
                                }
                            }
                            .uploaded-file
                            {
                                margin: 15px 0;
                                .inner-block
                                {
                                    padding: 0 15px;
                                    justify-content: space-between;
                                    display: flex;
                                    align-items: center;
                                    .file-name
                                    {
                                        color: $GREEN;
                                        margin-right: 10px;
                                    }
                                    .file-remove
                                    {
                                        height: 17px;
                                        width: 17px;
                                    }
                                }
                            }
                            .documents-block
                            {
                                .upload-file-list
                                {
                                    display: flex;
                                    justify-content: space-between;
                                    margin-top: 10px;
                                    .cross-size-holder
                                    {
                                        display: flex;
                                        .delete-icon
                                        {
                                            width: 16px;
                                            margin-left: 5px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .btn-holder {
                        width: 100%;
                        display: flex;
                        margin-top: 15px;
                        .btn-container{
                            button{
                                width: 100px;
                                padding: 6px 12px;
                                &:nth-child(2){
                                    margin-left: 10px;
                                    min-width: 83px;
                                }
                                &.disable {
                                    color: #888888;
                                    border-color: #888888;
                                    pointer-events: none;
                                    &:hover {
                                        color: none;
                                        background: none;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
