.unlink-modal{
    .modal{
        width: 421px;
        padding: 28px 44px;

        h2
        {
            margin-bottom: 15px;
            font-size: 24px;
            font-weight: bold;
        }

        p{
            .ui-icon{
                width: 30px;
            }
            margin-bottom: 30px;
        }
        .top-section{
            border-bottom:1px solid #979797;
            margin-bottom:20px;
        }
        .bottom-section{
            display: flex;
            float: right;
        }
        .bottom-section
        {
            button
            {
                padding: 8px 12px;
            }

            .filled.allow
            {
                margin-left: 15px;
                background-color: #F54A4F;
                border-color: #F54A4F;
                color:  #fff;
                &:hover
                {
                    background-color: transparent;
                    color:  #F55A5F;
                }
            }
        }
    }
}
