@import "../../../../../../../styles/variables";

.upload-side-area
{
    .modal {
        padding: 0;
        border-radius: 8px;
        max-width: 564px;
        width: 100%;
        min-width: auto;
        margin-top: -30px;
        .modal-content {
            width: 100%;
            .upload-modal-header {
                background: #3CD1B5;
                padding: 17px;
                display: flex;
                .ui-icon {
                    width: 20px;
                    height: 16px;
                }
                h3{
                    color: #000;
                }
            }
            .main-block {
                padding: 25px;
                margin-top: 0;
                .content-block {
                    width: 100%;
                    .custom-modal-header {
                        .header-holder {
                            display: flex;
                            justify-content: space-between;
                            margin-bottom: 20px;
                            .heading-holder {
                                display: flex;
                                h2{
                                    margin-right: 10px;
                                }
                                .ui-icon
                                {
                                    margin-top: 5px;
                                }
                            }
                            .cpn-tag-holder {
                                display: flex;
                                align-items: center;
                                color: #888888;
                                h4{
                                    margin-right: 15px;
                                }
                                .cpn-tag {
                                    border: 1px solid #888888;
                                    padding: 6px 8px;
                                }
                            }
                        }
                    }
                    .card-panel {
                        overflow: hidden;
                        .card-panel-header {
                            padding: 10px 25px;
                            background-color: #41414A;
                            border-top-left-radius: 8px;
                            border-top-right-radius: 8px;
                            h3 {
                                color: #888888;
                                font-weight: bold;
                            }
                        }
                        .card-panel-body {
                            padding: 25px;
                            border-bottom-left-radius: 8px;
                            border-bottom-right-radius: 8px;
                            margin-bottom: 30px;
                            &.last-panel
                            {
                                margin-bottom: 0px;
                            }

                            background-color: #353540;
                            .types-holder {
                                h5{
                                    color: #888888;
                                    margin: 0 0 15px;
                                }
                                .custom-radio {
                                    display: flex;
                                    margin-bottom: 10px;
                                    .radio {
                                        flex:1;
                                        display: flex;
                                        align-items: center;
                                        &:nth-child(1){
                                                max-width: 190px;
                                        }
                                    }
                                }

                            }
                            .components-fields {
                                    margin-left: 20px;
                                    h4{
                                        color: #888888;
                                        margin-bottom: 10px;
                                    }
                                    ul{
                                        list-style: none;
                                        color: #888888;
                                        li{
                                            list-style: none;
                                            margin-bottom: 10px;
                                        }
                                    }
                                }
                                .drop-zone {
                                    border: 1px dashed #888888;
                                    padding: 25px;
                                    text-align: center;
                                    margin: 20px 0;
                                    .expanded-block {
                                        cursor: pointer;
                                        display: flex;
                                        justify-content: center;
                                        flex-direction: column;
                                        align-items: center;
                                        .expanded-holder {
                                            display: flex;
                                            .browse
                                            {
                                                color: $GREEN;
                                                margin-left: 5px;
                                            }
                                        }
                                        p
                                        {
                                            color: #888888;
                                            margin-bottom: 0;
                                        }
                                        .ui-icon{
                                            width: 20px;
                                            height: 20px;
                                            margin-right: 10px;
                                        }
                                    }
                                    .modal
                                    {
                                        display: none;

                                    }

                                    &.accept-files
                                    {
                                        background-color: #3CD1B5;
                                        p
                                        {
                                            color: #3CD1B5;
                                        }
                                        .expanded-holder
                                        {
                                            color: #3CD1B5;
                                            .ui-icon
                                            {
                                                svg
                                                {
                                                    polyline,
                                                    path,
                                                    line
                                                    {
                                                        stroke: #3CD1B5;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    &.reject-files
                                    {
                                        background-color: #f54a4f;
                                        p
                                        {
                                            color: #f54a4f;
                                        }
                                        .expanded-holder
                                        {
                                            color: #f54a4f;
                                            .browse
                                            {
                                                color: #f54a4f;
                                            }
                                            .ui-icon
                                            {
                                                svg
                                                {
                                                    polyline,
                                                    path,
                                                    line
                                                    {
                                                        stroke: #f54a4f;
                                                    }
                                                }
                                            }
                                        }
                                    }

                                }
                                .indent {
                                    margin: 0;
                                    ul{
                                        margin: 0;
                                        padding: 0;
                                        li{
                                            list-style: none;
                                            margin-bottom: 10px;
                                            .ui-icon{
                                                width: 20px;
                                                height: 20px;
                                            }
                                        }
                                    }
                                }

                            .new-component-category-types {
                                margin: 0 0 17px;
                                display: flex;
                                justify-content: flex-start;
                                .category-nav {
                                    margin-right: 35px;
                                    text-align: center;
                                    &:last-child {
                                        margin-right: 0;
                                    }
                                    &:hover,
                                    &.selected
                                    {
                                        cursor: pointer;
                                        .ui-icon
                                        {
                                            svg {
                                                path{
                                                    fill: #3cd1b5;
                                                }
                                            }
                                        }
                                        label
                                        {
                                            color: #3cd1b5;
                                        }
                                    }
                                    .ui-icon {
                                        width: 25px;
                                        height: 25px;
                                        svg {
                                            fill: #cfcfcf;
                                        }
                                    }
                                    label {
                                        color: #cfcfcf;
                                    }
                                }
                            }
                            .categoryType-heading {
                                margin-bottom: 20px;
                                color: #888888;
                                font-size: 14px;
                            }
                            .form-group
                            {
                                margin-bottom: 30px;
                                label
                                {
                                    color: #888;
                                    font-size: 14px;
                                    text-transform: uppercase;
                                }
                                .form-control
                                {
                                    height: auto;
                                    padding: 6px 10px;
                                    color: #888;
                                    min-height: 30px;
                                    border: 0;
                                }
                            }
                        }
                    }
                    .component-file-scroll
                    {
                        max-height: 60vh;
                        padding-right: 20px;
                        margin-right: -20px;
                        padding-bottom: 20px;
                    }
                }
                .card-panel-footer {
                    display: flex;
                    justify-content: space-between;
                    padding-top: 20px;

                    .left-section {
                        button {
                            padding: 6px 12px;
                        }
                    }
                    .right-section {
                        display: flex;
                        align-items: center;
                        .checkbox-label {
                            display: flex;
                            align-items: center;
                            label {
                                width: 15px;
                                height: 15px;
                            }
                            .label_value {
                                margin:0 15px;
                            }
                        }
                    }
                }
            }
            .simple-rc-tip
            {
                max-width: 250px;
                width: 100%;
            }
            .tmp-instruction
            {
                margin-bottom: 10px;
            }
        }
    }
}

.rc-tooltip {
  z-index: 99999999;
}
