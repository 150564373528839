@import "../../../../styles/variables";

.manage-templates-modal
{
    .modal-content
    {
        .modal-heading
        {
            padding: 30px 45px 0px 45px;
            h3{
                font-weight: bold;
                border-bottom:2px solid #28272B;
                padding-bottom: 12px;
            }
        }
        .modal-body
        {
            h3{
                color:#888888;
                font-weight: bold;
                padding:5px 0;
            }
            padding: 0 30px 0 45px;
            .modal-body-content,.modal-company-templates{
                .row-item{
                    &:nth-child(1){
                        border-top: 1px solid #28272B;    
                    }
                    border-bottom: 1px solid #28272B;
                    padding:5px 0 3px;
                    display: flex;
                    justify-content:space-between;
                    .ui-icon{
                        width:auto;
                        height: auto;
                        border:none;
                        padding:0;
                        svg{
                            height: 20px;
                            width:20px;
                        }
                    }
                }
            }
            .modal-company-templates{
                margin-bottom: 18px;
            }
            .btn-holder{
                display: block;
                .btn-container{
                    button{
                        border-radius: 8px;
                        min-width: 83px;
                        padding: 6px 12px;
                        &.dark{
                            margin-right: 10px;
                            min-width: 99px;
                        }
                    }
                }
            }
            .scrollbar-container {
                margin-bottom: 25px;

            }
            .templates-screen-scroll{
                max-height: 23vh;
                padding-right: 15px;
            }
        }
    }
    .ui-modal-box
    {
        margin-top: 90px;
        .background
        {
            background: transparent;
        }
        .modal
        {
            padding:0;
            background: #18171d;
            width: 100%;
            height: auto;
            max-width: 446px;
        }
    }
}
