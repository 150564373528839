@import "../../../../../styles/variables";


.edit-product-route,
.edit-component-route
{
    .tabs-block .sources-tab .sourcing-table-wrap
    {
        position: relative;
        margin-top: 48px;

        input, select{
            font-size: $FONT_SIZE_DEFAULT;
        }

        tr
        {
            .link-icon
            {
                width  : 17px;
                height : 16px;
                padding: 5px;
                svg
                {
                    fill: black;
                    height: 16px;
                }
            }

            &.add-row
            {
                td
                {
                    .ui-icon
                    {
                        margin-right : 10px;
                        width        : 11px;
                        height       : 11px;

                        svg
                        {
                            transition : fill 0.05s;
                            fill       : $GREEN;
                        }

                        &:hover
                        {
                            svg
                            {
                                fill: white;
                            }
                        }
                    }
                }
            }
        }

        table{
            width: 100%;
        }

        table tbody tr
        {
            th{
                &.sortable
                {
                    > div div:nth-child(2)
                    {
                        opacity: 1;
                    }
                }
            }
            td{
                padding: 0;
                input{
                    padding: 0 10px;
                }
                input[type="text"]
                {
                    height: 100%;
                }
            }
            th,
            td{
                height: 30px;
                background-color: $FONT_COLOR;

                &:first-child,
                &:last-child
                {
                    padding: 0 10px;
                }

                &.mpn-val-cell, &.dist-dpn-key-cell
                {
                    height: 100%;
                    div.invalid{
                        border-color: $ERRORS;
                        background: $ERRORS;
                        &.link-icon{
                            border-color: unset;
                            background: unset;
                            svg{
                                fill: $GREY_LIGHT3;
                            }
                        }
                    }
                    > div
                    {
                        height: 29px;
                        padding-right: 10px;
                        display  : flex;

                        input
                        {
                            &:first-child
                            {
                                margin-right : 10px;
                            }

                            &:last-child
                            {
                                display     :   none;
                                padding     : 8px 10px;
                                position    : absolute;
                                width       : 400px;
                                margin-top  : 20px;
                                margin-left : -30px;
                                border      : 1px solid $GREY;
                                box-shadow  : 0px 0px 10px 0px rgba(0,0,0,0.3);
                                z-index     : 2;

                                &.edit
                                {
                                    display : block;
                                }
                            }
                        }
                    }
                }
                &.datasheet-val-cell
                {
                    > div {
                        height: 100%;
                    }
                    padding-right: 0px;
                    .link-icon{
                        margin-top: 5px;
                        margin-right: 10px;
                        float: right;
                        padding: 0;
                    }
                    input
                    {
                        display     : none;
                        padding     : 8px 10px;
                        position    : absolute;
                        width       : 400px;
                        margin-top  : 0px;
                        margin-left : -120px;
                        border      : 1px solid $GREY;
                        box-shadow  : 0px 0px 10px 0px rgba(0,0,0,0.3);
                        z-index     : 2;

                        &.edit
                        {
                            display : block;
                        }
                    }
                }
                &.leadtime-inputs,
                &.warranty-inputs{
                    padding-left: 3px;
                    padding-right: 3px;
                    input[type='text']{
                        display: inline-block;
                        width: 43px;
                        height: 25px;
                        border: 1px solid $GREY_LIGHT3;
                    }

                    div.radio{
                        padding-left: 5px;
                        display: inline-block;
                        label{
                            margin-right: 5px;
                        }
                        span{
                            color: $FONT_LIGHT;
                        }
                    }
                    input[name='warranty']
                    {
                        text-align: center;
                        width: 55px;
                    }

                }
            }
            select{
                height: 28px;
                padding: 2px 10px;
            }
        }

        table{

            input[type="text"],
            select{

                &:hover,
                &:active,
                &:focus {
                    outline-color : $GREEN !important;
                    border  : 2px solid $GREEN !important;
                }
                &:hover{
                    cursor: pointer;
                }

                &:focus{
                    cursor: default;
                }

                &.error,
                &.invalid{
                    border  : 2px solid $ERRORS !important;
                    background: $ERRORS;
                }
            }
            select{
                height: 29px !important;
            }
        }
    }

    .tabs-block
    {
        .sources-tab
        {
            .add-mpn-block
            {
                display       : inline-block;
                margin-bottom: 22px;
                .ui-message{
                    height: 22px;
                }

                > div:nth-child(1)
                {
                    display         : flex;
                    justify-content : left;

                    input
                    {
                        width      : 400px;
                        background : $HIGHLIGHT;
                        padding-left: 30px;
                        color      : $FONT_COLOR;
                        min-height : 37px;

                        &::placeholder
                        {
                            color: $GREY;
                        }

                        &:focus
                        {
                            color      : black;
                            background : white;
                        }
                    }

                    .search-icon
                    {
                        width: 15px;
                        margin-left: 10px;
                        margin-top: 12px;
                        margin-right: -25px;
                        pointer-events: none;
                        z-index: 2;
                        svg
                        {
                            fill: $GREY;
                        }
                    }

                    button
                    {
                        padding-top    : 7px;
                        margin-left    : 20px;
                        padding-bottom : 7px;
                    }

                    .invalid
                    {
                        background: $ERRORS;
                        border-color: $ERRORS;
                        color: rgb(0, 0, 0);
                        &:focus
                        {
                            background: $ERRORS;
                            border-color: $ERRORS;
                            color: rgb(0, 0, 0);
                        }
                    }
                }
            }
            .sourcing-table-icons
            {
                left: 0px;
            }
        }
    }


    .link-icon.ui-icon.invalid svg{
        fill: $ERROR !important;
    }

    .text-block{
        vertical-align: middle;
    }
    .sourcing-table{
        td.remove-col{
            padding-left: 6px !important;
            .ui-icon
            {
                width: 16px;
                height: 16px;
                svg
                {
                    width: 16px;
                    height: 16px;
                }
            }
        }
        th,td {
            .handler {
                    display: flex;
                    align-items: center;
                    justify-content: center;
            }
            .primary-source-checkbox {
                input[type="checkbox"]:not(old) {
                     width: 15px;
                        height: 15px;
                        font-size: 0;
                        opacity: 0;
                        cursor: pointer;
                        position: absolute;
                        top: 7px;
                        left: 0;
                        right: 0;
                        padding: 0 !important;
                        margin: 0 auto;
                    & + label
                    {
                        display: inline-block;
                        width: 15px;
                        height: 15px;
                        border: 1px solid #B3B3B3;
                        margin-left: -2px;
                        margin-top: 3px;
                        pointer-events: none;
                        margin-bottom: 0;
                    }
                }
            }

        }
    }

    td, tr{
        &.expand-btn
        {
            background-color: $TABLE_CELL_BG !important;
            td{
                background-color: $TABLE_CELL_BG !important;
            }
        }
    }


    tr td table tr td:first-child,
    tr td table tr th:first-child{
        background: $TABLE_CELL_BG !important;
        background-color: $TABLE_CELL_BG !important;
    }
    tr.expandable{
        > td:first-child{
            background-color: $BG_LIGHT !important;
        }
    }

    td, tr{
        &.add-row
        {
            td,
            th
            {
                &:first-child{
                    background-color: $BG_LIGHT !important;
                }
                background-color: $BG_LIGHT !important;

                .add-row-text{
                    visibility: hidden;
                    opacity: 0;
                    transition: visibility 0s, opacity 0.1s linear;
                }

            }
            &:hover{
                cursor: pointer;
                td
                {
                    &:first-child{
                        background-color: rgba(136, 136, 136, 0.5) !important;
                    }
                    background-color: rgba(136, 136, 136, 0.5) !important;
                    &:last-child{
                        background-color: $BG_LIGHT !important;
                    }
                    .add-row-text{
                        opacity: 0.5;
                        padding-left: 10px;
                        visibility: visible;
                    }
                }
            }
        }
    }

    .sources-tab table tr
    {
        &.primary-source
        {
            td{
                &.mpn-val-cell{
                    > div {
                        background-color: $TABLE_CELL_SUCCESS;
                        input:not(.invalid):not(.edit){
                            background-color: transparent;
                        }
                    }
                }
                &.datasheet-val-cell{
                    > div {
                        background-color: $TABLE_CELL_SUCCESS;
                        input{
                            background-color: transparent;
                        }
                    }
                }
                &:not(.remove-col):not(.mpn-val-cell):not(.datasheet-val-cell){
                    input:not(.invalid):not(.edit){
                        background-color: $TABLE_CELL_SUCCESS;
                    }
                }
            }
        }

        td.remove-col,
        th:last-child
        {
            background-color: $TABLE_CELL_BG !important;
        }

    }

    td.expand-btn
    {
        width   : 25px;
        margin  : 0;
        padding : 0;

        > div
        {
            cursor  : pointer;
            background        : url("../../../../../assets/icons/arrow-row.svg");
            background-size   : 10px;
            width             : 10px;
            height            : 10px;
            background-repeat : no-repeat;
            transform         : rotate(-90deg);
            &.expanded
            {
                transform : rotate(0deg);
            }
        }
    }

    .add-mpn-block {
        .mpn-input-holder-sourcing {
            display: flex;
            align-items: center;
            input {
                padding-right: 30px;
            }
            .ui-icon {
                width: 15px;
                height: 15px;
                position: absolute;
                right: 7px;
            }
        }
    }

}
.add-alternate-tooptip
{
    .rc-tooltip-inner{
        padding: 0px 15px !important;
    }
}
.disable-pointer-event {
    pointer-events: none;
}
/*.primary-source-checkbox {
    &.mfrCheckBox {
        padding: 0 12px;
    }
}*/
