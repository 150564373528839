@import "../../../../styles/variables";

.forecast-type-popover
{
    background-color: #17171F;
    z-index: 100000;
    min-width: 96px;
    max-width: 96px;
    min-height: 111px;
    padding: 5px 15px 20px;

    .popover-content-block {
        .forecast-type
        {
            padding: 15px 0 0;
            &:hover
            {
                color: $GREEN;
                cursor: pointer;
            }
        }
    }
}
.forecast-bottom-pointer
{
    padding-top: 0 !important;
}
