@import "../../../styles/variables";

.large-modal {
    .modal {
        .buttons.modal-close {
            top:-25px;
        }
    }
}

body[data-modal="open"]
{
    .scrollbar-container.custom-scroll
    {
        position: unset;
        height: unset;
    }
}

.ui-modal-box
{
    position        : fixed;
    top             : 0;
    left            : 0;
    width           : 100vw;
    height          : 100vh;
    z-index         : 100000;
    display         : flex;
    justify-content : center;
    align-items     : baseline;

    .background
    {
        position   : absolute;
        top        : 0;
        left       : 0;
        width      : 100%;
        height     : 100%;
        background : $BG_LIGHT3;
        opacity    : 0.9;
    }
    .modal-with-scroll {
        height: 400px;
    }
    .modal
    {
        background : $BG_LIGHT;
        padding    : 0 0 45px;
        display    : inline-block;
        box-shadow : 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
        min-width  : 350px;
        width      : 90%;
        height     : 90%;
        font-family: 'Roboto', sans-serif;
        font-size  : 14px;
        // top        : 175px;
        top        : 15%;
        height     : auto;
        // max-width  : 50%;

        .modal-header
        {
            display: flex;
            align-items: center;
        }

        .modal-content{

        }

        .buttons
        {

        }
    }
    .modal-header {
        height: 50px;
        width: 100%;
        color: #000;
        padding: 10px 30px;
        &.errors
        {
            background: $ERRORS;
        }
        &.warning
        {
            background: $CAUTION;
        }

        &.validating
        {
            background    : $GREY;
            pointer-event : none;
        }
        &.no-error,
        &.lable-mapped{
            background: $GREEN;
        }
        .header-heading {
            display: flex;
            width: 65%;
            vertical-align: middle;
            line-height: 12px;
            margin-top: 0;
            .ui-icon  {
                vertical-align: super;
                width: 16px;
                height: 17px;
                > svg {
                    width: 14px;
                    height: 17px;
                }
            }
            h3 {
                margin-bottom: 0;
                display: inline-block;
                margin-left: 13px;
                vertical-align: super;
            }
        }

    }
    .btn-holder {
        display: table-cell;
        text-align: right;
        vertical-align: top;
        width: inherit;
        .btn-header {
            padding: 6px 20px;
            border-color: $FONT_LIGHT;
            color: $FONT_LIGHT;
            min-width: auto;
            margin-left: 10px;
            &.back {
                border-color: #000;
                color: #000;
                &:hover {
                    background:#000;
                    color: $FONT_COLOR;
                }
            }
            &.active {
                background-color: #000;
                color: $FONT_COLOR;
            }
            &:hover {
                background:transparent;
                color: #000;
                border-color: #000;
            }
        }
    }
    .modal-close {
        position: absolute;
        right: -10px;
        top: -40px;
        width: 20px;
        height: 20px;
        .ui-icon  {
            width:20px;
        }
    }
    .custom-muted-text {
        color: $FONT_LIGHT !important;
    }
}
