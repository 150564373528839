@import "../../../../styles/variables";

.policy-route
{
    margin-top    : 100px;
    margin-bottom : 100px;

    .main-block
    {
        display: flex;

        .nav-block
        {
            margin-top : 6px;

            div, a
            {
                margin-bottom : 15px;
                display       : block;
                white-space   : nowrap;
            }

            a
            {
                color : $GREY;

                &:hover
                {
                    color : $FONT_COLOR;
                }
            }
        }

        .content-block
        {
            max-width   : 600px;
            margin      : 0 auto;
            line-height : 21px;

            h2, p
            {
                margin-bottom : 10px;
            }

            h1
            {
                margin-bottom: 30px;
            }

            h3
            {
                margin-top: 30px;
                margin-bottom: 10px;
                text-decoration: underline;

                .date
                {
                    text-decoration: none;
                }
            }

            h2.section
            {
                margin-top: 50px;
            }

            p.indent
            {
                margin-left: 25px;
            }
        }
    }

}
