.validation-running {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 5000;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    left: 0;
    .popover-content-block {
        color: #4A4A4A;
        /*max-width: 241px;*/
        padding: 15px 15px;
        background: #FFF;
    }
    .validation-heading{
        margin-bottom: 8px;
        display: flex;
        align-items: flex-end;
        h4 {
            color: #18171D;
            font-size: 14px;
            margin: 0px 0px 0px 10px;
        }
    }
    .loading-block {
        width: 20px;
        height: 20px;
        -webkit-animation-name: rotate;
        -webkit-animation-duration: 0.4s;
        animation-duration: 0.4s;
        -webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
        -webkit-animation-timing-function: linear;
        animation-timing-function: linear;
        svg {
            fill: #3CD1B5;
            height: 20px;
            width: 20px;
            path:nth-child(1){
                opacity: 0.5;
            }
        }
    }
    p {
        line-height: 24px;
    }
}
.hide-modal {
    display: none !important;
}
