@import "../../../../../styles/variables";

.configuration-sso-modal {
    z-index: 99!important;
    .modal {
        padding: 25px!important;
        border-radius: 8px;
        max-width: 480px;
        width: 100%;
        min-width: auto;
        margin-top: -30px;
    }
    .modal-content {
        .main-block {
            margin-left: 0;
            .content-block {
                min-width: auto;
                margin: 0 auto;
                .custom-modal-header {
                    margin-bottom: 20px;
                }
                .card-panel {
                    overflow: hidden;
                    .card-panel-body {
                        padding: 25px;
                        border-radius: 8px;
                        background-color: $BG_LIGHT2;
                        .form-group
                        {
                            margin-bottom: 20px;
                            label
                            {
                                color: $FONT_LIGHT;
                                font-size: 14px;
                                text-transform: uppercase;
                            }
                            .item-desc {
                                display: flex;
                                align-items: center;
                                .ui-icon {
                                    width: 15px;
                                    height: 16px;
                                    margin-left: 10px;
                                    transform: rotate(90deg);
                                    svg {
                                        g {
                                            stroke: $FONT_COLOR;
                                        }
                                    }
                                    &.clicked
                                    {
                                        svg {
                                        g {
                                            stroke: $GREEN;
                                        }
                                    }
                                    }
                                }
                            }
                            input, select {
                                padding: 4px 10px;
                            }
                            .empty
                            {
                                display: none;
                            }
                            .text-copied {
                                margin-left: 10px;
                                color: $GREEN;
                            }
                        }
                        .upload-metadata-block
                        {
                            margin-top: 10px;
                            .drop-zone {
                                border: 1px dashed $FONT_LIGHT;
                                display: flex;
                                align-items: center;
                                height: auto;
                                padding: 6px 15px;
                                .modal
                                {
                                    display: none;
                                    height: 100%;
                                    width: 100%;
                                }
                            }
                            .collapsed-block
                            {
                                display: flex;
                                align-items: center;
                                padding: 0;
                                .ui-icon
                                {
                                    width: 20px;
                                    height: 20px;
                                    margin:0 10px 0 0;
                                    svg
                                    {
                                        fill: $FONT_LIGHT;
                                    }
                                }
                                a{
                                    margin-left: 5px;
                                }
                            }
                            .uploaded-file
                            {
                                margin: 15px 0;
                                .inner-block
                                {
                                    padding: 0 15px;
                                    justify-content: space-between;
                                    display: flex;
                                    align-items: center;
                                    .file-name
                                    {
                                        color: $GREEN;
                                        margin-right: 10px;
                                    }
                                    .file-remove
                                    {
                                        height: 17px;
                                        width: 17px;
                                    }
                                }
                            }
                        }
                    }
                }
                .card-panel-footer {
                    padding-top: 20px;
                    display: flex;
                    justify-content: space-between;
                    button {
                        padding: 6px 12px;
                    }
                }
            }
        }
    }
}
