.valispace-modal-header
{
    background: #3CD1B5;
    padding: 12px 18px;
    display: flex;
    justify-content: space-between;
    .header-wrapper
    {
        align-items: center;
        display: flex;
        flex: 1;
        justify-content: flex-start;
        .header-heading
        {
            h3 {
                color: #000;
                margin-left: 0;
            }
        }
        .ui-icon {
            top: -2px;
            margin-right: 5px;
            width: 20px;
            height: 16px;
            display: inline-flex;
        }
    }
    .btn-container
    {
        padding-right: 10px;
        display: flex;
        flex: 1;
        justify-content: flex-end;
        button
        {
            background-color: transparent;
            color: #000;
            border-color: #000;
            padding: 6px 20px;
            margin-right: 10px;
            min-width: auto;
            &:hover
            {
                background-color: #000;
                color: #FFFFFF;
            }
        }
        .btn-continue
        {
            background-color: #000;
            color: #FFFFFF;
            border-color: #000;
            &:hover
            {
                background-color: transparent;
                color: #000;
            }
        }
    }
}
