@import '../../../../../styles/variables';

#checkbox-menu {
  display: none;
  position: absolute;
  top: 35px;
  border-radius: 3px;
  background-color: $BG_DARK;
  box-shadow: 0 0 10px 0 rgba(0,0,0,.5);
  z-index: 1;

  &.show-menu {
    display: block;
  }

  .modal {
    padding: 18px;
  }

  ul {
    list-style: none;

    li {
      display: flex;
      padding: 7px 0;

      span {
        margin-left: 10px;
      }
    }
  }
}