@import "../../../../../../styles/variables";

.view-release
{
    .release-heading
    {
        h1
        {
            font-size: $FONT_SIZE_23;
        }
    }
    .release-properties
    {
        display: flex;
        margin-top: 20px;

        .time {
            color: #AAA;
        }
        .items-block
        {
            display: flex;
            margin: 0;
            table
            {
                width: 315px;
                tr
                {
                    border-bottom: 0;
                    td,
                    th
                    {
                        padding: 0;
                        padding-bottom: 19px;
                        color: $FONT_COLOR;
                        font-weight: bold;
                        text-align: left;

                        &:first-child{
                            width: 130px;
                            color: $FONT_LIGHT;
                            font-size: 14px;
                            text-transform: uppercase;
                        }
                    }
                }

            }
        }
    }

    .name-holder
    {
        display: inline-block;
    }

    .decision-badge
    {
        float: right;
        background-color: #18171D;
        padding: 10px;
        .resolution-label
        {
            margin-left: 5px;        
        }
    }
}
