@import "../../../../styles/variables";

$bg-color: $BG_LIGHT2;
$faved-color: $LIKE;
$menu-color: $FONT_MENU;

.view-product-route .actions-block .nav-block,
.product-revision-route .actions-block .nav-block,
.view-component-route .actions-block .nav-block,
.component-revision-route .actions-block .nav-block {
  .truncate-name {
    font-size: $FONT_SIZE_23;
    padding-right: 20px;

    &:is(:nth-child(2)) {
      padding-top: 29px;
    }
  }

  .cta-icon-toolbar {
    z-index: 2;
    height: auto !important;
    position: relative;

    .dots {
      display: none;
      width: 56px;
      height: 36px;
    }

    .dots,
    .navigation,
    .navigate {
      background-color: $bg-color;
      border-radius: 8px;
    }

    .navigation,
    .navigate {
      display: flex;
      flex-direction: row;

      .ui-icon {
        display: flex;
        width: auto;
        height: auto;
        margin: 0;

        &:not(.default-img-icon) {
          padding: 9px 11px;
        }

        &:not(:first-child) {
          margin-left: 1px;
        }

        &.variant-stroke.active {
          svg > g > g {
            stroke: $GREEN;
          }
        }
        &.serialization {
          &:hover,
          &.active {
            svg > g {
              stroke: $GREEN;
            }
          }
        }

        &.faved {
          svg {
            fill: $faved-color;
          }

          &:hover {
            svg {
              fill: $faved-color;
            }
          }
        }

        svg {
          width: 16px;
          height: 16px;
        }

        .iconname {
          display: none;
        }
      }

      > .changeorder-actions-block {
        margin-left: 20px;

        .custom_trigger {
          .ui-icon {
            margin: 0;
          }
        }
      }

      .changeorder-actions-block {
        position: relative;
        width: 100%;
        height: auto;
        margin-left: 1px;

        a {
          display: flex;
        }

        .sub-menu {
          .ui-link {
            display: flex;
            flex-direction: column;

            .ui-icon {
              padding: 0;
            }

            .option {
              margin: 0 0 1px;
              padding: 10px 22px;
            }
          }
        }
      }

      .custom-duro-modal {
        margin-top: 45px;
      }

      .where-used-modal {
        .where-used-inline-image-viewer {
          .ui-icon {
            width: 16px;
            height: 16px;
            padding: 2px;
          }
        }

        .revision-value,
        .icon-holder {
          .ui-icon {
            display: inline;
            margin-left: 0;
            padding: 0;
          }
        }
      }

      .ui-loading .loading-block {
        svg {
          width: auto;
          height: auto;
        }
      }

      .production-date-picker-input-holder {
        .ui-icon {
          padding: 0;
        }
      }
    }

    @media screen and (max-width: 860px) {
      .dots {
        display: flex;
        align-items: center;
        justify-content: center;
        fill: $menu-color;
        transition: all 0.1s;

        &:hover {
          cursor: pointer;
          fill: $GREEN;
        }
      }

      .navigation {
        display: none;
        position: absolute;
        right: 0;
        flex-direction: column;
        width: 180px;
        margin-top: 10px;
        padding: 12px 0;
        border-radius: 0;
        background-color: #18171d;

        .ui-link > .ui-icon,
        .ui-icon {
          width: 100%;
          padding: 8px 22px !important;
          margin: 0 0 1px !important;
          align-items: center;
          text-transform: capitalize;
          color: $menu-color;
          transition: all 0.1s;

          &:hover {
            color: $GREEN;
          }

          svg {
            display: inline-block;
            margin-right: 14px;
          }

          .iconname {
            display: inline-block;
          }
        }

        .changeorder-actions-block {
          margin: 0;

          .option {
            svg {
              margin: 0;
            }

            .label {
              margin-left: 14px;
            }
          }
        }

        .sub-menu {
          .ui-icon {
            padding: 9px 11px !important;
          }

          .option {
            .ui-icon {
              padding: 0 !important;
            }
          }
        }
      }
    }
  }

  div:last-child {
    .modal h1 .ui-icon {
      width: 32px;
      height: 20px;
      margin: 0;
    }
  }
  .modal {
    h1,
    .warning-text {
      color: $BG_WHITE;
      text-transform: none;
    }

    h1 {
      .ui-icon {
        width: 32px;
        height: 20px;
        margin: 0;
      }
    }
  }
}
