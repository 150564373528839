@import "../../../../../../../styles/variables";

.new-component-file-route .map-screen
{
    margin        : 0 auto;
    max-height    : 82vh;
    position      : initial;
    display: flex;

    .scrollarea-content {
        overflow: inherit;
    }
    .form-holder {
        display: flex;
        .map-screen-content-holder {
            margin:39px 130px 7px ;
            position: inherit;
            .message.ui-message {
                margin: 0;
                height: auto;
                width: 100%;
                font-style: normal;
                min-height: 74px;
                padding-top: 25px;
                padding-bottom: 25px;
                p.invalid
                {
                    background: #F54A4F;
                    color: $FONT_COLOR;
                    padding: 2px 10px;
                    font-size: 14px;
                }
            }
        }

        input[type="text"],
        select {
            height: 24px;
            font-size:$FONT_SIZE_DEFAULT;
            padding:0 5px;
            width:208px;
        }
    }


    h1
    {
        margin-bottom : 10px;
    }

    h3
    {

        white-space: nowrap;
        margin-bottom : 75px;
    }

    table
    {
        margin-bottom: 40px;

        tr, th, td
        {
            border-color: transparent;
        }

        th
        {
            > div
            {
                width: max-content;

                &:nth-child(1)
                {
                    color         : $FONT_COLOR;
                    margin-bottom : 5px;
                    font-weight   : normal;
                }

                &:nth-child(2)
                {
                    font-size : $FONT_SIZE_12;
                }

            }

           /* .importToggle
            {
                top: 12px;
                left: 0;
                .ui-toggle-btn
                {
                    right: -3px;
                }
            }*/

            span
            {
                color: #B3B3B3;
                margin-left: 0;
            }
        }

        th, td
        {
            padding-bottom: 15px;
            overflow: inherit;
            &:last-child
            {
                width:      90px;
                text-align: right;
                .importToggle {
                    margin: 0;
                    float: right;
                }
            }

            &:nth-child(1)
            {
                width: 400%;
            }

            &:nth-child(2)
            {
                pointer-events : none;
                text-align     : center;
                 width: 80px;
                padding-right: 0;

                .ui-icon
                {
                    width: 20px;
                    height: auto;
                    svg
                    {
                        fill: $GREY;
                    }
                }
            }

            &:nth-child(3)
            {
                width: 400%;
            }
        }

        td
        {
            &:nth-child(4)
            {
                padding-right: 2px;
                [data-tip]
                {
                    width:40px;
                    margin:0;
                    float: right;
                     height: 18px;
                }

            }
        }
    }

    .required
    {
        margin: 25px 0 0;
        width: 100%;
        overflow: hidden;
        .fields{
            float: left;
            width: 47%;
            margin-bottom: 25px;
            > span {
                display: block;
                margin-bottom: 10px;
                .ui-icon{
                    margin-right: 20px;
                    g{
                        stroke: #4A4A54;
                    }
                }
                &.activated{
                    .ui-icon{
                        g{
                            stroke: $GREEN;
                        }
                    }
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
        .message{
            float: left;
            width: 50%;
        }
    }

    .btn-active {
        background: $GREEN;
        color: $FONT_LIGHT;
    }
    .notes-message {
        position     : absolute;
        right        : -110px;
        max-width    : 280px;
        margin-right : 20px;
        text-align   : right;
        padding      : 3px 5px;
        z-index      : 999;
        font-size    : $FONT_SIZE_DEFAULT;
        .ui-icon  {
            width   : 15px;
            display : inline;
            height  : 15px;
            margin  : 5px;
        }
        >div,
        P{
            display: inline;
        }
    }
    .in-active {
        opacity: 0.6;
        border: 1px solid grey;
        color:  grey;
        pointer-events: none;
    }



    .mpn-import {
        border: 1px solid $FONT_COLOR;
        padding:5px 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &.toggle_off{
            border-color: #4A4A54;
            color:  #4A4A54;
            .ui-toggle-btn{
                background: grey;
            }
        }
        .mpn-text {
            width: 89%;
            display: inline-block;
            vertical-align:-webkit-baseline-middle;
            p {
                font-size: 14px;
                line-height: 18px;
            }
        }
        .ui-toggle-btn
        {
            display: inline-block;
            margin-left: 10px;

        }
    }


    html input[type="checkbox"]:checked:not(old) + label, body input[type="checkbox"]:checked:not(old) + label
    {
        background: $GREEN !important;
    }

    button
    {
        margin-right:10px;
    }
}

.map-screen-modal {

    .ui-modal-box{
        align-items     : center;
    }
    .modal {
        max-width:900px;
        top: auto;
        height: 90%;
        .modal-header
        {
            .btn-holder
            {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                .map-screen-back
                {
                    padding: 6px 20px;
                    border-color: #000;
                    color: #000;
                    min-width: auto;
                    margin: 0;
                    &:hover
                    {
                        background: #000;
                        color: #fff;
                    }
                }
                .btn-header
                {
                    border-color: #000;
                }
            }
        }
    }
    .buttons.modal-close {
        right:-19px;
    }
}
