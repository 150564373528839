@import "../../../../styles/variables";

.users-settings-route
{
    .main-block{
        width: 100%;
    }
    .heading-block{
        max-width: 100%;
        width: 100%;
        overflow: hidden;
        margin-bottom: 30px;
        h1{
            display: inline-block;
        }
        button{
            float: right;
            padding: 6px 0;
            width: 114px;
            margin-right: 20px;
        }
    }
    &.saving
    {
        pointer-events: none;
    }


    .users-header-block
    {
        .nav-block
        {
            width           : 100%;
            display         : flex;
            justify-content : space-between;
            align-items     : flex-end;
            position: relative;
        }

        .header-block
        {
            .heading-section{
                margin-bottom: 16px;
                display: flex;
            }
            h1{
                display: inline-block;
                margin-bottom: 0;
                line-height: inherit;
            }
        }

        .records-count {
          margin-top: 2px;
          min-width: 150px;
          display: inline-block;
        }

    }


}
