@import "../../../../../styles/variables";
.reports-table-wrapper
{
    span.lead-time{
        span{
            &:first-child{
                margin-right: 15px;
            }
        }
    }
    .rolled-up-cost-cell{
        display: flex;
        justify-content: space-between;
    }
    .price-value{
        min-width: 70px;
        display: inline-block;
        vertical-align: top;
    }
    .warningEl
    {
        &.warning-icon
        {
            float: none;
            margin: 0px;
        }
    }
    .text-block
    {
        .column-with-icon
        {
            display: flex;
            justify-content: space-between;
            .price-value
            {
                &.red
                {
                    color: $ERRORS;
                }
                &.green
                {
                    color: $GREEN;
                }
            }
            .ui-icon {
                flex: 0;
                margin-right: 4px;
            }
        }
        .row-with-icon
        {
            width: 15px;
            .icon-value
            {
                padding-top: 0px;
                width: 15px;
                height: 15px;
                .ui-icon
                {
                    height: 15px;
                    width: 15px;
                    .down-icon-cirlce
                    {
                        stroke: $GREEN;
                    }
                }
            }
        }
        .quote-lead-time
        {
            margin-right: 5px;
        }
    }
}
