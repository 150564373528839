@import "../../../../../../styles/variables";

.sourcing-row-mode{
    table
    {
        tr
        {
            td{

                .ui-icon
                {
                    width  : 17px;
                    height : 17px;

                    svg
                    {
                        height : 15px;
                        fill: $GREEN;

                        #Down {
                            #Oval-2 {
                                stroke: $GREEN;
                            }
                        }
                    }
                }
                span.lead-time,
                span.warranty-field
                {
                    span{
                        &:first-child{
                            margin-right: 15px;
                        }
                    }
                }

                .center-state.checkbox{
                    cursor: pointer;
                }

                &.invalidSource{
                    &.error{
                        background-color: $ERROR !important;
                    }
                }

                .source-warning{
                    width: 100%;
                    height: 100%;
                    .warningEl{
                        width: 15px;
                        height: 15px;
                        svg{
                            width: 15px;
                            height: 15px;
                        }
                    }
                }
            }
        }
    }
    #search-table-show-hide-popup{
        right: unset;
        left : 414px;
        .modal{
            width: 180px;
        }
    }

    .sourcing-list-block.have-assembly{
        #search-table-show-hide-popup{
            right: unset;
            left: 522px;
            top: -185px;
        }
    }
    .sourcing-list-view
    {
        #extended-table-data
        {
            tbody
            {
                tr
                {
                    &:hover
                    {
                        cursor: default;
                    }
                }
            }
        }
    }
}

.row-with-icon {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .data-value {
        margin-bottom: auto;
        &.white {
            color: #fff;
        }
        &.red {
            color: #F54A4F;
        }
        &.green {
            color: #3CD1B5;
        }
    }
    .icon-value {
        padding-top: 1px;
        svg {
            fill-opacity: 0;
        }
    }
}

.simple-rc-tip {
    &.source-no-exist {
        .rc-tooltip-arrow {
            border-right-color: #18171D;
        }
        .rc-tooltip-inner {
            background-color: #18171D;
            padding: 15px !important;
            border-color: #18171D;
            .tooltip-text {
                font-size: 14px;
                color: #fff;
                margin-bottom: 15px;
            }
            .tooltip-action-area {
                display: flex;
                justify-content: center;
                align-items: center;
                .tooltip-checkbox {
                    margin-right: 10px;
                    display: flex;
                    span {
                        margin-left: 5px;
                    }
                    input,
                    label {
                        width: 14px;
                        height: 14px;
                    }
                }
                .sourcing-action-btn {
                    padding: 6px 12px;
                    margin-right: 10px;
                    background: #3CD1B5;
                    color: #18171D;
                    text-transform: none;
                    &:hover {
                        background-color: transparent;
                        color: #3CD1B5;
                    }
                }
            }
        }
    }
}
