@import "../../../../styles/variables";

.webhooks-settings-route
{
    padding-left  : $SITE_MARGINS;
    padding-right : $SITE_MARGINS;
    .main-block{
        width: 100%;
    }
    .heading-block{
        max-width: 100%;
        width: 100%;
        overflow: hidden;
        margin-bottom: 30px;
        h1{
            display: inline-block;
        }
        button{
            float: right;
            padding: 6px 0;
            width: 114px;
            margin-right: 20px;
        }
    }

    .webhooks-header-block
    {
        margin-bottom : 16px;
        .nav-block
        {
            width           : 100%;
            display         : flex;
            justify-content : space-between;
            align-items     : flex-end;
            position: relative;
        }

        .header-block
        {
            .heading-section{
                display: flex;
            }
            h1{
                display: inline-block;
                margin-bottom: 0;
                line-height: inherit;
            }
        }
    }
}
