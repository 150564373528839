@import "../../../../../styles/variables";

.delete-warning-modal
{
    .ui-modal-box
    {
        z-index : 99999 !important;
        margin:0;
        background-color: rgba(24,23,29,0.7490196078431373);
        .modal{
            margin-top: 120px;
            margin-right: 25px;
            background-color: #2a2a36;
            box-shadow: unset;
            .modal-close{
                right: -24px;
                top: -23px;
            }
            .modal-content{
                padding-bottom: 30px;
                .modal-heading{
                    h3{
                        font-size: 24px;
                        font-weight: 500;
                        padding:0;
                        border:0;
                        margin-bottom: 25px;
                    }
                }
                .modal-body{
                    span{
                        line-height: 22px;
                        display: block;
                        border-bottom: 2px solid #45454D;
                        padding-bottom: 27px;
                        margin-bottom: 42px;
                    }
                    .btn-holder{
                        .btn-container{
                            button{
                                border-radius: 6px;
                                &.delete{
                                    min-width: 173px;
                                    border-color: #f54a4f;
                                    color: #f54a4f;
                                    &:hover{
                                        background-color: #f54a4f;
                                        color: #18171d;
                                    }
                                }
                                &.dark{
                                    min-width: 101px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
