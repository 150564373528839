.cad-properties { 
    g {
        fill: #888;
        stroke: #979797;
        stroke-width: 0.2;
    }
    .cad-properties-key {
        width: 140px;
    }
    input {
        width: 120px !important;
    }
}