.cancel-download-modal
{
    .ui-modal-box
    {
        z-index : 99999 !important;
        margin:0;
        background-color: rgba(24,23,29,0.7490196078431373);
        .modal{
            padding:0;
            width:100%;
            height: auto;
            max-width: 445px;
            margin-top: 120px;
            background-color: #2a2a36;
            box-shadow: unset;
            .modal-close{
                right: -24px;
                top: -23px;
            }
            .modal-content{
                padding-bottom: 30px;
                .modal-heading{
                    padding: 30px 40px 0px 40px;
                    h3{
                        font-size: 24px;
                        font-weight: 500;
                        letter-spacing: 1px;
                        padding:0;
                        border:0;
                        margin-bottom: 25px;
                    }
                }
                .modal-body{
                    padding: 0 40px;
                    span{
                        line-height: 22px;
                        display: block;
                        border-bottom: 1px solid #979797;
                        margin-bottom: 42px;
                        padding-bottom: 28px;
                        padding-right: 30px;
                    }
                    .btn-holder{
                        display: block;
                        .btn-container{
                            display: flex;
                            button{
                                min-width:178px;
                                padding: 6px 12px;
                                border-radius: 6px;
                                &.dark{
                                    margin-left: 10px;
                                }
                                &.confirm{
                                    &:hover{
                                        color:#18171d;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
