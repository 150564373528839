@import "../../../../../styles/variables";

.view-product-route .tabs-block,
.product-revision-route .tabs-block,
.component-revision-route .tabs-block,
.diff-tool-container .tabs-block
{
    padding-top    : 25px;
    padding-bottom : 100px;
    min-height     : 300px;
    background     : $BG_LIGHT2;

    .nav-block
    {
        margin-bottom   : 22px;

        &.display-flex-sb{
            display: flex;
            justify-content: space-between;
        }

        .tabs-headings{
            display         : flex;
            cursor     : pointer;
            color      : $GREY;
            transition : color 0.1s;
        }

        .tabs-headings > div
        {
            font-size  : 16px;
            .table-cta-icons{
                *{
                    pointer-events: all;
                }
            }

            &[name="documents"]
            {
                margin-right: 10px;
                padding: 2px 5px;
            }

            &[name="sourcing"]
            {
                margin-left: 10px;
                margin-right: 10px;
                padding: 2px 5px;
            }

            &[name="variants"]
            {
                margin-left: 10px;
                margin-right: 10px;
                padding: 2px 5px;
            }

            &[name="assembly"]
            {
                margin-left: 10px;
                margin-right: 10px;
                padding: 2px 5px;
            }

            &[name="reports"]
            {
                margin-left: 10px;
                margin-right: 10px;
                padding: 2px 5px;
            }

            &[name="serialization"]
            {
                margin-left: 10px;
                margin-right: 10px;
                padding: 2px 5px;
            }

            &.icons-holder{
                *
                {
                    pointer-events: all;
                }

                .ui-icon
                {
                    cursor     : pointer;
                    width: fit-content;
                    width: 16px;
                    height: 16px;
                    margin-left: 20px;

                    svg{
                        fill: white;
                    }
                    &:hover{
                        svg{
                            fill: $GREEN;
                            g{
                                fill: $GREEN;
                            }
                        }
                    }

                }
            }

            &:hover
            {
                color: $FONT_COLOR;
            }

            &.selected
            {
                pointer-events : none;
                color          : $FONT_COLOR;
            }

            &.hidden
            {
                display: none;
            }
        }
    }

    .view-block
    {
        position: relative;
        padding-right: 44px;
        padding-left: 44px;

        > div
        {
            display : none;

            &.selected
            {
                display : block;
            }
        }
    }

    .table-cta-icons
    {
        .collapse-icon{
            transform: rotate(180deg);
            height: 16px;
        }
    }
}

.disable-pointer-event {
    pointer-events: none;
}
