//
// TileItem component
// ---------------------------

@import "../../../../styles/variables";

// Variables
// --------------------
$header-color: $FONT_LIGHT;
$schedule-icon-color: $FONT_MENU;
$thumb-color-hover: $THUMB_HOVER;

.icon-20 {
  width: 20px;
  height: 20px;
}

.icon-16 {
  width: 16px;
  height: 16px;
}

.truncate {
  display: inline;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.tile-item {
  display: flex;
  flex-direction: column;
  min-width: 300px;
  max-width: 300px;
  height: 364px;
  border-radius: 8px;
  background-color: $BG_LIGHT2;
  margin: 0 10px 15px;
  &:first-child {
    margin-left: 0px;
  }
  &:nth-last-child(1) {
    margin-right: 0px;
  }

  // Fix hover .ui-icon
  .ui-icon {
    width: 100%;
    height: 20px;
    cursor: inherit;
    align-items: center;
  }

  *:not([class^="react-datepicker"]) {
    display: flex;
  }

  .tile-header {
    min-height: 35px;
    padding: 0 15px;
    border-radius: 8px 8px 0 0;
    background-color: #41414a;

    svg {
      margin-right: 10px;
      fill: $header-color;

      path {
        fill: inherit;
      }
    }

    h6 {
      font-size: 14px;
      color: $header-color;
      text-transform: uppercase;
    }
  }

  .tile-body-scroll {
    flex-direction: column;
    overflow: auto;
    margin: 15px 0;
    padding: 0px 20px;
    scrollbar-width: auto;
    &::-webkit-scrollbar-track {
      width: auto;
      scrollbar-gutter: stable;
    }

    .ui-image-viewer-tile {
      width: 100%;
      height: 145px;
      margin-bottom: 10px;

      &.edit {
        height: auto;
        margin-bottom: 4px;
      }

      .default-img {
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
        background-image: linear-gradient(-180deg, #353540 0%, #18171d 100%);
      }

      .ui-icon {
        width: 70px;
        height: 70px;
      }
    }

    .inner-info {
      min-height: 20px;
      margin-bottom: 5px;
      line-height: 20px;

      .default {
        .date-span {
          min-width: 86px;
        }
        .time-span {
          min-width: 64px;
        }
      }
      &.pov {
        // pov == product overview
        .inner-attribute {
          min-width: 100px;
        }
      }

      &.erp-info {
        .inner-attribute {
          min-width: 100px !important;
          text-transform: unset;
        }
      }

      .status-label {
        line-height: 20px;
      }

      .revision-value {
        width: 100%;
      }

      .inner-attribute,
      .inner-value {
        align-items: center;
        &.free-form-input {
          display: flex;
        }
        .spec-select {
          width: 120px;
        }
      }

      .inner-attribute {
        min-width: 96px;
        color: $header-color;
        text-transform: uppercase;

        // cl = capital letters
        &.no-cl {
          text-transform: none;
        }

        &.specs {
          min-width: 140px;
        }
      }

      .inner-attribute,
      .inner-value {
        @extend .truncate;

        .ui-icon.inline-icon {
          float: left;
          width: 16px;
          height: 20px;
        }

        .inline-icon,
        .inline-icon-right,
        .inside-icon {
          svg {
            @extend .icon-16;
          }
        }
        .inline-icon:not(:last-child) {
          margin-right: 6px;
        }
        .inline-icon-right {
          margin-left: 6px;
        }
        .icon-in-input {
          padding-left: 20px;
        }
        .inside-icon {
          position: absolute;
          left: 2px;
          @extend .icon-16;
        }

        &.separation {
          padding-left: 10px;
        }

        .gray-checkbox {
          background-color: $GREY;
          border-color: $GREY;

          &::before,
          &::after {
            background-color: $FONT_BLACK;
          }
        }

        span {
          @extend .truncate;

          &.unit-price {
            display: block;
            max-width: 150px;
            text-align: right;
          }
        }
      }

      &.values-to-right {
        justify-content: space-between;

        input,
        select {
          text-align: right;
        }
      }

      &.last-info {
        min-height: 30px;
        padding-bottom: 10px;
        border-bottom: 1px solid $header-color;
      }

      .modified {
        .ui-icon {
          width: 10px;
          height: 10px;
        }
      }

      // Edit view
      input,
      select {
        height: 20px;
      }
      select {
        padding-right: 25px;
      }
    }

    .erp {
      height: 100%;
      .erp-message {
        color: #888888;
        position: absolute;
        bottom: 0;
        text-align: center;
        left: 0;
        right: 0;
        font-size: 13px;
        display: block;
      }
    }

    .player,
    .schedule,
    .erp,
    .schedule-edit {
      flex-direction: column;
      padding-bottom: 5px;
      border-bottom: 1px solid $header-color;
      margin-bottom: 8px;
      .erp-section-separator {
        margin-top: 10px;
        padding-top: 10px;
        border-top: 1px solid $header-color !important;
      }
      .player-title,
      .schedule-title,
      .erp-title {
        height: 20px;
        text-transform: uppercase;
        color: $header-color;
        font-weight: bold;
        margin-bottom: 4px;

        &.item-type-title {
          min-width: 100px;
        }
      }

      .player-email {
        height: 20px;
        color: $GREEN;
        @extend .truncate;

        &:hover {
          color: #fff;
        }
      }
      .player-edit-email {
        height: 20px;
        color: #000;
      }

      .schedule-date,
      .erp-date,
      .schedule-amount {
        width: 62%;
        align-items: center;

        .ui-icon {
          width: 20px;

          svg {
            @extend .icon-16;
          }
        }

        .no-day {
          color: $header-color;
        }
      }

      .erp-custom {
        justify-content: flex-end;
        .no-day {
          margin-right: 5px;
        }
        .ui-icon {
          margin-right: 0 !important;
          width: 12px;
        }
      }

      .schedule-date,
      .erp-date {
        .ui-icon {
          margin-right: 4px;
        }

        svg {
          path {
            fill: $schedule-icon-color;
          }
        }
      }

      .schedule-amount {
        width: 38%;
        justify-content: end;

        .ui-icon {
          margin-left: 3px;
          justify-content: end;
        }
      }
    }

    .player {
      min-height: 49px;
    }

    .schedule-edit {
      .schedule-date,
      .schedule-amount {
        width: 50%;
      }

      .schedule-date {
        .react-datepicker-wrapper {
          width: 100px;

          input {
            height: 20px;
          }
        }
      }

      .schedule-amount {
        input {
          width: 84px;
          height: 20px;
          text-align: right;
        }

        .remove-icon {
          margin-right: 5px;

          .ui-icon {
            @extend .icon-16;
            margin: 0;

            &:not(.disabled) {
              &:hover {
                cursor: pointer;
              }
            }
          }
        }
      }
    }

    // Edit view
    .ui-image-viewer-tile {
      flex-direction: column;

      .remove-btn {
        top: 2px;
        left: 2px;

        .ui-icon {
          width: 25px;
          height: 25px;
          border-radius: 50%;
        }
      }

      .images {
        width: 100%;
        border: 0;
      }

      .controls {
        .count {
          span {
            display: inline-flex;
          }
        }
      }

      .primary-block {
        * {
          display: inline-flex;
        }
      }

      .dropzone-container {
        position: absolute;
        top: 0;
        width: 100%;

        .drop-zone {
          width: 100%;
          height: 140px;
          margin: 0;
          border-color: $header-color;
        }
      }
    }
  }

  .description {
    border-bottom: 1px solid $header-color;
    padding-bottom: 5px;
    margin-bottom: 10px;
    white-space: pre-line;

    textarea {
      height: 98px;
      margin-bottom: 0;
      resize: none;
      line-height: 21px;
    }
  }
  .no-scroll {
    overflow: visible; /* Override overflow for noScroll */
  }
  .no-scroll div {
    position: static; /* Override any relative positioning */
  }
  .primary-source {
    color: $header-color;
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 5px;

    svg {
      @extend .icon-20;
      margin-right: 8px;

      g {
        stroke: $header-color;
      }
    }
  }

  // Edit view
  .add-schedule {
    width: 170px;
    color: $GREEN;
    margin-top: 10px;
    padding: 0 10px 10px 0;

    .ui-icon {
      @extend .icon-16;
      margin-right: 10px;
    }

    svg {
      fill: $GREEN;
    }

    &:hover {
      cursor: pointer;
      color: #fff;

      svg {
        cursor: pointer;
        fill: #fff;
      }
    }
  }

  .pi-date-icon {
    position: absolute;
    width: 14px;
    top: 2px;
    right: 10px;
    height: 16px;

    &:hover {
      cursor: pointer;
    }
  }
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected {
  background-color: $GREEN;
  color: #000;
  border: 1px solid transparent;

  &:hover {
    background-color: #fff;
    border: 1px solid $GREEN;
  }
}
