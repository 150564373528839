@import "../../../../styles/variables";


.custom-duro-modal
{
    position   : absolute;
    box-shadow : 0px 0px 10px 0px rgba(0,0,0,0.5);
    background : $BG_DARK;
    margin-top : 20px;
    display    : none;
    z-index    : 5000;
    width      : 940px;
    right      : 0;

    .custom-table-scroll
    {
        max-height: 404px;
        .ps__thumb-y
        {
            background-color: $GREY_LIGHT3 !important;
        }

    }

    .header-block
    {
        padding: 14px 0;
        margin: 0 25px;
        border-bottom : 1px solid rgba(151, 151, 151, 0.25);
        font-size: $FONT_SIZE_DEFAULT !important;
        color: $FONT_LIGHT;
        min-height: 47px;

        .item-flex
        {
            display: flex;
            align-items: center;
        }
    }

    .content-block
    {
        padding: 25px 10px 25px 25px;
        text-align : left;
        .simple-table
        {
            padding-right: 15px;
            table
            {
                thead
                {
                    tr
                    {

                        th
                        {
                            background: #17171F;
                            position: sticky;
                            top: 0;
                            padding: 0;
                            z-index: 999;
                            min-height: 37px;
                            .th-text
                            {
                                color: $FONT_LIGHT;
                            }

                            &:after
                            {
                                content: '';
                                position: absolute;
                                bottom: -1px;
                                height: 1px;
                                width: 100%;
                                background-color: #979797;
                            }
                            .th-holder
                            {
                                position: relative;
                                display: inline-block;
                                padding-right: 10px;
                                .sorting-icon-holder
                                {
                                    position: absolute;
                                    right: 4px;
                                    top: 8px;
                                }
                            }

                            .text-block
                            {
                                padding: 0;
                            }
                        }
                    }
                }
                tbody
                {
                    tr
                    {
                        border-bottom : 1px solid rgba(151, 151, 151, 9);
                        td
                        {
                            padding: 4px 9px;
                            .text-block
                            {
                                padding: 0;
                            }
                            .red-font
                            {
                                color: $ERRORS;
                                &:hover
                                {
                                    color: $FONT_COLOR;
                                }
                            }
                            .grey-font
                            {
                                color: $GREY_LIGHT3;
                                &:hover
                                {
                                    color: $FONT_COLOR;
                                }
                            }
                        }
                    }
                }
            }

        }
    }

    &.open
    {
        display: block;
        min-height: 163px;
    }
    .help-icon {
        .ui-icon {
            margin-left: 3px !important;
            svg
            {
                rect
                {
                    fill: transparent;
                }
            }
        }
    }
}

.rc-left-custom-arrow,
.rc-bottom-custom-arrow
{
    .rc-tooltip-arrow
    {
        left: inherit !important;
        border-left-color: #18171d;
    }

    .rc-tooltip-inner
    {
        color: #FFF;
        background-color: #18171d;
        border: 1px solid #18171d;
        padding: 5px 10px !important    ;
        line-height: 20px !important;
        min-height: unset;
        font-style: normal !important;
    }
}

.rc-bottom-custom-arrow
{
    .rc-tooltip-arrow
    {
        left: 50% !important;
        border-top-color: black;
        border-left-color: transparent;
    }

    .rc-tooltip-inner
    {
        max-width: 240px;
    }
}

