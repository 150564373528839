@import "../../../styles/variables";

.ui-icon
{
    width    : 1px;
    height   : 1px;
    cursor   : pointer;
    display  : inline-block;


    svg
    {
        fill: white;
        &.active
        {
            fill: $GREEN;
        }
    }

    &.active,
    &:hover
    {
        svg
        {
            fill: $GREEN;
        }
    }



    &.disabled
    {
        opacity        : 0.25;

        svg
        {
            fill: $GREY;
        }
    }

    .cls-2
    {
        fill: none !important;
    }
}
