@import "../../../../styles/variables";

$LEFT_COL_WIDTH         : 500px;
$LEFT_COL_WIDTH_AFERO   : 460px;

.production.instance,
.product
{
    &.edit
    {
        .routes
        {
            padding-bottom: 0px;
            min-height: 100%;

            .rc-tooltip-placement-right
            {
                z-index: 10000;
            }

        }
    }
}

.production.instance
{
    background: $BG_LIGHT2;
}

.edit-production-instance-route,
.edit-product-route
{
    min-height: 100vh;

    .open-right-nav
    {
        margin-right: 250px;
    }

     .banner-block
     {
         background : $CAUTION;

         > div
         {
             display         : flex;
             color           : black;
             justify-content : space-between;
             align-items     : center;

             > div
             {
                 margin      : 0;
                 display     : flex;
                 align-items : center;
                 margin      : 5px 0;
                 height      : 40px;

                 &:nth-child(1)
                 {
                     .ui-icon
                     {
                         pointer-events : none;
                         width          : 16px;
                         height         : 19px;
                         margin-right   : 10px;

                         svg
                         {
                             fill : black;
                         }
                     }
                 }

                 &:nth-child(2)
                 {
                     a
                     {
                         margin-right : 20px;
                         color        : black;
                     }

                     input[type="submit"]
                     {
                         padding      : 5px 10px;
                         color        : $FONT_YELLOW;
                         border-color : black;
                         background   : black;

                         &:hover
                         {
                             background : none;
                             color      : black;
                         }
                     }
                 }
             }
         }

        .delete_btn {
            margin-right: 20px;
            color: black;
            background: transparent;
            border: 0;
            cursor: pointer;
        }
     }

     .top-block
     {
        background: $BG_LIGHT;
        padding-bottom: 15px;
        overflow: auto;

        input, select
        {
            font-size : $FONT_SIZE_DEFAULT;
            padding: 0px 6px;
            height: 24px;
        }

        select
        {
            font-size : $FONT_SIZE_12;
        }

         .nav-block
         {
             display         : flex;
             padding-top     : 15px;
             margin-bottom   : 10px;
             div
             {
                 &:first-child:not(.edit-pi-title)
                 {
                     color          : $GREY;
                     text-transform : uppercase;
                 }

                 &:last-child
                 {
                     .ui-icon
                     {
                         pointer-events : none;
                         width          : 12px;
                         height         : 12px;

                         svg
                         {
                             fill : $CAUTION;
                         }
                     }
                 }
             }
         }

         .name-block
         {
             margin: 15px 0;
             width: $LEFT_COL_WIDTH;
             padding-right: 20px;
             &.afero{
                width: $LEFT_COL_WIDTH_AFERO;
             }
             input
             {
                 height: 30px;
                 font-size: 23px;
                 padding: 0px 10px;
             }
         }

         .info-block
         {
            .row-holder{
                display: flex;
                .col{
                    flex: 1;
                }
            }
            .description-box{
                margin-top: 15px;
                textarea{
                    height: 103px;
                    resize: none;
                }
            }
             h2
             {
                 font-size: $FONT_SIZE_DEFAULT;
                 font-weight: 700;
                 color: $FONT_COLOR;
             }

             tr
             {
                border-bottom: none;
                th, td{
                    padding: 0px 10px 10px 0;
                }
                th
                {
                    text-align: left;
                }

                td{
                    font-weight: normal;
                }

                td.forecast-date
                {
                    overflow: visible;
                }
             }

             .row-one
             {
                padding-right: 20px;
                width: $LEFT_COL_WIDTH;
                &.afero{
                    width: $LEFT_COL_WIDTH_AFERO;
                }

                 .left
                 {
                     flex: 1;
                     display: flex;

                     .ui-image-viewer
                     {
                         margin-right: 40px;
                     }

                     td, th
                     {
                        padding: 0px;
                        &:first-child
                        {
                            width: 80px;
                            height: 30px;
                            text-transform: uppercase;
                        }
                     }
                     td{
                        text-align: left;
                        padding-left: 20px;

                        input, select{
                            padding-bottom: 0px;
                        }
                     }
                     tr{
                        td.have-input{
                            padding-left: 10px;
                        }
                     }
                 }

                 .right
                 {
                     margin-left: 80px;
                     flex: 1;

                     td, th
                     {
                        &:first-child
                        {
                             width: 165px;
                             text-transform: uppercase;
                        }
                     }
                 }
             }

             .row-two
             {
                 .top, .primary-source
                 {
                     th{
                        width: 185px;
                        text-transform: uppercase;
                     }
                     h2{
                        padding-bottom: 10px;
                     }
                 }

                 .right
                 {
                    .headings{
                        margin-top: 5px;
                        h2{
                            display: inline-block;
                            width: 200px;
                        }
                        div{
                            display: inline-block;
                            span{
                                display: inline-block;
                                &:first-child{
                                    width: 72px;
                                }

                            }
                        }
                    }
                     td, th
                     {
                         &:first-child
                         {
                             width: 184px;
                             text-transform: uppercase;
                         }

                         &:nth-child(4)
                         {
                             width: 30px;
                         }
                     }

                     table
                     {
                         tr
                         {
                              .ui-icon
                              {
                                  width  : 18px;
                                  height : 18px;
                              }

                              &.add-row
                              {
                                  border-bottom   : 0 !important;

                                  td
                                  {
                                      > div
                                      {
                                          cursor          : pointer;
                                          display         : flex;
                                          justify-content : left;
                                          align-items     : center;
                                          width           : 175px;
                                          padding-top     : 12px;

                                          > div:last-child
                                          {
                                              transition  : color 0.05s;
                                              color       : $GREEN;
                                              padding-top : 3px;
                                          }

                                          .ui-icon
                                          {
                                              margin-right : 10px;
                                              width        : 11px;
                                              height       : 11px;

                                              svg
                                              {
                                                  transition : fill 0.05s;
                                                  fill       : $GREEN;
                                              }
                                          }

                                          &:hover
                                          {
                                              > div:last-child
                                              {
                                                  color: $FONT_COLOR;
                                              }

                                              .ui-icon
                                              {
                                                  svg
                                                  {
                                                      fill: white;
                                                  }
                                              }
                                          }
                                      }
                                  }
                              }
                         }
                     }
                     table.forecast{
                        td{
                            .volume-cross-icon-holder
                            {
                                display: flex;
                                align-items: center;
                                .remove-icon
                                {
                                    margin-left: 10px;
                                    .ui-icon.disabled
                                    {
                                        cursor: initial;
                                    }
                                }
                            }
                            &.forecast-date {
                                .react-datepicker-wrapper,
                                .react-datepicker__input-container {
                                    width: 100%;
                                }
                            }
                        }

                        .truncate-build-labels
                        {
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }

                        thead th{
                            padding-top: 15px;
                            padding-bottom: 10px;
                        }

                        .custom-date-picker-input-holder {
                            input {
                                padding-right: 21px;
                                font-size: 13px;
                            }
                            .ui-icon{
                                position: absolute;
                                top: 5px;
                                right: 5px;
                            }
                        }
                    }
                 }
             }
             .default-img
             {
                 background-image: linear-gradient(-180deg, #353540 0%, #18171D 100%);
                 min-width: 185px;
                 min-height: 144px;
                 display: flex;
                 align-items: center;
                 justify-content: center;
                 .ui-icon
                 {
                     width: 68px;
                     height: 69px;
                     svg
                     {
                         width: 100%;
                         height: 100%;
                         fill: $GREY_LIGHT3;
                     }
                 }
             }
         }
        .tile-wrapper { display: flex; }
     }

    .edit-cpn-field
    {
        display: flex;
        align-items: center;
        .edit-cpn-variant-field
        {
            display: flex;
            align-items: center;
            width: 45px;

            input
            {
                text-transform: uppercase;
            }
        }
    }
    .edit-cpn-variant-field
    {
        &.free-form-input {
            max-width: 100px;        
        }
    }

    .col-2{
        max-width: 240px;
        width: 222px;
    }
    .col-3{
        min-width: 350px;
    }
    .primary-source
    {
        td, th
        {
            line-height: 17px;
            &:first-child
            {
                width: 110px;
                text-transform: uppercase;
            }

            &:nth-child(2)
            {
                width: 72px;;
            }
        }
        td.rolled-up-extended-cost-cell,
        td.rolled-up-cost-cell{
            > div{
                display: flex;
                align-items: center;
                gap: 6px;
                background-color: #B3B3B3 !important;
                color: #000;
                min-height: 24px;
                .extended-cost,
                .unit-price
                {
                    max-width: 79px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: block;
                }
            }
            &.valid{
                div
                {
                    height: 24px;
                    padding-top: 4px;
                }
            }
            span{
                &.not-all-valid{
                    display: inline-flex;
                    padding-left: 4px;

                    .ui-icon{
                        height: 17px;
                        width: 17px;
                    }
                    svg{
                        width: 17px;
                        height: 17px !important;
                    }
                }
            }
        }
        .mass-row
        {
            th
            {
                text-transform: none;
            }
            .rolled-up-mass-cell
            {
                display: flex;
                align-items: center;
                height: 100%;
                .not-all-valid
                {
                    z-index: 1;
                    top: 3px;

                    background: white;
                    height: 100%;
                    min-height: 24px;
                    display: flex;
                    align-items: center;
                    padding-left: 4px;
                }
            }
        }
    }

}
