@import "../../../../styles/variables";

.product-revision-route
{
    margin-bottom : -100px;
    overflow-x      : hidden;

    .background-holder
    {
        pointer-events : none;
        position       : absolute;
        left           : 0;
        top            : 0;
        opacity        : 0.02;
        font-weight    : bold;
        z-index        : 1;
        width          : 100%;
        height         : 100%;
        overflow       : hidden;
        .background {
            transform      : rotate(-45deg);
            width          : 150%;
            height         : 200%;
            position       : absolute;
            top            : -50%;
        }
    }

    .description-text
    {
        white-space: pre-line;
    }
    .lower-case
    {
        text-transform: lowercase;
    }

    .top-block
    {
        background: $BG_LIGHT;
        padding-bottom: 15px;

        tr
        {
            th, td
            {
                text-align: left;
                margin: 0;
            }
        }

        .name-block
        {
            font-size     : $FONT_SIZE_23;
            margin-bottom : 15px;
        }

        .info-block
        {
            h2
            {
                font-size: 17px;
                margin-bottom: 15px;
            }

            .row-one
            {
                display: flex;
                margin-bottom: 40px;

                tr
                {
                    border-bottom: none;
                }

                .left
                {
                    flex: 1;
                    display: flex;

                    .ui-image-viewer
                    {
                        margin-right: 40px;
                    }

                    td, th
                    {
                       &:first-child
                       {
                            width: 105px;
                            text-transform: uppercase;
                       }
                    }
                }

                .right
                {
                    margin-left: 80px;
                    flex: 1;

                    td, th
                    {
                       &:first-child
                       {
                            width: 165px;
                            text-transform: uppercase;
                       }
                    }
                }
            }

            .row-two
            {
                display: flex;

                tr
                {
                    border-bottom: none;
                }

                .left
                {
                    flex: 1;

                    div
                    {
                        line-height: 160%;
                        max-width: 450px;
                    }
                }

                .right
                {
                    flex: 1;
                    margin-left: 80px;

                    td, th
                    {
                        &:first-child
                        {
                            width: 220px;
                            text-transform: uppercase;
                        }

                        &:nth-child(2)
                        {
                            width: 105px;
                        }
                    }
                }
            }
            .default-img
            {
                background-image: linear-gradient(-180deg, #353540 0%, #18171D 100%);
                min-width: 185px;
                min-height: 140px;
                display: flex;
                align-items: center;
                z-index: 2;
                justify-content: center;
                .ui-icon
                {
                    width: 68px;
                    height: 69px;
                    svg
                    {
                        width: 100%;
                        height: 100%;
                        fill: $GREY_LIGHT3;
                    }
                }
            }
        }
    }
}
