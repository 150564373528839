@import "../../../../../../styles/variables";

.details-block
{
    .webhook-event-heading
    {
        h1
        {
            font-size: $FONT_SIZE_23;
        }
    }
    .webhook-properties
    {
        display: flex;
        margin-top: 30px;

        .items-block
        {
            display: flex;
            margin: 0;
            table
            {
                width: 315px;
                tr
                {
                    border-bottom: 0;
                    td,
                    th
                    {
                        padding: 0;
                        padding-bottom: 19px;
                        color: $FONT_COLOR;
                        font-weight: bold;
                        text-align: left;

                        &:first-child{
                            width: 130px;
                            color: $FONT_LIGHT;
                            font-size: 14px;
                            text-transform: uppercase;
                        }
                    }
                    td{
                        width: 415px;
                    }
                    .include-flag-lable
                    {
                        white-space: initial;
                    }
                }

            }
        }
    }
}
