@import "../../../../styles/variables";

.library-modal-holder
{
    .library-modal
    {
        .modal
        {
            max-width: 675px;
            height: auto;
            padding-bottom: 0;
            .modal-content
            {
                padding:0;
                h2
                {
                    font-weight: normal;
                    margin-bottom: 30px;
                    font-size: 23px;
                }
            }
        }
    }
    .library-contianer
    {
        .library-content-holder
        {
            width: 100%;
            .heading-holder
            {
                font-size: 14px;
                color: $FONT_LIGHT;
                max-width: 527px;
                margin: 0 auto;
                h4
                {
                    font-size: $FONT_SIZE_19;
                    color: $FONT_LIGHT;
                    margin-bottom: 50px;
                    font-weight: normal;
                }
                p
                {
                    font-size: 16px;
                    font-weight: 600;
                    text-transform: uppercase;
                    margin-bottom: 50px;
                    line-height: 24px;
                }
            }
            .library-holder
            {
                display: flex;
                justify-content: space-between;
                margin-bottom: 30px;
                &:hover {
                    .personal-library{
                        .inner-holder {
                            border-color: transparent;
                        }
                    }


                }
                .personal-library
                {
                    cursor: pointer;
                    padding: 20px 0;
                    text-align: center;
                    flex-basis: 50%;

                    .inner-holder
                    {
                        border-right: 1px solid rgba(151,151,151,0.5);
                        &:last-child
                        {
                            border: 0;
                        }
                    }

                    &:hover
                    {
                        -webkit-box-shadow: 0px 1px 4px 0px rgba(0,0,0,0.50);
                        -moz-box-shadow: 0px 1px 4px 0px rgba(0,0,0,0.50);
                        box-shadow: 0px 1px 4px 0px rgba(0,0,0,0.50);
                        border: 0;
                        .tutorial-btn {
                            border: 1px solid $BG_LIGHT;
                            background: $BG_LIGHT;
                            color: $GREEN;
                        }

                        .recommended-text
                        {
                            color: $GREEN;
                        }
                    }
                    &:nth-child(2){
                        .ui-icon {
                            width: 70px;
                        }
                    }
                    .ui-icon
                    {
                        width: 33px;
                        height: 38px;
                        margin-bottom: 25px;
                    }
                    h3
                    {
                        font-size: $FONT_SIZE_19;
                        margin-bottom: 20px;
                    }
                    p
                    {
                        font-size: 16px;
                        line-height: 24px;
                        max-width: 207px;
                        margin: 0 auto 30px;
                        color: #514d4d;
                    }
                    .recommended-text
                    {
                        font-size: 16px;
                        color: #514d4d;
                        font-weight: normal;
                    }
                }
            }
            .btn-holder
            {
                text-align: center;
                width: 100%;
                display: block;
                margin-bottom: 0;
                .tutorial-btn
                {
                    border: 1px solid $GREEN;
                    background: $GREEN;
                    color: $FONT_LIGHT;
                    min-width: 200px;
                    margin-bottom: 20px;
                    &:hover
                    {
                        border: 1px solid $BG_LIGHT;
                        background: $BG_LIGHT;
                        color: $GREEN;
                    }
                }

                .skip-tutorial
                {
                    color: $GREEN;
                    font-size: 14px;
                    cursor: pointer;
                    font-weight: 600;
                }
            }

            .back-link
            {
                &:hover
                {
                    color: $GREEN;
                }
            }
        }
    }
}
