@import "../../../../../styles/variables";

.new-changeorder-route .co-types
{
    margin-top      : 15px;
    display         : flex;
    > div
    {
        cursor : pointer;

        *
        {
            pointer-events: none;
        }

        > div
        {
            text-align: center;
            margin-bottom: 10px;
        }

        label
        {
            font-weight: bold;
        }

        .ui-icon
        {
            width: 35px;
            height: 35px;
            margin-right: 22px;
        }

        &:hover
        {
            color: $GREEN;

            svg
            {
                fill: $GREEN;
            }
        }

        &.selected
        {
            cursor: default;
            color          : $GREEN;

            svg
            {
                fill: $GREEN;
            }
        }
    }

    &.disabled
    {
        pointer-events: none;

        > div
        {
            &:not(.selected)
            {
                color: $GREY;

                svg
                {
                    fill: $GREY;
                }
            }
        }
    }
}
