.valispace-modal{
    .projects-list{
        .modal {
            max-width: 600px;
            padding-bottom: 0;
            top: 10%;
            .modal-content
            {
                padding: 30px;
                .modal-heading {
                    padding-bottom: 10px;
                    text-align: left;
                    h1 {
                        font-size: 23px;
                        margin-bottom: 10px;
                    }
                    h2 {
                        font-size: 16px;
                        margin-left: 10px;
                    }
                }
                .project-list
                {
                    text-align: right;
                    .list-area
                    {
                        .table-wrapper-scroll {
                            max-height: 62vh;
                        }
                        tr {
                            border-bottom: 0;
                            td {
                                text-align: left;
                                padding-left: 25px;
                            }
                        }
                    }
                }
            }
        }
    }
}
