@import "../../../../../../styles/variables";

.view-changeorder-route .details-block
{
/*    flex: 0 0 350px;

    .items-block
    {

        margin-bottom: 10px;

        > div
        {
            display       : flex;
            border-bottom : 1px solid $GREY;
            padding       : 10px 0;

            &:first-child
            {
                padding-top: 0;
            }

            > div
            {
                &:nth-child(1)
                {
                    flex  : 0 0 100px;
                    color : $GREY;
                }

                &:nth-child(2)
                {

                }
            }
        }
    }

    .description-block
    {
        margin-bottom : 100px;
        line-height   : 20px;
    }

    .history-block
    {
        > h2
        {
            margin-bottom: 20px;
        }

        > div
        {
            border-bottom : 1px solid $GREY;
            padding       : 10px 0;

            &:first-of-type
            {
                padding-top : 0;
            }

            &:last-of-type
            {
                border-bottom : none;
            }

            > div
            {
                &:nth-child(1)
                {
                    color          : $GREY;
                    text-transform : uppercase;
                }

                &:nth-child(2)
                {
                    margin-top  : 5px;
                    line-height : 20px;
                    display     : none;

                    &.visible
                    {
                        display: block;
                    }
                }
            }
        }
    }*/


    .co-heading
    {
        h1
        {
            font-size: $FONT_SIZE_23;
        }
    }
    .co-properties
    {
        display: flex;
        margin-top: 30px;

        .items-block
        {
            display: flex;
            margin: 0;
            table
            {
                width: 315px;
                display: flex;
                display: -webkit-box;
                display: -ms-flexbox;
                flex-direction: column;
                flex-basis: 100%;
                flex: 1;
                -webkit-box-flex: 1;
                -ms-flex: 1;
                tr
                {
                    border-bottom: 0;
                    td,
                    th
                    {
                        padding: 0 20px 19px 0;
                        color: $FONT_COLOR;
                        font-weight: bold;
                        text-align: left;

                        &:first-child{
                            width: 130px;
                            color: $FONT_LIGHT;
                            font-size: 14px;
                            text-transform: uppercase;
                        }
                    }
                }

            }
        }
    }

    .name-holder
    {
        display: inline-block;
    }

    .decision-badge
    {
        float: right;
        background-color: #18171D;
        padding: 10px;
        .resolution-label
        {
            margin-left: 5px;        
        }
    }
}
