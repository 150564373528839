@import "../../../styles/variables";

.spinner-wrapper{
    display: flex;
    align-items: center;
    margin-top: 30px;
    .spinner{
        margin: 0 auto;

        .ui-icon {
            padding: 0 !important;

            svg {
                width: auto !important;
                height: auto !important;
            }
        }
    }
    .spinner,
    .ui-icon,
    .ui-icon svg
    {
        width  : 40px !important;
        height : 40px !important;
        fill: $GREEN;
    }
}

.spinner-wrapper-table{
    margin-top: 0px;
    .spinner{
        margin: 0 auto;
    }
    .spinner,
    .ui-icon,
    .ui-icon svg
    {
        width  : 25px;
        height : 25px;
        fill: $GREEN;
    }
}
