@import "../../../styles/variables";

.global-modal-box-for-errors{
    .modal
    {
        width: 564px;
        padding: 28px 44px;
        .bottom-section{
            width: 100%;
            margin-top: 25px;
            float: right;
            padding-top: 30px;
            border-top: 1px solid #979797;
            .button-sections{
                    float: right;
                button{
                    width: 138px;
                    height: 30px;
                    padding: 0;
                    margin-left: 10px;
                }
            }
        }
        .error-heading
        {
            h1
            {
                margin-bottom: 10px;
            }
        }
        .errors-sub-heading
        {
            h3
            {
                font-size: $FONT_SIZE_16
            }
        }
        .errors-list
        {
            p
            {
                color: #F54A4F;
                margin: 10px 0;
            }

            &.custom-err
            {
                p
                {
                    color: $FONT_COLOR;
                }
            }
        }
    }

    &.custom-err
    {
        .error-heading
        {
            display: inline-block;
            margin-left: 30px;
        }
        .errors-list
        {
            p
            {
                color: $FONT_COLOR;
            }
        }
    }
}

.warning-err
{
    .modal
    {
        h1
        {
            display: inline-block;
            margin-left: 30px;
            margin-bottom: 10px !important;
        }
        .warning-text
        {
            .save-warning-text
            {
                margin: 10px 0 !important;
            }
        }
    }
}

.ui-alert
{
    position        : fixed !important; //Added important to override parent css
    top             : 0;
    left            : 0;
    width           : 100vw;
    height          : 100vh;
    z-index         : 100000;
    display         : flex;
    justify-content : center;
    align-items     : center;

    input[type="text"]
    {
        width   : 0 !important; //Added important to override parent css
        height  : 0;
        opacity : 0;
    }

    .background
    {
        position   : absolute;
        top        : 0;
        left       : 0;
        width      : 100%;
        height     : 100%;
        background : rgba(74, 79, 93, 0.7);
    }

    .modal
    {
        background : #17171F;
        padding    : 45px;
        display    : inline-block;
        box-shadow : 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
        min-width  : 350px;
        max-width  : 33%;

        .messages
        {
            margin-bottom: 50px;
        }

        .buttons
        {

        }
    }

    &.default
    {

    }

    &.string
    {

    }

    &.errors
    {
        .modal
        {
            .messages
            {
                h1
                {
                    margin-bottom: 10px;
                }

                h3
                {
                    margin-bottom: 15px;
                }

                .list
                {
                    .item
                    {
                        color       : #F54A4F;
                        padding-top : 10px;
                    }
                }
            }
        }
    }

    &.confirm
    {
        .buttons
        {
            .no-btn
            {
                margin-right : 15px;
                filter       : grayscale(100%)
            }
        }
    }
}

