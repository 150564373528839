@import "../../../../../styles/variables";

.rolled-up-table{
    .rolled-up-table{
        &.edit-mode{
            tr{
                &.success{
                    // background-color: $FONT_COLOR;
                    color: black;
                    td{
                        background-color: $TABLE_CELL_SUCCESS;

                        &.edit-mode{
                            background-color: $FONT_COLOR;
                            .text-block{
                                height: 100%;
                                input{
                                    background-color: $TABLE_CELL_SUCCESS;
                                }
                            }
                        }
                   }
                }

                td{
                    &.edit-mode{
                        height: 34px;
                        padding: 0;
                        .text-block{
                            height: 100%;
                            input{
                                height: 34px;
                            }
                        }
                    }
                }


            }
        }
    }
}
.extended-cost-rolledup,
.unit-price-rolledup{
    vertical-align: top;

    .not-all-valid{
        float: left;

        svg{
            height: 17px;
        }
    }
}
