@import "../../../../../../../styles/variables";

.edit-product-route,
.edit-component-route
{
    .tabs-block .sources-tab .distributors .quotes
    {
        margin-left   : 20px;
        margin-right: 50px;
        table{
            width: 100%;
            max-width: 720px;
            @-moz-document url-prefix() {
                margin-left: 5px;
            }
            tr{
                th{
                    color: $FONT_LIGHT;
                    background-color: #EEEEEE;
                    /*padding: 0 !important;*/
                    /*text-align: center;*/
                    &:first-child,
                    &:last-child
                    {
                        padding: 0 10px;
                    }
                    .header-checkbox {
                        margin-right: -2px;
                    }
                }

                td{
                    .primarySource{
                            &.active{
                                &.checkbox{
                                    margin-left: -2px;
                                    margin-top: 3px;
                                    &:after{
                                        margin: 4px 0px 0px -3px;
                                    }
                                }
                            }
                        }
                }

                &.warning-source {
                    td{
                        /*input[type="radio"],*/
                        .lastUpdated,
                        input[type="text"]{
                            border-top: 1px solid red !important;
                            border-bottom: 1px solid red !important;
                            background-color: rgba(245, 74, 79, .25);
                            /*&:not(.remove-col){

                            }

                            &.remove-col{
                                border-bottom: initial !important;
                                border-top: initial !important;
                            }*/
                        }
                    }
                    .leadtime-inputs{
                        border-top: 1px solid red !important;
                        border-bottom: 1px solid red !important;
                        .leadtime-inner-style
                        {
                            height: 100%;
                            background-color: rgba(245, 74, 79, .25);
                            /*input[type="text"]{
                                border: 2px solid #000;
                            }*/
                        }

                    }
                    .unitPrice-style {
                        .unitPrice-icon {
                            border-top: 1px solid red !important;
                            border-bottom: 1px solid red !important;
                            background-color: rgba(245, 74, 79, .25);
                        }
                    }
                }
                &.primary-source
                {
                    td{
                        &:not(.remove-col){

                            input[type="text"]{
                                background: $TABLE_CELL_SUCCESS;
                            }

                            input.error{
                                background: $ERRORS;
                            }

                            &.leadtime-inputs{
                                padding-left: 0;
                                padding-right: 0;
                                .leadtime-inner-style
                                {
                                    /*padding-top: 3px;
                                    padding-left: 3px;
                                    padding-right: 3px;*/
                                    height: 100%;
                                    background: $TABLE_CELL_SUCCESS;

                                    input[type="text"]{
                                        background: transparent;
                                    }
                                }

                            }
                            .unitPrice-style {
                                .unitPrice-icon {
                                    background: $TABLE_CELL_SUCCESS;
                                }
                            }
                            .lastUpdated {
                                background: $TABLE_CELL_SUCCESS;
                            }
                        }
                    }
                }
                td {
                     &.leadtime-inputs{
                                padding-left: 0;
                                padding-right: 0;
                                .leadtime-inner-style
                                {
                                    input[type="text"]{
                                        background: transparent;
                                        width: 55px;
                                        text-align: center;
                                    }
                                }

                            }
                }
            }
            .lastUpdated
            {
                .date-span
                {
                    color: #888888;
                }
            }
        }
    }
}

.unitPrice-style {
    display: flex;
    align-items: center;
    width: 100%;
    .unitPrice-icon {
        /*min-width: 30px;
        padding: 3px;*/
        width: 23px;
        height: 30px;
        padding-top: 8px;
        fill-opacity: 0;
        svg
        {
            #Down {
                #Oval-2 {
                    stroke: $GREEN;
                }
            }
        }
    }
}
.warning-icon-width {
    width: 15px;
    height: 15px;
    margin-left: -2px;
    .warning-icon-inner-width {
        width: 15px;
        svg {
            width: 15px;
        }
    }
}
.leadtime-inner-style {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 5px;
    .leadtime-icon-style {
        width: 15px;
        height: 15px;
        fill-opacity: 0;
        .unitPrice-icon {
            svg
            {
                #Down {
                    #Oval-2 {
                        stroke: $GREEN;
                    }
                }
            }
        }
    }
}
.min-height {
    min-height: 30px;
}
.lastUpdated {
    color: #888888;
    font-size: 14px;
    padding: 7px 10px;
    height: 30px;
}
