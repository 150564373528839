@import "../../../../styles/variables";
.extended-table-section{
    .index-checkbox {
        input[type="checkbox"]
        {
            width: 15px;
            height: 15px;
        }
        input[type="checkbox"] + label
        {
            width: 15px;
            height: 15px;
            margin-left: -15px;
        }
    }

    .warningEl{
        width: 15px;
        height: 15px;
        svg{
            width: 15px;
            height: 15px;
        }
    }
}
.extended-table
{
    display: flex;
    .table-icons-section{
        position: absolute;
        display: flex;
        width: 100%;
        justify-content: space-between;
        top: -36px;
    }
    .records-count{
        min-width: 120px;
        padding-top: 13px;
        padding-right: 10px;
        text-align: right;
    }
    &.have-table-icons{
        padding-top: 36px;
    }

    tr, td, th
    {
        padding: 0;
        padding-bottom: 0;
        padding-top: 0;
        padding-right: 0;
        padding-left: 0;
    }

    a{
        color: $FONT_COLOR;
        user-select: auto;
    }

    .text-block{
        user-select: auto;
    }

    td.user-info-col{
        .text-block{
            height: auto;
            display: flex;
            align-items: center;

            .user-profile-img {
                display: flex;
                align-items: center;
                margin-right: 5px;
                img{
                    width: 28px;
                    height: 28px;
                    border-radius: 100%;
                }
            }
            .user-name{
                width: fit-content;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            .face-avatar{
                margin-right: 5px;
            }

            .ui-icon{
                width: auto;
                height: auto;
                width   : 28px;
                height  : 28px;
                top: 2px;
                svg
                {
                    width   : 28px;
                    height  : 28px;
                }
            }
        }
    }

    .right-border-highlight, .expand-me
    , .resizing{
        border-right: 1px solid $BORDER_COLOR;
    }


    width: 100%;
    position: relative;

    .sindu_dragger{
        .gu-transit{
            margin-right: 0px !important;
            th{
                border-right: 2px solid $BORDER_COLOR;
                border-right: 1px solid $BORDER_COLOR;
                border-left: 1px solid $BORDER_COLOR;
                background: $GREY_LIGHT3;
                color: $FONT_COLOR;
            }
            td, th{
                opacity: 0.5;
            }
        }
        table{
            background: transparent;
        }
    }

    #extended-data-table-wrapper{
        padding-bottom: 14px;
        &.have-footer-row{
            padding-bottom: 60px;
        }
        &::-webkit-scrollbar {
            width: 0px;
            background: transparent;
        }

    }

    table, tr, td, th{
        border-collapse: collapse;
        border-spacing: 0;
        border: 1px solid $GREY_LIGHT3;
        background-clip: padding-box;
        user-select: auto;
    }

    .index-table{
        display: block;
        padding-bottom: 14px;
        border: none;
        user-select: none;
        -webkit-user-select: none;
    }

    #extended-table-data,
    .extended-table-data{
        border-left: 0px;
        thead,
        tbody{
            border-left: 0px;
            td{
                border: 0px solid $GREY_LIGHT3;
                &.have-input{
                    .text-block{
                        padding: 0;
                        height: auto;
                    }
                }
                &.file-size
                {
                    text-align: right;
                }
                &.timestamp-column-edit-mode
                {
                    background: $BG_WHITE;
                }
            }
            tr{
                border-left: 0px;
                &:hover{
                    cursor: pointer;
                    background-color: $GREY_HOVER;
                    background-clip: border-box;
                }
                &.active{
                    border-bottom: 1px solid $GREEN;
                    td{
                        border-bottom: 1px solid $GREEN;
                    }
                }
                td, th{
                    border-left: 0px;
                    &.static-column{
                        > div{

                            div:nth-child(2){
                                top : -6px;
                            }
                        }
                    }

                    div.th-text
                    {
                        height        : 100%;
                        overflow      : hidden;
                        text-overflow : ellipsis;
                        white-space   : nowrap;
                        display       : inline-block;
                        line-height   : 35px;
                    }
                }
                th{
                    height: auto;
                    .text-block
                    {
                        justify-content: space-between;
                        display: flex;
                        align-items: center;
                        height: 35px;

                    }
                    i.table-handle{
                        flex: 1;
                        height: 35px;
                    }
                    .sorting-icon-holder
                    {
                        height: 100%;
                        display: flex;
                        align-items: center;

                        .sorting-icon
                        {
                            width: 10px;
                            height: 0;
                            border-left: 5px solid transparent;
                            border-right: 5px solid transparent;
                            border-top: 6px solid $GREY;
                            cursor   : pointer;
                            opacity: 0.3;
                            &:hover
                            {
                                opacity: 1;
                                border-top-color: $GREEN;
                            }
                        }

                        &:hover{
                            .sorting-icon{
                                opacity: 1;
                                border-top-color: $GREEN;
                            }
                        }
                    }
                    &.ascending
                    {
                        .sorting-icon
                        {
                            width: 10px;
                            height: 0;
                            border-left: 5px solid transparent;
                            border-right: 5px solid transparent;
                            border-top: 6px solid $GREEN;
                            opacity: 1 !important;
                        }
                    }

                    &.descending
                    {
                        .sorting-icon
                        {
                            opacity: 1 !important;
                            width: 10px;
                            height: 0;
                            border-left: 5px solid transparent;
                            border-right: 5px solid transparent;
                            border-bottom: 6px solid $GREEN;
                            border-top: none;
                        }
                    }
                }
                &.file-upload-error, &.file-uploading
                {
                    td, span{
                        padding: 0px 5px 0px 5px;
                        border: none;
                        color: #F54A4F;
                        svg
                        {
                           g#SMALL-REMOVE
                           {
                                path{
                                    fill: #F54A4F;
                                }
                           }
                        }
                    }
                    td:last-child
                    {
                        border-right: 1px solid $GREY_LIGHT3;
                    }
                    .current-status{
                        float: right;
                    }
                }
                &.file-uploading
                {
                    background: #2f7165;
                    td, span
                    {
                        color: white;
                    }
                }
            }
        }

        &.bordered
        {
            tbody{
                td{
                    border: 1px solid $GREY_LIGHT3;
                    &:first-child{
                        border-left: 0px;
                    }
                    &:not(.hidden){
                        border-left: 0px;
                    }
                }

            }
        }
        &.striped {
            tbody{
                tr:nth-child(even) {
                    background: #3E3E48;
                }
                tr:nth-child(odd) {
                    background: #393943;
                }
                tr:hover{
                    background-color: $GREY_HOVER !important;
                }
                tr{
                    td
                    {
                        border-top: 1px solid #42424B;
                    }
                }
            }
        }
    }
    table
    {
        width: fit-content;
        position: relative;
        // overflow: hidden;
        width: -moz-min-content;
        table-layout: fixed;
        tr
        {
            .indexes,
            th
            {
                user-select: none;
                font-weight: bold;
                text-transform: uppercase;
                color: $GREY_LIGHT3;
                min-width: $INDEX_CELL_WIDTH;
                width: $INDEX_CELL_WIDTH;
                background-color: #373741;


            }
            th.indexes{
                width: $INDEX_CELL_WIDTH !important;
                > span{
                    width: 100%;
                    display: flex;
                    text-align: center;
                    justify-content:center;
                    padding: 7px;
                    cursor: pointer;
                    .index-checkbox{
                        max-height: 14px;
                    }
                }
            }
            th.indexes,
            td.indexes{
                &:hover,
                &.selected{
                    // background-color: #42424B;
                }
                > span.index-el{
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .index-numberasd
                    {
                        display: block;
                        width: 100%;
                        height: 100%;
                        padding: 8px 0;

                    }

                    cursor: pointer;
                    .index-checkbox{
                        display: none;
                    }
                    &.active,
                    &:hover{
                        .index-checkbox{
                            display: block;
                        }
                        .index-number{
                            display: none;
                        }
                        &.persistIdxTableEl{
                            .index-number{
                                display: block;
                                position: absolute;
                                opacity: 0;
                                z-index: 99;
                            }
                        }
                    }
                    .index-checkbox.block{
                        display: block;
                    }

                }

                .index-el-on-hover-wrapper{
                    display: block;
                    width: 100%;
                    .index-number{
                        display: block;
                        width: 100%;
                    }
                    .index-el{
                        display: none;
                    }
                    &:hover{
                        .index-number{
                            display: none;
                        }
                        .index-el{
                            display: block;
                        }
                    }
                }
            }

            th, td
            {
                text-align : left;
                height: 36px;
                font-size : 14px;
                overflow: inherit !important;
                position: relative;

                .text-block{
                    padding: 0px 10px 0px;
                }
                a{
                    font-size : 14px;
                }

                &:last-child{
                    text-align: unset;
                }
                &.remove-col{
                    padding-left: 6px;
                    border: 1px solid $GREY_LIGHT3 !important;
                    .text-block{
                        padding: 0;
                        text-align: center;
                        .ui-icon
                        {
                            width: 16px;
                            height: 17px;
                        }
                    }
                }
                span.col-move {
                    position: absolute;
                    right: -1px;
                    top:0;
                    z-index: 999;
                    height: 40px;
                    width: 3px;
                    &.resizeable {
                        border-right:1px solid $BORDER_COLOR;
                    }
                }
                .text-block {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    width: 100%;
                    height: 20px;
                    line-height: 18px;
                    user-select: auto;
                    .status-label{
                        font-size-adjust:0.47;
                    }

                }

                .width-comparison{
                    padding: 0 10px;
                }
            }

            th
            {
                span.col-move {
                    &:hover{
                        cursor: ew-resize;
                    }
                }

                &.hover-state:hover{
                    border-right: 1px solid $BORDER_COLOR;
                    border-left: 1px solid $BORDER_COLOR;
                }

                &.hover-state{
                    &:active{
                        background: $GREY_LIGHT3;
                        color: $FONT_COLOR;
                    }
                }

                &.sortable{
                    cursor: auto;

                    div{
                        pointer-events: all;
                        display: inline-block;
                    }
                    .text-block{
                        div
                        {
                            cursor: pointer;
                        }
                        &:hover{
                            div:nth-child(2)
                            {
                                border-top-color: $GREEN;
                            }
                        }
                    }
                    .sindu_hand
                    le.handler{
                        pointer-events: all;
                        cursor: grab;
                        display: inline-block;
                        width: 100%;
                        height: 18px;

                        &:active{
                            cursor: grabbing;
                        }
                    }

                }
            }
            th, td{
                &.indexes{
                    padding: 0px;
                    text-align: center;
                }
                &.text-center{
                    text-align: center;
                }
            }
            .mass-heading
            {
                .th-text
                {
                    text-transform: none !important;
                }
            }
        }
    }

    .sindu_dragger {
        display: flex;
        li {
            float: none;
            margin-right: -1px !important;
        }
        table {
            border-collapse: collapse !important;
        }

    }

    input[type="checkbox"]:not(old) + label.middle:after{
        content: "__";
        width: 11px;
        overflow: hidden;
        color: $GREEN;
        position: absolute;
        bottom: 4px;
        right: 0px;
        vertical-align: middle;
        left: 0;
        padding: 0;
    }

    table tr.warning-row,
    table tr.warning{
        td{
            border-top: 1px solid red !important;
            border-bottom: 1px solid red !important;
            &:not(.remove-col){
                background-color: rgba(245, 74, 79, .25);
            }

            &.remove-col{
                border-bottom: initial !important;
                border-top: initial !important;
            }
        }
    }
    table tr.no-bottom-border
        {
            td
            {
                border-bottom: 1px solid $GREY_LIGHT3!important;
            }
        }

    table tr.success{
        td{
            &:not(.remove-col){
                background-color: $TABLE_CELL_SUCCESS;
            }
        }
    }

    table.auto-fit-class{
        table-layout: auto !important;

        th, td{
            width: auto !important;
        }
    }

    .index-el-on-hover-wrapper{
        display: block;
        width: 100%;
        .index-number{
            display: block;
            width: 100%;
        }
        .index-el{
            display: none;
        }
        &:hover{
            .index-number{
                display: none;
            }
            .index-el{
                display: block;
            }
        }
    }

    .text-align-right{
        text-align: right !important;
        &.no-row-with-icon .row-with-icon{
            display: none;
        }
        .row-with-icon{
            justify-content: flex-end;
        }
        .column-with-icon{
            & > div{
                flex-basis: 100%;
            }
        }
    }
}

.extra-footer-row{
    height: 36px;

    position: absolute;
    display: flex;
    bottom: 24px;

    pointer-events: none;

    &.data-cell{
        width: 100%;
        border-bottom: 1px solid $GREY_LIGHT3;
        border-right: 1px solid $GREY_LIGHT3;
    }
    &.index-cell{
        border-bottom: 1px solid $GREY_LIGHT3;
        border-left: 1px solid $GREY_LIGHT3;
        .bordered{
            border-right: 1px solid $GREY_LIGHT3;
        }
    }
    .footer-index-cell{
        display: flex;
        pointer-events: all;
        width: $INDEX_CELL_WIDTH;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        &.hover:hover{
            background-color: $GREY_HOVER;
        }
    }
    .footer-data-cell{
        padding: 10px;
        p{
            font-size: 14px;
            color: $GREY_LIGHT3;
        }
    }
}

.ps--active-x + .extra-footer-row.data-cell{
    border-right: 0px;
}
.border-right + .extra-footer-row.data-cell{
    border-right: 1px solid $GREY_LIGHT3;
}

.gu-mirror{
    cursor: grabbing;
    background: $GREY_FOCUSED;
    table{
        background: $GREY_FOCUSED;
        td,th{
            display: none;
        }
    }
}

.sindu_dragger {
    display: flex;
    li {
        float: none;
        margin-right: -1px !important;
        &:first-child, table {
        }
    }
    table {
        border-collapse: collapse !important;
    }
}



.search.app{
    .ps__thumb-x,
    .ps__thumb-y{
        background-color: $GREY_LIGHT3 !important;
        opacity: 0.75;
    }
    .ps__thumb-y{
        width: 6px !important;
    }
    .ps__thumb-x{
        height: 6px !important;
    }
}

.custom-right-border {
    position: relative;
      &:after {
        content: "";
        width: 1px;
        height: calc(100% - 24px);
        background-color: #888888;
        position: absolute;
        right: 0;
        bottom: 24px;
    }
    &.last-row-missing{
         &:after {
            height: calc(100% - 14px);
            bottom: 14px;
        }
    }
}

.time-span {
    color: #fff;
}
.timestamp-column
{
    background: transparent;
    .date-span
    {
        color: #fff;
    }
}

.pagination-ui {
    position: absolute;;
    bottom: -60px;
    left: 0;
    display: flex;
    width: 100%;
    justify-content: flex-end;
    padding-bottom: 30px;
}
