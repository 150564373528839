@import "../../../styles/variables";

.right-search-menu
{
    height     : 100%;
    min-height : 100%;
    flex       : 0 0 200px;
    text-align : right;
    position   : initial;
    overflow   : hidden;
    position   : absolute;
    background : $BG_LIGHT2;
    z-index    : 2;
    top        : 0px;
    overflow   : hidden;
    width      : 0px;
    display    : block;
    font-size  : $FONT_SIZE_DEFAULT;

    span.cpn, span.result-count{
        padding-right: 5px;
        color: $GREY_LIGHT3;
    }

    > div
    {

        text-align : left;
        h3 {
            font-size: $FONT_SIZE_DEFAULT;
        }
    }
    .scrollarea {
        overflow: inherit;
        .scrollarea-content {
            overflow: initial;
        }
    }

    .header-block
    {
        padding-top    : 20px;
        padding-bottom : 10px;
        text-align     : left;
        padding-left   : 20px;
        padding-right  : 20px;
        h2 {
            font-size      : 16px;
            font-weight    : normal;
        }
        .clear-btn
        {
            font-size: $FONT_SIZE_DEFAULT;
            cursor: pointer;

            &:hover
            {
                color: $GREEN;
            }
        }
        .add-component-child-assemblies
        {
            text-align: left;
        }
    }
    .btn-add{
        &:focus
        {
            outline    : none;
            background : none;
            color      : $GREEN;
        }
        > div{
            display: flex;
            align-items: center;
        }
        .spinner{
            margin-right: 6px;
            margin-left: 10px;
        }
        .spinner,
        .ui-icon,
        .ui-icon svg
        {
            width  : 20px;
            height : 20px;
        }
    }
    label
    {
        font-size : $FONT_SIZE_DEFAULT;
        color     : $GREY;
    }

    input
    {
        line-height: 10px;
        padding: 6px 10px 7px;
    }

    &.open
    {
        width         : 270px !important;
        transition    : 0.1s;
        right         : 0;
        border-right  : 1px solid $BG_DARK;
        border-left   : 1px solid $BG_DARK;
        border-bottom : 1px solid $BG_DARK;
    }
    .components-fields {
        .required-fields-list {
            padding: 25px 0;
            list-style: none;
            li {
                margin-bottom: 5px;
            }
        }
    }
    .custom-muted-text {
        color: $FONT_LIGHT;
    }
    .note {
        margin-bottom: 50px;
    }
    .search-area-holder {
        border: 1px solid $FONT_COLOR;
        background: $BG_WHITE;
        display: flex;
        align-items: center;
        // margin-bottom: 20px;
        margin-left   : 20px;
        margin-right  : 20px;
        .ui-icon {
            width: 18px;
            height: 18px;
            margin-left: 10px;
            svg {
                fill: $GREY_LIGHT3;
            }
        }
        input{
            outline-style: none;
        }
        &.active{
            border: 1px solid $GREEN;
        }
    }

    .select-all-area {
        background: #41414B;
        display: flex;
        align-items: center;
        padding-left   : 20px;
        padding-right  : 20px;
        .select-all-holder {
            padding: 6px 0px;
            height: 36px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            /*margin-bottom: 15px;*/
            .select-all-label{
                margin: 0 10px;
                font-size: $FONT_SIZE_DEFAULT;
            }
            .checked-input{
                margin-top: 0px;
                &.disabled {
                    cursor: default;
                }
            }
            .btn-add {
                float: right;
                padding: initial;
                width: 100px;
                height: 24px;
                font-size: $FONT_SIZE_DEFAULT;
            }
        }
    }
    #right-list-content{
        height: 100%;
        .searchListHolder{
            height: 100%;
        }
        .result-count{
            //display         : flex;
            margin          : 10px 0px 10px 0px;
            padding         : 0 20px;
            //justify-content : space-around;
            color           : $FONT_LIGHT;
            font-size       : 14px;
        }
        .selected_count{
            min-width: 90px;
            text-align: right;
            float: right;
        }
        .visible
        {
            height: 100%;
            .list-area {
                max-height    : 85%;
                overflow      : auto;
                padding-left  : 20px;
                padding-right : 20px;
                ul {
                    list-style: none;
                    margin-bottom: 40px;
                    li {
                        display: flex;
                        padding: 0 0 10px;
                        align-items: center;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        label {
                            margin-right: 10px;
                            width: 12px;
                            height: 12px;
                        }
                    }
                }

                .cpn-name-holder
                {
                    margin-top: 7px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
    }
    .checked-input{
        width: 12px;
        height: 12px;
        margin-top: 10px;
    }

    .checked-label{
        width: 12px;
        height: 12px;
        &.middle
        {
            &.disabled
            {
                border: 1px solid $GREY_LIGHT3!important;
                &:after
                {
                    color: $GREY_LIGHT3;
                }
            }
        }
        &.disabled.cross-checked
        {
            border: 1px solid $GREY_LIGHT3;
            background: transparent !important;
            &:after,&:before
            {
                background:$GREY_LIGHT3;
            }
        }
        &.disabled
        {
            border: 1px solid $GREY_LIGHT3 !important;
        }
    }

    .right-search-expand {
        position: absolute;
        left: -7px;
        z-index: 2;
        width: 15px;
        height: 100%;
        cursor: ew-resize;
        .border{
            border-right: 1px solid transparent;
            transition: border-right 1s;
            height: 100%;
            right: 6px;
            text-decoration: none;
        }
        &:hover, &:active{
            .border{
                border-right: 1px solid $GREEN;
                right: 6px;
                height: 100%;
            }
        }
    }

    .close-icon
    {
        position: absolute;
        right: 20px;
        top: 20px;
        width:10px;
        z-index: 9;
        .ui-icon
        {
            width: 10px;
            height: 10px;
            &:hover
            {
                svg
                {
                    fill: $GREEN;
                }
            }
        }
    }
}

.sandbox-env{
    .right-search-menu
    {
        .select-all-holder{
            button{
                margin-right: 10px;
            }
        }
    }

}

.co-form-tip
{
    .rc-tooltip-inner
    {
        max-width: 250px;
        text-align: center;
    }
}
.disabled-all-tooltip
{
    top: 300px !important;
    .rc-tooltip-arrow
    {
        border-left-color: $FONT_BLACK;
        right: -3px;
        left: inherit !important;
    }
    .rc-tooltip-inner
    {
        padding: 8px 10px !important;
        background-color: $FONT_BLACK !important;
        border: 0 !important;
        color: $BG_WHITE;
        border-radius: 0px !important;
        line-height: 15px;
        min-height: unset;
        max-width: 200px;
        font-style: normal !important;
        margin-right: 3px;
    }
    .disbale-tooltip-text
    {
        line-height: 14px;
        font-size: 12px!important;
    }
}
