@import "../../../../styles/variables";

.view-release
{
    .header-section
    {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin: 20px 0 20px;
    }
    .status-nav
    {
        list-style: none;
        display: flex;
        align-items: center;

        .status-item
        {
            border: 1px solid $GREY_LIGHT3;
            background-color: unset;
            color: $FONT_LIGHT;
            border-radius: 16px;
            padding: 4px 8px;
            margin-right: 10px;

        }

        .DRAFT
        {
            border: 1px solid $CAUTION;
            color: $CAUTION;
        }

        .OPEN
        {
            border: 1px solid $BLUE_COLOR;
            color: $BLUE_COLOR;
        }

        .close-with-approve
        {
            border: 1px solid $PARROT_COLOR;
            color: $PARROT_COLOR;
        }

        .close-with-reject
        {
            border: 1px solid $ERROR;
            color: $ERROR;
        }

        li
        {
            .ui-icon
            {
                width: 12px;
                height: 7px;
                margin-right: 10px;
            }
        }
    }
    .nav-block{
        display: flex;
        color: $GREY_LIGHT3;
        margin-top: 25px;

        svg{
            fill: $GREY_LIGHT3;
        }

        >div{
            margin-right: 25px;

            > div{
                display: flex;
            }

            h2{
                font-size: $FONT_SIZE_19;
            }

            .ui-icon
            {
                width: 17px;
                height: 18px;
                margin-right: 10px;
                svg{
                    width: 17px;
                    height: 18px;
                }

            }
            h2
            {
                font-weight: normal;
            }

            .prd-cmp-holder
            {
                svg g{
                     stroke: $GREY_LIGHT3;
                }
            }
            .notification-holder{
                .ui-icon{
                    width: 20px;
                    svg{
                        width: 20px;
                        height: 20px;
                    }
                }
            }

            &:hover,
            &.selected{
                color: $FONT_COLOR;
                cursor: pointer;
                svg{
                    fill: $FONT_COLOR;
                }

                .prd-cmp-holder{
                    svg g{
                         stroke: $FONT_COLOR;
                    }
                }
                .notification-holder{
                    svg #shape-area{
                        fill: $FONT_COLOR;
                    }
                }
                .documents-holder{
                    &:hover{
                        svg path{
                            fill: $FONT_COLOR;
                        }
                    }
                }
            }
        }
    }

    .user-info-col
    {
        .user-profile-img
        {
            &.hidden
            {
                display: none !important;
            }
        }
    }

    .deactive {
        path {
            fill: #888888;
        }
    }
    .action-btn
    {
        color: $FONT_COLOR;
        border: none;
        background-color: rgba(216, 216, 216, 0.10);
        border-radius: 8px;
        margin-left: 10px;
        padding: 8px 12px;
        display: inline-flex;
        align-items: center;
        min-width: auto;

        .ui-icon
        {
            height: 16px;
            width: 16px;
            margin-right: 10px;
            svg
            {
                fill: $FONT_COLOR!important;
            }
        }
        &:hover{
            color:  $GREEN;
            svg{
                fill: $GREEN !important;
            }
            &.delete-btn
            {
                g{
                    fill: $GREEN !important;
                    stroke: $GREEN !important;
                }
            }
        }
    }
    .release-vendor-label {
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
        justify-content: space-evenly;
        color: #18171D;
        font-size: 14px;
        height: 18px;
        line-height: 0px;
        border-radius: 10rem;
        background-color: #04ADC3;
        padding: 0px 5px;
        .ui-icon {
            margin-right: 5px;
            pointer-events: none;
            width: 20px;
            height: 20px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            svg {
                g {
                    fill: #000;
                }
            }
        }
        span {
            color: #000;
        }
    }
    .records-count {
        color: #FFF;
    }
    .co-link-icon
    {
        width: 15px;
        height: 15px;
        .open-link-icon
        {
            #arrow-area
            {
                stroke: #3CD1B5;
            }
        }
    }
    .link
    {
        &:hover
        {
            #arrow-area
            {
                stroke: #FFFFFF;
            }
        }
    }
}
.vendor-nav {
    max-width : 140px;
}
.release-children {
    .link
    {
        color: $GREEN;
        cursor: pointer;
        &:hover {
            color: $FONT_COLOR;
        }
    }
}
