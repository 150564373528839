@import "../../../../../styles/_variables.scss";

.edit-product-route .tabs-block .documents-tab-drop-zone,
.edit-component-route .tabs-block .documents-tab-drop-zone,
.edit-production-instance-route .tabs-block .documents-tab-drop-zone
{
      width         : 400px;
      padding-bottom: 25px;
      .drop-zone
      {
          cursor   : pointer;
          overflow : hidden;
          height   : 37px;
          border   : 1px dashed white;

          > div:first-child
          {

              display         : flex;
              padding-left    : 15px;
              padding-right   : 15px;
              align-items     : center;
              height          : 100%;
              justify-content : space-between;

              .ui-icon
              {
                  width  : 15px;
                  height : 15px;

                  svg
                  {
                      fill: $GREEN;
                  }
              }

              > div:first-child
              {
                  span:nth-child(2)
                  {
                      margin-left     : 10px;
                      font-size       : 14px;
                      color           : $GREY;
                      text-decoration : underline;
                  }
              }
          }

          .modal
          {
              display: none;
              height: 100%;
              width: 100%;
          }

          &.accept-files
          {
              padding: 5px;

              > div:first-child
              {
                  display: none;
              }

              .modal
              {
                  display: block;
                  background: $GREEN;
              }
          }

          &.reject-files
          {
              padding: 5px;

              > div:first-child
              {
                  display: none;
              }

              .modal
              {
                  display    : block;
                  background : $ERRORS
              }
          }
      }
}

.edit-product-route .tabs-block .documents-tab,
.edit-component-route .tabs-block .documents-tab,
.edit-production-instance-route .tabs-block .documents-tab
{
    select, input[type='text']{
        height: 35px;
    }
    .documents-table-view{
        #table-settings-menu{
            .modal{
                top: -45px;
            }
        }
    }
    .current-status{
      .current-status-text{
        font-style: italic;
        line-height: normal;
      }
      .cancel-file{
        color: #3CD1BF !important;
        cursor: pointer;
      }
  }
}
