.rev-action-modal
{
    display: block;
    background-color: #17171F;
    max-width: 225px;
    min-height: 171px;
    padding: 14px;
    z-index: 1;
    position: absolute;
    &:after
    {
        content: '';
        position: absolute;
        right: -8px;
        top: 50%;
        width: 0px;
        height: 0px;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-left: 10px solid #17171F;

    }
    .header-block
    {
        margin-bottom: 15px;
        h2
        {
            font-size: 14px;
        }
    }
    .rev-action-types
    {
        .custom-radio
        {
            .radio
            {
                margin-bottom: 15px;
                display: flex;
                align-items: center;
                input
                {
                    height: 15px !important;

                }
                .custom-rev-input
                {
                    width: 58px;
                    height: 24px !important;
                    opacity: 9;
                    margin-left: 10px;
                    padding: 3px;
                    cursor: text;
                }
                &.custom-rev-holder
                {
                    margin-bottom: 20px;
                }
            }
            .custom-revision-label
            {
                width: 58px;
                height: 24px;
                margin-left: 10px;
                border: 2px solid #9b9b9b;
                padding: 2px 4px;
                color: #9b9b9b;
            }

            .custom-rev-input
            {
                color: #000 !important;
                &.hidden
                {
                    display: none;
                }
            }

        }
    }
    .bottom-section
    {
        display: flex;
        .cancel-btn
        {
            border: 0;
            color: #9b9b9b;
            padding: 8px 14px;
            min-width: auto;
            &:hover
            {
                background: transparent;
                color: #9b9b9b;
            }

        }
        .apply-btn
        {
            border-radius: 8px;
            padding: 8px 14px;
            min-width: auto;
            &:hover
            {
                color: #18171d;
            }
        }
    }
}

body
{
    .custom-rev-tip {
        .rc-tooltip-content
        {
            .rc-tooltip-inner
            {
                padding: 5px 10px !important;
                max-width: 203px;
                text-align: left;
                font-style: normal;
            }
        }
    }
}
