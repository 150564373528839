@import "../../../../styles/variables";

.template-select {
	.manage-templates {
		.ui-icon {
			svg {
				&:hover {
					g {
						fill: $GREEN;
					}
				}
			}
		}
	}
}