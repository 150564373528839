@import "../../../../../styles/variables";

.customized-documents-types
{
    .modal-content{
        padding: 0 !important;
        .scrollbar-container {
            max-height: 62vh;
            padding: 0 15px 0 50px;
            max-width: 415px;
        }
        .modal-heading{
            padding: 0 50px;
            h2{
                font-size: 14px;
                font-weight: bold;
                padding-bottom: 10px;
                margin-bottom: 15px;
                border-bottom: 1px solid #d8d8d840;
            }
        }
    }

     .customise-documents-table-header{
            padding: 0 50px;
            max-width: 350px;
            margin: 0 auto;
            thead{
                tr{
                    border-bottom: 1px solid #d8d8d840;
                    th{
                        font-weight: bold;
                        font-size: 14px;
                        color: #888;
                        padding: 0px 10px 6px 0;
                        text-align: left;
                        .checkbox-holder{
                            height: 16px;
                            label.middle:after {
                                content: "__";
                                width: 100%;
                                margin: 0 auto;
                                color: #3CD1BF;
                                position: absolute;
                                left: 4%;
                                bottom: 4px;
                            }
                            input{
                                margin-bottom: 0px;
                            }
                        }
                        &:nth-child(1){
                            width: 35px;
                            padding: 0;
                        }
                    }
                }
            }
        }
        .customise-documents-table-cotent{
            tbody{
                tr{
                    border-bottom: 1px solid #d8d8d840;
                    td{
                        padding: 6px 0;
                        text-align: left;
                        .header-input{
                            margin-bottom: 0px;
                            height: 25px;
                        }
                        .checkbox-holder{
                            input{
                                margin-bottom: 0px;
                            }
                        }
                        &:nth-child(1){
                            width: 35px;
                            .checkbox-container{
                                display: flex;
                                align-items: center;
                                padding-top: 6px;
                                .checkbox-holder{
                                    height: 21px;
                                }
                            }
                        }
                    }
                }
            }
        }

    .ui-modal-box
    {
        margin-top: 90px;
        .background
        {
            background: transparent;
        }
        .modal
        {
            background: #18171d;
            width: 100%;
            height: auto;
            max-width: 450px;
            padding: 30px 0;
            .modal-content
            {
                .ui-icon
                {
                    height: 18px;
                    width: 17px;
                }
                .btn-holder {
                    margin-top: 15px;
                    width: 100%;
                    display: flex;
                    padding: 0 40px 0 50px;
                    justify-content: flex-end;

                    .btn-container{
                        button{
                            padding: 6px 12px;
                            &:nth-child(2){
                                margin-left: 10px;
                                min-width: 83px;
                            }
                            &.disable {
                                color: #888888;
                                border-color: #888888;
                                pointer-events: none;
                                &:hover {
                                    color: none;
                                    background: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
