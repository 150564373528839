@import "../../../../styles/variables";

.edit-changeorder-route
{
    .banner-block
    {
        background : $CAUTION;

        > div
        {
            display         : flex;
            margin-bottom   : 30px;
            color           : black;
            justify-content : space-between;
            align-items     : center;

            > div
            {
                margin      : 0;
                display     : flex;
                align-items : center;
                margin      : 5px 0;

                &:nth-child(1)
                {
                    .ui-icon
                    {
                        pointer-events : none;
                        width          : 16px;
                        height         : 19px;
                        margin-right   : 10px;

                        svg
                        {
                            fill : black;
                        }
                    }
                }

                &:nth-child(2)
                {
                    a
                    {
                        margin-right : 20px;
                        color        : black;
                    }

                    input[type="submit"]
                    {
                        padding      : 5px 10px;
                        color        : $FONT_YELLOW;
                        border-color : black;
                        background   : black;

                        &:hover
                        {
                            background : none;
                            color      : black;
                        }
                    }
                }
            }
        }
    }

    .crumb-block
    {
        color         : $GREY;
        margin-top    : 20px;
        margin-bottom : 40px;
    }

    h1
    {
        margin-bottom: 40px;
    }

    .align-block
    {
        display: flex;
        justify-content: space-between;
    }
}

.changeorder.edit,
.changeorder.new{
    #routes{
        > div:first-child{
            height: 100%;
        }
    }
}
