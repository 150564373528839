@import "../../../styles/variables";

.users-settings-route,
.user-settings-route,
.webhooks-settings-route,
.company-settings-route,
.company-settings-cpn-scheme-route,
.configuration-settings-route,
.groups-settings-route
{
    display: flex;

    .crumb-block
    {
        color         : $GREY;
        margin-bottom : 30px;
    }

    button.cancel-btn
    {
        margin-right: 10px;
        width: 96px;
        min-height: 38px;
        padding: 0;
    }

    .submit-btn
    {
        width: 96px;
        min-height: 38px;
        padding: 0;
        background: transparent;
        color: $GREEN;
        &.disabled {
            border-color: $GREY;
            color: $GREY;
            background: transparent;
        }
    }

    .main-block
    {
        margin-left: 65px;
        .content-block
        {
            margin        : 0 auto;
            min-width     : 755px;
            h1
            {
                line-height: 34px;
                margin-bottom: 10px;
                font-size: 23px;
                margin-bottom : 10px;
            }

            h3
            {
                font-size: 14px;
                margin-bottom : 40px;
            }

            .form-block
            {
                display         : flex;
                justify-content : space-between;

                .info-block
                {
                    width : 405px;
                }
            }
        }
    }
}
