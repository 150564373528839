@import "../../../../styles/variables";

.cmp-success-popover
{
    background-color: #fff;
    z-index: 100000;
    max-width: 350px;
    margin-top: -25px;
    width: 100%;

    &.without-add-another
    {
        margin-top: 180px;
        margin-left: 350px;
    }

    .popover-content-block {
        padding: 22px 15px;
        color: #4a4a4a;
        h4 {
            color: #18171d;
            font-size: 14px;
            font-weight: bold;
            margin-bottom: 5px;
        }
        p{
            margin-bottom: 5px;
        }
        .ui-link {
            max-width: 99%;
            display: inline-block;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            padding-right: 20px;
            position: relative;
            color: #1ac7a7;
            &:hover {
                color: #1ac7a7;
            }
        }
        .ui-icon {
            position: absolute;
            right: 0;
            width: 20px;
            height: 20px;
            svg
            {
                g
                {
                    fill: #1ac7a7;
                }
            }
        }
    }
}
