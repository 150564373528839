@import "../../../styles/variables";


.ui-toggle-btn
{
    background    : $BG_DARK;
    border-radius : 13px;
    width : 44px;
    padding : 2px 0;
    cursor        : pointer;
    transition    : background 0.08s;

    > div
    {
        height        : 17px;
        width         : 17px;
        background    : white;
        border-radius : 100%;
        transition    : margin-left 0.08s;
    }

    &.selected
    {
        background: $GREEN;

        > div
        {
            margin-left: 24px;
        }
    }

    &.middle
    {
        border: 1px solid $GREEN;
        padding: 1px 0;
        > div
        {
            margin-left : 13px;
        }
    }

    &.disabled
    {
        pointer-events : none;
        opacity        : 0.5;
    }
}
