.workspaces-list {
    .modal {
        max-width: 600px;
        padding-bottom: 0;
        top: 10%;
        .modal-content {
            padding: 30px;
            .workspaces-list {
                .modal-heading {
                    margin-bottom: 20px;
                }
                .list-table {
                    text-align: right;
                    .table-wrapper-scroll {
                        max-height: 25vh;
                    }
                    .workspaces-table {
                        tr {
                            border-bottom: 0;
                            td {
                                text-align: left;
                                padding-left: 25px;
                            }
                        }
                    }
                }
            }
        }
    }
}
