@import "../../../../../styles/variables";

#rev-history-table
{
    div:first-child
    {
        text-transform : capitalize;
    }

    .header-block
    {
        .help-icon
        {
            margin-left: 2px;
            .ui-icon
            {
                svg
                {
                    rect
                    {
                        fill: transparent;
                    }
                }
            }
        }
        .compare-rev-btn
        {
            align-items: center;
            cursor: pointer;
            margin-bottom: 5px;

            &:last-child {
                margin-bottom: 0;
            }

            .ui-icon
            {
                width: auto;
                margin-right: 5px;
                margin-left: 0;

                svg {
                    margin: 0;
                }
            }
            &.active
            {
                .ui-icon
                {
                    svg
                    {
                        g
                        {
                            fill: $GREEN;
                        }
                    }
                }
                color: $GREEN;
                &:hover
                {
                    .ui-icon
                    {
                        svg
                        {
                            g
                            {
                                fill: $BG_WHITE;
                            }
                        }
                    }
                    color: $BG_WHITE;
                }
            }

            &.add-to-co
            {
                .ui-icon
                {
                    svg
                    {
                        g
                        {
                            fill: $BG_WHITE;
                        }
                    }
                }
                color: $BG_WHITE;
                &:hover
                {
                    .ui-icon
                    {
                        svg
                        {
                            g
                            {
                                fill: $GREEN;
                            }
                        }
                    }
                    color: $GREEN;
                }
            }

            &.revert-back
            {
                .ui-icon
                {
                    svg
                    {
                        .ui-icon svg g path
                        {
                            stroke: $BG_WHITE;
                        }
                    }
                }
                color: $BG_WHITE;
                &:hover
                {
                    .ui-icon svg g path
                    {
                        stroke: $GREEN;
                    }
                    color: $GREEN;
                }
            }
        }
    }

    &.open
    {
        display: block;
        min-height: 100px;
    }

    .content-block
    {
        .simple-table
        {
            table
            {
                thead
                {
                    tr
                    {
                        th
                        {
                            &:first-child
                            {
                                width: 30px;
                                .ui-icon
                                {
                                    width: 15px;
                                    height: 15px;
                                    margin-left: 0;
                                }
                            }
                            &.cpn-col
                            {
                                width: 173px;
                            }
                            &:last-child
                            {
                                text-align: left;
                                width: 180px;
                            }
                            &:nth-child(3)
                            {
                                width: 160px;
                            }
                            &:nth-child(4)
                            {
                                width: 95px;
                            }
                            &:nth-child(5)
                            {
                                width: 50px;
                            }
                            &:nth-child(6)
                            {
                                width: 127px;
                            }
                            .sorting-icon-holder
                            {
                                display: none;
                            }
                            .text-block
                            {
                                padding: 8px 9px;
                            }
                            .th-text
                            {
                                text-transform: uppercase;

                                .ui-icon {
                                    width: 17px;
                                    height: 17px;
                                }
                            }
                        }
                    }
                }
                tbody
                {
                    tr
                    {
                        td
                        {
                            &:first-child
                            {
                                padding: 4px 8px;
                                .rounded-checkbox
                                {
                                    .arrow-button
                                    {
                                        position: absolute;
                                        top: 0;
                                        left: 0;
                                        right: 0;
                                        margin: 0 auto;
                                        width: 15px;
                                        height: 15px;
                                        &.right
                                        {
                                            left: -1.8px;
                                        }
                                    }
                                }
                            }

                            &.cpn-col
                            {
                                .ui-link
                                {
                                    display: inline-block;
                                    max-width: 100%;
                                    .user-name
                                    {
                                        width: fit-content;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        white-space: nowrap;
                                    }
                                    .text-block
                                    {
                                        position: relative;
                                        padding-left: 45px;
                                        color: #fff;
                                        text-transform: initial;
                                        display: flex;
                                        align-items: center;
                                        .sub-rev-icon
                                        {
                                            margin: 0;
                                            position: absolute;
                                            left: -1px;
                                            top: 7px;
                                            width: 10px;
                                            height: 10px;
                                            transform: rotate(270deg);
                                            &.open
                                            {
                                                top: 7px;
                                                left: 1px;
                                                transform: rotate(0);
                                            }
                                        }
                                        .user-avatar-holder
                                        {
                                            width: 20px;
                                            height: 20px;
                                            display: block;
                                            position: absolute;
                                            top: 2px;
                                            left: 16px;
                                        }
                                        .face-avatar
                                        {
                                            min-width: 20px;
                                            height: 20px;
                                            margin: 0;
                                            .ui-icon
                                            {
                                                margin: 0 10px 0 0;
                                                width: 20px;
                                                height: 20px;
                                            }
                                            svg
                                            {
                                                width: 20px;
                                                height: 20px;
                                            }
                                        }
                                    }

                                    .user-profile-img
                                    {
                                        display: flex;
                                        width: 20px;
                                        height: 20px;
                                        overflow: hidden;
                                        border-radius: 100%;
                                        margin-right: 10PX;
                                        align-items: center;
                                        img
                                        {
                                            width: 100%;
                                        }
                                        &.hidden
                                        {
                                            display: none;
                                        }
                                    }
                                }
                            }

                            &.revision-col {
                                .revision-value {
                                    display: flex;

                                    .ui-icon {
                                        width: 15px;
                                        height: 15px;
                                    }
                                }
                            }

                            .text-block
                            {
                                padding: 5px 0px;

                                .link {
                                    display: flex;
                                }
                            }
                        }
                        &.sub-rev
                        {
                            td.cpn-col
                            {
                                .ui-link
                                {
                                    .text-block
                                    {
                                        padding-left: 60px;
                                        .user-avatar-holder
                                        {
                                            left: 32px;
                                            width: 20px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .ui-icon {
                    padding: 0;
                }
            }
        }
        .ui-loading
        {
            position: relative;
            height: auto;
            .ui-icon
            {
                width: 0;
                height: 0;
                margin-left: 0;
            }
        }
    }
}
.help-container {
    margin-top: -6px;
    .rc-tooltip-arrow {
        border-width: 6px 8px 0 !important;
        border-top-color: #222 !important;
    }
    .rc-tooltip-inner {
        color: #FFF;
        background-color: #222;
        border: 1px solid #222;
        padding: 5px;
        line-height: 15px;
        min-height: unset;
    }
}

.custom-rev-modal {
    .header-block {
        align-items: flex-end;
        .item-flex {
            &:last-child {
                flex-flow: column;
                align-items: flex-start;
                .compare-rev-btn {
                    margin-bottom: 17px;
                    text-transform: initial!important;
                    padding-left: 10px;
                    &:first-child {
                        padding-left: 0;
                        cursor: initial!important;
                    }
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}
