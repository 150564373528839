@import "../../../styles/variables";


.ui-loading
{
    position        : fixed;
    top             : 0;
    left            : 0;
    width           : 100%;
    height          : 100vh;
    z-index         : 10000;
    display         : flex;
    justify-content : center;
    align-items     : center;

    .background
    {
        position   : absolute;
        width      : 100%;
        height     : 100vh;
        background : black;
        opacity    : 0;
    }

    .loading-block
    {
        @keyframes rotate
        {
            from {transform: rotate(0deg);}
            to {transform: rotate(360deg);}
        }

        width                     : 40px;
        height                    : 40px;
        animation-name            : rotate;
        animation-duration        : 0.4s;
        animation-iteration-count : infinite;
        animation-timing-function : linear;
        display: flex;
        align-items: center;
        justify-content: center;

        .ui-icon
        {
            display: flex;
            justify-content: center;
            align-items: center;
            width  : 40px;
            height : 40px;

            svg
            {
                overflow: visible;
                fill: $GREEN;
            }
        }
    }

    .text-block
    {
        position   : absolute;
        padding-left: 230px;
    }
}

.spinner{
    @keyframes rotate
    {
        from {transform: rotate(0deg);}
        to {transform: rotate(360deg);}
    }
    width   : 30px;
    height  : 30px;
    .ui-icon,
    .ui-icon svg
    {
        width  : 30px;
        height : 30px;
    }
    animation-name            : rotate;
    animation-duration        : 0.4s;
    animation-iteration-count : infinite;
    animation-timing-function : linear;
}
