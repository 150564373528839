@import "../../../styles/variables";

.progress-modal {
    margin-top: 50px;
    display: flex;
    align-items: center;
    width: 100%;
    height: 89vh;
    .progress-bar-holder {
        margin: 0 auto;
    }
}
.progress-bar-holder {
    text-align: center;
    h2{
        font-size: 23px;
    }
    span {
        color: $FONT_LIGHT;
        font-size: $FONT_SIZE_19;
        display: inline-block;
        margin-top: 5px;
    }
    svg
    {
        transform: rotate(180deg);
    }
    .progressbar-text
    {
        color: white !important;
    }

    .percentage-text
    {
        font-size: 36px;
        color: $FONT_COLOR;
    }
    .percentage-sign
    {
        font-size: 20px;
        color: $FONT_COLOR;
        vertical-align: top;
        margin-top: 7px;
        display: inline-block;
    }
    .progressbar-container
    {
        width: 159px;
        height: 159px;
        margin: 0 auto 40px;
        svg{
            width: auto;
        }
    }

}
