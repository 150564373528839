@import "../../../../../styles/variables";

.view-product-route .actions-block,
.product-revision-route .actions-block
{
    .banner-block
    {
        background : $MODIFED;
        &.archived
        {
            background : #F54A4F;
        }

        a
        {
            color           : black;
            margin          : 0px 5px;
            text-decoration : underline;

            &:hover
            {
                color: black;
            }
        }

        > div
        {
            display         : flex;
            color           : black;
            justify-content : space-between;
            align-items     : center;
            height          : 50px;

            > div
            {
                margin      : 0;
                display     : flex;
                align-items : center;
                margin      : 5px 0;

                &:nth-child(1)
                {
                    .ui-icon
                    {
                        pointer-events : none;
                        width          : 16px;
                        height         : 19px;
                        margin-right   : 10px;

                        svg
                        {
                            fill : black;
                        }
                    }
                }

                &:nth-child(2)
                {
                    a
                    {
                        margin-right : 20px;
                        color        : black;
                    }

                    input[type="submit"]
                    {
                        padding      : 5px 10px;
                        color        : $FONT_YELLOW;
                        border-color : black;
                        background   : black;

                        &:hover
                        {
                            background : none;
                            color      : black;
                        }
                    }
                }
            }
        }
    }

    .nav-block
    {
        display         : flex;
        justify-content : space-between;
        padding-top     : 19px;
        margin-bottom   : 5px;
        position        : relative;

        > div:last-child{
            height: 10px;
        }

        .product-name {
            margin-right: 2rem;
        }

        div
        {
            &:last-child
            {
                .ui-icon
                {
                    width       : 15px;
                    height      : 15px;
                    margin-left : 20px;

                    &.active
                    {
                        svg
                        {
                            fill: $GREEN;
                        }
                    }

                    &.fav-icon
                    {
                        &:hover
                        {
                            svg
                            {
                                fill: white;
                            }
                        }

                        &.faved
                        {
                            svg
                            {
                                fill: #f55ee4;
                            }

                            &:hover
                            {
                                svg
                                {
                                    fill: #f55ee4;
                                }
                            }
                        }
                    }
                    &.variant-stroke
                    {
                        &:hover
                        {
                            svg
                            {
                                g
                                {
                                 stroke: $GREEN;
                                }

                            }
                        }
                    }
                    &:hover
                    {
                        #Create-Variant-1
                        {
                            stroke: $GREEN;
                        }
                    }

                }
            }


        }
    }

    .changeorder-actions-block{
        width: 15px;
        height: 15px;
        display: inline-block;
        margin-left: 0;
        position: inherit;
        .trigger{
            padding: 1px;
            position: inherit;
            &:hover{
                .ui-icon svg{
                    stroke: $GREEN;
                }
            }
        }

        .changeorder-actions{
            position: inherit;
            &.open{
                .sub-menu{
                    display: block;
                }
                .trigger{
                    .ui-icon svg g{
                        stroke: $GREEN;
                    }
                }
            }

            &.editable-while-in-CO
            {
                .sub-menu{
                    min-height: 76px;
                }
            }

            .sub-menu{
                z-index: 1;
                min-height: 105px;
                display: none;
                position: absolute;
                background: #17171F;
                margin-top: 5px;
                right: -20px;
                padding: 5px 0;
                text-align: left;
                white-space: nowrap;
                min-width: 219px;
                font-family: 'Roboto', sans-serif;
                div {
                    text-transform: none;
                }
                .option {
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    align-items: center;
                    margin:5px 5px 15px;
                    &:hover{

                        .ui-icon svg {
                            fill: $GREEN;
                        }
                        .ui-icon svg g path{
                            stroke: $GREEN;
                        }
                        .ui-icon svg g polygon{
                            fill: $GREEN;
                        }
                        .label {
                            color: $GREEN;
                        }
                    }
                    .ui-icon {
                        width: 16px;
                        height: 15px;
                        margin-right: 10px;
                        margin-top: -6px;
                    }
                    .label{
                        font-size: $FONT_SIZE_DEFAULT;
                        color: $FONT_COLOR;
                    }
                }
                .heading-holder {
                    font-size: $FONT_SIZE_DEFAULT;
                    margin:9px 15px;
                    text-transform: none;
                    color: $FONT_LIGHT;
                }
            }
        }
    }

}
