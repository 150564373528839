@import "../../../../../styles/variables";

.new-component-web-route
{
    .modal {
        padding: 25px;
        border-radius: 8px;
        max-width: 564px;
        width: 100%;
        min-width: auto;
        .modal-content {
            .main-block {
                .content-block {
                    .custom-modal-header {
                        .header-holder {
                            display: flex;
                            justify-content: space-between;
                            margin-bottom: 20px;
                            .heading-holder {
                                display: flex;
                                h2{
                                    margin-right: 10px;
                                }
                                .ui-icon
                                {
                                    margin-top: 6px;
                                }
                            }
                            .cpn-tag-holder {
                                display: flex;
                                align-items: center;
                                color: #888888;
                                h4{
                                    margin-right: 15px;
                                }
                                .cpn-tag {
                                    border: 1px solid #888888;
                                    padding: 6px 8px;
                                }
                            }
                        }
                    }
                    .card-panel {
                        overflow: hidden;
                        .card-panel-header {
                            padding: 10px 25px;
                            background-color: #41414A;
                            border-top-left-radius: 8px;
                            border-top-right-radius: 8px;
                            h3 {
                                color: #888888;
                                font-weight: bold;
                            }
                        }
                        .card-panel-body {
                            padding: 25px;
                            border-bottom-left-radius: 8px;
                            border-bottom-right-radius: 8px;
                            margin-bottom: 30px;
                            background-color: #353540;


                            .new-component-category-types {
                                margin: 0 0 17px;
                                display: flex;
                                justify-content: flex-start;
                                .category-nav {
                                    margin-right: 35px;
                                    text-align: center;
                                    &:last-child {
                                        margin-right: 0;
                                    }
                                    &:hover,
                                    &.selected
                                    {
                                        cursor: pointer;
                                        .ui-icon
                                        {
                                            svg {
                                                .path{
                                                    fill: #3cd1b5;
                                                }
                                            }
                                        }
                                        label
                                        {
                                            color: #3cd1b5;
                                        }
                                    }
                                    .ui-icon {
                                        width: 25px;
                                        height: 25px;
                                        svg {
                                            fill: #cfcfcf;
                                        }
                                    }
                                    label {
                                        color: #cfcfcf;
                                    }
                                }
                            }
                            .categoryType-heading {
                                margin-bottom: 20px;
                                color: #888888;
                                font-size: 14px;
                            }
                            .form-group
                            {
                                margin-bottom: 30px;
                                label
                                {
                                    color: #888;
                                    font-size: 14px;
                                    text-transform: uppercase;
                                }
                                .form-control
                                {
                                    height: auto;
                                    padding: 6px 10px;
                                    min-height: 30px;
                                    border: 0;
                                }

                                .mpn-input-holder {
                                    display: flex;
                                    align-items: center;
                                    .form-control {
                                        padding-right: 30px;
                                    }
                                    .ui-icon {
                                        width: 15px;
                                        height: 15px;
                                        position: absolute;
                                        right: 7px;
                                    }
                                }

                            }
                        }
                        .card-panel-footer {
                            display: flex;
                            justify-content: space-between;
                            .left-section {
                                button {
                                    padding: 6px 12px;
                                }
                            }
                            .right-section {
                                display: flex;
                                align-items: center;
                                .checkbox-label {
                                    display: flex;
                                    align-items: center;
                                    label {
                                        width: 15px;
                                        height: 15px;
                                    }
                                    .label_value {
                                        margin:0 15px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .simple-rc-tip
            {
                max-width: 250px;
                width: 100%;
                &.not-found
                {
                    max-width: 176px;
                }
            }

        }
    }
    .two-scheme-mpn-tooltip {
        width: 15px;
        height: 15px;
        position: absolute;
        right: 7px;
        top: 10px;
    }
    .duplicate-name-modal
    {
        .modal{
            width: 564px;
            padding: 28px 44px;

            h1, p,.warning-text{
                .ui-icon{
                    width: 30px;
                }
                margin-bottom: 30px;
            }
            .name-field-label{
                margin-bottom: 11px;
            }

            .inputs-section{
                div{
                    label, input, span{
                        display: inline-block;
                    }
                    input{
                        height: 24px;
                    }
                    label{
                        width: 115px;
                    }
                }
            }
            .bottom-section{
                width: 100%;
                float: right;
                margin-top: 15px;
                padding-top: 44px;
                border-top: 1px solid #979797;
                .button-sections{
                    float: right;
                    button{
                        width: 160px;
                        height: 30px;
                        padding: 0;
                        margin-left: 10px;
                    }
                }
            }
        }
    }

}
