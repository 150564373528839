$GREEN        : #3CD1BF;
$GREY         : #B3B3B3;
$GREY_LIGHT   : #CCCCCC;
$GREY_LIGHT2  : #E1E1E1;
$GREY_LIGHT3  : #888888;
$GREY_LIGHT4  : #5D5C5C;
$GREY_FOCUSED : #D8D8D8;
$GREY_HOVER   : #35354A;

// Background Styles
$BG_DARK      : #17171F;
$BG_DARK2     : #23232A;
$BG_LIGHT     : #2A2A36;
$BG_LIGHT2    : #353540;
$BG_LIGHT3    : #18171D;
$BG_LIGHT4    : #2D2D39;
$BG_WHITE     : #FFFFFF;

$HIGHLIGHT    : #4A4A54;
$ERRORS       : #F54A4F;
$PLACEHOLDER  : #c5c3c3;

$TABLE_CELL_BG : #3D3D47;
$TABLE_CELL_SUCCESS : rgba(60, 209, 181, .20);
$BORDER_COLOR : #56d2b5;

// Font Colors
$FONT_COLOR   : #FFFFFF;
$FONT_LIGHT   : #888888;
$FONT_YELLOW  : #FFFD91;
$FONT_BLACK   : #000000;
$FONT_MENU    : #cbcbcb;
$BLUE_COLOR   : #9accff;
$PARROT_COLOR : #7AE173;
$DIFF_ADD_COLOR : #6DBA69;
$DIFF_REMOVE_COLOR : #C64D51;
$DIFF_UPDATE_COLOR : #4F8BC9;


// Font Families
$FONT_FAMILY  : 'Roboto', sans-serif;
$BUTTON_FONT  : 'Roboto', sans-serif;

$MIN_WIDTH    : 900px;
$MAX_WIDTH    : 10000px;
$MESSAGE_SIZE : 11px;
$SITE_MARGINS : 44px;
$SITE_MARGINS_OUTER : 24px;
$SLIM_ROUTE   : 550px;
$CHECK_SIZE   : 11px;

$CAUTION      : #FFFD91;
$ERROR        : #F54A4F;
$BLACK_ERROR  : #000;
$SUCCESS      : #3CD185;
$MODIFED      : #F5A64F;
$TRANSITION   : all 0.1s;
$BLUE_TOOL_TIP_COLOR   : #04adc3;

$INDEX_CELL_WIDTH   : 35px;

// Font Sizes
$FONT_SIZE_DEFAULT  : 14px;
$FONT_SIZE_12    	: 12px;
$FONT_SIZE_16    	: 16px;
$FONT_SIZE_19    	: 19px;
$FONT_SIZE_21    	: 21px;
$FONT_SIZE_23    	: 23px;

//Font width and height variables
$DEFAULT_REVISION_INPUT_WIDTH: 33px;
$CUSTOM_REVISION_INPUT_WIDTH: 61px;
$REVISION_INPUT_HEIGHT: 24px;

// Icon colors
$LIKE: #f55ee4;

// Utilities
$THUMB_HOVER: rgba(170, 170, 170, .7);
