@import "../../../../../styles/variables";

.new-component-manual-route
{
    .modal {
        padding: 25px;
        border-radius: 8px;
        max-width: 564px;
        width: 100%;
        min-width: auto;
        margin-top: -30px;
        &.more-then-five-cat
        {
            max-width: 745px;
        }
        .modal-content {
            .main-block {
                .content-block {
                    .custom-modal-header {
                        .header-holder {
                            display: flex;
                            justify-content: space-between;
                            margin-bottom: 20px;
                            .heading-holder {
                                display: flex;
                                h2{
                                    margin-right: 10px;
                                }
                                .ui-icon
                                {
                                    margin-top: 5px;
                                }
                            }
                            .cpn-tag-holder {
                                display: flex;
                                align-items: center;
                                color: #888888;
                                h4{
                                    margin-right: 15px;
                                }
                                .cpn-tag {
                                    border: 1px solid #888888;
                                    padding: 6px 8px;
                                }
                            }
                        }
                    }
                    .component-manual-scroll
                    {
                        max-height: calc(85vh - 200px);
                        padding-right: 20px;
                        margin-right: -20px;
                        padding-bottom: 20px;
                        overflow-y: auto;
                    }
                    .card-panel {
                        overflow: hidden;
                        .card-panel-header {
                            padding: 10px 25px;
                            background-color: #41414A;
                            border-top-left-radius: 8px;
                            border-top-right-radius: 8px;
                            h3 {
                                color: #888888;
                                font-weight: bold;
                            }
                        }
                        .card-panel-body {
                            padding: 25px;
                            border-bottom-left-radius: 8px;
                            border-bottom-right-radius: 8px;
                            margin-bottom: 30px;
                            background-color: #353540;
                            &.last-panel
                            {
                                margin-bottom: 0px;
                            }

                            .new-component-category-types {
                                margin: 0 0 17px;
                                display: flex;
                                justify-content: flex-start;
                                .category-nav {
                                    margin-right: 35px;
                                    text-align: center;
                                    &:last-child {
                                        margin-right: 0;
                                    }
                                    &:hover,
                                    &.selected
                                    {
                                        cursor: pointer;
                                        .ui-icon
                                        {
                                            svg {
                                                .path{
                                                    fill: #3cd1b5;
                                                }
                                            }
                                        }
                                        label
                                        {
                                            color: #3cd1b5;
                                        }
                                        .assembly
                                        {
                                            .ui-icon
                                            {
                                                svg {
                                                    path{
                                                        stroke: #3cd1b5;
                                                    }
                                                    rect
                                                    {
                                                        stroke: #3cd1b5;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    .ui-icon {
                                        width: 25px;
                                        height: 25px;
                                        svg {
                                            fill: #cfcfcf;
                                        }
                                    }
                                    label {
                                        color: #cfcfcf;
                                    }
                                }
                            }
                            .categoryType-heading {
                                margin-bottom: 20px;
                                color: #888888;
                                font-size: 14px;
                            }
                            .form-group
                            {
                                textarea
                                {
                                    resize: none;
                                    height: 70px;
                                }
                                margin-bottom: 30px;
                                label
                                {
                                    color: #888;
                                    font-size: 14px;
                                    text-transform: uppercase;
                                }
                                .spec-select{
                                    padding: 0px 5px;
                                    font-size: 12px;
                                    color: #2a2a36;
                                }
                                .form-control
                                {
                                    height: auto;
                                    padding: 6px 10px;
                                    min-height: 30px;
                                    border: 0;
                                }
                            }
                            .spec-block
                            {
                                .specs
                                {
                                    display: flex;
                                    flex-wrap: wrap;
                                    .spec-holder
                                    {
                                        flex-basis: 50%;
                                        padding: 0 8px;
                                    }
                                }
                            }
                            .spec-label-holder
                            {
                                padding: 10px;
                                text-align: center;
                                font-size: 16px;
                                color: #888888;
                                .ui-icon
                                {
                                    width: 20px;
                                    height: 15px;
                                    margin-bottom: 10px;
                                }
                            }
                            .form-group-holder {
                                display: flex;
                                margin: 0 -8px;
                                .form-group
                                {
                                    flex:1;
                                    padding: 0 8px;
                                    &:nth-child(1)
                                    {
                                        max-width: 138px;
                                    }
                                     &:nth-child(2)
                                     {
                                        max-width: 74px;
                                     }
                                }
                            }
                            .thumbnails-block,
                            .documents-block,
                            {
                                margin-top: 10px;
                                .drop-zone {
                                    border: 1px dashed #888888;
                                    display: flex;
                                    align-items: center;
                                    height: auto;
                                    padding: 6px 15px;
                                    &.accept-files
                                    {
                                        background: #3CD1B5;
                                        .collapsed-block
                                        {
                                            .ui-icon
                                            {
                                                svg
                                                {
                                                    display: none;
                                                }
                                            }
                                            span
                                            {
                                                color: #3CD1B5;
                                            }
                                        }
                                    }
                                    &.reject-files
                                    {
                                        background: #F54A4F;
                                        .collapsed-block
                                        {
                                            .ui-icon
                                            {
                                                svg
                                                {
                                                    display: none;
                                                }
                                            }
                                            span,
                                            a
                                            {
                                                color: #F54A4F;
                                            }
                                        }
                                    }
                                }
                                .collapsed-block
                                {
                                    display: flex;
                                    align-items: center;
                                    padding: 0;
                                    .ui-icon
                                    {
                                        width: 20px;
                                        height: 20px;
                                        margin:0 10px 0 0;
                                        svg
                                        {
                                            fill: #888888;
                                        }
                                    }
                                    a{
                                        margin-left: 5px;
                                    }
                                }
                                .list
                                {
                                    margin: 15px 0;
                                    .upload-file-list
                                    {
                                        display: flex;
                                        align-items: center;
                                        justify-content: space-between;
                                        margin-bottom: 10px;
                                        padding: 0 15px;

                                        .img-name-holder,
                                        .cross-size-holder
                                        {
                                            display: flex;
                                            text-align: center;

                                            .image-holder
                                            {
                                                width: 24px;
                                                height: 24px;
                                                margin-right: 10px;
                                                background-size: contain;
                                                background-repeat: no-repeat;
                                            }
                                            .file-name
                                            {
                                                color: #3CD1B5;
                                                margin-right: 10px;
                                            }
                                            .delete-icon
                                            {
                                                width: 17px;
                                                height: 17px;
                                                margin-left: 5px;
                                            }
                                            #progress-icon-svg
                                            {
                                                margin-left: 5px;
                                            }
                                            .current-progress
                                            {
                                                .cancel-file
                                                {
                                                    margin-right: 5px;
                                                    color: #3CD1C9;
                                                    cursor: pointer;
                                                }
                                            }
                                        }
                                        &.file-upload-error
                                        {
                                            .img-name-holder
                                            {
                                                .file-name
                                                {
                                                    color: #888888 !important;
                                                }
                                            }
                                            .cross-size-holder
                                            {
                                                .file-size,
                                                .current-progress
                                                {
                                                    color: #f54a4f;
                                                }
                                                .delete-icon
                                                {
                                                    .ui-icon
                                                    {
                                                        svg
                                                        {
                                                            g
                                                            {
                                                                path
                                                                {
                                                                    fill: #f54a4f;
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        &.file-uploading
                                        {
                                            padding-right: 8px;
                                        }
                                    }
                                    table
                                    {
                                        tr
                                        {
                                            td
                                            {
                                                &:nth-child(1)
                                                {
                                                    width: 30px;
                                                }
                                                .file-name
                                                {
                                                    color: #3CD1B5;
                                                }
                                            }
                                        }
                                    }

                                }
                            }
                        }
                        // end panel body
                    }
                    .card-panel-footer {
                        padding-top: 20px;
                        display: flex;
                        justify-content: space-between;
                        .left-section {
                            button {
                                padding: 6px 12px;
                            }
                        }
                        .right-section {
                            display: flex;
                            align-items: center;
                            .checkbox-label {
                                display: flex;
                                align-items: center;
                                label {
                                    width: 15px;
                                    height: 15px;
                                }
                                .label_value {
                                    margin:0 15px;
                                }
                            }
                        }
                    }
                }
            }
        }
        .simple-rc-tip
        {
            max-width: 250px;
            width: 100%;
        }
    }
}
