:root{
  --app-modal-min-width: 450px;
  --app-modal-width: 450px;
  --app-modal-bgcolor: #2A2A36;
  --app-modal-border-radius: 8px;
  --app-modal-box-shadow: 0;
  --app-modal-padding: 24px;
  --app-modal-header-margin-bottom: 16px;
  --app-modal-content-margin-bottom: 0px;
  --app-modal-footer-margin-top: 48px;
}
