@import "../../../../../styles/variables";

.view-product-route
{
    margin-bottom : -100px;

    &.tiles-view
    {
        .tiles-wrapper
        {
            .tiles-section-scroll
            {
                display: flex;
                overflow: auto; 
                scrollbar-width: auto;
                ::-webkit-scrollbar-track {
                    width: auto;
                }
            }
        }
    }

    .top-block
    {
        background: $BG_LIGHT;
        padding-bottom: 15px;
        tr
        {
            th, td
            {
                text-align: left;
                margin: 0;
            }
        }

        .name-block
        {
            font-size: $FONT_SIZE_23;
            margin-bottom: 14px;
            line-height: 24px;
        }

        .info-block
        {
            .row-holder{
                display: flex;
                .col{
                    flex: 1;
                }
            }

            h2
            {
                font-size: 17px;
                margin-bottom: 15px;
            }

            .row-one
            {
                display: flex;
                h2{
                    font-size: $FONT_SIZE_DEFAULT;
                }
                tr
                {
                    border-bottom: none;
                }

                .left
                {
                    flex: 1;
                    display: flex;
                    min-width: 470px;

                    .ui-image-viewer
                    {
                        margin-right: 40px;

                    }

                    td, th
                    {
                       &:first-child
                       {
                            width: 120px;
                            text-transform: uppercase;
                       }
                        font-weight: normal;
                        line-height: 20px;
                    }
                }

            }

            .row-two
            {
                h2{
                    font-size: $FONT_SIZE_DEFAULT;
                }
                tr
                {
                    border-bottom: none;
                }

                .top, .bottom
                {
                    // margin-left: 55px;

                    .headings{
                        h2{
                            display: inline-block;
                            width: 206px;
                        }
                        div{
                            display: inline-block;
                            span{
                                display: inline-block;
                                &:first-child{
                                    width: 72px;
                                }

                            }
                        }
                    }
                    h2{
                        color: $FONT_COLOR;
                        margin-bottom: 0px;
                    }

                    td, th
                    {
                        line-height: 17px;
                        &:first-child
                        {
                            width: 155px;
                            text-transform: uppercase;
                        }

                        &:nth-child(2)
                        {
                            width: 72px;;
                        }
                    }
                }

                .bottom{
                    padding-top: 15px;

                    table tbody{
                       tr:last-child{
                        td, th{
                            padding-bottom: 0px;
                        }

                       }
                    }
                    td, th
                        {
                            line-height: 17px;
                            min-height: 27px;
                            &:first-child
                            {
                                width: 155px;
                            }

                            &:nth-child(2)
                            {
                                width: 90px;
                            }
                        }
                }
            }
            .default-img
            {
                background-image: linear-gradient(-180deg, #353540 0%, #18171D 100%);
                min-width: 185px;
                min-height: 144px;
                display: flex;
                align-items: center;
                justify-content: center;
                .ui-icon
                {
                    width: 68px;
                    height: 69px;
                    svg
                    {
                        width: 100%;
                        height: 100%;
                        fill: $GREY_LIGHT3;
                    }
                }
            }
        }
        .col-2{
            max-width: 240px;
            width: 240px;
        }
        .primary-source
        {
            td, th
            {
                line-height: 17px;
                &:first-child
                {
                    width: 126px;
                    text-transform: uppercase;
                }

                &:nth-child(2)
                {
                    width: 72px;;
                }
            }
            td
            {
                &.rolled-up-cost-cell, &.rolled-up-mass-cell, &.rolled-up-extended-cost-cell
                {
                    > .icon-cell
                    {
                        display: flex;
                        align-items: center;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        > .not-all-valid {
                            display: inline-flex;
                            margin-left: 0;
                            .ui-icon{
                                height: 17px;
                                width: 17px;
                            }
                            svg{
                                width: 17px;
                                height: 17px !important;
                            }
                        }
                    }
                }
                .rolled-up-cost-cell .rolled-up-mass-cell .rolled-up-extended-cost-cell
                {
                    .icon-cell
                    {
                        position: relative;
                        padding-right: 20px;
                        display: block;
                        min-height: 16px;
                        max-width: 88px;
                    }
                        vertical-align: top;
                        &.not-all-valid{
                            position: absolute;
                            right: 0;
                            margin-left: 10px;
                            .ui-icon{
                                height: 17px;
                                width: 17px;
                            }
                            svg{
                                width: 17px;
                                height: 17px !important;
                            }
                        }
                }
            }
            .mass-row
            {
                th
                {
                    text-transform: none;
                }
            }
        }
        .build-schedule{
            thead tr th,
            tbody tr th
            {
                &:first-child
                {
                    width: 210px !important;
                }
            }
        }
    }
    .ui-modal-box-inner .modal
    {
        top: 16%;
    }
}
