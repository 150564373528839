@import "../../../../../styles/variables";

.product-revision-route .actions-block
{
    .banner-block
    {
        position   : relative;
        background : $ERRORS;
        z-index    : 1;

        a
        {
            color           : black;
            margin-left     : 5px;
            text-decoration : underline;

            &:hover
            {
                color: black;
            }
        }

        > div
        {
            display         : flex;
            color           : black;
            justify-content : space-between;
            align-items     : center;
            height          : 50px;

            > div
            {
                margin      : 0;
                display     : flex;
                align-items : center;
                margin      : 5px 0;

                &:nth-child(1)
                {
                    .ui-icon
                    {
                        pointer-events : none;
                        width          : 16px;
                        height         : 19px;
                        margin-right   : 10px;

                        svg
                        {
                            fill : black;
                        }
                    }
                }

                &:nth-child(2)
                {
                    button
                    {
                        padding      : 5px 10px;
                        color        : $FONT_COLOR;
                        border-color : black;
                        background   : black;

                        &:hover
                        {
                            background : none;
                            color      : black;
                        }
                    }
                }
            }
        }
    }

    .new-warning-modal
    {
        .background
        {
            background: $BG_LIGHT3;
            opacity: 0.9;
            transform: none;
            z-index: -1;
        }
    }

    .nav-block
    {
        display         : flex;
        justify-content : space-between;

        &.released-revision
        {
            margin-top: 0px;
            padding-top: 20px;
        }

        div
        {
            &:last-child
            {
                .ui-icon
                {
                    width       : 15px;
                    height      : 15px;
                    margin-left : 20px;

                    &.active
                    {
                        svg
                        {
                            fill: $GREEN;
                        }
                    }

                    &.fav-icon
                    {
                        &:hover
                        {
                            svg
                            {
                                fill: white;
                            }
                        }

                        &.faved
                        {
                            svg
                            {
                                fill: #f55ee4;
                            }

                            &:hover
                            {
                                svg
                                {
                                    fill: #f55ee4;
                                }
                            }
                        }
                    }
                    &.variant-stroke
                    {
                        &:hover
                        {
                            svg
                            {
                                g
                                {
                                 stroke: $GREEN;
                                }

                            }
                        }
                    }
                }
            }
        }
    }
}
