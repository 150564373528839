@import "../../../../../../styles/variables";
.assembly-list-tree
{
    table
    {
        tbody
        {
            tr
            {
               td
                {
                    &:first-child
                    {
                        .text-block
                        {
                            overflow: inherit;
                        }
                        .tree-child-assembly
                        {
                            .ui-link.link
                            {
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                width: 100%;
                                height: 18px;
                                display: inline-block;
                                padding-right: 30px;
                                &.not-variant
                                {
                                    padding-right: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
.text-block
{
    position: inherit;
}
.tree-child-assembly
{
    display: flex;
    > span{
        margin-right: 5px;
    }
    .cpn-link
    {
        color: #3CD1BF;
    }
    .ui-icon {
        width: 10px;
        height: 16px;
        margin-right: 10px;
        position: absolute;
        left: -15px;
        &.open {
            -webkit-transform: rotate(0);
            -ms-transform: rotate(0);
            transform: rotate(0);
        }
        &.close {
            -webkit-transform: rotate(270deg);
            -ms-transform: rotate(270deg);
            transform: rotate(270deg);
        }
    }
}
.variant-table-holder
{
    #variant-table
    {
        width: 100%;
        max-width: 930px;
        .header-block-left-col
        {
            min-width: auto;
            justify-content: space-between;
        }
        .content-block
        {
            .simple-table
            {
                table
                {
                    thead
                    {
                        tr
                        {
                            th
                            {
                                &.checkbox-col
                                {
                                    width: 42px;
                                    .text-block
                                    {
                                        padding: 10px 0 10px 9px;
                                    }
                                }
                                &.img-col
                                {
                                    width: 43px;
                                }
                                &.cpn-col
                                {
                                    width: 59px;
                                }
                                &.name-col
                                {
                                    width: 120px;
                                }
                                &.status-cell
                                {
                                    width: 70px;
                                }
                                &.last-update-cell
                                {
                                    width: 90px;
                                }
                                &.remove-cell
                                {
                                    width: 27px;
                                }
                            }
                        }

                    }
                    tbody
                    {
                        tr
                        {
                           td
                            {
                                &:first-child
                                {
                                    .checked-input
                                    {
                                        width: 15px;
                                        height: 15px;
                                    }
                                    .checked-label
                                    {
                                        width: 15px;
                                        height: 15px;
                                        margin-left: -15px;
                                    }
                                    .disable-checkbox
                                    {
                                        .checked-input
                                        {
                                            cursor: default;
                                        }
                                        .checked-label
                                        {
                                            background: $GREY_LIGHT3;
                                            border: $GREY_LIGHT3;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.rolled-up-modified-header
{
    .ui-icon
    {
        width: 20px;
        padding-left: 3px;
        svg
        {
            width: 13px;
            padding-top: 4px;
        }
    }
}

.production
{
    .view-product-route
    {
        .popover-content-block
        {
            p
            {
                font-size: 12px;
                text-align: center;
            }
        }
    }
}
