#production-build-modal
{
    width: 400px;
    right: 9%;

    &.having-children
    {
       width: 682px;
    }

    &.open
    {
        display: block;
    }

    .content-block
    {
        padding: 18px 30px 24px 26px;
        .content-header
        {
            max-width: 400px;
            .heading-block
            {
                margin-bottom: 15px;
                h2 {
                    font-size: 19px;
                }
            }
            .warning-text {
                .inputs-section {
                    display: flex;
                    align-items: center;
                    margin-bottom: 14px;
                    input {
                        padding: 2px 6px;
                        min-height: 30px;
                    }
                    label {
                        margin-bottom: 0;
                    }
                    .total-units-input-holder,
                    .production-date-picker-input-holder {
                        flex: 1;
                    }
                    .total-units-input-label,
                    .production-date-input-label {
                        padding-right: 24px;
                    }
                    .production-date-picker-input-holder {
                        position: relative;
                        .react-datepicker-wrapper {
                            width: 100%;
                            .react-datepicker__input-container {
                                width: 100%;
                            }
                        }
                        .ui-icon {
                            position: absolute;
                            top: 5px;
                            right: 10px;
                            width: 20.9px;
                            height: 19px;
                            svg {
                                width: 20px;
                                height: 19px;
                            }
                        }
                    }
                }
            }
            .button-holder {
                margin-top: 20px;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                button {
                    min-width: 83px;
                    padding: 5px;
                    min-height: 30px;
                    &:first-child {
                        margin-right: 16px;
                    }
                }
            }
        }
        .content-body {
            margin-top: 20px;
            .heading-block {
                padding-bottom: 10px;
            }
            .custom-scroolbar {
                max-height: 400px;
            }
            .extended-table {
                .custom-right-border {
                    max-width: 91% !important;
                }
                .extended-data-table-wrapper-scroll {
                }
                .extended-table-data {
                    tbody {
                        tr {
                            th {
                                .text-block {
                                    height: 25px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
