@import "../../../../styles/variables";


.header .private-view
{
    .top-row
    {
        height: 70px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-right: 40px;

        a
        {
            color: $FONT_COLOR;

            &:hover
            {
                color: $FONT_COLOR;
            }
        }

        .branding
        {
            padding: 10px 44px;
            width: 100%;
            max-width: 270px;
            a {
                display: flex;
            }
            .company-logo
            {
                max-width: 100%;
                max-height: 50px;
            }

            .company-name
            {
                font-size: $FONT_SIZE_23;
            }
        }

        .on-boarding-block
        {
            .navigation
            {
                font-size: 16px;

                .menu.cos
                {
                    margin-right: 40px;
                }

                .menu
                {
                    margin-left: 0;
                }

                .menu.avatar
                {
                    vertical-align : middle;
                    .trigger
                    {
                        border-radius: 100%;
                        border: 1px solid white;
                        top: -24px;
                        width: 41px;
                        height: 41px;
                        right: 0;
                        position: absolute;
                        overflow: hidden;

                        .ui-icon
                        {
                            width: 20px;
                            position: absolute;
                            right: 9px;
                            top: 9px;

                            svg
                            {
                                fill: white;
                            }
                        }

                        &:hover
                        {
                            .ui-icon
                            {
                                svg
                                {
                                    fill: white;
                                }
                            }
                        }
                    }

                    .custom_trigger
                    {
                        width : auto;
                        height : auto;
                        a
                        {
                            width : auto;
                            height : auto;
                        }
                        .profile-image
                        {
                            width : 42px;
                            height : 42px;
                            border-radius: 100%;
                        }
                        .ui-icon
                        {
                            display         : flex;
                            align-items     : center;
                            justify-content : center;
                            width           : 42px;
                            height          : 42px;
                            svg
                            {
                                height : 40px;
                                width : 40px;
                            }
                        }
                    }

                    .sub-menu
                    {
                        right: -40px;
                        padding-top: 0px;
                        .label
                        {
                            font-size: 14px;
                            line-height: 15px;
                        }

                        .ui-link
                        {
                            min-height  : auto;
                            .border-bottom
                            {
                                svg
                                {
                                    g
                                    {
                                        fill: $GREY_LIGHT3;
                                    }
                                }
                            }
                        }
                    }

                    &.user
                    {
                        .trigger
                        {
                            border: none;

                            .profile-image
                            {
                                width: 100%;
                                height: 100%;
                                background-position : center;
                                background-size     : cover;
                                background-repeat   : no-repeat;
                            }
                        }
                    }
                }
            }
        }
    }



    .sanbox-header-bar
    {
        display: flex;
        justify-content: center;
        border-top: 5px solid #04ADC3;
        position: absolute;
        z-index: 999;
        left: 0;
        right: 0;
        width: 100%;
        top: 0;

        .sanbox-msg
        {
            background: #04ADC3;
            font-size: 14px;
            min-width: 239px;
            padding: 1px 10px 4px;
            border-bottom-right-radius: 6px;
            border-bottom-left-radius: 6px;
            display: flex;
            .ui-icon
            {
                width: 13px;
                height: 13px;
            }
            .exit-sandbox-btn
            {
                color: rgba(255, 255, 255, 0.788235294117647);
                text-decoration: underline;
                cursor: pointer;
                display: flex;
                margin-left: 5px;

                &:hover
                {
                    color: $FONT_COLOR;
                }
            }
        }


    }
}
img.company-sandbox-logo
{
    height: 37px;
}
img.sandbox-logo{
    position: absolute !important;
    left: 32px;
    top: -10px;
    height: 15px;
}
