@import "../../../styles/variables";

.ui-image-viewer-tile
{
    user-select : none;
    position    : relative;
    width       : 185px;

    .remove-btn
    {
        cursor        : pointer;
        position      : absolute;
        left          : -13px;
        top           : -13px;
        width         : 25px;
        height        : 25px;
        background    : $GREEN;
        border-radius : 100%;
        z-index       : 3;
        display       : none;
        box-shadow    : 0 0 5px #000;
        transition    : all .1s ease-in-out;

        .ui-icon
        {
            width       : 9px;
            margin-left : 0px;
            margin-top  : 0px;

            svg
            {
                width  : 26px;
                height : 27px;

                path {
                    transition: all .1s ease-in-out;

                    &:hover {
                        fill: #fff;
                    }
                }
            }
        }
    }

    .images
    {
        position   : relative;
        width      : 185px;
        height     : 145px;
        overflow   : hidden;

        &.no-image-vendor {
            border: 1px solid $FONT_COLOR !important;
        }

        .image
        {
            opacity             : 0;
            position            : absolute;
            left                : 0;
            top                 : 0;
            width               : 100%;
            height              : 100%;
            background-size     : contain;
            background-position : center;
            background-repeat   : no-repeat;
            background-color    : transparent;

            .image-copy
            {
                display: none;
            }

            &.slide-from-left
            {
                transition: left 0s;
                left : -100%;
            }

            &.slide-from-right
            {
                transition: left 0s;
                left : 100%;
            }

            &.selected
            {
                transition : left 0.07s ease-out;
                left       : 0;
                opacity    : 1;
            }
        }

        .table-image-viewer-wrapper {
            position: absolute;
            width: 100%;
            height: 100%;

            .default-img-icon {
                justify-content: center;
            }
        }
    }

    .controls
    {
        position: absolute;
        display: flex;
        justify-content: space-between;
        width: 260px;
        height: 145px;
        overflow: hidden;
        background: transparent;

        &:hover
        {
            .arrow {
                background-color: rgba(0, 0, 0, .75);
                .ui-icon { display: block; }
            }
        }

        .arrow
        {
            display: flex;
            width: 40px;
            height: 40px;
            cursor: pointer;
            align-content: center;
            padding: 0;
            align-items: center;
            align-self: center;
            justify-content: center;
            z-index: 2;
            border-radius: 8px;
            background-color: transparent;
            transition: all .1s ease-in-out;

            .ui-icon
            {
                display: none;
                width: 17px;
                height: 17px;

                path, polygon
                {
                    fill: #fff;
                }
            }

            &.previous
            {
                left: 5px;

                .ui-icon
                {
                    transform: scaleX(-1);
                }
            }

            &.next {
                right: 5px;
            }

        }

        .count
        {
            width: 100px;
            padding: 0;
            justify-content: center;
            color: $FONT_LIGHT;
            align-self: end;
            z-index: 2;

            i {
                display: inline-flex;
                width: 10px;
                height: 10px;
                border-radius: 50%;
                // box-shadow: 0 0 5px $GREY_HOVER;
                border: 1px solid #fff;
                background-color: $GREY_LIGHT;

                &:not(:first-child),
                &:not(:last-child) {
                    margin: 0 2px;
                }

                &:first-child { margin-right: 2px; }
                &:last-child { margin-left: 2px; }

                &.active {
                    background-color: $GREEN;
                    border: 1px solid $FONT_COLOR;
                }

                &:hover {
                    cursor: pointer;
                }
            }
        }
    }

    .primary-block
    {
        display    : none;
        margin-top : 10px;

        > span
        {
            margin-left: 10px;
            font-style: italic;
            color: $GREY;
        }
    }

    .drop-zone
    {
        display    : none;
        cursor     : auto;
        overflow   : hidden;
        height     : 40px;
        border     : 1px dashed white;
        margin-top : 15px;

        > div.recipient,
        > div.recipient-hover
        {
            display: flex;
            flex-direction: column;
            padding-left: 12px;
            padding-right: 12px;
            font-size: 12px;
            align-items: center;
            height: 100%;
            color: transparent;
            justify-content: center;
            transition: all .2s ease-in-out;

            svg > g > g {
                stroke: transparent;
                transition: all .2s ease-in-out;
            }

            p {
                display: block;
                margin: 5px 25px;
                text-align: center;
                font-size: 14px;

                span {
                    display: inline;
                    margin-left: 4px;
                    color: $GREEN;
                    transition: color .2s ease-in-out;

                    &:hover {
                        color: $FONT_COLOR;
                        cursor: pointer;
                    }
                }
            }
        }
        > div.recipient {
            svg > g > g {
                stroke: $FONT_LIGHT;
            }
            p {
                color: $FONT_LIGHT;
            }
        }

        > div.recipient-hover {
            color: $FONT_BLACK;
            background-color: $GREEN;
            cursor: pointer;

            svg > g > g {
                stroke: $FONT_BLACK;
            }

            p {
                span {
                    color: $FONT_BLACK;
                    text-decoration: underline;

                    &:hover {
                        color: lighten($FONT_BLACK, 40);
                    }
                }
            }
        }

        .modal
        {
            display : none;
            height  : 100%;
            width   : 100%;
        }

        &.accept-files
        {
            padding: 5px;

            > div:first-child
            {
                display: none;
            }

            .modal
            {
                display    : block;
                background : $GREEN;
            }
        }

        &.reject-files
        {
            padding: 5px;

            > div:first-child
            {
                display: none;
            }

            .modal
            {
                display    : block;
                background : $ERRORS;
            }
        }
    }

    &.edit
    {
        .remove-btn
        {
            display: block;
        }

        .primary-block
        {
            display: block;
        }

        .drop-zone
        {
            display: block;
        }
    }

    &.single
    {
        .controls
        {
            display: none;
        }

        .primary-block
        {
            display: none;
        }
    }

    &.empty
    {
        .remove-btn
        {
            display: none;
        }

        .controls
        {
            display: none;
        }

        .primary-block
        {
            display: none;
        }

        .images
        {
            background      : none;
            border          : 1px solid white;
            display         : flex;
            align-items     : center;
            justify-content : center;

            .image-copy
            {
                display: block;
            }
        }
    }
}
