.review-screen{
    height: 100%;
    .bulk-action-block
    {
        max-width: max-content;
        margin-left: 41%;
        padding-bottom: 15px;
        padding-top: 5px;
        .bulk-category
        {
            height: fit-content;
            &.disabled
            {
                color: #FFFFFF !important;
                background-color: #2A2A36 !important;
                // Error: Can't resolve '/static/media/arrow-select-white.28a02c12.svg'
                // background-image: url(/static/media/arrow-select-white.28a02c12.svg) !important;
                border: 1px solid #888888 !important;
                pointer-events: none;
            }
        }
    }
    .category-cell
    {
        width: max-content;
        height: 36px;
        margin: 5px;
    }
    .valispace-components
    {
        height: 100%;
        #valispace-review-table
        {
            tr
            {
                cursor: default;
            }
            .category-col
            {
                .text-block
                {
                    height: 30px;
                    padding: 0px 5px;
                    input
                    {
                        padding: 5px 10px;
                    }
                    .select-category
                    {
                        height: 30px;
                        padding: 5px 10px;
                        &.invalid
                        {
                            background-color: #F54A4F;
                            color: #B3B3B3;
                        }
                    }
                }
            }
            .name-col
            {
                &.invalid
                {
                    background-color: #F54A4F;
                    color: #B3B3B3;
                }
            }
            .cpn-col
            {
                .link
                {
                    a
                    {
                        .cpn-value
                        {
                            color: #3CD1BF;
                        }
                        .ui-icon
                        {
                            .open-link-icon
                            {
                                g
                                {
                                    fill: #3CD1BF;
                                }
                            }
                        }
                        &:hover
                        {
                            .cpn-value
                            {
                                color: #FFFFFF;
                            }
                            .ui-icon
                            {
                                .open-link-icon
                                {
                                    g
                                    {
                                        fill: #FFFFFF;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .inline-image-viewer
            {
                .default-img-icon
                {
                    cursor: default;
                }
            }
        }
    }
}

