@import "../../../../styles/variables";
.update-status-screen-modal
{
    .heading-details
    {
        margin-bottom: 15px;
        max-width: 570px;
    }

    .status-holder
    {
        display: flex;
        align-items: center;
        .ui-icon
        {
            height: 17px !important;
        }
    }

    #parentRevisionTable
    {
        .text-block
        {
            height: auto;
        }
        .field-select
        {
            .status-label
            {
                opacity: 1;
            }
        }
    }

    .field-select
    {
        .select-status
        {
            height: 24px;
            min-width: 115px;
        }
        .bulk-status
        {
            margin-left: 10px;
        }
    }
    .bulk-revision-label
    {
        margin-left: 25px;
    }

    #update-revision-holder
    {
        .set-bulk-revision-holder
        {
            .field-select
            {
                .status-label
                {
                    height: 24px;
                    display: inline-block;
                }
            }
        }
    }

    .header-heading{
        svg{
            g{
                fill: none !important;
            }
        }
    }
    .header-update-status-icon{
        svg{
            g{
                stroke: #18171D;
            }
        }
    }
}

body[data-modal='update-revision-bd open']
{
    .status-error-tooltip
    {
        .rc-tooltip-inner
        {
            background: #F54A4F !important;
            border: 1px solid #F54A4F !important;
        }

        .rc-tooltip-arrow
        {
            border-right-color: #F54A4F !important;
        }
    }

    .co-warning-tooltip
    {
        a.ui-link
        {
            margin: 8px 0 5px;
            display: inline-block;
            padding: 5px 10px;
            border-radius: 8px;
            border: 1px solid $GREEN;
            &:hover
            {
                background: $GREEN;
                .link-text.co-text
                {
                    color: $GREY_LIGHT3;
                }
            }
        }
        .co-link-text.ui-link
        {
            color: $GREEN;
            margin: 0;
            margin-left: 5px;
            padding: 0;
            border-radius: 0;
            border: 0;
            &:hover
            {
                background: transparent;
            }
        }
    }
}

