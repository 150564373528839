@import "../../../../styles/variables";

.add-users-modal-holder
{
    .add-users-modal
    {
        .modal
        {
            max-width: 675px;
            height: auto;
            padding-bottom: 0;
            .modal-content
            {
                padding:0;
                h2
                {
                    font-weight: normal;
                    margin-bottom: 30px;
                    font-size: 23px;
                }
            }

        }
    }
    .library-contianer
    {
        .input-holder
        {
            .ui-message {
                top: 15px;
            }
        }
        .library-content-holder
        {
            width: 100%;
            padding: 40px;
            .heading-holder
            {
                font-size: 14px;
                color: $FONT_LIGHT;
                max-width: 527px;
                margin: 0 auto;
                h4
                {
                    font-size: $FONT_SIZE_19;
                    color: $FONT_LIGHT;
                    margin-bottom: 50px;
                    font-weight: normal;
                }
                p
                {
                    font-size: 16px;
                    margin-bottom: 25px;
                    line-height: 24px;
                    color: #282836;
                }
            }
            .library-holder
            {
                display: flex;
                justify-content: space-between;
                &:hover {
                    .personal-library{
                        .inner-holder {
                            border-color: transparent;
                        }
                    }


                }
                .personal-library
                {
                    cursor: pointer;
                    padding: 20px 0;
                    text-align: center;
                    flex-basis: 50%;

                    .inner-holder
                    {
                        border-right: 1px solid rgba(151,151,151,0.5);
                        &:last-child
                        {
                            border: 0;
                        }
                    }

                    &:hover
                    {
                        -webkit-box-shadow: 0px 1px 4px 0px rgba(0,0,0,0.50);
                        -moz-box-shadow: 0px 1px 4px 0px rgba(0,0,0,0.50);
                        box-shadow: 0px 1px 4px 0px rgba(0,0,0,0.50);
                        border: 0;
                        .tutorial-btn {
                            border: 1px solid $GREEN;
                            background: $GREEN;
                            color: $FONT_LIGHT;
                        }

                        .recommended-text
                        {
                            color: $GREEN;
                        }
                    }
                    &:nth-child(2){
                        .ui-icon {
                            width: 70px;
                        }
                    }
                    .ui-icon
                    {
                        width: 33px;
                        height: 38px;
                        margin-bottom: 25px;
                    }
                    h3
                    {
                        font-size: $FONT_SIZE_19;
                        margin-bottom: 20px;
                    }
                    p
                    {
                        font-size: 16px;
                        line-height: 24px;
                        max-width: 207px;
                        margin: 0 auto 30px;
                        color: #514d4d;
                    }
                    .recommended-text
                    {
                        font-size: 16px;
                        color: #514d4d;
                        font-weight: normal;
                    }
                }
                .add-user-scroll
                 {
                  max-height: 19vh;
                  padding-right: 10px;
                  margin-right: 0px;
                  padding-bottom: 5px;
                }
                .create-account-link {
                    margin-bottom: 10px;
                    display: inline-block;
                    text-decoration: underline;
                    &:hover {
                        color: #3CD1BF;
                    }
                }
                .skip-details {
                    font-size: 11px;
                    color: #888;
                    font-style: italic;
                }
                .new-user-form-block {
                    input[name="email"]
                    {
                        width: 100%;
                    }
                    width: 100%;
                    label {
                        margin-top: 15px;
                        line-height: 1;
                    }
                    .names-block {
                        .col-item {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            .input-holder {
                                flex:1;
                                &:nth-child(1){
                                    margin-right: 15px;
                                }
                                .select-role
                                {
                                    .role-label
                                    {
                                        position: absolute;
                                        margin: 0;
                                        top: 4px;
                                        left: 15px;
                                        color: #999;
                                        text-transform: uppercase;
                                    }
                                    select
                                    {
                                        height: 45px;
                                        padding: 18px 15px 8px;
                                        border: 1px solid #979797;
                                    }
                                }

                            }
                        }
                    }
                }
                .user-record
                {
                    margin-bottom: 25px;
                    border-bottom: 1px solid #e3e3e3;
                    min-height: 170px;
                    .user-list {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding: 15px;
                        border-bottom: 1px solid #e3e3e3;
                        color: #999;
                        font-weight: 500;
                        .email
                        {
                            width: 54% !important;
                            min-width: 293px;
                            .email-content
                            {
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                max-width: 80%;
                            }

                        }
                        .role
                        {
                            width: 40%;
                            margin-right: 15px;
                        }
                        .status
                        {
                            width: 8%;
                        }
                    }
                }
                .user-record-display
                {
                    border-top: 1px solid #e3e3e3;
                }
                .footer-btn {
                    .btn-holder {
                        justify-content: space-between;
                        align-items: flex-end;
                    }
                }
            }
            .btn-holder
            {
                text-align: center;
                width: 100%;
                display: block;
                margin-top: 10px;
                margin-bottom: 0;
                display: flex;
                justify-content: flex-end;

                .tutorial-btn
                {
                    border: 1px solid $GREEN;
                    background: $GREEN;
                    color: #2a2a36;
                    min-width: 111px;
                    margin-bottom: 25px;
                    padding: 9px 8px;
                    &.no-margin-b {
                        margin-bottom: 0;
                    }
                    &.btn-next{
                        min-width: 138px;
                    }
                    &:hover
                    {
                        border: 1px solid $GREEN;
                        background: $GREEN;
                    }
                }
                .skip-tutorial
                {
                    color: $GREEN;
                    font-size: 14px;
                    cursor: pointer;
                    font-weight: 600;
                }
            }
        }
    }
}

.two-col .main-content .ps__thumb-y {
    background-color: #aaa;
    border-radius: 6px;
}

/* Note: LibraryModal also depends on these styles */
.library-contianer
{
    margin: -50px auto 0;
    display: flex;
    align-items: stretch;
    max-width: 900px;
    color: #000;
    .ui-message {
        height: auto;
    }
    .heading-holder {
        text-align: center;
        font-size: 16px;
        margin-bottom: 30px;
        h1 {
            font-size: 32px;
            color: #282836;
            font-weight: 500;
            margin-bottom: 15px;
        }
        p{
            font-size: 14px;
            line-height: 22px;
        }
        .already-content {
                margin-bottom: 60px;
                margin-top: -5px;
                span {
                    margin: 0;
                    font-size: 16px;
                    letter-spacing: 0.5px;
                    color: #282849;
                }
                a {
                    font-size: 16px;
                    letter-spacing: 0.5px;
                    margin-left: 5px;
                    border-bottom: 1px solid #3CD1BF;
                    &:hover{
                        color: #3CD1BF;
                    }
                }
            }
    }
    .input-holder {
        margin-bottom: 0;
        input:not(.MuiInputBase-input) {
            border:1px solid #979797;
            padding: 20px 15px 5px;
            &.invalid {
                background: transparent;
                border-color: #e25755;
            }
            &:focus{
                outline: none;
            }
        }

        input[name='user.password'],
        input[name='user.confirmPassword']
        {
            padding-right: 204px;
        }


        label {
            margin-bottom: 0px;
        }
        .ui-message {
            position: absolute;
            right: 0;
            top: 0;
            margin: 0;
            color: #e25755;
            padding: 5px;
            text-align: right;
            font-size:11px;
            font-weight: 300;
        }
    }
    .signup-form,
    .library-content-holder
    {
        width: 45%;
        padding: 30px 40px 30px;
        background: $BG_WHITE;
        min-height: 630px;
         .heading-holder {
             color: #282836;
             font-size: 16px;
         }
        .domain-exist-error
        {
            margin-bottom: 10px;
            color: #F54A4F;
            font-size: 14px;
            text-align: center;
            a{
                color: $GREEN;
            }
        }
        .product-list
        {
            display: flex;
            align-items: center;
            margin: 80px 0;
        }
         .pagination {
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: absolute;
            width: 100%;
            left: 0;
            right: 0;
            padding: 0 20px;
            bottom: 20px;
            a {
                color: #000;
                opacity: 0.5;
                text-decoration: underline;
            }
         }
    }

    h1
    {
        margin-bottom: 10px;
    }

    h3
    {
        margin-bottom: 40px;
    }

    .names-block
    {
        .column
        {
            width: 45%;
        }
    }

    .accept-block
    {
        color         : #333;
        margin-bottom : 20px;
        text-align: right;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        .checkbox-holder{
            width: 30px;
        }
        .accept-text{
            display: inline-block;
            margin-bottom: 3px;
        }
        label
        {
            margin-right : 10px;
            margin-left : 0px;
        }
        a
        {
            font-size: 12px;
            color : #3CD1BF;
            border-bottom: 1px solid #3CD1BF;
            &:hover{
                color: #3CD1BF;
            }
        }

        input {
            position: absolute;
            z-index: 999;
        }
        input[type="checkbox"]:not(old) + label,
        input[type="checkbox"]:not(old) {
            width: 18px;
            height: 18px;
        }
        input[type="checkbox"]:not(old):checked + label {
            border: 1px solid #B3B3B3;
            background-color: transparent;
        }
        .ui-icon {
            position: absolute;
            width: 16px;
            height: 16px;
            right: 13px;
            top: 1px;
            background-color: #3CD1BF;
        }
    }

    .oauth2-block {
        color: #333;
        margin-bottom: 20px;
        text-align: center;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        .google {
            display: flex;
            .ui-icon {
                width: 25px;
                height: 25px;
                margin-right: 5px;
                svg {
                    width: 15px;
                    height: 15px;
                }
            }

        }

        button.google, button.google:hover {
            min-width: 210px;
            height: 35px;
            padding: 9px 9px 9px 9px;
            color: #2A2A36;
            border-color: #CCC;
        }
    }

    .or-block {
        color: #888;
        margin-top: 10px;
        margin-bottom: 10px;
        text-align: center;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-style: italic;
    }

    .submit-block
    {
        font-size : 12px;
        color     : $GREY;
        text-align: center;
        justify-content: center;
        button {
            max-width: 210px;
            width: 100%;
            border: 0;
            min-height: 35px;
            background: $GREEN;
            color: #363640;
            margin: 0;
        }
        span
        {
            margin: 0 5px 0 35px;
            font-weight: 500;
            color: $FONT_LIGHT;
        }
        a{
            text-decoration: underline;
            &:hover {
                color:$GREEN;
            }
        }
    }
    .continue-button{
        margin-top: 40px;
    }
    .go-back-link-block
    {
        font-size : 14px;
        text-align: center;
        justify-content: center;
        margin-top : 20px;
        button {
            min-width: 120px;
            background: $GREEN;
            color: #363640;
            &:hover {
                background: #363640;
                color: $GREEN;
                border: 1px solid #363640;
            }
        }
        a {
            border-bottom: 1px solid #3CD1BF;
            &:hover {
                color:$GREEN;
            }
        }
    }
}

.input-holder .label-text {
  color: #999999;
  cursor: text;
  font-size: 14px;
  line-height: 10px;
  padding: 0 17px;
   letter-spacing: 1px;
  text-transform: uppercase;
  -moz-transform: translateY(-36px);
  -ms-transform: translateY(-36px);
  -webkit-transform: translateY(-36px);
  transform: translateY(-36px);
  transition: all 0.3s;
  text-align: left;
  display: inline-block;
}
.input-holder input {
  background-color: transparent;
  border: 0;
  color: #000;
  font-size: 14px;
  line-height: 18px;
  outline: 0;
  padding: 5px 20px;
  text-align: left;
  transition: all 0.3s;
}
.input-holder input:focus + .label-text {
  color: #B3B3B3;
  font-size: $FONT_SIZE_12;
  letter-spacing: 1px;
  -moz-transform: translateY(-40px) !important;
  -ms-transform: translateY(-40px)  !important;
  -webkit-transform: translateY(-40px)  !important;
  transform: translateY(-40px)  !important;
}

.input-holder input.shrink + .label-text {
  font-size: $FONT_SIZE_12;
  letter-spacing: 1px;
  -moz-transform: translateY(-40px)  !important;
  -ms-transform: translateY(-40px)  !important;
  -webkit-transform: translateY(-40px)  !important;
  transform: translateY(-40px)  !important;
}