@import "../../../../styles/variables";


.new-product-route .thumbnails-block,
.new-component-manual-route .thumbnails-block
{
    margin-top : 25px;

    .list
    {
        margin-bottom : 40px;

        &.empty
        {
            display: none;
        }

        table
        {

            tr
            {

                td
                {
                    &:nth-child(1)
                    {
                        width: 40px;

                        div
                        {
                            width               : 30px;
                            height              : 30px;
                            background-size     : cover;
                            background-position : center;
                        }
                    }

                    &:nth-child(2)
                    {
                        width: 250px;
                        span
                        {
                            &.current-progress
                            {
                                right: 33px;
                                position: absolute;
                                text-transform: unset;
                            }
                            &.cancel-file
                            {
                                color: #3CD1BF !important;
                                cursor: pointer;
                            }
                        }
                    }

                    &:nth-child(3)
                    {
                        text-transform: uppercase;
                        color: $GREY;
                        width: 47px;
                    }

                    &:nth-child(4)
                    {
                        text-transform: uppercase;
                        color: $GREY;
                        width: 65px;
                    }

                    &:nth-child(5)
                    {
                        width: 18px;

                        div
                        {
                            width: 18px;
                            height: 18px;
                        }
                    }
                }
                &.file-upload-error
                {
                    border-bottom: 1px solid #f54a4f;
                    td
                    {
                        color: #f54a4f !important;
                        input,
                        select
                       {
                        background: #f54a4f;
                       }
                       label
                        {
                            color:#f54a4f;
                        }
                        .ui-icon
                        {
                            svg
                            {
                                fill: #f54a4f;
                            }
                        }
                    }
                }
            }
        }
    }

}
