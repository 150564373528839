@import "../../../../../../styles/variables";

.edit-product-route,
.edit-component-route
{
    .tabs-block .sources-tab .distributors
    {
        margin-left   : 20px;
        overflow: auto;
        margin-right: 50px;
        @-moz-document url-prefix() {
            margin-left: 25px;
        }

        table,
        table tr,
        table tr th,
        table tr td{
            border-top: none;
            &:last-child{
                border-bottom: none;
                td{
                    border-bottom: none;
                }
            }
        }
        > table > tbody > tr
        {
            th{
                color: $FONT_LIGHT;
                background-color: #CECECE;
                &:first-child,
                &:last-child
                {
                    padding: 0;
                    white-space: normal;
                }
                /*.handler {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .primary-source-checkbox {
                        input[type="checkbox"]:not(old) {
                            padding: 0px;
                            & + label
                            {
                                margin-left: 0;
                            }
                        }
                    }
                }*/
            }
        }
        // for highlight the primary distributor
        table tr
        {
            &.primary-source
            {
                td{
                    &.dist-dpn-key-cell{
                        > div {
                            background-color: $TABLE_CELL_SUCCESS;
                            input:not(.invalid):not(.edit){
                                background-color: transparent;
                            }
                        }
                    }
                    &:not(.remove-col):not(.dist-dpn-key-cell){
                        input:not(.invalid){
                            background-color: $TABLE_CELL_SUCCESS;
                        }
                        select{
                            background-color: $TABLE_CELL_SUCCESS;
                        }
                    }
                }
            }
        }
    }
}
