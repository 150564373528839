.custom-popup-pos {
    .popover-content-block {
        .popover-content-header {
            display: flex;
            align-items: center;
            margin-bottom: 12px;
            h4.popover-heading {
                margin-bottom: 0;
                font-family: sans-serif;
            }
            .ui-icon{
                position: unset;
                margin-right: 5px;
                width: 18px;
                height: 18px;
            }
        }
        .popover-content-body {
            div {
                margin-bottom: 5px;
            }
            .bold-text
            {
                font-weight: bold;
                font-family: sans-serif;
            }
        }
    }
}
