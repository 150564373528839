@font-face {
  font-family: 'duro-app';
  src:  url('../assets/duro-icons/duro-app.eot?kgthdn');
  src:  url('../assets/duro-icons/duro-app.eot?kgthdn#iefix') format('embedded-opentype'),
    url('../assets/duro-icons/duro-app.ttf?kgthdn') format('truetype'),
    url('../assets/duro-icons/duro-app.woff?kgthdn') format('woff'),
    url('../assets/duro-icons/duro-app.svg?kgthdn#duro-app') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="duro-icon-"], [class*=" duro-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'duro-app' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.duro-icon-confirm-circle:before {
  content: "\e900";
  color: $GREEN;
}
.duro-icon-error-triangle:before {
  content: "\e901";
  color: #f54a4f;
}
.duro-icon-warning-circle:before {
  content: "\e902";
  color: #f5a623;
}
