@import '../../../../styles/variables';

// Variables
// ------------------
$thumbColorHover: $THUMB_HOVER;
$scheduleIconColor: $FONT_MENU;

.tiles-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    padding: 15px 44px 0;
    max-width: 1360px;
    z-index: 0;

    .tiles-section-scroll { 
        display: flex;
        overflow-x: auto; 
        scrollbar-width: auto;
        ::-webkit-scrollbar-track {
            width: auto;
        }
    }

    &.edit { padding: 0; }

    .tiles-container {
        display: flex;
        
        .tile-item:first-child {
            margin: 0 10px 15px 0;
        }
    }
}
.tile-box {
    flex-grow: 1;
    margin: 0 10px 15px;
    background: #353540;
    border-radius: 8px;
    overflow: hidden;
    max-width: 270px;
    min-width: 270px;

    &:first-child {
        margin-left: 0;
    }
    &:nth-child(4) {
        margin-right: 0;
    }

    &.production-instance-tile-box
    {
        max-width: 290px;
        min-width: 290px;
    }
    &.item-specs-header
    {
        max-width: 325px;
    }
    .shedule-scroll,
    .description-tile-scroll {
        overflow: hidden;

        .ps__rail-y {
            &:hover {
                .ps__thumb-y {
                    width: 6px;
                    background-color: $thumbColorHover;
                }
            }
        }
    }

    .ui-icon{
        width: 15px;
        height: 15px;
        cursor: auto;
    }
    .tile-header {
        background: #41414A;
        padding: 0px 15px;
        min-height: 35px;
        display: flex;
        align-items: center;
        h6{
            color: #888888;
            margin: 0;
            font-size: 14px;
            font-weight: bold;
            text-transform: uppercase;
        }
        .icon-holder {
            .ui-icon {
                width: 15px;
                height: 15px;
                margin-right: 10px;

            }
            &.product-icon{
                  .ui-icon {
                    svg
                    {
                        path
                        {
                            fill: #888888;
                        }
                    }
                }
            }

            &.component-icon{
                  .ui-icon {
                    svg{
                        g{
                            stroke: #888;
                            path {
                                fill: none;
                            }
                        }
                    }
                  }
            }
        }
        .specs-scroll
        {
            max-height: 355px;
        }
    }
    .scroll-container {
        padding: 15px 0 25px 15px;
    }
    .tile-content {
        height: 281px;
        padding: 0 15px 0 0;
        color: #fff;

        .unit-price {
            vertical-align: top;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-right: 10px;
        }
        .ui-icon{
            svg{
                width: 15px;
                height: 15px;
            }
        }
        .tile-list-holder {
            .tile-list {
                position: relative;

                &:not(:last-child) {
                    padding-bottom: 5px;
                    margin-bottom: 10px;
                }

                &:after {
                    content: '';
                    position: absolute;
                    width:100%;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    height: 1px;
                    background-color: #979797;
                    margin: 0 auto;
                }
                &.after-none{
                    &:after {
                        display: none;
                    }
                }
                &:last-child{
                    border-bottom: 0;
                }
                .inner-heading{
                    display: flex;
                    align-items: center;
                    margin: 0 0 10px;

                    .ui-icon{
                        margin-right: 8px;
                        svg{
                            fill: #888888;
                            width: 15px;
                            height: 15px;
                            stroke: #888888;
                        }
                    }
                }
                label {
                    color: #888888;
                    font-weight: 500;
                    font-size: 14px;
                    margin-bottom: 0;
                    line-height: 14px;
                    font-weight: bold;
                    text-transform: uppercase;
                }
                .list-email {
                    color: #3CD1B5;
                }
                .list-schedule {
                    display: flex;
                    align-items: center;
                }
                .description-scroll
                {
                    max-height: 96px;
                }
                .description-box
                {
                    margin: 0px 5px 5px 5px;
                    textarea
                    {
                        resize: none;
                    }
                }
                .detail_content {
                    max-width: 240px;
                    line-height: 21px;
                    padding: 0 0 5px;

                    .description-scrol {
                        padding: 0 10px 0 5px;
                    }
                    &.diff-description-section {
                        white-space: pre-wrap;
                        word-wrap: break-word;
                    }
                }
                .list-sourcing {
                    .inner-holder {
                        display: flex;
                        align-items: center;
                        margin-bottom:8px;
                        padding: 1px 0 0;

                        .label-heading {
                            max-width: 86px;
                            min-width: 86px;
                            color: #888888;
                            text-transform: uppercase;
                            margin-right: 5px;
                        }
                        .content-text {
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;

                            .not-all-valid { margin-right: 5px; }

                            &.icon-inside {
                                > span {
                                    position: absolute;
                                    top: 5px;
                                    left: 3px;
                                }

                                > input {
                                    padding-left: 22px;
                                }
                            }
                        }

                        .inner-procurement {
                            margin-left: 20px;
                        }
                    }
                    .lower-case
                    {
                        text-transform: lowercase;
                    }
                }
                .col-item {
                    flex-grow: 1;
                }
                .procurement-cell
                {
                    margin-left: 15px;
                }
                .image-holder {
                    margin-bottom: 10px;
                    min-height: 145px;

                    .images,
                    .table-image-viewer-wrapper,
                    .ui-image-viewer{
                        width: 100%;
                    }
                    .default-img {
                        background-image: linear-gradient(-180deg, #353540 0%, #18171D 100%);
                        min-height: 145px;
                        width: 100%;
                        display: flex;
                        width: 100%;
                        align-items: center;
                        justify-content: center;
                        .ui-icon {
                            width: 68px;
                            height: 69px;
                            svg{
                                width: 68px;
                                height: 69px;
                            }
                        }
                    }
                }
            }
            &.team-block {
                .tile-list {
                    padding-bottom: 5px;
                    label {
                        margin-bottom: 8px;
                    }
                    .list-email {
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        padding: 2px 0;
                    }
                }
            }
            &.build-schedule-box {
                .tile-list {
                    padding-bottom: 10px;
                    label {
                        margin-bottom: 10px;
                    }
                    .list-schedule {
                        .count-holder,
                        .date-holder {
                            display: flex;
                            align-items: center;
                            padding: 2px 3px 2px 0;

                            &.no-day {
                                color: #888;
                            }

                            .ui-icon{
                                margin-right: 10px;

                                > svg > path {
                                    fill: $scheduleIconColor;
                                }
                            }
                        }

                        .count-holder {
                            max-width: 92px;
                            min-width: 92px;
                            justify-content: space-between;
                            padding: 2px 0 2px 3px;

                            .ui-icon {
                                margin: 0;

                                &:hover {
                                    cursor: auto;
                                }
                            }
                            .volume-holder {
                                margin-left: 10px;
                            }
                        }

                        &.edit-schedule-list {
                            .date-holder,
                            .count-holder {
                                display: flex;

                                .ui-icon {
                                    margin-right: 5px;
                                }
                            }
                            .count-holder {
                                max-width: 128px;
                                min-width: 128px;
                                margin-left: 14px;

                                .remove-icon {
                                    margin: 0 0 0 10px;

                                    .ui-icon {
                                        margin: 0;

                                        &:not(.disabled):hover {
                                            cursor: pointer;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            &.item-specs
            {
                .tile-list
                {
                    .list-sourcing{
                        .inner-holder{
                            .label-heading
                            {
                                min-width: 140px;
                                max-width: 140px;
                            }
                        }
                    }
                }

            }
        }

        .add-schedule {
            display: inline-flex;
            color: $GREEN;
            margin-top: 10px;
            padding: 0 10px 10px 0;

            .ui-icon { margin-right: 10px; }

            svg {
                fill: $GREEN;
            }

            &:hover {
                cursor: pointer;
                color: #fff;

                svg {
                    cursor: pointer;
                    fill: #fff;
                }
            }
        }
    }
}

.production-instance-item-details-inputs-section {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    input {
        padding: 2px 6px;
    }
    .total-units-input-label,
    .total-units-input-holder,
    .production-date-input-label,
    .production-date-picker-input-holder {
        flex: 1;
    }
    .production-date-picker-input-holder {
        position: relative;
        .react-datepicker-wrapper {
            width: 100%;
            .react-datepicker__input-container {
                width: 100%;
            }
        }
        .ui-icon {
            position: absolute;
            top: 5px;
            right: 10px;
            width: 14px;
            height: 16px;
        }
    }
}

.serial-validation-tooltip
{
    .ui-icon
    {
        margin-right: 15px;
    }
}
