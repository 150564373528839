button.spinner-btn{
    height: 30px;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    vertical-align: middle;
    .spinner-holder{
        display: flex;
        align-items: center;
        .spinner{
            margin-right: 5px;
        }
        .spinner,
        .ui-icon,
        .ui-icon svg
        {
            width  : 20px;
            height : 20px;
        }
    }
}