@import "../../../../styles/variables";

.export-route
{
    display: flex;
    .export-table{
        width: 100%;
        margin-left: 0;
    }
    .crumbs
    {
        color: $GREY;
        margin-top: 30px;
        margin-bottom: 37px;
        width: max-content;
    }

    h1
    {
        margin-bottom: 15px;
    }

    .export-menu-block
    {
        position : relative;
        height   : 0;
    }

    .search-block
    {
        display       : inline-block;
        margin-bottom : 40px;

        > div:nth-child(1)
        {
            display         : flex;
            justify-content : left;

            input
            {
                width      : 400px;
                background : $HIGHLIGHT;
                padding-left: 35px;
                font-size: $FONT_SIZE_DEFAULT;
                outline: none;
                &::placeholder
                {
                    color: $GREY;
                }
            }

            .ui-icon
            {
               width: 18px;
               margin-right: -28px;
               margin-top: 8px;
               pointer-events: none;
               margin-left: 10px;

                svg
                {
                    fill: $GREY;
                }
            }

            button
            {
                padding-top    : 7px;
                padding-bottom : 7px;
            }
        }

        > div:nth-child(2)
        {
            user-select : none;
            display     : none;
            height      : 0;
            color       : black;
            position    : absolute;
            z-index     : 1000;
            width       : 400px;

            table
            {
                width: 100%;
                background : white;

                tr
                {
                    cursor : pointer;

                    td
                    {
                        padding : 8px;

                        &:nth-child(1)
                        {
                            padding-left: 12px;
                        }

                        &:nth-child(2)
                        {
                            padding-left : 0;
                            width        : 200%;
                        }
                    }

                    &.selected,
                    &:hover
                    {
                        background: $GREY_LIGHT2;
                    }
                }
            }
        }

        &.searching
        {
            > div:nth-child(1)
            {
                input
                {
                    background    : white;
                    color         : black;
                    border-bottom : 1px solid $GREY;
                }
            }
        }

        > div:nth-child(2)
        {
            &.visible
            {
                display: block;
            }
        }
    }

    .export-right-side-area
    {
        display: flex;
        flex-basis: 70%;
        justify-content: flex-end;
        .right-search-menu
        {
            flex-basis: 270px;
            position: absolute;
            right: 270px;
        }
        .export-side-area {
            flex-basis: 270px;
            background: #353540;
            border-right: 1px solid #17171F;
            border-left: 1px solid #17171F;
            border-bottom: 1px solid #17171F;
        }
    }
}
