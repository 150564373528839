@import "../../../../../styles/variables";
.variants-table-wrapper
{
    table
    {
        tbody
        {
            th:not(:first-child)
            {
                .text-block
                {
                    display: block !important;
                    text-align: center !important;
                    .th-text{
                        width: 100%;
                    }
                }
                &.active-variant
                {
                    background: #FFFD91;
                    .cpn-heading-link
                    {
                        color: #000;
                    }
                }
            }
        }
    }
    .variant-list
    {
        #table-settings-menu
        {
            .modal{
                // height: max-content;
            }
        }
    }

    .not-same{
       background: rgba(4, 173, 195, 0.57);
    }

    .grey-highlighted
    {
        a
        {
            cursor: default;
        }
        cursor: default;
    	background: rgba(136, 136, 136, 0.26);
        .ui-link
        {
            cursor: default;
            .not-all-valid-s
            {
                cursor: default;
            }
        }
    }
    .static-column
    {
        cursor: default;
    }

    .cpn-link
    {
        margin-right: 10px;
    }

    .cpn-heading-link
    {
        color: $GREEN;
        &:hover
        {
            color: $FONT_COLOR;
        }
    }

    .doc-label
    {
        color: $GREEN;
    }

    .sindu_handle
    {
        cursor: pointer;
        &:active
        {
            cursor: move !important;
        }
    }
    .variants-tab-label 
    {
        font-size: 14px;
        text-transform: uppercase;
        margin-bottom: 10px;
        color: #888888;
    }

    .extended-table-section
    {
        .extended-table
        {
            margin-bottom: 16px;
            &.have-table-icons
            {
                margin-top: 0px;
            }
        }
    }
     .comparison-table
    {
        .extended-table-data
        {
            tbody
            {
                tr
                {
                    &:hover
                    {
                        cursor: default;
                    }
                    td
                    {
                        a
                        {
                            cursor: default;
                            &:hover
                            {
                                cursor: default;
                            }
                        }
                    }
                }
            }

        }
    }
    .rolled-up-cost-cell, .rolled-up-mass-cell
    {
        .icon-cell
        {
            position: relative;
            padding-right: 0 !important;
            display: block;
            min-height: 16px;

            &.not-all-valid{
            margin-left: 5px;
            position: absolute;
            right: 0;
            .ui-icon{
                height: 17px;
                width: 17px;
            }
            svg{
                width: 17px;
                height: 17px;
            }
            .warning-el
            {
                .not-all-valid-s
                {
                    width: 17px;
                    height: 17px;
                }
            }
        }
        }

        &:not(.edit)
        {
            span{
                vertical-align: top;
            }
        }

    }

    .assembly-table, .comparison-table
    {
        min-height: 175px;
        .ui-loading
        {
            top: 60px;
            position: absolute;
            height: 100%;
            width: 70%;
        }

        .unit-price-rolledup
        {
            display: flex;
            margin-left: 10px;
        }
        .not-all-valid
        {
            margin-left: 10px;
        }
    }

}
