.initial-notifier {
	.modal {
		width: 564px;
		height: 355px;
		.modal-content {
			.header-block {
				padding: 40px 40px 21px 40px;
				h2 {
					display: flex;
					font-size: 24px;
					.ui-icon {
						width: 20px;
						height: 20px;
						margin-right: 12px;
					}
				}
			}
			.content-block {
				padding: 0px 40px 90px 40px;
				.modal-content {
					font-size: 14px;
					font-weight: 400;
					letter-spacing: 0px;
					text-align: left;
					line-height: 22px;
					color: #d0d0d2;
				}
				ul.rules-list{
					padding: 25px 0px 0px 0px;
					li {
						padding-bottom: 7px;
						list-style: none;
						color: #d0d0d2;
						b {
							color: white;
						}
					}
				}
				.button-handler
				{
					display: flex;
					justify-content: flex-end;
					.save-button{
						height: 27px;
						padding: 0;
			            min-width: 75px;
					}
				}
			}
		}
	}
	
}
