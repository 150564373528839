@import "../../../../../../styles/_variables.scss";

.diff-tool-sidebar {
    .diff-right-sidebar-expand
    {
        position: absolute;
        left: -8px;
        z-index: 2;
        width: 15px;
        height: 100%;
        cursor: ew-resize;
        &:hover
        {
         .border
         {
             border-right: 1px solid #3CD1B5;
         }
        }
        .border
        {
         border-right: 1px solid transparent;
         transition: border-right 1s;
         height: 100%;
         right: 6px;
         text-decoration: none;
        }
    }

    .co-mode-header
    {
        display: flex;
        margin-top: 15px;
        .cross-icon-holder
        {
            position: absolute;
            right: 20px;
            .ui-icon
            {
                width: 13px;
                margin-top: 8px;
            }
        }
        .details-btn-holder
        {
            display: flex;
            .details-btn
            {
                color: $FONT_COLOR;
                border: none;
                background-color: rgba(216, 216, 216, 0.10);
                border-radius: 8px;
                margin-left: 20px;
                padding: 6px 12px;
                display: inline-flex;
                align-items: center;
                min-width: auto;
                .rev-icon-holder
                {
                    margin-right: 22px;
                }
                .ui-icon
                {
                    svg
                    {
                        g
                        {
                            fill: $BG_WHITE;
                        }
                    }
                }
                &:hover
                {
                    .ui-icon
                    {
                        svg
                        {
                            g
                            {
                                fill: $GREEN;
                            }
                        }
                    }
                    color: $GREEN;
                }
            }
        }
    }


    #right-list-content
    {
        padding: 0 20px;
    }
    .ps__thumb-y {
        background-color: #888888 !important;
    }
    .header-block {
        padding-left: 0;
        padding-right: 0;
        border-bottom: 1px solid #18171D;
        padding-bottom: 15px;
        margin-bottom: 20px;
        h2{
            margin-bottom: 8px;
            font-size: 18px;
            display: flex;
            justify-content: space-between;
            .help-icon
            {
                width: 15px;
                height: 17px;
            }
        }
        .header-inner {
            color: #888888;
            max-height: 16px;
            display: flex;
            .total-changes
            {
                margin-left: 8px;
                color: $FONT_COLOR;
            }
            .spinner-wrapper
            {
                top: -2px;
                margin-top:0;
                margin-left: 4px;
                .spinner
                {
                    width: 20px;
                    height: 20px;
                    fill: #044e45;
                    .ui-icon
                    {
                        svg
                        {
                          width: 20px;
                          height: 20px;
                          fill: #05a28f;
                        }
                    }
                }
            }
        }
        .name-header-inner {
            color: #888888;
            margin-bottom: 5px;
            .cpn-holder
            {
                color: $GREEN;
                padding-right: 25px;
            }
        }
    }
    .box-item {
        margin: 15px 0 20px;
        .box-heading {
            margin-bottom: 5px;
            color: #888888;
            font-weight: 600;
        }
        .assembly-updates-heading
        {
            display: flex;
            margin-bottom: 5px;
            align-items: center;
            max-height: 16px;
            .box-heading
            {
                margin-bottom: 0;
            }
        }
        .spinner-wrapper
        {
            margin-left: 20px;
            top: -2px;
            margin-top:0;
            .spinner
            {
                width: 20px;
                height: 20px;
                fill: #044e45;
                .ui-icon
                {
                    svg
                    {
                      width: 20px;
                      height: 20px;
                      fill: #05a28f;
                    }
                }
            }
        }
        .changes-loading
        {
            .item-label,
            .item-content
            {
                width: 60px;
                height: 19px;
                border-radius: 3px;
                margin-bottom: 3px;
                background: $GREY_LIGHT3;
            }
            .item-content
            {
                max-width: 20px;
            }
        }
        .box-holder {
            background: #41414B;
            padding: 8px;
            border-radius: 2px;
            // cursor: pointer;
            &.diff-build-schedule-section,
            &.diff-specs-section
            {
                .tag-item {
                    margin-right: 5px;
                }
            }
            .diff-check {
                display: inline;
                flex-wrap: wrap;
                justify-content: flex-start;
                align-items: center;
                .alert-icon{
                    display:inline-block;
                    svg {
                        width: 6px;
                        height: 6px;
                        margin-bottom:5px;
                    }
                }
                &.remove {
                    span{
                        background: $DIFF_REMOVE_COLOR;
                        text-decoration: line-through;
                    }
                    .alert-border-black
                    {
                        svg
                        {
                            g
                            {
                                g
                                {
                                    fill: white;
                                }
                            }
                        }
                    }
                }
                &.add {
                    span{
                        background: $DIFF_ADD_COLOR;
                    }
                }
                &.update {
                    span{
                        background: $DIFF_UPDATE_COLOR;
                    }
                }
                &.no-change {
                    span {
                        display: inline-block;
                    }
                }
                &.add,
                &.remove {
                    span{
                        display: inline-block;
                        padding: 2px 0;
                        margin: 2px 0;
                        padding-right: 2px;

                        &:first-child{
                            border-top-left-radius: 2px;
                            border-bottom-left-radius: 2px;

                            padding-left: 2px;

                        }
                         &:last-child{
                            border-top-right-radius: 2px;
                            border-bottom-right-radius: 2px;
                            padding-right: 4px;
                            margin-right:5px;
                        }
                    }
                }
            }
            &.primary_source {
                .tag-item{
                    margin-right: 3px;
                    min-width: 20px;
                    text-align: center;
                }
            }
            .item-list {
                display: flex;
                align-items: flex-start;
                margin-bottom: 3px;
                &:last-child{
                    margin-bottom: 0;
                }
                .item-label {
                    flex: 1;
                    max-width: 74px;
                    min-width: 74px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    margin-right: 10px;
                    color: #888888;
                    text-transform: uppercase;
                    .tag-item{
                        padding: 1px 4px 1px 5px;
                        border-radius: 2px;
                        display: inline-block;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        max-width: 139px;
                        color: $FONT_COLOR;
                        text-align: left;
                        width: 100%;
                        &.remove {
                            background: $DIFF_REMOVE_COLOR;
                        }
                        &.add {
                            background: $DIFF_ADD_COLOR;
                        }
                        &.update {
                            background: $DIFF_UPDATE_COLOR;
                        }
                    }
                }
                .item-content {
                    flex: 1;
                    .tag-item{
                        padding: 1px 5px;
                        border-radius: 2px;
                        display: inline-block;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        max-width: 165px;
                        white-space: nowrap;
                        &.remove {
                            background: $DIFF_REMOVE_COLOR;
                            text-decoration: line-through;
                        }
                        &.add {
                            background: $DIFF_ADD_COLOR;
                        }
                        &.update {
                            background: $DIFF_UPDATE_COLOR;
                        }
                    }
                }
            }
        }
    }
}
.right-search-menu.open {
    width: 310px !important;
    height: 100vh;
}
