@import "../../../../../styles/variables";

.add-users-list{

    label
    {
        font-size : $FONT_SIZE_DEFAULT;
        color     : $GREY;
    }

    input
    {
        line-height: 10px;
        padding: 6px 10px 7px;
    }

    .search-area-holder {
        border: 1px solid $FONT_COLOR;
        background: $BG_WHITE;
        display: flex;
        align-items: center;
        // margin-bottom: 20px;
        margin-left   : 20px;
        margin-right  : 20px;
        .ui-icon {
            width: 18px;
            height: 18px;
            margin-left: 10px;
            svg {
                fill: $GREY_LIGHT3;
            }
        }
        input{
            outline-style: none;
        }
        &.active{
            border: 1px solid $GREEN;
        }
    }

    .select-all-area {
        background: #41414B;
        display: flex;
        align-items: center;
        padding-left   : 20px;
        padding-right  : 20px;
        .select-all-holder {
            padding: 6px 0px;
            height: 36px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            // margin-bottom: 15px;
            .select-all-label{
                margin: 0 10px;
                font-size: $FONT_SIZE_DEFAULT;
            }
            .btn-add {
                float: right;
                padding: initial;
                width: 100px;
                height: 24px;
                font-size: $FONT_SIZE_DEFAULT;
            }
        }
    }

    #right-side_bar-content
    {
        height: 100%;
        .result-count{
                display: block;
                padding : 10px 20px;
            }
        
    }
    .co-select-wrapper{
        border-bottom: 1px solid $BG_LIGHT3;
        margin: 0 20px 15px 20px;
    }

    .visible {
        height: 100%;
        padding-bottom: 20px;
        .list-area {
            max-height: 64vh;
            overflow: scroll;
            -ms-overflow-style: none;  /* Internet Explorer 10+ */
            scrollbar-width: none;  /* Firefox */
            padding-left  : 20px;
            padding-right : 20px;
            ul {
                list-style: none;
                li {

                    border-bottom: 1px solid $BG_LIGHT3;
                    padding: 17px 0 20px;

                    display: flex;
                    align-items: center;

                    &:first-child{
                        border-top: 0;
                    }

                    label {
                        margin-right: 10px;
                        width: 12px;
                        height: 12px;
                    }

                }
            }

            .avatar-holder
            {
                display: flex;
               align-items: center;
                padding-top:0;
            }

            .user-img,
            .default-icon
            {
                margin-right: 10px;
            }
            .group-name{
                max-width: 160px;
                padding-top: 5px;
            }
            .check-box{
                margin-top: 0;
                display: inherit;
            }
            .default-icon
            {
                top         : 0px;
                width       : 28px;
                height      : 28px;
                padding     : 0px;
                border : none;
                svg
                {
                    width   : 28px;
                    height  : 28px;

                }
            }

        }
        .list-notifiers {
            max-height: 47vh !important;
        }
        .list-area::-webkit-scrollbar { 
            display: none;  /* Safari and Chrome */
        }
    }

    .add-new-user,
    .mail-block
    {
        padding: 20px 10px 20px;
    }
    .mail-block
    {
        padding: 5px 20px 0px;
    }
    .add-new-user{
        border-bottom: 1px solid $BG_LIGHT3;
        margin  : 0 20px 20px 20px;
        /*padding-right : 20px;*/
    }

    .mail-block{
        overflow: hidden;
        p{
            margin-bottom: 5px;
        }
        textarea{
            background: transparent;
            border: 1px solid #979797;
            resize: none;
            margin-bottom: 20px;
            color: #979797;
            height: auto;
            min-height: 100px;
            font-size: $FONT_SIZE_DEFAULT;
            &:active,
            &:focus{
                background: $BG_WHITE;
            }
            &:focus.invalid
            {
                border-color: $ERRORS;
                background: $ERRORS;
                color: $FONT_COLOR;
            }
        }

        button{
            float: right;
        }
    }

    .checked-input{
        width: 12px;
        height: 12px;
    }

    .checked-label{
        width: 12px;
        height: 12px;
    }

    .right-search-expand {
        position: absolute;
        left: -14px;
        z-index: 2;
        width: 15px;
        height: 100%;
        cursor: ew-resize;
        .border{
            border-right: 1px solid transparent;
            transition: border-right 1s;
            height: 100%;
            right: 6px;
            text-decoration: none;
        }
        &:hover, &:active{
            .border{
                border-right: 1px solid $GREEN;
                right: 6px;
                height: 100%;
            }
        }
    }

}
