@import "../../../styles/variables";

.search-page
{
    // export right menu styles start
    .export-side-area{
        .export-scroll-area{
            // height: 100% !important;
        }
        .custom-dropdown,
        .export-depth
        {
            display: none;
            &.sourcing-block{
                display: block;
            }
        }
        .file-to-include{
            border-top: none;
            padding-top: 0px;
        }
        .export-block{
            .cancel-btn{
                margin-right: 8px;
            }
        }
    }
}

.search{
    .routes{
        padding-bottom: 0;
    }
}

.add-more-actions{
    position: inherit;
    button.add-trigger{
        color: $FONT_COLOR;
        .ui-icon{
            width: 14px;
            svg{
                fill: $FONT_COLOR!important;
            }
        }
        &:hover,
        &:focus
        {
            color: $GREEN;
            .ui-icon{
                svg{
                    fill: $GREEN !important;
                }
            }
            .plus-icon{
                #hover-area{
                    stroke: $GREEN;
                }
            }
        }
        .trigger{
            .ui-icon svg g{
                stroke: $GREEN;
            }
        }
    }
    button.add-trigger,
    button.add-trigger:hover,
    button.add-trigger:focus{
        min-width: $INDEX_CELL_WIDTH;
        height: 35px;
        padding: 0;
        border: none;
        background: none;
        background-color: none;

    }

    &.open{
        .sub-menu{
            display: block;
        }
    }
    .sub-menu{
        z-index: 9999;
        display: none;
        position: absolute;
        background: #17171F;
        margin-top: 5px;
        left: 30px;
        top: -35px;
        padding: 5px;
        text-align: left;
        white-space: nowrap;

        div {
            text-transform: none;
        }
        .option {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            margin:10px;

            .ui-icon {
                height: 14px;
                width: 14px;
              svg {
                height: 14px;
                width: 14px;
                stroke : $FONT_COLOR;
              }
                margin-right: 10px;
            }
            .label{
                font-size: $FONT_SIZE_DEFAULT;
                color: $FONT_COLOR;
            }

            &:hover{
                .label {
                    color: $GREEN;
                }

                .ui-icon{
                    color: $GREEN;
                    svg
                    {
                        fill : $GREEN;
                        stroke : $GREEN;
                    }
                }
                svg#import-icon{
                    #path-1{
                        stroke: $GREEN;
                        fill: $GREEN;
                    }
                    #path-2{
                         fill: $GREEN;
                    }
                }
                svg#assembly-add-icon{
                    g{
                        stroke: $GREEN;
                    }
                }
            }

        }
    }
    &.assembly-actions{
        .sub-menu{
            top: -15px;
        }
    }
}

.release-search-list {
    .release-vendor-label {
        display: inline-flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
        justify-content: flex-start;
        color: #18171D;
        font-size: 14px;
        height: 18px;
        line-height: 0px;
        border-radius: 10rem;
        background-color: #04ADC3;
        padding: 0px 5px;
        .ui-icon {
            margin-right: 5px;
            pointer-events: none;
            width: 20px;
            height: 20px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            svg {
                g {
                    fill: #000;
                }
            }
        }
        span {
            color: #000;
        }
    }
    .records-count {
        color: #FFF;
    }
    .text-block {
        .th-text {
            line-height: 37px !important;
        }
    }
    .user-avatar-holder{
        width: 35px;
        .user-profile-img {
            &.hidden {
                display: none !important;
            }
        }
    }
    .face-avatar{
        min-width: 20px;
        height: 20px;
        margin: 0;
        .ui-icon {
            margin: 0 10px 0 0;
            width: 20px;
            height: 20px;
        }
        svg {
            width: 20px;
            height: 20px;
        }
    }
    .custom-source-pillbox
    {
        &.vendor-label
        {
            height: 18px;
            display: inline-flex;
            justify-content: flex-start;
            padding: 0 9px;
            min-height: unset;
        }
    }
}

table
{
    tbody
    {
        tr
        {
           td
            {
                &.name-block
                {
                    .text-block
                    {
                        overflow: inherit;
                    }
                    .item-name
                    {
                        .ui-link.link
                        {
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            width: 100%;
                            height: 18px;
                            display: inline-block;
                            padding-right: 30px;
                            &.not-variant
                            {
                                padding-right: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}

.active-filter {
    svg {
        path {
            fill: $GREEN;
        }
    }
    > span {
        color: $GREEN;
    }
}
