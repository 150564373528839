@import "../../../../../styles/variables";

.table-cta-icons{
    left: -36px;
    margin-right: 40px;

    background-color : #3D3D47;
    padding             : 0px 9.5px;
    border-top-right-radius: 7px;
    border-top-left-radius: 7px;
    border           : 1px solid #888888;
    border-bottom    : 0px !important;
    width            : fit-content;
    min-height       : 36px;
    display          : flex;
    align-items      : center;
    justify-content  : center;
    user-select      : none;
    -webkit-transition: all 1s linear;
    transition: all 1s linear;

    span
    {
        cursor       : pointer;
        margin-right : 15px;
        .ui-icon
        {
            // width          : 16px;
            // height         : 19px;
            width: auto;
            height: 20px;
            vertical-align : middle;
            &.setting-icon{
                width          : 16px;
            // height         : 19px;
            }

            &.auto-fit-icon{
                width          : 20px;
            }
        }
        &:last-child{
            margin-right : 0px;
        }
        &.hide-unhide-toolbar-icon{
            margin-right: 8px;
        }
    }
    .action-item{
        color: $GREY_LIGHT3;
        display: inline-flex;
        white-space: nowrap;
        &.disabled{
            pointer-events: none;
        }
        #collapse-icon
        {
            g{
                fill: $GREY_LIGHT3;
            }
            #auto-icon{
                fill: $GREY_LIGHT3;
                stroke: $GREY_LIGHT3;
            }
        }
        &.vendor-icon
        {
            svg
            {
                height: 17px;
                width: 17px;
                stroke: #ffffff;

            }
            &:hover {
                svg {
                    stroke: $GREEN;
                }
            }
        }
        &.active
        {
            color: $FONT_COLOR;
            #collapse-icon{
                g{
                    fill: $FONT_COLOR;
                }
                #auto-icon{
                    fill: $FONT_COLOR;
                    stroke: $FONT_COLOR;
                }
            }
            &:hover
            {
                color: $GREEN;

                svg {
                    fill: $GREEN;

                    path {
                        fill: $GREEN;
                    }
                    #refresh-24px {
                        fill: $GREEN;
                    }
                    &#duplicate-icon-new
                    {
                        #icon-g
                        {
                            fill: $GREEN;
                        }

                    }
                    &#delete-svg{
                        g{
                            fill: $GREEN;
                            stroke: $GREEN;
                        }
                    }
                    &#Bulk-Status-from-Search
                    {
                        g{
                            g{
                                g{
                                    stroke: $GREEN;
                                }
                            }
                        }
                        stroke: $GREEN;
                    }
                    &#width-auto-adjust-svg{
                        g{
                            fill: $GREEN;
                        }
                        path
                        {
                            stroke: $GREEN;
                            fill: $GREEN;
                        }
                    }
                    &#enlarge-svg{
                        g{
                            fill: $GREEN;
                            stroke: $GREEN;

                        }
                    }
                    &#export-new-svg,
                    &#assembly-add-icon{
                        g{
                            stroke: $GREEN;
                        }
                    }
                    &#import-icon{
                        #path-1{
                            stroke: $GREEN;
                            fill: $GREEN;
                        }
                        #path-2{
                             fill: $GREEN;
                        }
                    }
                    &#tree-svg-icon{
                        #tree-icon> g {
                            stroke: $GREEN;
                        }
                    }

                    &#grid-svg-icon{
                        path {
                            fill: $GREEN;
                        }
                    }

                    &#collapse-icon{
                        g{
                            fill: $GREEN;
                        }
                        #auto-icon{
                            fill: $GREEN;
                            stroke: $GREEN;
                        }
                    }

                    &#checkbox-svg-with-dot
                    {
                        #Primary
                        {
                            stroke: #3CD1BF;
                        }
                        circle
                        {
                            stroke: #3CD1BF;
                            fill: #3CD1BF;
                        }
                    }
                    &#revert-icon
                    {
                        g > g > g > g,.path1
                        {
                            stroke: $GREEN;
                        }
                        .path2
                        {
                            fill: $GREEN;
                        }
                    }
                }
            }

        }
        .item-title{
            margin-left    : 8px;
            margin-right   : 0px;
            font-size: 14px;
            display: inline-block;
        }
        .collapse
        {
            svg
            {
                transform: rotate(180deg);
            }
        }
        .ui-icon {
            padding: 0 !important;
            &.expand,
            &.collapse{
              &.disabled
              {
                opacity: 1;
              }
            }
        }
    }

    .table-view-toggler
    {
        background-color: rgba(136, 136, 136, 0.15);
        padding: 3px 12px;
        margin-right: 10px;
        border-radius: 8px;
        span
        {
            margin-right: 0;
            &:first-child
            {
                margin-right: 19px;
            }
        }
    }

    .divider{
        border-right   : 1px solid $GREY_LIGHT3;
        vertical-align : middle;
        min-height     : 20px;
        cursor         : default;
        width          : 2px;
    }

    &.hide-title{
        .action-item{
            .item-title{
                display: none;
            }
        }
        .assembly-icons
        {
            .muted-text{
                display: none;
            }
        }
    }
    &.hide-actions{
        > span
        {
            display: none;
        }
        span[data-tip="Table settings"],
        > span:nth-child(1),
        > span:nth-child(2)
        {
                display: unset;
        }
        .dots-holder
        {
            .dot
            {
                display: unset;
            }
        }
        .menu
        {
            display: unset;
        }
    }
    .menu
    {
        display: none;
        .add-trigger,
        .add-trigger:hover,
        .add-trigger:focus
        {
            padding-right: 15px;
        }
        .add-trigger{
                .dots-holder
                {
                display         : inline-flex;
                align-items     : center;
                justify-content : center;
                margin-right    : 0px;
                cursor          : pointer;
                padding-bottom  : 8px;
                .dot
                {
                    width            : 5px;
                    height           : 5px;
                    background-color : #fff;
                    border-radius    : 50%;
                    margin           : 0 1.5px;
                }
            }
            &:hover
            {
                .dot
                {
                    background-color : $GREEN;
                }
            }
        }
        .sub-menu
        {
            padding-right: 7px;
            padding-top: 0px;
            padding-bottom: 10px;
            padding-left: 0px;
            .option
            {
                font-size: 14px;
            }
        }
    }
    .assembly-icons
    {
        .icon-section
        {
            background-color: hsla(0,0%,53%,.15);
            padding: 3px 12px;
            border-radius: 8px;
        }
    }
    .help-icon {
        position: absolute;
        right: -32px;
        top: 10px;
        .ui-icon {
            width: 15px;
            height: 15px;
        }
    }
}
.display-none
{
    display: none;
}
.hide-toolbar-icons
{
    span
    {
        display: none !important;
    }
    span:first-child
    {
        display: unset !important;
        margin-right: 0px;
    }
    .add-more-actions
    {
        display: none;
    }
    .table-view-toggler
    {
        display: none;
    }
}

.filters-active {
    display: flex;
    align-items: center;
    width: 100%;
    min-width: 120px;
    margin-left: -60px;

    span {
        &:first-child {
            color: $GREEN;
        }

        &:last-child {
            &:hover {
                cursor: pointer;

                svg {
                    color: $GREEN;
                }
            }

            svg {
                margin-left: 7px;
            }
        }
    }
}

.arrow {
    width: 16px;
    height: 16px;
    margin-left: 10px;

    &:hover {
        cursor: auto;

        svg { fill: $FONT_LIGHT; }
    }

    svg {
        font-size: 1rem;
        color: $FONT_LIGHT;
    }

    &.active {
        &:hover {
            cursor: pointer;

            svg {
                color: $GREEN;
            }
        }

        svg {
            color: $FONT_COLOR;
        }
    }
}