@import "../../../../../styles/variables";

.search-route .actions-block
{

    .query-string
    {
        background: $BG_LIGHT2;

        > div
        {
            text-transform : uppercase;
            font-size      : 9px;
            letter-spacing : 2px;
            word-spacing   : 4px;
            overflow       : hidden;
            line-height    : 14px;
            text-align     : left;
            color          : #B3B3B3;
            font-style     : italic;
            padding-top    : 8px;
            padding-bottom : 5px;
            text-align     : left;
        }
    }

    .nav-block
    {
        width           : 100%;
        display         : flex;
        justify-content : space-between;
        // align-items     : flex-end;
        align-items     : flex-start;
        padding-top     : 24px;
        position: relative;
        .types-block
        {
            white-space     : nowrap;
            display         : flex;
            justify-content : space-between;

            > div
            {
                cursor       : pointer;
                margin-right : 45px;
                opacity      : 0.3;

                .ui-icon
                {
                    width        : 11px;
                    height       : 11px;
                    margin-right : 8px;

                    svg
                    {
                        fill: $GREEN;
                    }
                }

                &:hover
                {
                    opacity : 1;
                    color   : $FONT_COLOR;
                }

                &.selected
                {
                    pointer-events : none;
                    color          : $FONT_COLOR;
                    opacity        : 1;
                }
            }
        }

        .refine-block
        {
            flex       : 0 0 0px;
            text-align : right;
            position: initial;

            .trigger
            {
                cursor : pointer;
                top: -10px;

                *
                {
                    pointer-events: none;
                }

                .ui-icon
                {
                    width       : 9px;
                    height      : 9px;
                    margin-left : 8px;
                    transform: rotate(90deg);

                    svg
                    {
                        fill: $GREEN;
                    }
                }
                &.open
                {
                    .ui-icon
                    {
                        padding-bottom: 14px;
                    }
                }
            }

            .modal
            {
                position      : absolute;
                box-shadow    : 0px 0px 10px 0px rgba(0,0,0,0.5);
                background    : $BG_LIGHT2;
                z-index       : 5000;
                top           : 0px;
                height        : 100vh;
                overflow      : hidden;
                width         : 0px;
                display       : block;

                > div
                {
                    padding    : 12px 20px 30px;
                    text-align : left;
                }

                .header-block
                {
                    padding-top    : 31px;
                    padding-bottom : 15px;
                    padding-right  : 20px;
                    padding-left   : 20px;
                    .clear-btn
                    {
                        font-size: 14px;
                        cursor: pointer;

                        &:hover
                        {
                            color: $GREEN;
                        }
                    }
                    .close-icon{
                        position: absolute;
                        right: 30px;
                        top: 31px;

                        .ui-icon{
                            width: 10px;
                            height: 10px;
                        }
                    }
                }

                label
                {
                    font-size : 14px;
                    color     : $GREY;
                }

                input
                {
                    margin-bottom: 18px;
                    line-height: 10px;
                    padding: 6px 10px 7px;
                }

                &.open
                {
                    width         : 270px !important;
                    transition    : 0.1s;
                    right         : 0;
                    border-right  : 1px solid $BG_DARK;
                    border-left   : 1px solid $BG_DARK;
                    border-bottom : 1px solid $BG_DARK;
                }

            }
        }
    }

    .actions-header-block
    {
        h1{
            display: inline-block;
        }
        .release-heading {
            span {
                cursor: pointer;
                margin-left: 18px;
                .ui-icon {
                    margin-top: 5px;
                    margin-left: -6px;
                }
            }
        }
    }
    .hidden {
        display: none;
    }

    span.success-message {
        margin: 2px 5px 0 6px;
        font-size: $FONT_SIZE_DEFAULT;
        display: inline-block;
        vertical-align: top;

        .error-msg
        {
            color: red;
        }
    }

    .release-block {
        display: block;
        /*max-width: 1037px;*/
        .vendors-container {
            .action-btn
            {
                align-items: flex-start;
                text-transform: none;
                color: $FONT_COLOR;
                border: none;
                background-color: rgba(216, 216, 216, 0.10);
                border-radius: 8px;
                margin-left: 10px;
                padding: 8px 12px;
                display: inline-flex;
                align-items: center;
                min-width: auto;

                .ui-icon
                {
                    height: 16px;
                    width: 12px;
                    margin-right: 10px;
                    path{
                        fill: $FONT_COLOR;
                    }
                }
                &:hover{
                    color:  $GREEN;
                    path{
                        fill: $GREEN;
                    }
                }
            }
            .release-vendor-label {
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                -webkit-box-pack: space-evenly;
                -ms-flex-pack: space-evenly;
                justify-content: space-evenly;
                color: #18171D;
                font-size: 14px;
                height: 18px;
                line-height: 0px;
                border-radius: 10rem;
                background-color: #04ADC3;
                padding: 0px 5px;
                .vendor-label {
                    padding: 0 5px;
                }
                .ui-icon {
                    pointer-events: none;
                    width: 20px;
                    height: 20px;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    align-items: center;
                    -webkit-box-pack: center;
                    -ms-flex-pack: center;
                    justify-content: center;
                    svg {
                        g {
                            fill: #000;
                        }
                    }
                }
                span {
                    text-transform: uppercase;
                    color: #000;
                }
            }
        }
    }
}
.ml-10 {
    margin-left: 10px;
}
