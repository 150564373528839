@import "../../../../../styles/variables";

.custom-history-table {
    table
    {
        thead
        {
            tr
            {
                th
                {
                    background: #17171F;
                    position: sticky;
                    top: 0;
                    z-index: 999;
                    min-height: 37px;
                    &:nth-child(1)
                    {
                        width: 25px;
                        .icon-div{
                            width:50px;
                        }
                    }
                    &:nth-child(2)
                    {
                        width: 25px;
                    }
                    &:nth-child(3)
                    {
                        width: 150px;
                    }
                    &:nth-child(4)
                    {
                        width: 160px;
                    }
                    &:nth-child(5)
                    {
                        width: 90px;
                    }
                    &:nth-child(6)
                    {
                        width: 70px;
                    }
                    &:nth-child(7)
                    {
                        width: 130px;
                    }
                    &:nth-child(8)
                    {
                        width: 180px;
                        text-align: left;
                    }
                    
                }
            }
        }
        tbody
        {
            tr
            {
                td
                {
                    text-align: left;
                    .ui-loading {
                        padding-left: 10px;
                        .loading-block,
                        svg {
                            width: 20px!important;
                            height: 20px!important;
                            // padding-left: 10px!important;
                        }
                    }
                    .custom-arrow-icon {
                        height: 10px;
                        display: flex;
                        .ui-icon {
                            width: 100%;
                            height: 100%;
                            svg {
                                width: 10px!important;
                                height: 10px!important;
                            }
                        }
                    }
                    .history-table-title {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        color: #fff;
                        &.text-block {
                            padding-left: 15px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            .user-name {
                                width: -webkit-fit-content;
                                width: -moz-fit-content;
                                width: fit-content;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                            }
                        }
                    }
                    .sub-rev-icon {
                        padding: 0!important;
                        transform: rotate(270deg);
                        &.open {
                            transform: none;
                        }
                    }
                    .user-avatar-holder {
                        margin-right: 10px;
                        .face-avatar {
                            .ui-icon {
                                width: 100%;
                                height: 100%;
                                padding: 0!important;
                                display: flex;
                                svg {
                                    width: 16px;
                                    height: 16px;
                                }
                            }
                        }
                    }
                    .user-profile-img
                        {
                            display: flex;
                            width: 20px;
                            height: 20px;
                            overflow: hidden;
                            border-radius: 100%;
                            margin-right: 10PX;
                            img
                            {
                                width: 100%;
                            }
                            &.hidden
                            {
                                display: none;
                            }
                        }
                    .rounded-checkbox {
                        .ui-icon.arrow-button {
                            position: absolute;
                            left: 0;
                            padding: 0!important;
                        }
                    }
                    .con-holder {
                        .ui-link.link {
                            display: flex;
                            align-items: center;
                            &.red-font {
                                color:#f54a4f;
                            }
                            &:hover {
                                color: #fff;
                            }
                            .co-link-icon {
                                padding: 0!important;
                            }
                        }
                    }
                    .revision-value {
                        display: flex;
                        .ui-icon {
                            padding: 0!important;
                        }
                    }
                    .comment {
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            }
        }
    }
}

.custom-loader-holder {
    padding: 10px 0;
}