@import "../../../../styles/variables";

.company-settings-route
{
    .modal
    {
        width: 400px;
        padding: 28px 44px;
        h1
        {
            margin-bottom: 30px;
        }
        .buttons-holder
        {
            border-top: 1px solid #979797;
            margin-top: 30px;
            padding-top: 40px;
            display: flex;
            justify-content: flex-end;
        }
    }
    .main-block
    {
        .content-block
        {
            .form-block
            {
                .info-block
                {
                    input[name="address.street"]
                    {
                        margin-bottom: 10px;
                    }

                    .city-block
                    {
                        display : flex;

                        div:nth-child(1)
                        {
                            flex : 1;
                        }

                        div:nth-child(2)
                        {
                            margin : 0 15px;
                            width  : 125px;
                        }

                        div:nth-child(3)
                        {
                            width : 75px;
                        }
                    }
                }

                .two-fa
                {
                    display: flex;
                    align-items: center;
                    margin-bottom: 25px;
                    label
                    {
                        margin-bottom: 0;
                        margin-right: 25px;
                    }
                    &.disable
                    {
                        color: grey;
                    }
                }

                .right-block
                {
                    position: relative;

                    .image-block
                    {
                        cursor : pointer;
                        width  : 200px;
                        height : 315px;

                        .display-block
                        {
                            height          : 200px;
                            border          : 1px solid white;
                            margin-bottom   : 15px;
                            display         : flex;
                            justify-content : center;
                            align-items     : center;

                            .image
                            {
                                display             : none;
                                width               : 100%;
                                height              : 100%;
                                background-position : center;
                                background-size     : contain;
                                background-repeat   : no-repeat;
                            }
                        }

                        .drag-block
                        {
                            position        : relative;
                            display         : flex;
                            align-items     : center;
                            justify-content : center;
                            border          : 1px dashed white;
                            height          : 50px;

                            span
                            {
                                height : 13px;

                                &:nth-child(2)
                                {
                                    color           : $GREY;
                                    text-decoration : underline;
                                    margin-left     : 5px;
                                }
                            }

                            .modal
                            {
                                display: none;
                                width: 190px;
                                height: 40px;
                            }
                        }

                        &.filled
                        {
                            .display-block
                            {
                                .copy
                                {
                                    display: none;
                                }

                                .image
                                {
                                    display: block;
                                }
                            }
                        }

                        &.accept-files
                        {
                            .drag-block
                            {
                                span
                                {
                                    display : none;
                                }

                                .modal
                                {
                                    display    : block;
                                    background : $GREEN;
                                }
                            }
                        }

                        &.reject-files
                        {
                            .drag-block
                            {
                                span
                                {
                                    display : none;
                                }

                                .modal
                                {
                                    display    : block;
                                    background : $ERRORS;
                                }
                            }
                        }
                        &.disabled
                        {
                            pointer-events: none;
                        }
                    }

                    .remove-btn
                    {
                        display       : none;
                        width         : 20px;
                        height        : 20px;
                        background    : $GREEN;
                        border-radius : 100%;
                        position      : absolute;
                        top           : 15px;
                        right         : -10px;
                        cursor        : pointer;

                        .ui-icon
                        {
                            pointer-events : none;
                            margin-top     : 3px;
                            margin-left    : 6px;

                            svg
                            {
                                width  : 8px;
                                height : 8px;
                                fill   : black;
                            }
                        }

                        &.filled
                        {
                            display : block;
                        }
                    }
                }
            }
        }
    }
}
