@import "../../../../../styles/variables";

.inline-image-viewer
{
    .text-block
    {
        height: auto !important;
        position: relative !important;
        .table-image-viewer-wrapper
        {
            justify-content: center;
            display: flex;
            .table-image-viewer-holder
            {
                background: #fff;
                padding: 4px 5px;
                display: flex;
                min-width: 38px;
                justify-content: center;
                border-radius: 2px;
                min-height: 28px;
                &.default-img
                {
                    background: linear-gradient(-180deg, #353540 0%, #18171D 100%);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .default-img-icon {
                        width: 15px;
                        height: 16px;
                        margin: 0;
                        svg
                        {
                            width: 16px;
                            height: 16px;
                            fill: $FONT_LIGHT;
                        }
                    }
                    &.white-background
                    {
                        background: #F4F4F4;
                    }
                }
            }
            .table-image-viewer
            {
                background-size: contain;
                background-repeat: no-repeat;
                cursor: default;
                background-position: center;
                height: 20px;
                width: 24px;
            }
            .enlarge-icon-holder
            {
                position: absolute;
                right: -17px;
                bottom: 0;
                .ui-icon
                {
                    height: 10px;
                    width: 10px;
                    cursor: pointer;
                }
                &:hover
                {
                    .enlarge-icon
                    {
                        stroke: $GREEN;
                    }
                }
                &.where-used-inline-image-viewer
                {
                    right: -22px;
                }
            }
        }
    }
}
.inline-image-viewer-tooltip
{
    .rc-tooltip-content
    {
        .rc-tooltip-arrow
        {
            display: none;
        }
        .rc-tooltip-inner
        {
            min-height: auto;
            max-height: 128px;
            padding-left: 5px !important;
            padding-right: 5px !important;
            padding: 3px !important;
            border: 0;
            .ui-image-viewer
            {
                padding: 3px;
                width: 100%;
                min-width: 150px;
                .images
                {
                    max-width: 172px;
                    min-height: 95px;
                    height: 95px;
                    border: 1px solid #CBCBCB;
                    border-radius: 2px;
                    // padding: 7px;
                    width: auto;
                    margin: 0 auto;
                    min-width: 145px;
                    .image
                    {
                        background-size: contain;
                        margin: 5px;
                        width: 134px;
                        height: 84px;
                    }
                }
                .controls {
                    margin-top: 5px;
                    height: auto;
                    justify-content: center;
                    align-items: center;
                    .arrow {
                        width: 18px;
                        height: 18px;
                        padding:0;
                        display: flex;
                        align-items: center;
                        .ui-icon  {
                            width: 18px;
                            height: 21px;
                            padding:2px;
                        }
                        &:hover
                        {
                            background: transparent;
                        }
                    }
                    .count {
                        width: auto;
                        margin: 0 13px;
                        padding:0;
                        .of {
                            padding:0;
                        }
                    }
                }
            }
            .inline-image-viewer
            {
                .text-block
                {
                    .table-image-viewer-wrapper
                    {
                        .table-image-viewer-holder
                        {
                            &.default-img
                            {
                                background: linear-gradient(-180deg, #353540 0%, #18171D 100%);
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                width: 134px;
                                height: 84px;
                                background-size: contain;
                                margin: 5px;
                                .default-img-icon {
                                    width: 120px;
                                    height: 80px;
                                    margin: 0;
                                    svg
                                    {
                                        width: 120px;
                                        height: 55px;
                                        margin-top: 12px;
                                        fill: $FONT_LIGHT;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
