@import "../../../styles/variables";

.right-sidebar-menu
{
    height: 100%;
    min-height: 100%;
    max-height: 100%;
    overflow: auto !important;
    span.cpn, span.result-count{
        padding-right: 5px;
        color: $GREY_LIGHT3;
    }
    .scrollbar-container.vertical {
        display:none;
    }
    &:hover {
        .scrollbar-container.vertical {
            display:block;
        }
    }
    flex       : 0 0 200px;
    text-align : right;
    background    : $BG_LIGHT2;
    z-index       : 1;
    top           : 0px;
    width         : 0px;
    display       : block;
    font-size     : $FONT_SIZE_DEFAULT;
    > div
    {
        text-align : left;
        h3 {
            font-size: $FONT_SIZE_DEFAULT;
        }
    }

    .header-block
    {
        padding-top : 20px;
        padding-bottom : 10px;
        padding-left: 20px;
        text-align     : left;
        h2 {
             font-size  : 16px;
             font-weight: normal;
        }
    }


    &.open
    {
        width         : 270px !important;
        transition    : 0.1s;
        right         : 0;
        border-right  : 1px solid $BG_DARK;
        border-left   : 1px solid $BG_DARK;
        border-bottom : 1px solid $BG_DARK;
    }

    .close-icon
    {
        position: absolute;
        right: 10px;
        top: 10px;
        width:10px;
        z-index: 9;
        .ui-icon
        {
            width: 10px;
            height: 10px;
            &:hover
            {
                svg
                {
                    fill: $GREEN;
                }
            }
        }
    }
}
