@import "../../../../styles/variables";

.view-changeorder-route
{
    .header-section
    {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin: 20px 0 20px;
    }
    .status-nav
    {
        list-style: none;
        display: flex;
        align-items: center;

        .status-item
        {
            border: 1px solid $GREY_LIGHT3;
            background-color: unset;
            color: $FONT_LIGHT;
            border-radius: 16px;
            padding: 4px 8px;
            margin-right: 10px;

        }

        .DRAFT
        {
            border: 1px solid $CAUTION;
            color: $CAUTION;
        }

        .OPEN
        {
            border: 1px solid $BLUE_COLOR;
            color: $BLUE_COLOR;
        }

        .close-with-approve
        {
            border: 1px solid $PARROT_COLOR;
            color: $PARROT_COLOR;
        }

        .close-with-reject
        {
            border: 1px solid $ERROR;
            color: $ERROR;
        }

        li
        {
            .ui-icon
            {
                width: 12px;
                height: 7px;
                margin-right: 10px;
            }
        }
    }

    .co-decision-holder
    {
        .text-block{
            height: auto;
            display: flex;
            align-items: center;
            .resend-email,
            .sent-email
            {
                overflow: hidden;
                display: flex;
                align-items: center;
                .ui-icon
                {
                    width: 21px;
                    height: 24px;
                    margin-right: 10px;
                }
                .resend-email-text
                {
                    width: fit-content;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
            .sent-email
            {
                span
                {
                    color: $GREY_LIGHT3;
                }
            }
            .resend-email
            {
                &:hover
                {
                    .ui-icon
                    {
                        #RESEND
                        {
                            fill: $GREEN;
                        }
                    }
                    .resend-email-text
                    {
                        color: $GREEN;
                    }
                }
            }

        }
    }
    .compare-rev-icon-holder
    {
        margin-left: 30px;
        cursor: pointer;
        color: $BG_WHITE;
        .ui-icon
        {
            svg
            {
                g
                {
                    fill: $BG_WHITE;
                }
            }
        }
        &:hover
        {
            .ui-icon
            {
                svg
                {
                    g
                    {
                        fill: $GREEN;
                    }
                }
            }
            color: $GREEN;
        }
    }
    .highlight-row
    {
        background-color: #35354A;
        background-clip: border-box;
    }
    .disable-icon
    {
        pointer-events: none;
        .ui-icon
        {
            svg
            {
                g
                {
                    fill: $GREY;
                }
            }
        }
    }
    .diff-side-bar-holder
    {
        transition    : 0.1s;
        .header-block {
            padding-top: 10px;
        }
    }
}

.view-changeorder-route,
.new-changeorder-route,
.edit-changeorder-route{
    .nav-block{
        display: inline-flex;
        justify-content: space-evenly;
        color: $GREY_LIGHT3;
        margin-top: 24px;
        margin-bottom: 24px;

        svg{
            fill: $GREY_LIGHT3;
        }

        >div{
            margin-right: 16px;
            margin-bottom: 0px;
            flex: 1;
            white-space: nowrap;

            > div{
                display: flex;
            }

            h2{
                font-size: $FONT_SIZE_16;
            }

            .ui-icon
            {
                width: 17px;
                height: 18px;
                margin-right: 8px;
                svg{
                    width: 16px;
                    height: 24px;
                }

            }
            h2
            {
                font-weight: normal;
            }

            .prd-cmp-holder
            {
                svg g{
                     stroke: $GREY_LIGHT3;
                }
            }
            .notification-holder{
                .ui-icon{
                    width: 20px;
                    svg{
                        width: 20px;
                        height: 20px;
                    }
                }
            }
            .documents-holder{
                .deactive {
                    path {
                        fill: #888888;
                    }
                }
                &:hover {
                    path {
                        fill: rgb(207, 207, 207);
                    }
                }
            }

            &:hover,
            &.selected{
                color: $FONT_COLOR;
                cursor: pointer;
                .ui-icon {
                    svg{
                        fill: $FONT_COLOR;
                    g{

                        &.white-fill
                        {
                            fill: $FONT_COLOR;

                        }
                    }
                    }
                }

                .prd-cmp-holder{
                    svg g{
                         stroke: $FONT_COLOR;
                    }
                }
                .notification-holder{
                    svg #shape-area{
                        fill: $FONT_COLOR;
                    }
                }
            }
        }
    }

    .user-info-col
    {
        .user-profile-img
        {
            &.hidden
            {
                display: none !important;
            }
        }
    }

    #co-list-table
    {
        .where-used-holder
        {
            display: inline-block;
            margin-left: 10px;
        }
    }
&.view-changeorder-route-block {
    padding: 0 0 100px !important;
    .content-inner-block{
        padding-left: 44px;
        padding-right: 44px;
        background: #2A2A36;
        padding-top: 20px;
        padding-bottom: 25px;
        .header-section {
            margin-top:0;
        }
    }
    .tabs-block {
        padding-right: 44px;
        padding-left: 44px;
        .extended-table.have-table-icons {
            margin-top: 0px;
            margin-bottom: 16px
        }
    }
}
}

.changeorder {
    &.view {
        .two-col {
            .main-content{
                background: #353540;
                padding-bottom: 0;
            }
        }
    }
}
