.release-sync-modal {
    .modal {
        width: 435px!important;
        .modal-content {
            .bottom-section {
                margin-top: 0;
            }
        }
    }
}
