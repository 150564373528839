@import "../../../../styles/variables";

.header .public-view
{
    height          : 70px;
    display         : flex;
    justify-content : space-between;
    align-items     : center;

    .branding
    {
        a
        {
            color: $FONT_COLOR;

            &:hover
            {
                color: $FONT_COLOR;
            }
        }

        .company-name
        {
            font-size: 28px;
        }
    }

    .navigation
    {
        a
        {
            font-size: 17px;
        }
    }
}
