@import "../../../../../styles/variables";

.app.changeorder{
    &.edit,
    &.new{
        .routes{
            padding-bottom: 0px;
        }
    }
}
.change-order-form
{
    height: 100%;
    .new-changeorder-route
    {
        display: flex;
        min-height: 100%;
        padding-right: 0 !important;
        padding-left: 0 !important;
        .main-block
        {
            flex: 1;
            padding-bottom: 100px;
            z-index: 1;

            .content-block
            {

                width: 100%;
                .fields-block
                {
                    .fields-block-holder {
                        max-width: 720px;
                    }
                }
                @media screen and (min-width: 1460px)
                {
                    .fields-block
                    {
                        .fields-block-holder {
                            max-width: 886px;
                        }
                    }
                }

                .heading-holder
                {
                    h1
                    {
                        font-size: $FONT_SIZE_23;
                        color: $FONT_COLOR;
                        margin-bottom : 10px;
                    }
                    .help-icon-container {
                        margin-top: 7px;
                        margin-left: 14px;
                        .ui-icon {
                            margin-top: 0px;
                            margin-left: -6px;
                        }
                    }
                }

                form
                {
                    .muted-text
                    {
                        font-size: 16px;
                    }
                    .input-label
                    {
                        font-size: 14px;
                        font-weight: bold;
                        color: $FONT_COLOR;
                        margin-top: 20px;
                    }
                    .tool-tip-co {
                        position: absolute;
                        border-radius: 5px;
                        right: 10px;
                        width: 243.5px;
                        height: 68px;
                        background: #9ACCFF;
                        font-family: Roboto-Regular;
                        font-size: 15px;
                        color: #18171D;
                        text-align: left;
                        padding: 8px;
                        &:before, &:after {
                            position: absolute;
                            content: "";
                            display: block;
                          }
                          &:before {
                            right: -10px;
                            top: 23px;
                            z-index: 2;
                            border-bottom: 10px solid transparent;
                            border-left: 10px solid #9ACCFF;
                            border-top: 10px solid transparent;
                          }
                    }
                    input
                    {
                      &:not([type="checkbox"])
                      {
                        font-size: $FONT_SIZE_DEFAULT;
                        color: $FONT_LIGHT;
                        height: 34px;
                      }
                    }
                    textarea
                    {
                        font-size: $FONT_SIZE_DEFAULT;
                        color: $FONT_LIGHT;
                    }
                    input[name="check-parent-assembly"]
                    {
                      font-size: $FONT_SIZE_DEFAULT;
                      height: 34px;
                    }
                }

                .check-parent-assembly-holder,
                .co-approver-list
                {
                    .prd-cmp-text-holder{
                        color: $FONT_LIGHT;
                    }
                    overflow: hidden;
                    h2{
                        float: left;
                    }
                    .assemblies-block
                    {
                        display: flex;
                        align-items: center;
                        margin: 20px 0px 0px;
                        .item-label
                        {
                            border: 1px solid #979797;
                            border-radius: 8px;
                            display: flex;
                            align-items: center;
                            padding: 2px 15px;
                            margin-right: 10px;
                            margin-bottom: 16px;
                            .label-heading {
                                margin-left: 10px;
                            }

                            label
                            {
                                width: 14px;
                                height: 14px;
                            }
                        }
                        input[name="include-children"]
                        {
                            margin-left: 15px;
                        }
                    }
                    .approval-type-block
                    {
                        margin: 20px 0;
                        
                        .item-label, .one-approval-type
                        {
                            border: 1px solid #979797;
                            border-radius: 8px;
                            width: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            padding: 10px;
                            
                            .label-heading,
                            .type-holder
                            {
                                flex: 1;
                            }
                            .type-holder
                            {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                input
                                {
                                    height: auto;
                                }
                                label {
                                    border-radius: 50%;
                                    margin-right: 10px;
                                    width: 15px;
                                    height: 15px;
                                }
                                span {
                                    vertical-align: top;
                                    margin-top: 2px;
                                    display: inline-block;
                                }

                                &.selected {
                                    input[type="checkbox"] {
                                        cursor: not-allowed; 
                                        pointer-events: none;
                                    }                                    
                                    
                                    label {
                                        opacity: 0.6;
                                    }

                                    span {
                                        color: #999;
                                    }
                                }
                                &.disabled input[type="checkbox"] {
                                    cursor: not-allowed; 
                                }
                            }
                        }
                        .one-approval-type 
                        {
                            max-width: 250px;
                        }
                        .item-label 
                        {
                            max-width: 450px;
                        }
                    }
                }

                .co-internal-notification-users,
                .co-approver-list,
                .co-documents
                {
                    .notify-holder
                    {
                        color: $FONT_LIGHT;
                        margin-bottom: 25px;

                        .approver-list-link
                        {
                            color: $GREEN;
                            cursor: pointer;
                        }
                    }
                    .approver-holder
                    {
                        color: $FONT_LIGHT;
                    }
                    .doc-holder
                    {
                        color: $FONT_LIGHT;
                        margin-bottom: 25px;
                        .products-and-components-link
                        {
                            color: $GREEN;
                            cursor: pointer;
                        }
                    }
                }
                .co-approver-list{
                  overflow: inherit;
                }
                input[type="submit"]
                {
                    margin-top: 30px;
                    float: right;
                    width: 130px;
                    height: 30px;
                    padding: unset;
                }

                textarea{
                    height: 72px;
                }
                .view-block{
                    #documents-tab{
                        &.app-row {
                            padding-right: 0;
                            padding-left: 0;
                        }
                    }
                }
            }
        }
        .right-search-menu
        {
            float: right;
            height: auto;
            position: relative;
            z-index: 0;
            .right-search-expand{
                height: 100% !important;
                min-height: 100% !important;
            }
        }
    &.edit-changeorder-route-block {
        background: #353540;
        .content-block {
            // padding: 0;
            .content-inner-block {
                padding: 15px 15px 25px 44px;
                background: #2A2A36;
            }
            .tabs-block-holder{
                padding-right: 15px;
                padding-left: 44px;
                .nav-block{
                    > div{
                        margin-bottom: 0;
                    }
                }

            }
        }
        &.edit-component-route {
            .banner-block {
                .holder {
                    > div {
                        height: auto;
                    }
                }
            }
        }
    }
    }
}

.banner-block
{
    background : $CAUTION;
    height: 50px;

    .ui-icon
    {
        pointer-events : none;
        width          : 17px;
        height         : 17px;
        margin-right   : 10px;

        svg
        {
            fill: #17171F
        }
    }

    .edit-text
    {
        font-size: 14px;
        color: #17171F
    }
    .holder
    {
        padding: 5px 44px;
        .btn-black-theme
        {
            color: $FONT_YELLOW;
        }
    }
    > div
    {
        display         : flex;
        justify-content : space-between;
        align-items     : center;

        > div
        {
            margin      : 0;
            display     : flex;
            align-items : center;
            margin      : 5px 0;

            &:nth-child(2)
            {
                span
                {
                    margin-right : 20px;
                    color        : black;
                    cursor: pointer;
                }
            }
        }
    }
}

.OverrideWarningModal {
    .modal {
        width: 450px !important;
    }
}
    .black-background-tooltip {
        text-align: left;
        padding: 15px;
        max-width: 256px;
        line-height: 18px;
        margin-top: -6px !important;
        a {
            text-decoration: underline;
        }
        pointer-events: auto !important;
        &:hover {
           visibility: visible !important;
           opacity: 1 !important;
        }
        .ta-right {
            text-align: right;
            button {
                max-width: 120px;
                padding: 4px 14.33px !important;
            }
        }
    }

