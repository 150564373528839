@import "../../../../styles/variables";

.description-block
{
    display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
    flex-direction: column;
    .heading
    {
        color: $FONT_LIGHT;
        margin-bottom: 15px;
        font-size: 14px;
        font-weight: bold;
    }
    .content
    {
        white-space: pre-line;
    }
    .bottom {
        margin-top: 25px;
        font-style: italic;
        word-wrap: break-word;
        font-size: 14px;
        overflow: hidden;
        &.w-normal {
          width: 455px;
        }
        &.less {
          -webkit-line-clamp: 5;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
        &.w-extended {
          width: 475px;
        }
        &.side {
            font-style: normal;
            margin-top: unset;
        }
    }
    .show-fulltext {
      font-style: normal;
      cursor: pointer;
      white-space: initial;
    }
}
