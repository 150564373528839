@import "../../../styles/variables";


.on-boarding-block 
{
   .icon-holder
   {
       margin: 0 0 0 20px;
       .ui-icon 
       {
           width: 100%;
           height: 100%;
           display: flex;
       }
       .progress-icon 
       {
           position: absolute;
           top: 45%;
           right: -20%;
       }

       .help-menu-actions
       {
            .sub-menu
            {
                top: 48px;
                left: -200px;
                width: 246px;
                height: 112px;
                padding: 20px 20px;
                .label
                {
                    font-size: 14px;
                }
                .ui-link 
                {
                    .option
                    {
                        margin: 0 10px 25px;
                        .ui-icon
                        {
                            height: 19px;
                            width: 19px;
                            margin-top: 0;
                            margin-right: 15px;
                            svg
                            {
                                height: 19px;
                                width: 19px;
                            }
                        }
                    }
                    &:hover
                    {
                        .option
                        {
                            .ui-icon
                            {
                                svg
                                {
                                    g
                                    {
                                        fill: $GREEN;
                                        stroke-width: 0;
                                        #Fill-1 
                                        {
                                            stroke: $GREEN;
                                        }
                                        g
                                        {
                                            fill: $GREEN;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }                
            }
            .trigger
            {
                &:hover
                {
                    .ui-icon
                    {
                        path
                        {
                            fill: $GREEN;
                        }
                    }
                }
            }
       }
   }

   .on-boarding-holder
   {
       .on-boarding-holder-list
       {
            margin-top: 20px;
       } 
       width: 129px;
       height: 0px;
       position: absolute;
       right: -40px;
       top: 56px;
       height: 90vh;
       // border-left: 1px solid #000;
       background-color: rgba(53, 53, 64, 0.97);

       padding: 0;
       .area {
            position: relative;
            height: 100%;
        }
       ul {
            list-style: none;
            padding: 0;
            position: relative;
            li{
                text-align: center;
                cursor: pointer;
                margin-top: 0;
                padding: 7px;
                width: 128px;
                    .arrow-icon 
                    {
                        position: absolute;
                        left: -59px;
                        top: 0;
                        display: none;
                        .ui-icon {
                           border: 0;
                        }
                        &.show-arrow-icon{
                           display: block;
                        }
                    }
               &.active {
                   .ui-icon  {
                        border: 2px solid #04adc3;
                        svg {
                            fill: #04adc3;
                        }
                    }
                  .list-info{
                   opacity: 9;
                  }
               }

               &.li-hover:hover 
               {
                   .ui-icon  
                    {
                        border: 2px solid rgba(4, 173, 195, 0.55);
                        transition: border 1s;
                        svg 
                        {
                            fill: rgba(4, 173, 195, 0.55);
                        }
                    }
                  .list-info
                  {
                    opacity: 9;
                  }
                }

               .ui-icon  
               {
                    text-align: center;
                    width: 32px;
                    height: 32px;
                    margin: 0 auto 10px;
                    display: flex;
                    align-items: center;
                    border: 1px solid transparent;
                    border-radius: 35px;
                    svg 
                    {
                        fill: $GREEN;
                        margin: 0 auto;
                    }
               }
               .list-info
               {
                    max-width: 110px;
                    display: inline-block;
                    line-height: 18px;
                    opacity: 0.8;
                    font-size: 14px;
                    padding: 0 9px;

                    &.completed
                    {
                        color: $FONT_LIGHT;
                    }
                }

                &.onboarding-setp-item{
                    &.change-lib-button{
                        background-color: rgba(24, 23, 29, 0.5);
                        padding: 10px;
                    }
                }

                &.hover-links:hover {
                    color: $GREEN;
                    .ui-icon
                    {
                        svg
                        {
                            polygon,path
                            {
                                fill: $GREEN !important;
                            }
                            #Fill-1 
                            {
                                stroke: $GREEN;
                            }
                        }
                    }
                }
            }
        }

        .ribbon-resize
        {
            position: absolute;
            left: 0;
            height: 100%;
            border-left: 1px solid #000;
            transition: border-left 1s;
            display: flex;
            align-items: center;
            cursor: ew-resize;
            z-index: 9;
            min-width: 10px;

            .ribbon-icon-holder
            {
                width: 30px;
                height: 30px;
                // border: 1px solid #D8D8D8;
                transition: border 1s;
                // border-radius: 100%;
                display: flex;
                justify-content: center;
                align-items: center; 
                position: absolute;
                left: -15.5px;
                top: 123px;
                // background: #353540;
                cursor: pointer;

                .ui-icon 
                {
                    width: 28px;
                    height: 28px;
                    display: flex;
                    align-items: center;

                    &.close-icon
                    {
                        visibility: hidden;
                        opacity: 0;
                        transition: visibility 0s, opacity 0.5s linear;
                    } 
                }           
            }

            &:hover,&.dragged
            {
                .ui-icon
                {

                    &.close-icon
                    {
                        visibility: visible;
                        opacity: 1;
                    } 
                }
                border-left: 1px solid $GREEN;
                .ribbon-icon-holder
                {
                    // border: 1px solid $GREEN;
                }
            }
        } 
   }
}

.popover-tool-tip-container{
    overflow: initial !important;
    z-index: 3;
    &.popover-0,&.popover-1 {
        margin-top: 70px;
    }
}

.env-info-popover
{
    /*> div {
        > div:nth-child(1){
            //left: 200px !important;
        }
    }*/
    
    z-index: 999;
    padding-top: 20px;
    margin-left: -13px;
    .sandbox, .live, .skipped, .completed
    {
        .icon-holder
        {
            display: flex;
            margin-bottom: 20px;
            .ui-icon
            {
                flex: 1;
                svg
                {
                    max-width: 15px;
                    max-height: 24px;
                }
            }
            h1 {
                flex: 12;
                margin-bottom: 0;
            }
        }
        .body-container
        {
            margin-bottom: 15px;
        }

        .do-not-show-agian
        {
            color: $GREEN;
            cursor: pointer;
        }
    }
    .live
    {
        .icon-holder
        {
            .ui-icon
            {
                svg
                {
                    max-width: 28px;
                }
            }
            h1 {
                flex: 8;
            }
        }
    }
    .sandbox, .live, .skipped, .completed
    {
        padding: 25px 30px;
        width: 403px;
        min-height: 180px;
        font-size: 14px;
        line-height: 25px;
        margin-right: 20px;
        overflow: hidden;
        h1{
            font-size: $FONT_SIZE_23;
            margin-bottom: 5px;
        }
        .body-container
        {
            .icon-holder 
            {
                margin-left: 17px;
                margin-top: 7px;
                height: auto;
                width: auto;
            }
        }
    }

    .sandbox, .completed, .live, .skipped
    {
        color: $FONT_LIGHT;
        background: $BG_WHITE;
        .body-container
        {
            .icon-holder {
                display: inline-block;
                vertical-align: top;
                svg {
                    path, polygon
                    {
                        fill: $BG_LIGHT;
                    }
                }
                .ui-icon
                {
                    cursor: auto;
                }
            }
        }

        .got-it-btn
        {
            background: $GREEN;
            color: #363640;
            &:hover
            {
                background: #363640;
                color: $GREEN;
                border: 1px solid #363640;

            }
        }
    }

    .got-it-btn
    {
        float: right;
        min-width: 96px;
        padding: 3px;
    }

    .notify 
    {
        padding: 10px 22px;
        width: 245px;
        min-height: 73px;
        font-size: 14px;
        line-height: 25px;
        margin-right: 20px;
        overflow: hidden;
        background: #04adc3;
        .body-container
        {
            font-size: $FONT_SIZE_19
        }
    }
}

.video-holder
{
    iframe
    {
        border: none;
    }
}

.on-boarding-tool-tip
{
    &.rc-tooltip
    {
        z-index: 99;
    }
}

.live {
    &.on-boarding-holder{
        ul{
            li{
                &:last-child{
                    border-bottom: 1px solid #000;
                }
            }
        }
    }
}
.quick-start-popup{
     > div {
            > div:nth-child(1){
               // left: 360px !important;
            }
        }   
}
