@import "../../../../../styles/variables";

.search-route .mixed-list-block
{
    table
    {
        td
        {
            text-align : left;
            .ui-icon.result-type-icon{
                width: 11px;
                height: 11px;
                cursor: unset;
            }
        }
    }
}
