@import "../../../../styles/variables";

.routes
{
    .default
    {
        .date-span
        {
            color: $BG_WHITE;
            margin-right: 4px;
        }
        .time-span
        {
            color: #fff
        }
    }
    .edit-mode
    {
        .date-span
        {
            margin-right: 4px;
        }
        .time-span, .date-span
        {
            color: #fff;
        }
    }
}
