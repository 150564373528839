.delete-warning{
  p{
      margin-bottom: 0;
  }
  &.new-warning-modal .modal{
      width: 421px;
      .bottom-section{
          margin-top: 0;
      }
      h1{
          margin-bottom: 20px;
      }
      .errors{
          margin-bottom: 25px !important;
      }
  }
}