@import "../../../styles/variables";


.new-product-route .file-drop-block,
.new-component-manual-route .file-drop-block
{

    cursor: pointer;

    &.collapsed
    {
        .drop-zone
        {
            height: 50px;

            .expanded-block
            {
                display: none;
            }

            .collapsed-block
            {
                display: flex;
            }
        }
    }

    .drop-zone
    {
        overflow   : hidden;
        transition : height 0.08s;
        height     : 250px;
        border     : 1px dashed white;
        padding    : 10px;

        .expanded-block
        {
            text-align: center;

            .ui-icon
            {
                margin-top : 25px;
                margin-bottom : 25px;
                width      : 100px;
                height     : 100px;

                svg
                {
                    fill: $GREEN;
                }
            }

            div
            {
                &:nth-child(2)
                {
                    margin-bottom: 5px;
                    font-size: 14px;
                }

                &:nth-child(3)
                {
                    font-size: 14px;
                    color: $GREY;
                    text-decoration: underline;
                }
            }
        }

        .collapsed-block
        {
            padding-top: 5px;
            padding-left: 5px;
            padding-right: 5px;
            display: none;
            justify-content: space-between;

            .ui-icon
            {
                width: 20px;
                height: 20px;

                svg
                {
                    fill: $GREEN;
                }
            }

            div:first-child
            {
                span:nth-child(2)
                {
                    margin-left: 10px;
                    font-size: 14px;
                    color: $GREY;
                    text-decoration: underline;
                }
            }
        }

        .modal
        {
            display: none;
            height: 100%;
            width: 100%;
        }

        &.accept-files
        {
            .expanded-block,
            .collapsed-block
            {
                display: none;
            }
        }

        &.reject-files
        {
            .expanded-block,
            .collapsed-block
            {
                display: none;
            }
        }
    }
}
