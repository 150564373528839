/*@import "../../../../styles/variables";*/

.open-link-holder {
    display: block;
    text-align: left;
    margin: 5px 0 0;
    padding: 5px 0 0;
    border-top: 1px solid #888;
    &:hover {
        color: #3CD1BF!important;
    }
}

.duro__control.duro__control--menu-is-open {
    border-color: #3CD1BF!important;
}
.duro__menu {
    border-top: 0!important;
}
