@import "../../../../../../styles/variables";

.view-changeorder-route .actions-block
{
    .banner-block
    {
        background : $MODIFED;
        > div
        {
            display         : flex;
            color           : black;
            justify-content : space-between;
            align-items     : center;
            height          : 50px;
        }
    }
    .action-btn
    {
        color: $FONT_COLOR;
        border: none;
        background-color: rgba(216, 216, 216, 0.10);
        border-radius: 8px;
        margin-left: 10px;
        padding: 8px 12px;
        display: inline-flex;
        align-items: center;
        min-width: auto;

        .ui-icon
        {
            height: 16px;
            width: 16px;
            margin-right: 10px;
            svg
            {
                fill: $FONT_COLOR!important;
            }
        }
        &:hover{
            color:  $GREEN;
            svg{
                fill: $GREEN !important;
            }
            &.delete-btn
            {
                g{
                    fill: $GREEN !important;
                    stroke: $GREEN !important;

                }
            }
        }

        &.resend-email {
            float: left;
            padding: 5px 12px;

            svg {
                stroke: transparent;
                fill: currentColor !important;
            }

            &:hover {
                svg {
                    g#Approved {
                        fill: $GREEN;
                    }
                }
            }
        }
    }

    .resolution-btn
    {
        color: #18171D;
        border: none;
        background-color: orange;
        border-radius: 18px;
        margin-left: 10px;
        padding: 8px 12px;
        display: inline-flex;
        align-items: center;
        min-width: auto;
        cursor: initial;

        .ui-icon
        {
            height: 16px;
            width: 16px;
            margin-right: 10px;
            svg
            {
                fill: #000 !important;
            }
        }
        &.UNRESOLVED
        {
            background-color: #F4A543;
        }
        &.APPROVED
        {
            background-color: #7AE173;
        }

        &.REJECTED
        {
            background-color: #F54A4F;
        }
        &.NONE
        {
            background-color: $FONT_LIGHT;
        }
        &.FINALIZING
        {
            background-color: #F4A543;
        }
    }

    .co-action-type-modal,
    .revisions-in-progress-modal {
        .modal{
            width: 564px;
            padding: 28px 44px;

            h1, p{
                .ui-icon{
                    width: 30px;
                }
                margin-bottom: 30px;
            }

            h3
            {
                margin-bottom: 20px;
                font-size: 14px;
            }
            .bottom-section{
                width: 100%;
                margin-top: 25px;
                float: right;
                padding-top: 30px;
                .button-sections{
                        float: right;
                    button{
                        width: 138px;
                        height: 30px;
                        padding: 0;
                        margin-left: 10px;
                    }
                .action-button-disabled {
                    background: none;
                    color: $GREY;
                    border-color: $GREY;
                    opacity: 0.3;
                    cursor: default;
                    }
                }
            }
        }

        textarea
        {
            resize: none;
            font-size: 14px;
        }
    }
    .revisions-in-progress-modal
    {
        .modal
        {
            min-height: 374px;
            width: 506px;
            padding-bottom: 0;
            padding-left: 0px;
            padding-right: 0px;
            h1
            {
                font-size: 24px;
                margin-bottom: 10px;
            }
        }
        .modal-content
        {
            padding-top: 40px;
            .email-sent-block
            {
                text-align: center;
                .ui-icon
                {
                    width: 80px;
                    height: 68px;
                    margin-bottom: 30px;
                }
                .email-info
                {
                    max-width: 447px;
                    border-bottom: 1px solid #979797;
                    margin: 0 auto;
                    font-size: 13px;
                    line-height: 17px;
                    height: 80px;
                }
                .bottom-section
                {
                    margin-top: 0px;
                    .button-sections
                    {
                        padding-right: 30px;
                    }
                }
            }

        }
    }

}
