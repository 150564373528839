@import "../../../../../styles/variables";

.align-icon-variant {
    justify-content: center;
    align-items: center;

    svg { overflow: visible; }
}

#variant-table
{
    width: 1075px;
    .header-block
    {
        padding: 5px 0;
        div:first-child
        {
            color: $FONT_COLOR;
            text-transform: capitalize;
        }
        .variant-popup-header-btn
        {
            align-items: center;
            cursor: pointer;
            .ui-icon
            {
                width: 16px;
                height: 16px;
                @extend .align-icon-variant;
            }
            &.active
            {
                .ui-icon
                {
                    svg
                    {
                        g
                        {
                            fill: $GREEN;
                        }
                    }
                }
                color: $GREEN;
                &:hover
                {
                    .ui-icon
                    {
                        svg
                        {
                            g
                            {
                                fill: $BG_WHITE;
                            }
                        }
                    }    
                    color: $BG_WHITE;
                }
            }
        }

        .header-block-left-col
        {
            min-width: 790px;
            flex: 1;
            white-space: nowrap;
            justify-content: flex-start;

            .product-variant-title
            {
                color: #fff;
                margin-right: 30px;
                font-weight: 600;
                min-width: 130px
            }
            .creat-new-variant-link {
                color: #fff;
                margin-right: 20px;
                .ui-icon {
                    margin-right: 10px;
                }
                .variant-popup-header-btn
                {
                    &:hover
                    {
                        color: $GREEN;
                        .ui-icon {
                            svg {
                                g
                                {
                                    stroke: $GREEN;
                                }
                            }
                        }
                       
                    }
                    &.disabled
                    {
                        pointer-events: none;
                        opacity: 0.5;
                    }
                    
                }
            }
            .search-bar
            {
                .search-input
                {
                    background: transparent;
                    padding: 6px 10px;
                    color: #fff;
                    &:focus {
                        outline: 0;
                    }
                }
                .ui-icon
                {
                    margin-left: 10px;
                    margin-right: 5px;
                    width: 17px;
                    height: 17px;
                    @extend .align-icon-variant;
                }
            }
            .search-row
            {
                margin: 0 0px 0 0;
                width: 100%;
                .search-row
                {
                    margin: 0 10px 0 0;
                }
                .search-input
                {
                    width: 410px;
                    max-width: 767px;
                    border: 1px solid $GREY_LIGHT3;
                    float: left;
                    -webkit-transition: all .5s ease;
                   -moz-transition: all .5s ease;
                   transition: all .5s ease;
                }
                &.focused
                {
                    margin: 0;
                    .search-input
                    {
                        width:100%;
                    }
                }
                .search-bar
                {
                    height          : 24px;
                    font-size       : 14px;
                    display         : flex;
                    justify-content : space-between;
                    align-items     : center;
                    padding-right   : 0;

                    .ui-icon
                    {
                        margin-left: 10px;
                        width      : 15px;
                        height     : 15px;

                        svg
                        {
                            fill: $GREY;
                        }
                    }

                    input
                    {
                        font-size      : 14px;
                        font-weight    : 400;
                        font-family    : 'Roboto', sans-serif;
                        margin-left    : 5px;
                        margin-bottom  : 0;
                        border         : none;
                        background     : none;
                        color          : $FONT_COLOR;
                        width          : 100%;
                        outline        : none;
                        min-height     : 21px;
                        margin-left    : 0;
                        flex           : 1;
                        &::placeholder
                        {
                            color          : $GREY_LIGHT3;
                            text-transform : none;
                        }
                    }
                    .variant-search-prefix {
                        color : $GREY_LIGHT3;
                        overflow: hidden;
                        white-space: nowrap;
                        text-transform: lowercase;
                    }
                }

                .search-results
                {
                    z-index: 9999;
                    max-width: 100%;
                    overflow: hidden;
                    position    : absolute;
                    color       : black;
                    background  : white;
                    border-top  : 1px solid $GREY;
                    // padding-top : 8px;
                    font-size   : 14px;
                    .search-results-scroll
                    {
                        color: $FONT_BLACK;
                    }
                    table
                    {

                        tbody tr
                        {
                            td
                            {
                                .ui-icon.item-icon
                                {
                                    margin-left : 10px;
                                }
                            }

                            &:not(.footer-row)
                            {
                                cursor: pointer;

                                &.focused,
                                &:hover
                                {
                                    background: $GREY_FOCUSED;
                                }
                            }
                        }

                        th, td
                        {
                            font-size: $FONT_SIZE_DEFAULT;
                            padding: 15px 5px;
                            text-align: left;

                            &:nth-child(1)
                            {
                                padding-left : 10px;
                                width        : 55px;
                            }

                            &:nth-child(2)
                            {
                                width        : 135px;
                            }

                            &:nth-child(3)
                            {
                                width        : 60px;
                            }

                            &:nth-child(4)
                            {
                                width        : 135px;
                                max-width    : 180px;
                            }

                            &:nth-child(5)
                            {
                                width        : 90px;
                            }

                            &:nth-child(6)
                            {
                                width        : 60px;
                            }
                            &:nth-child(7)
                            {
                                padding-right: 15px;
                                width: 100px;
                            }
                        }
                    }
                }

                &.active
                {
                    .ui-icon
                    {
                        svg
                        {
                            fill: white;
                        }
                    }
                }

                &.focused
                {
                    .search-input{
                        background: white;
                    }
                    .ui-icon
                    {
                        svg
                        {
                            fill: black;
                        }
                    }

                    input
                    {
                        min-width: 200px;
                        color: black;
                        &::placeholder
                        {
                            color: $GREY_LIGHT;
                        }
                    }
                }

            }

            .compare-variants-link {
                color: #fff;
                margin-right: 30px;
                cursor: pointer;
                min-width: 275px;

                .ui-icon {
                    margin-right: 20px;
                    margin-left: 5px;
                }
                .link-icon
                {
                    margin-left: 0;
                }
                .compare-var-btn
                {
                    align-items: flex-start;
                    cursor: pointer;
                    color: $GREEN;
                    .ui-icon
                    {
                        svg
                        {
                            g
                            {
                                fill: $GREEN;
                            }
                        }
                    }
                    &:hover
                    {
                        .ui-icon
                        {
                            svg
                            {
                                g
                                {
                                    fill: $BG_WHITE;
                                }
                            }
                        }
                        color: $BG_WHITE;
                    }
                }
            }
        }
        .header-block-right-col
        {
            color: #fff;
            &.hidden
            {
                display: none;
            }
            .compare-variant-btn {
                .ui-icon {
                    margin-left: 15px;
                    height: 20px;
                    svg{
                        g{
                            fill: #fff;
                        }
                    }
                }
                &:hover {
                    color: $GREEN;
                    .ui-icon {
                        svg {
                            g
                            {
                                fill: $GREEN;
                            }
                        }
                        &.disabled
                        {
                            cursor: default;
                            svg{
                                g{
                                    fill: #fff;
                                }
                            }
                        }
                    }
                }
            }
            .total-variants
            {
                text-transform: none;
            }
        }
    }

    .content-block
    {
        padding-bottom: 15px;
        .simple-table
        {
            table
            {
                thead
                {
                    tr
                    {
                        th
                        {
                            .text-block
                            {
                                margin-right: 0;
                                padding: 10px 0 10px 9px;
                            }
                            &:after
                            {
                                content: '';
                                position: absolute;
                                bottom: 0;
                                height: 0;
                                width: 50%;
                                background-color: #d8d8d840;
                            }
                            &.checkbox-col
                            {
                                width: 20px;
                                .text-block
                                {
                                    padding: 4px 0 0 10px;
                                    .ui-icon
                                    {
                                        margin-left: 0;
                                        cursor: initial;
                                        width: 16px;
                                        height: 16px;
                                        svg
                                        {
                                            g
                                            {
                                                stroke: #888;
                                            }
                                        }
                                    }
                                    .th-text
                                    {
                                        color: $FONT_LIGHT;
                                    }
                                }
                            }
                            &.img-col
                            {
                                width: 40px;
                            }
                            &.cpn-col
                            {
                                width: 60px;
                            }
                            &.name-col
                            {
                                width: 180px;
                            }
                            &:nth-child(4)
                            {
                                width: 180px;
                            }
                            &.revision-col
                            {
                                width: 35px;
                            }
                            &.status-cell
                            {
                                width: 70px;
                            }
                            &.last-update-cell
                            {
                                text-align: left;
                                width: 85px;
                            }
                            &.remove-cell
                            {
                                width: 20px;
                            }
                        }
                    }
                }

                tbody
                {
                    tr
                    {
                        td
                        {
                            div:first-child
                            {
                                color: $FONT_COLOR;
                                text-transform: capitalize;
                            }
                            &.inline-image-viewer
                            {
                                .text-block
                                {
                                    padding-left: 5px;
                                    .table-image-viewer-wrapper
                                    {
                                        justify-content: flex-start;
                                    }
                                }
                            }
                            &:first-child
                            {
                                .text-block {
                                    padding: 0;
                                    height: 15px;
                                }
                            }
                            &.cpn-col {
                                .ui-link {
                                    display: inline-block;
                                    max-width: 100%;
                                    .user-name {
                                        width: fit-content;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        white-space: nowrap;
                                    }
                                    .text-block {
                                        position: relative;
                                        padding-top: 5px;
                                        color: #fff;
                                        text-transform: initial;
                                        .user-avatar-holder{
                                            width: 20px;
                                            height: 20px;
                                            display: block;
                                            position: absolute;
                                            top: 7px;
                                            left: 16px;
                                        }
                                        .face-avatar{
                                            min-width: 20px;
                                            height: 20px;
                                            margin: 0;
                                            .ui-icon {
                                                margin: 0 10px 0 0;
                                                width: 20px;
                                                height: 20px;
                                            }
                                            svg {
                                                width: 20px;
                                                height: 20px;
                                            }
                                        }
                                    }

                                    .user-profile-img
                                    {
                                        display: flex;
                                        width: 20px;
                                        height: 20px;
                                        overflow: hidden;
                                        border-radius: 100%;
                                        margin-right: 10PX;
                                        align-items: center;
                                        img
                                        {
                                            width: 100%;
                                        }
                                    }
                                }
                            }
                            .text-block {
                                text-transform: capitalize;
                                .date-time-holder {
                                    color: #fff;
                                    text-transform: initial;
                                }
                                .co-label
                                {
                                    color: $GREEN;
                                }

                                .date-time-holder
                                {
                                    .time-span
                                    {
                                        color: #fff;
                                        margin-left: 5px;

                                    }
                                }

                                .revision-value {
                                    display: flex;

                                    .ui-icon {
                                        width: 15px;
                                        height: 15px;
                                    }
                                }
                            }
                            .revision
                            {
                                color: $FONT_COLOR;
                            }
                            .modified
                            {
                                color: $GREY_LIGHT3;
                            }
                            &.revision-col,
                            &.details-col
                            {
                                .text-block
                                {
                                    color: $FONT_COLOR;
                                }
                            }
                            &.revision-col
                            {
                                .rev-holder
                                {
                                    color: $BG_WHITE;
                                }
                            }
                            .con-holder
                            {
                                .ui-link
                                {
                                    .ui-icon
                                    {
                                        margin-left: 0;
                                        svg
                                        {
                                            g
                                            {
                                                fill: $GREEN;
                                            }
                                        }
                                    }
                                    &:hover
                                    {
                                        svg
                                        {
                                            g
                                            {
                                                fill: #fff;
                                            }
                                        }
                                    }
                                    &.red-font
                                    {
                                        color: $ERRORS;
                                        &:hover
                                        {
                                            color: $FONT_COLOR;
                                        }
                                    }
                                }
                            }
                            &.remove-cell
                            {
                                .ui-icon
                                {
                                    margin-left: 0;
                                }
                                    .action-popover-trigger
                                    {
                                        display: flex;
                                        align-items: flex-start;
                                        height: 20px;
                                        .dotted-icon
                                        {
                                            font-size: 35px;
                                            line-height: 0;
                                        }

                                        &.disabled
                                        {
                                            color: $GREY_LIGHT3;
                                            cursor: default;
                                        }
                                    }
                            }

                            .enlarge-icon-holder {
                                svg {
                                    width: 10px;
                                    height: 10px;
                                }
                            }
                        }
                    }
                }

                .ui-icon {
                    padding: 0;
                }
            }
        }
        .ui-loading
        {
            position: relative;
            height: auto;
        }
        table tr.success
        {
            td{
                &:not(.remove-col){
                    background-color: $TABLE_CELL_SUCCESS;
                }
            }
        }
    }

    .bottom-section
    {
        width: 100%;
        margin-bottom: 15px;
        float: right;
        padding-right: 70px;
        .button-sections
        {
            float: right;
            button{
                width: 50px;
                height: 30px;
                padding: 0;
                margin-left: 10px;
                &.save-btn
                {
                    display: flex;
                    align-items: center;
                    justify-content:center;

                    .spin-block
                    {
                        margin: 0 0 0 5px;
                        .spinner
                        {
                            width: 20px;
                            height: 20px;
                            svg,
                            .ui-icon
                             {
                                margin: 0;
                                width: 20px;
                                height: 20px;
                            }
                        }
                    }
                }


            }
        }
    }

    .disable-element
    {
        display: none;
    }

    .ui-loading
    {
        .ui-icon
        {
            margin: 0;
        }
    }

    .action-popover{
    background: #2A2A36;
    padding: 10px;
    min-width: 135px;
    max-width: 160px;
    min-height: 51px;
    align-items: center;
    position: absolute;

    .action-option{
        display: flex;
        align-items: center;
        margin: 0;
        cursor: pointer;
        padding-top: 4px;
        .label
        {
            padding-top: 3px;
        }
        &:hover
        {
            .ui-icon
            {
                svg
                {
                    g
                    {
                        stroke: $GREEN;
                        circle
                        {
                            fill: $GREEN;
                        }
                    }
                }
            }

            .label
            {
                color: $GREEN;
            }
        }
        .ui-icon {
            width: 15px;
            height: 15px;
            margin-right: 10px;
            svg
            {
                g
                {
                    stroke: #fff;
                    circle
                    {
                        fill: #fff;
                    }
                }
            }
        }
    }

}
}
