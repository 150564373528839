@import "../../../../styles/variables";
.update-revision-screen-modal,
.update-status-screen-modal
{
    font-family: Roboto;
    .ui-modal-box{
        .modal
        {
            top: 8%;
            width: 78.5%;
            height: 90%;
            .modal-content{
                height: 100%;
            }
        }
    }
    .update-status-screen
    {
        display: flex;
        height: 100%;
        margin: 0 auto;
        max-height: 82vh;
        position: initial;
        table{
            tr{
                th{
                    &.remove-col {
                        width: 31px !important;
                        margin-top: -2px;
                    }
                    &:nth-child(5) {
                        border-right: 0;
                    }
                    &.remove-with-no-row-footer {
                        &::after {
                            position: absolute;
                            height: 1px;
                            bottom: -1px;
                            background-color: #888888;
                            content: '';
                            width: 31px;
                            left: 0;
                            right: 0;
                        }
                    }
                }
                th,
                td{
                    &.remove-col {
                        position: sticky;
                        z-index: 9999;
                        right: 0;
                        width: 32px !important;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-top: -1px;
                        border-bottom: 0 !important;
                        padding: 0;

                        .ui-icon
                        {
                            width: 17px;
                            height: 17px;
                        }
                    }
                }
                td{
                    &.remove-col
                    {
                        background: #2a2a36 !important;
                    }
                }
                &:last-child{
                    td{
                        &.remove-col{
                            border-bottom: 1px solid #888888 !important;
                            margin: -1px 0 -1px;
                            min-height: 37px;
                        }
                    }
                }

            }
        }

        #updateRevisionTable,
        #revisionTable
        {
            tr{
                td
                {
                    &:nth-last-child(2) {
                        border-right:0;
                    }
                }
            }
        }
        .scrollarea-content {
            overflow: inherit;
        }
        .form-holder {
            display: flex;
            flex-direction: column;
            width: 100%;
            .modal-header
            {
                padding: 10px 20px 10px 30px;
                height: 56px;
                .header-heading{
                    h3{
                        margin-left: 9px;
                    }
                    svg{
                        g{
                            fill: #000;
                        }
                    }
                    .error-icon
                    {
                        svg{
                            g{
                                fill: none;
                            }
                        }
                    }
                }
                .btn-holder
                {
                    .disabled
                    {
                        background: #18171D!important;
                    }
                    .reset-btn
                    {
                        background: transparent!important;
                        color: #000!important;
                        border-color: #18171D;
                        &:hover{
                            background: #000!important;
                            color: $FONT_COLOR !important;
                        }
                    }
                    .btn-holder-inner
                    {
                        display: flex;
                        justify-content: flex-end;
                    }
                }
            }
            .contain-errors{
                background-color: #F54A4F;
            }
            .grey{
                background: $GREY_LIGHT3;
            }
            .update-status-screen-content-holder {
                display: flex;
                flex-direction: column;
                height: 100%;
                padding: 30px 60px;
                .content-details{
                    max-width: 554px;
                }
                .modal-heading {
                    padding-bottom:6px;
                    display: flex;
                    align-items: baseline;
                    h1 {
                        font-size: $FONT_SIZE_23;
                        margin-right: 10px;
                    }
                }
                position: inherit;
                .message.ui-message {
                    margin: 0;
                    height: auto;
                    width: 100%;
                    font-style: normal;
                    min-height: 74px;
                    padding-top: 25px;
                    padding-bottom: 25px;
                    p.invalid
                    {
                        background: #F54A4F;
                        color: $FONT_COLOR;
                        padding: 2px 10px;
                        font-size: 14px;
                    }
                }
            }

            input[type="text"],
            select {
                min-height: 24px;
                font-size:$FONT_SIZE_DEFAULT;
                padding:0 5px;
            }
            .revision-input
            {
                padding-right: 3px !important;
                height: 20px;

                &.hidden
                {
                    display: none;
                }
            }

            #update-revision-holder,
            #parentRevisionTable,
            #updateParentRevisionTable
            {
                height: 100%;
                overflow: auto;
                &.apply-scrol
                {
                    max-height: calc(100vh - 33rem);
                }
                position: relative;
                .scrollbar-container{
                    max-height: inherit;
                    height: inherit;
                    .ps__rail-y
                    {
                        margin-top: 37px;
                    }
                    .ps__thumb-y
                    {
                        background: #888;
                    }
                }
                .revision-table{
                    padding-right: 10px;
                    margin-top: 36px;
                    .table-icons-section
                    {
                        padding-right: 120px;
                        .grey.records-count
                        {
                            position: absolute;
                            right: 0;
                        }
                        justify-content: flex-start;
                        .table-cta-icons
                        {
                            margin-right: 0;
                            .modal.open
                            {
                                min-width: auto;
                            }
                        }
                    }
                    .col-move {
                        right: 0;
                        z-index: 99999;
                    }
                }
                .update-revision-table
                {
                    .status-holder
                    {
                        .status-label {
                            &:first-child
                            {
                                opacity: 9;
                            }

                         .status-slect.invalid
                         {
                            background-color: #F54A4F;
                         }
                        }
                    }
                    .not-modified
                    {
                        .text-block
                        {
                            color: $FONT_LIGHT;
                            pointer-events: none;
                            opacity: 0.5;
                        }
                    }
                }
                .revision-holder
                {
                    align-items: center;
                    .revision-label {
                        text-align: left;
                        margin-top: 0;
                        margin-right: -10px;
                        margin-left: 10px;
                        &.doted-border
                        {
                            margin-left: 0;
                            text-align: left;
                            color: $GREY_LIGHT3;
                        }
                        &.custom-rev-scheme
                        {
                            margin-right: 15px;
                        }
                    }
                    .ui-icon{
                        &.arrow{
                            margin: 0 5px;
                            width: 20px;
                            height: 20px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                    }
                }
                &.disabled-child
                {
                    opacity: 0.4;
                    pointer-events: none;
                    .revision-holder
                    {
                        input
                        {
                            display: none;
                        }
                    }
                }

                &.enabled-child
                {
                    .revision-holder
                    {
                        .enable-disable-label
                        {
                            display: none;
                        }
                    }
                }

                .set-bulk-revision-holder
                {
                    display: inline-flex;
                    align-items: center;
                    margin-left: -15px;
                    color: #fff;
                    input
                    {
                        margin: 0 15px;
                    }
                    button
                    {
                        height: 23px;
                        min-width: 67px;
                        padding: 12px 30px;
                        .spinner-holder
                        {
                            .spinner
                            {
                                position: absolute;
                                left: -25px;
                            }
                        }
                    }
                }
                .custom-right-border {
                    &:after {
                        display: none !important;
                    }
                }

                .extra-footer-row.data-cell
                {
                    border-right: 1px solid $GREY_LIGHT3;
                }
            }

            #update-parent-revision-holder
            {
                #table-cta-icons
                {
                    .modal.open
                    {
                        min-width: auto;
                    }
                }
                .last-row-missing {
                    padding-right: 10px;
                }
                .custom-right-border:after {
                    right: 10px;
                }
            }
        }

        h1
        {
            margin-bottom : 6px;
        }

        h3
        {
            font-size: 14px;
            white-space: nowrap;
            margin-bottom : 75px;
        }

        .required
        {
            margin: 15px 0px 20px 0px;
            display: inline-flex;
            align-items: center;
            padding: 5px 1px 0 0;
            border: 1px solid $BG_LIGHT;
            width: 550px;
            overflow: hidden;
            .field-holder {
                padding-left: 0;
                padding-top: 1px;
                padding-bottom: 2px;
                width: 100%;
            }
            .fields{
                display: flex;
                align-items: flex-start;
                .field-status {
                    margin-right: 12px;
                    font-weight: bold;
                    text-transform: uppercase;
                    color: #888;
                    margin: 0 5px 0 0;
                }
                .field-revision {
                    width: 50px;
                    margin-right: 16px;
                }
                .field-input {
                    width: 100%;
                }
                > span {
                    display: inline-block;
                    .ui-icon{
                        margin-right: 20px;
                        g{
                            stroke: #4A4A54;
                        }
                    }
                    &.activated{
                        .ui-icon{
                            g{
                                stroke: $GREEN;
                            }
                        }
                    }
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
                button
                {
                    height: 23px;
                    min-width: 81px;
                    padding: 0px;
                }
            }
            .message{
                float: left;
                width: 50%;
            }
        }

        .edit-page-required{
            max-width: 632px;
            width: 100%;
        }
        .child-revision-label {
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            input,label
            {
                width: 15px;
                height: 15px;
            }
            .label-heading {
                color: #888;
                margin-left: 10px;
            }
        }

        html input[type="checkbox"]:checked:not(old) + label, body input[type="checkbox"]:checked:not(old) + label
        {
            background: $GREEN !important;
        }

        button
        {
            margin-right:10px;
            &.pl-15{
                padding-left: 15px;
            }
        }
        .grey.records-count
        {
            background: transparent;
        }
    }

    .child-revision-label
    {
        .child-revision-label-holder
        {
            border: 1px solid #979797;
            padding: 11px;
            border-radius: 8px;
            display: inline-flex;
            .label-heading
            {
                color: #fff;
            }
        }
    }

    .child-cmp-heading
    {
        margin-top: 15px;
        font-weight: bold;
        color: #888;
        margin-bottom: 10px;
    }
}

body[data-modal='update-revision-bd open']
{
    .scrollbar-container.custom-scroll
    {
        position: unset;
        height: unset;
    }

    .header
    {
        z-index: inherit;
    }
    .ui-modal-box{
        z-index: 9;
    }

    .component.edit,
    .product.edit
    {
        .__react_component_tooltip {
            padding: 5px 10px;
            max-width: 203px;
            text-align: left;
            font-style: normal;
        }
    }

    .update-status-screen-tool-tip
    {
        line-height: 1.25!important;
        max-width: 244px;
        .rc-tooltip-inner{
            padding-right:10px!important;
            .ta-right{
                text-align: right;
                .open-btn{
                    min-width: 60px;
                    margin-right: 0px;
                    padding: 4px 10px ;
                    background-color: $GREEN;
                    border-color: black;
                    color: $FONT_BLACK;
                }
            }
            .co-text{
                color: #F8CFD0;
                svg{
                    g{
                        fill: #F8CFD0;
                    }
                }
            }
        }
    }

    .bulk-update-heading-info-tooltip,
    .co-warning-tooltip,
    .error
    {
        .rc-tooltip-content
        {
            .rc-tooltip-inner
            {
                background: $FONT_BLACK !important;
                border: 1px solid $FONT_BLACK !important;

                .link-text.co-text
                {
                    color: $GREEN;
                    .ui-icon
                    {
                        svg
                        {
                            g
                            {
                                fill: $GREEN;
                            }
                        }
                    }
                }
            }
            .rc-tooltip-arrow
            {
                border-top-color: $FONT_BLACK !important;
            }
        }
    }
}
