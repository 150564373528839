@import "../../../../../styles/variables";

.co-list,
.update-revision-table
{
    .revision-holder,
    .status-holder
    {
        display: inline-flex;
        align-items: flex-end;
        gap: 0.25rem;
        .ui-icon
        {
            width: 10px;
            min-width: 10px;
            height: 10px;
            margin: 0 5px;
        }

        .status-label{
            min-width: 110px;
            width: 110px;
            display: inline-block;
            line-height: 17px;
            text-align: center;
            height: auto;
            &:first-child{
                opacity: 0.5;
            }
            .status-slect{
                margin-left: -10px;
                height: 23px;
                padding: 0px 5px;
                font-size: 12px;
                width: 110px;
                color: #2a2a36;
                option:disabled {
                    color: #888;
                }
            }
        }
        .revision-label
        {
            margin-left: 10px;
            &:first-child{
                width: 29px;
                min-width: 25px;
                display: inline-block;
                color: #fff;
            }
            width: 25px;
            min-width: 25px;
            .ui-icon
            {
                margin: 0;
                svg
                {
                    width: 6px;
                    height: 6px;
                    vertical-align: top;
                }
            }
        }
    }

    .status-cell{
        .text-block{
            height: auto;
        }
    }
    .cpn-holder{
        a{
            color: $GREEN;
        }
        display: flex;
    }

    .revision-cell{
        .plus-icon{
            min-width: 29px;
            min-height: 23px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0px;
        }
        .remove-exist{
            .revision-label {
                display: inline-block;
                &.text{
                    margin-bottom: 2px;
                }
            }
        }
        .remove-revision {
            right: -6px;
            bottom: -9px;
            position: absolute;
            .ui-icon {
                width: 13px;
                min-width: 10px;
                height: 13px;
                margin: 0px;
                svg {
                    height: 13px;
                    width: 13px;
                }
            }
        }
        .text-block{
            height: auto;
            .revision-holder{
                .doted-border{
                    margin-left: 0;
                    border: 1px dashed #888888;
                }
                display: flex;
                align-items: flex-end;
                input{
                    color: #000000 !important;
                    width: 29px;
                    height: 22px !important;
                    padding: 2px 5px 2px;
                    text-align: left;
                }
                .revision-label{
                    text-align: left;
                    margin-top: 3px;
                    margin-right: -10px;
                    &.text{
                        margin-top: 0;
                        min-width: $DEFAULT_REVISION_INPUT_WIDTH;
                        min-height: 24px;
                        padding: 2px 5px 2px;
                        text-align: left;
                    }
                    &.text.custom-rev-scheme
                    {
                        min-width: $CUSTOM_REVISION_INPUT_WIDTH;;
                    }
                    &.custom-rev-scheme
                    {
                        margin-right: 15px;
                    }
                }
                .ui-icon.rev{
                    width: 7px;
                    min-width: 7px;
                    svg
                    {
                        width: 6px;
                        height: 6px;
                        vertical-align: top;
                    }
                }
                .ui-icon.arrow{
                    margin: 1px 7px 5px 13px;
                }
                .rev-actions-icon
                {
                    margin-left: 10px;
                }
            }
        }
    }

    .last-release-cell {
        .text-block {
            .last-release-holder {
                .revision-label {
                    color: #fff;
                    .rev{
                        color: #FFFFFF;
                    }
                    .revision-value
                    {
                        padding-left: 5px;
                    }
                }
                &.status-holder {
                    .status-label {
                        &:first-child {
                            opacity: 9;
                        }
                    }
                }
            }
        }
    }

    .warningEl{
        width: 15px;
        height: 15px;
        svg{
            width: 15px;
            height: 15px;
        }
    }
    .error-alert-icon{
        width: 15px;
        height: 15px;
        svg{
            width: 15px;
            height: 15px;
        }
    }
    .where-used-holder
    {
        display: inline-block;
        margin-left: 10px;
    }
}

.co-list {
    .disable-row {
        opacity: 0.7;
    }
    .have-footer-row {
        padding-bottom: 50px !important;
    }
    .extra-footer-row {
        height: 37px;
        bottom: 14px;
    }
    .custom-right-border:after {
        height: calc(100% - 15px);
        bottom: 15px;
    }
    .spinner {
        margin-left: 6px;
        width  : 23px;
        height : 23px;
        .ui-icon
        {
            width  : 23px;
            height : 23px;
            svg {
                width  : 23px;
                height : 23px;
            }
        }
    }
}

.hide-modal {
    display: none !important;
}

.validation-running {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 5000;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    left: 0;
    .popover-content-block {
        color: #4A4A4A;
        max-width: 241px;
        padding: 15px 15px;
        background: #FFF;
    }
    .validation-heading{
        margin-bottom: 8px;
        display: flex;
        align-items: flex-end;
        h4 {
            color: #18171D;
            font-size: 14px;
            margin: 0px 0px 0px 10px;
        }
    }
    .loading-block {
        width: 20px;
        height: 20px;
        -webkit-animation-name: rotate;
        -webkit-animation-duration: 0.4s;
        animation-duration: 0.4s;
        -webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
        -webkit-animation-timing-function: linear;
        animation-timing-function: linear;
        svg {
            fill: #3CD1B5;
            height: 20px;
            width: 20px;
            path:nth-child(1){
                opacity: 0.5;
            }
        }
    }
    p {
        line-height: 24px;
    }
}

.co-list-icon-tooltip {
    max-width: 340px;
    width: 100%;
    .rc-tooltip-arrow {
        border-top-color: #000 !important;
    }
    .rc-tooltip-inner {
        background-color: #000 !important;
        border: 1px solid #000 !important;
        color: #FFFFFF;
        font-size: 14px;
        border-radius: 0px !important;
        padding: 10px;
        p {
            margin-bottom: 5px;
        }
        .status-label {
            line-height: 20px;
            display: inline-block;
        }
    }
    .rc-tooltip-arrow-inner {
        border-top-color: #000 !important;
    }
    .ta-right {
        text-align: right;
    }
    .tooltip-button {
        font-size: 14px;
        padding: 4px 14.33px !important;
        border-radius: 8px;
    }
    .status {
        color: #3CD1B5;
        font-weight: bold;
    }
    ul {
        list-style: none;
        margin-top: 15px;
        li {
            display: flex;
            align-items: center;
            margin-bottom: 5px;
            .fill-bullet {
                width: 8px;
                height: 8px;
                border-radius: 100%;
                background-color: #FFF;
                display: inline-block;
                margin-right: 10px;
            }
        }
    }
    .ui-link {
        line-height: 16px;
        &:hover {
            .ui-icon {
                .open-link-icon {
                    fill: #FFF;
                    g {
                        fill: #FFF;
                    }
                }
            }
        }
    }
    .mt-10 {
        margin-top: 10px;
    }
    .mt-5 {
        margin-top: 5px;
    }
    .mt-15 {
        margin-top: 15px;
    }
}
