.erp-wrapper {
        margin-top: 25px;
        .container {
            background: none;
            .erp-options-arrow{
                margin-right: 10px;
                display: inline-block;
                cursor: pointer;
                svg {
                    fill: #fff;
                }
                &.open {
                    transform: rotate(90deg);
                }
                &.close {
                    transform: rotate(0deg);
                }
            }
            .erp-title {
                font-weight: bold;
            }
        }
        
            .erp-options {
                margin-top: 20px;
                background: none;
            .erp-effectivity,
            .erp-item-type {
                display: flex;
                margin-bottom: 15px;
                padding-left: 0;
            }
        
            .erp-item-type {
                .custom-datepicker {
                    display: flex;
                    justify-content: flex-end;
                    select {
                        width: 100%;
                        height: 26px;
                        padding: 5px 10px;
                        &.disabled {
                            color: black !important;
                            background-color: #888888 !important;
                        }
                        &.enabled {
                            background-color: white !important;
                        }
                    }
                }
            }
            .custom-checkbox {
                display: inline-flex;
                align-items: center;
                &:last-child {
                    .checkbox-label {
                        font-weight: normal;
                        &.disabled {
                            color: #888888;
                        }
                        &.enabled {
                            color: #fff;
                        }
                    } 
                }
                .checkbox-label {
                    margin-left: 10px;
                    font-weight: bold;
                }
                label {
                    &.disabled-override-existing {
                        border-color: gray;
                    }
                }
            }

            .item-type {
                margin-right: 36px;
            }

            .disabled-item-type {
                color: #888888;
            }

            .custom-datepicker {
                max-width: 400px;
                width: 100%;
                display: flex;
                align-items: center;
                margin: 0 15px;
                .ui-icon {
                    width: 16px;
                    height: 18px;
                    margin: 0 9px;
                }
                .react-datepicker-wrapper {
                    .custom-date-trigger {
                        height: 24px!important;
                        padding: 5px 10px;
                        &.disabled {
                            background-color: #888888!important;
                            color: black !important;
                        }
                        &.enabled {
                            background-color: white !important;
                            color: black !important;
                        }
                    }
                    ::placeholder   {
                        color: #17171F;
                    }
                }
            }
            .select-class {
                width: 365px;
            }
        }
    }