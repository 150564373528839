table {
  tbody tr td {
    &:nth-child(2),
    &.name-block .item-name {
      .variant-holder {
        top: 3px;
        padding: 0;
        right: 10px;
        width: 28px;
        height: 28px;
        border-radius: 4px;
        position: absolute;
        background: rgba(216, 216, 216, 0.1);
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover {
          .ui-icon {
            svg {
              g {
                path {
                  stroke: #3cd1bf;
                }
              }
            }
          }
        }
        .ui-icon {
          width: 16px;
          height: 17px;
          margin: 0;
          position: relative;
          left: auto;
        }
      }
    }

    &.name-block .item-name{
      .variant-holder{
        top: -4.5px;
        right: 0px;
      }
    }
  }
}
