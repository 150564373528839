@import "../../../../../../styles/variables";

.modal-box
{
    .modal
    {
        width: 560px;
        padding: 44px;
    }
    .approval-model-heading {
        font-size: 24px;
        font-weight: 500;
    }
    .type-status-block
    {
        display: flex;
        width: 100%;
        justify-content: space-between;
        .component-type
        {
            display: flex;
            flex-direction: column;
            padding: 5px;
            width: 50%
        }
        .status-type
        {
            display: flex;
            flex-direction: column;
            padding: 5px;
            width: 50%
            
        }

    }
    .template-selector
    {
        width: 100%;
        
    }
    .co-rule-btn
    {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin-top: 20px;
    }
    .rules-table
    {
        margin-top: 20px;
        table {
            tr {
                border-color: #979797;
                border-width: 0.5px;
            }
            thead {
                th {
                    text-transform: initial;
                    color: #888888;
                    padding-bottom: 10px;
                    &:nth-child(1) {
                        width: 12%;
                    }
                    &:nth-child(2) {
                        width: 20%;
                    }
                    &:last-child {
                        width: 55%;
                        text-align: left;
                    }
                }
            }
            tbody {
                td {
                    &:last-child {
                        display: flex;
                        align-items: center;
                        overflow: inherit;
                        position: relative;
                    }
                    .approval-modal-visibility {
                        width: 22px;
                        height: 25px;
                        background: rgba(136, 136, 136, 0.15);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 6px;
                        margin-right: 15px;
                        padding: 0 4px;
                        svg {
                            width: 16px;
                            height: 16px;
                        }
                        &.visibility-white
                        {
                            pointer-events: all;
                            svg {
                                g {
                                    path {
                                        fill: white;
                                    }
                                }
                            }
                            &:hover {
                                svg {
                                    g {
                                        path {
                                            fill: $GREEN;
                                        }
                                    }
                                }
                            }

                        }
                        &.visibility-green
                        {
                            pointer-events: all;
                            svg {
                                g {
                                    path {
                                        fill: $GREEN;
                                    }
                                }
                            }
                        }
                    }
                    .Choose-Template {
                        width: 100%;
                        .duro__menu-list {
                            text-align: left;
                        }
                        .duro__option--is-disabled {
                            color: rgba(24, 23, 29, 0.5);
                        }
                    }
                }
            }
        }
    }
    .finalize-block
    {
        margin-top: 20px;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        .close-button {
            height: 27px;
            padding: 0;
            min-width: 75px;
            margin: 0 15px 0 0;
        }
    } 
}
