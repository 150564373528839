@import "../../../../styles/variables";

.component-revision-route
{
    position      : relative;
    margin-bottom : -100px;
    overflow-x    : hidden;

    .background-holder
    {
        pointer-events : none;
        position       : absolute;
        left           : 0;
        top            : 0;
        opacity        : 0.02;
        font-weight    : bold;
        z-index        : 1;
        width          : 100%;
        height         : 100%;
        overflow       : hidden;
        .background {
            transform      : rotate(-45deg);
            width          : 130%;
            height         : 200%;
            position       : absolute;
            top            : -50%;
        }
    }

    .description-text
    {
        white-space: pre-line;
    }
    .lower-case
    {
        text-transform: lowercase;
    }
    .top-block
    {
        background: $BG_LIGHT;
        padding-bottom: 15px;

        .name-block
        {
            font-size     : $FONT_SIZE_23;
            margin-bottom : 50px;
        }

        .info-block
        {
            display: flex;
            justify-content: space-between;

            table
            {
                width: auto;

                tr
                {
                    border-color: transparent;

                    th, td
                    {
                        vertical-align: top;
                        padding-top: 0;
                        padding-bottom: 15px;
                    }

                    th
                    {
                        width: 125px;
                        overflow: auto;
                    }

                    td
                    {
                        text-align: left;
                    }

                    &:last-child
                    {
                        td:last-child
                        {
                            white-space: normal;
                        }
                    }
                }
                .eid-info
                {
                    a
                    {
                        .co-link-icon
                        {
                            width: 15px;
                            height: 15px;
                            .open-link-icon
                            {
                                #arrow-area
                                {
                                    stroke: #3CD1B5;
                                }
                            }
                        }
                        &:hover
                        {
                           .open-link-icon
                            {
                                #arrow-area
                                {
                                    stroke: #FFF;
                                }
                            }
                        }
                    }
                }
            }

            > div
            {
                &:nth-child(1)
                {
                    flex-grow   : 0;
                    flex-shrink : 0;
                    flex-basis  : 175px;
                    margin-right: 30px;
                }

                &:nth-child(2)
                {
                    margin-right: 30px;
                    padding-right: 25px;

                    tr:nth-child(1) td,
                    tr:nth-child(2) td,
                    tr:nth-child(3) td,
                    tr:nth-child(4) td
                    {
                        text-transform: uppercase;
                    }

                    tr:nth-child(5) td
                    {
                        overflow: auto;
                        white-space: normal;
                        line-height: 20px;
                    }
                }

                &:nth-child(3)
                {
                    margin-right: 30px;
                    padding-right: 25px;

                    th
                    {
                        text-transform: uppercase;
                        width: 150px;
                    }
                }

                &:nth-child(4)
                {
                    flex-grow   : 0;
                    flex-shrink : 0;
                    flex-basis  : 190px;

                    th
                    {
                        width: 100px;
                    }
                }
            }
            .default-img
            {
                background-image: linear-gradient(-180deg, #353540 0%, #18171D 100%);
                min-width: 185px;
                min-height: 140px;
                display: flex;
                align-items: center;
                z-index: 2;
                justify-content: center;
                .ui-icon
                {
                    width: 68px;
                    height: 69px;
                    svg
                    {
                        width: 100%;
                        height: 100%;
                        fill: $GREY_LIGHT3;
                    }
                }
            }
        }
    }
}

