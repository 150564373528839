@import "../../../../../../styles/variables";

.new-component-category-types
{
    margin-top    : 20px;
    margin-bottom : 40px;
    display         : flex;
    justify-content : space-between;
    .category-nav
    {
        max-width: 80px;
        width: 100%;
        margin-bottom: 20px;
    }

    > div
    {
        cursor : pointer;
        *
        {
            pointer-events: none;
        }

        .mechanical
        {
            height: 25px;
        }

        &:hover
        {
            color: $GREEN;

            svg
            {
                fill: $GREEN;
            }

            .general,
            .plumbing,
            .architectural,
            .structural,
            .material.metal,
            .material.non-metal,
            .finished.goods,
            .electro-optical,
            .other
            {
                .ui-icon
                {
                    svg
                    {
                        fill: $GREEN!important;
                        g,path
                        {
                            stroke: $GREEN;
                        }
                    }
                }
            }
        }

        &.selected
        {
            pointer-events : none;
            color          : $GREEN;

            svg
            {
                fill: $GREEN;
            }

            .general,
            .plumbing,
            .architectural,
            .structural,
            .material.metal,
            .material.non-metal,
            .finished.goods,
            .electro-optical,
            .other
            {
                .ui-icon
                {
                    svg
                    {
                        fill: $GREEN!important;
                        g,path
                        {
                            stroke: $GREEN;
                        }
                    }
                }
            }
        }

        .disabled
        {
            pointer-events  : none;
            opacity         : 0.3;
        }
    }
    .web-mechanical
    {
        label
        {
            color: $GREY !important;
        }
        .ui-icon
        {
            svg
            {
                path{
                    fill: $GREY !important;
                }
            }
        }
    }
}

.simple-rc-tip
{
    &.custom-disable-tooltip
    {
        max-width: 273px;
        .rc-tooltip-inner
        {
            background-color: #000 !important;
            border: 1px solid #000 !important;
            color: #FFFFFF;
            padding: 10px!important;
            line-height: 18px!important;
            .integration-disconneted {
                display: grid;
            }
        }
        .rc-tooltip-arrow
        {
            left: 50%!important;
            border-top-color: #000;
        }
    }
}


