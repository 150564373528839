.error-alert
{
    position: absolute;
    right: 10px;
    top: -10px;
    .__react_component_tooltip{
        &.place-left{
            padding: 5px 10px;
            max-width: 187px;
            &:after {
                right: 13px;
                top: -3px;
                margin-top: -4px;
                transform: rotate(270deg);
            }
            .multi-line{
                text-align: left;
            }
        }
    }
    .ui-icon{
        pointer-events: auto;
    }
}
