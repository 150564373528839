@import "../../../../../styles/variables";

.users-settings-route .user-list-form-block
{
    width: 100%;
    overflow: unset;
    user-select: none;
    -webkit-user-select: none;
    position: relative;
    padding-bottom: 75px;

    button.cancel
    {
        margin-left: 10px;
    }

    .star-svg {
        width: 10px;
        position: absolute;
        top: 28%;
        left: 0;
    }
    .edit-svg {
        height: 100%;
        width: 100%;
        padding-top: 8px;
        .ui-icon
        {
            pointer-events: none;
            user-select: none;
            width: 15px;
            height: auto;
            svg{
                fill: $GREEN;
            }

        }
    }
    .index-checkbox{
        height: 100%;
        width: 100%;
    }

    tr{
        &:hover{
            .user-info-col{
                .user-name{
                    color: $GREEN;
                }
            }
        }
    }

    .user-disable
    {
        color: $FONT_LIGHT;
    }

    .disable-user-modal,.delete-user-modal{
        .warning-text{
            color: $FONT_LIGHT;
            font-style: italic;
            font-size: 14px;
        }
    }

    .user-session-cell{
        display: flex;
        justify-content: space-between;
        .action-popover-trigger{
            font-size: $FONT_SIZE_23;
            padding-left: 10px;
            line-height: 10px;

            &.disabled{
                color: $GREY_LIGHT3;
                cursor: default;
            }
        }
    }
}

.action-popover{
    background: #17171f;
    padding: 10px 5px 0;
    min-width: 135px;
    max-width: 160px;
    padding-bottom: 5px;

    position: absolute;

    .action-option{
        display: flex;
        align-items: center;
        margin: 5px 12px 15px;
        cursor: pointer;

        .ui-icon{
            width: auto;
            height: auto;
            margin-right: 10px;
        }

        &:hover{
            .ui-icon svg g{
                stroke: $GREEN;
            }
            .label {
                color: $GREEN;
            }
        }
        .ui-icon {
            width: 15px;
            height: 15px;
            margin-right: 10px;
        }
    }

}
.invisibile{
    visibility: hidden;
}
.visibile{
    visibility: visible;
}
