@import "../../../../../styles/variables";

.edit-product-route .tabs-block,
.edit-production-instance-route .tabs-block
{
    padding-top    : 25px;
    padding-bottom : 100px;
    min-height     : 300px;

    .nav-block
    {
        margin-bottom   : 22px;
        display         : flex;
        justify-content : left;

        [name~="assembly"],
        [name~="documents"],
        [name~="sourcing"]
        {
            cursor     : pointer;
        }

        &.display-flex-sb{
            display: flex;
            justify-content: space-between;
        }

        .tabs-headings{
            display         : flex;
            cursor     : pointer;
            color      : $GREY;
            transition : color 0.1s;
        }
        .assembly-icons{
            .muted-text{
                margin-right: 8px;
                cursor: default;
            }
            .disabled {
                cursor: default;
            }
            .icon-section{
                background: rgba(136, 136, 136, .15);
                border-radius: 8px;
                padding: 4px 10px;
                &.disable-cursor {
                    cursor: default;
                }
            }
        }

        .tabs-headings > div
        {
            color      : $GREY;
            font-size  : 19px;
            transition : color 0.1s;

            > div
            {
                pointer-events: none;
                position: relative;
                padding: 2px 5px;
            }

            &:nth-child(1)
            {
                // border-right: 1px solid $GREY;
                padding-right: 10px;
            }

            &:nth-child(2)
            {
                padding-left  : 10px;
                padding-right : 10px;
                // border-right  : 1px solid $GREY;
            }

            &:nth-child(3)
            {
                padding-left: 10px;
                padding-right: 10px;
            }

            &:last-child
            {
                border-right: none;
            }

            &:hover
            {
                color: $FONT_COLOR;
            }

            &.selected
            {
                color          : $FONT_COLOR;
            }
            &.error-text
            {
                .tab-text
                {
                    background-color: #A84148;
                    border-radius: 6px;
                }
                .tab-text-error
                {
                    background-color: #F54A4F;
                    border-radius: 50px;
                    position: absolute;
                    top: -13px;
                    right: 0;
                    width: 22px;
                    height: 22px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 14px;
                    border: 1px solid black;
                }
            }
        }
    }

    .table-cta-icons
    {
        .collapse-icon{
            transform: rotate(180deg);
            height: 16px;
        }
    }

    .view-block
    {
        // position: relative;

        > div
        {
            display : none;
            // visibility: hidden;
            // height: 0px;
            &.selected
            {
                // height: auto;
                display : block;
                // visibility: visible;
            }
        }
    }
}
