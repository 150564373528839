@import "../../../../../styles/variables";


#where-used-modal
{
    width: 863px;

    .header-block
    {
        color: $FONT_COLOR;
        .where-used-header
        {
            font-weight: 700;
        }
    }

    .content-block
    {
        table
        {
            thead
            {
                tr
                {
                    th
                    {
                        .text-block
                        {
                            margin-right: 0;
                            padding: 10px 0 10px 9px;
                        }

                        &:nth-child(1)
                        {
                            width: 80px;
                        }

                        &:nth-child(2)
                        {
                            width: 105px;
                        }

                        &:nth-child(3)
                        {
                            width: 100%;
                        }

                        &:nth-child(4)
                        {
                            width: 100px;
                            .sorting-icon-holder
                            {
                                display: none;
                            }
                        }

                        &:nth-child(5),
                        &:nth-child(6)
                        {
                            width: 75px;
                        }
                        &:last-child
                        {
                            text-align: left;
                            width: 135px;
                        }
                    }
                }
            }
            tbody
            {
                tr
                {
                    &.product-row
                    {
                        background-color: #29282E;
                    }

                    td
                    {
                        &:nth-child(1)
                        {
                            .text-block
                            {
                                .icon-holder
                                {
                                    padding-left: 10px;
                                    .ui-icon
                                    {
                                        margin-left: 0px;
                                    }
                                }
                            }
                        }

                        .table-image-viewer-wrapper
                        {
                            justify-content: flex-start;
                        }
                    }
                }
            }
        }
    }
}
