@import "../../../../../../styles/variables";

.save-template-modal {
        .modal-content {
            .modal-body {
                margin-bottom: 25px;
                .checkbox-container {
                    margin-top:15px;
                }
            }
        }
    }

.manage-templates-modal {
    .co-manage-template{
        margin-top: 0;
    }
    .modal-content {
        .modal-body {
           margin-bottom: 30px;
        }
    }
}

.disable-save {
    pointer-events: none !important;
    svg { 
        transform: scale(1.1);
        fill: #888888 !important;
    }
}

.show-save {
    cursor: pointer;
    svg {
        transform: scale(1.1);
        &:hover {
            fill: $GREEN !important;
        }
    }
}

.template-select {
    display: flex;
    align-items: center;
    margin-bottom: 15px;   
    .ui-icon {
        width: 15px;
        margin-left: 10px;
        svg {
            g {
                stroke: #fff;
            }
        }
    }
    .template {
        width: 100%;
        max-width: 193px;
    }
}
