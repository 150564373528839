@import "../../../styles/variables";
.actions-header-block {
  >div{
    display: flex;
    width: 100%;
  }
}
.top-cmp-nav-holder
{
    display: flex;
    align-items: center;
}
.components-header-nav
{
    background: #353540;
    padding: 6px 0;
    border-radius: 8px;
    margin-left: 15px;
    display: flex;
    &.top-padding-require
    {
      margin-top: 35px;
      display: inline-flex;
    }
    h1
    {
      margin-right: 0px;
    }
    span
    {
        display: inline-flex;
        justify-content: flex-start;
        a
        {
            margin-left: 5px;
            margin-right: 15px;
            color : $FONT_COLOR;
            display: flex;
            align-items: center;
            &:hover, &.selected-tab
            {
                span{
                    color: #3cd1b5;
                }
                .ui-icon
                {
                    #import-icon
                    {
                        g{
                          fill: #3cd1b5;
                        }
                    }
                    svg {
                        fill: #3cd1b5;
                        stroke: #3cd1b5;
                    }
                }
            }
            .ui-icon {
              width: 19px;
              max-width: 19px;
              height: auto;
              margin-right: 8px;
              svg {
                fill    : $FONT_COLOR;
                stroke  : $FONT_COLOR;
              }
            }
        }
        &.all-search-nav{
            a{
                margin-left: 25px;
                margin-right: 20px;
            }
        }
    }
    .web-icon
    {
        .ui-icon,
        .ui-icon svg {
            width: 19px;
            height: 19px;

        }
    }
    .hand-icon
    {
        .ui-icon,
        .ui-icon svg {
            width: 17px;
            height: 19px;
        }
    }
    .cmp-nav-label
    {
        padding-left: 15px;
        a{
            pointer-events: none;
            color: #888888;
            margin-right: 10px;
            .ui-icon
            {
                margin-right: 0;
            }
        }
    }
    #assembly-add-icon
    {
        height: 16px;
        width: 19px;
    }
    .selected .ui-icon
    {
        pointer-events : none;
        color          : $GREEN;
        svg
        {
            fill: $GREEN;
        }
    }
    span
    {
      font-size   : $FONT_SIZE_DEFAULT;
    }
}
