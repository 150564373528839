@import "../../../styles/variables";
.tool-tip-modal {
    position: initial ;
    .on-boarding-step-tool-tip {
    max-width:350px;
        .flexbox-item {
            padding: 0;
        }

        .arrow-icon {
            position: absolute;
            right: 11px;
            top: 150px;
            z-index: -1;
            .ui-icon  {
                border:0;
            }
        }
        .header-top {
            text-align: left;
            p{
                background-color: #04adc3;
                padding: 3px 15px;
                min-height: 26px;
                color: $FONT_COLOR;
                display: inline-block;
                font-weight: 600;
            }
        }
        .modal-content {
            background-color: $FONT_COLOR;
            color: #000;
            text-align: left;
            padding: 20px 30px 0;
            overflow-y: auto;
            .image-holder  {
                margin: 0 -10px 20px;
                img{
                    width: 100%;
                    cursor: pointer;
                }
            }
            .heading-holder {
                h1{
                    font-size: $FONT_SIZE_23;
                    color: #000;
                    font-weight: 600;
                    margin-bottom: 15px;
                }
            }
            p{
                font-size: 16px;
                color: #000;
                opacity: 0.5;
                margin-bottom: 20px;
                line-height: 24px;
            }
        }
        .footer-modal {
             background-color: #04adc3;
            padding: 3px 20px;
            min-height: 46px;
            display: flex;
            align-items: center;
            justify-content: space-around;
            span{
                font-weight: bold;
                width: 50%;
                text-decoration: underline;
            }
            .invite-link {
                text-align: left;
                cursor: pointer;
            }
            .next {
                text-align: right;
                cursor: pointer;
            }
        }
    }

    .steps-video-modal {
        .modal {
            max-width: 864px;
            margin: 0 auto;
            padding: 0;
            height: auto;
            .main-text-holder {
                padding: 40px;
                padding-bottom: 10px;
                .heading-holder {
                    border-bottom:1px solid $BG_LIGHT;
                    margin-bottom: 30px;
                    h1 {
                        font-size: $FONT_SIZE_23;
                        line-height: 28px;
                        font-weight: 600;
                        margin-bottom: 15px;
                    }
                    p{
                        color: $FONT_COLOR;
                        opacity: 1;
                    }
                }
                p{
                    margin-bottom: 20px;
                    font-size: 16px;
                    opacity: 0.5;
                }


            }
            .modal-content{
                height: 100%;
                >div {
                    height: 100%;
                }
                .footer-modal {
                    /*position: absolute;*/
                    width: 100%;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    padding: 3px 40px;
                    min-height: 46px;
                    display: flex;
                    align-items: center;
                    justify-content: space-around;
                    span{
                        font-weight: bold;
                        width: 50%;
                        text-decoration: underline;
                        opacity: 0.5;
                        &:hover {
                            opacity:1;
                        }
                    }
                    .invite-link {
                        text-align: left;
                        cursor: pointer;
                    }
                    .next {
                        text-align: right;
                        cursor: pointer;
                    }
                }
                .video-holder {
                    video {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
            .modal-close{
                position: absolute;
                right: -21px;
                top: -22px;
                width: 20px;
                height: 20px;
            }
        }
        
        .flexbox-parent
        {
            width: 100%;
            height: 100%;

            display: flex;
            flex-direction: column;
            
            justify-content: flex-start; /* align items in Main Axis */
            align-items: stretch; /* align items in Cross Axis */
            align-content: stretch; /* Extra space in Cross Axis */
        }

        .flexbox-item
        {
            padding: 0;
        }
        .flexbox-item-grow
        {
            flex: 1; /* same as flex: 1 1 auto; */
        }
        .fill-area
        {
            display: flex;
            flex-direction: row;
            
            justify-content: flex-start; /* align items in Main Axis */
            align-items: stretch; /* align items in Cross Axis */
            align-content: stretch; /* Extra space in Cross Axis */
            
        }
        .fill-area-content
        {
            /* Needed for when the area gets squished too far and there is content that can't be displayed */
            overflow: auto; 
        }
    }
}
