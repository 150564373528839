@import "../../../../styles/variables";

.new-product-route
{
    .crumb-block
    {
        color         : $GREY;
        margin-top    : 20px;
        margin-bottom : 40px;
    }

    .main-block
    {
        display     : flex;
        margin-top  : 50px;

        .content-block
        {
            margin : 0 auto;
            width  : 450px;

            h1
            {
                margin-bottom : 10px;
            }

            h3
            {
                margin-bottom : 40px;
            }

            .small-margin
            {
                margin-bottom : 20px;
            }

            .sub-heading
            {
                font-size: 16px;
                font-weight: normal;
            }

            label[for="name"],
            label[for="description"],
            label[for="thumbnails"],
            label[for="documents"]
            {
                margin-top: 40px;
            }

            .rev-block
            {
                margin-top      : 40px;
                margin-bottom   : 40px;
                display         : flex;
                justify-content : space-between;

                > div
                {
                    &:first-child
                    {
                       width: 100px;
                    }

                    &:last-child
                    {
                        width: 300px;
                    }
                }
            }

            .team-block
            {
                margin-top      : 40px;
                margin-bottom   : 40px;

                table
                {
                    th
                    {
                        text-align:left;
                    }
                }
            }

            .forecasting-block
            {
                margin-top      : 40px;
                margin-bottom   : 40px;

                table
                {
                    th
                    {
                        text-align:left;

                        &:nth-child(1)
                        {
                            width: 185px;
                        }

                        &:nth-child(2)
                        {
                            width: 130px;
                        }

                        &:nth-child(3)
                        {
                            width:120px;
                        }
                        &:last-child{
                            width: 18px;
                        }
                        padding: 9px 10px 9px 0;
                    }
                    td{
                      padding: 9px 10px 9px 0;
                      .ui-icon.disabled.remove-forecast
                      {
                        cursor: initial;
                      }
                    }
                    tr
                    {
                        .ui-icon
                        {
                            width  : 18px;
                            height : 18px;
                        }

                        td.forecast-date
                        {
                            overflow: visible;
                        }

                        &.add-row
                        {
                            border-bottom   : 0 !important;

                            td
                            {
                                > div
                                {
                                    cursor          : pointer;
                                    display         : flex;
                                    justify-content : left;
                                    align-items     : center;
                                    width           : 175px;
                                    padding-top     : 6px;

                                    > div:last-child
                                    {
                                        transition  : color 0.05s;
                                        color       : $GREEN;
                                        padding-top : 3px;
                                    }

                                    .ui-icon
                                    {
                                        margin-right : 10px;
                                        width        : 11px;
                                        height       : 11px;

                                        svg
                                        {
                                            transition : fill 0.05s;
                                            fill       : $GREEN;
                                        }
                                    }

                                    &:hover
                                    {
                                        > div:last-child
                                        {
                                            color: $FONT_COLOR;
                                        }

                                        .ui-icon
                                        {
                                            svg
                                            {
                                                fill: white;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .custom-date-picker-input-holder {
                    input {
                        padding-right: 21px;
                        font-size: 13px;
                    }
                    .ui-icon{
                        position: absolute;
                        top: 10px;
                        right: 5px;
                    }
                }
            }

            .product-category-block
            {
                label
                {
                    margin-right: 8px;
                }

                > div
                {
                    width:100%;
                    display: inline-block;
                }

                .form-group
                {
                    margin-top: 40px;
                }
            }

            input[type="submit"]
            {
                margin-top: 40px;
            }

            .thumbnails-block,
            .documents-block,
            {
                margin-top: 10px;
                .drop-zone {
                    border: 1px dashed #888888;
                    display: flex;
                    align-items: center;
                    height: auto;
                    padding: 6px 15px;
                    &.accept-files
                    {
                        padding: 16px 15px;
                        background: #3CD1B5;
                        .collapsed-block
                        {
                            .ui-icon
                            {
                                svg
                                {
                                    // display: none;
                                }
                            }
                            span
                            {
                                color: #3CD1B5;
                            }
                        }
                    }
                    &.reject-files
                    {
                        padding: 16px 15px;
                        background: #F54A4F;
                        .collapsed-block
                        {
                            .ui-icon
                            {
                                svg
                                {
                                    // display: none;
                                }
                            }
                            span,
                            a
                            {
                                color: #F54A4F;
                            }
                        }
                    }
                }
                .collapsed-block
                {
                    display: flex;
                    align-items: center;
                    padding: 0;
                    .ui-icon
                    {
                        width: 20px;
                        height: 20px;
                        margin:0 10px 0 0;
                        svg
                        {
                            fill: #888888;
                        }
                    }
                    a{
                        margin-left: 5px;
                    }
                }
                .list
                {
                    margin: 15px 0;
                    .upload-file-list
                    {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin-bottom: 10px;
                        padding: 0 15px;

                        .img-name-holder,
                        .cross-size-holder
                        {
                            display: flex;
                            text-align: center;
                            .image-holder
                            {
                                width: 24px;
                                height: 24px;
                                margin-right: 10px;
                                background-size: contain;
                                background-repeat: no-repeat;
                            }
                            .file-name
                            {
                                color: #3CD1B5;
                                margin-right: 10px;
                            }
                            .delete-icon
                            {
                                width: 17px;
                                height: 17px;
                                margin-left: 5px;
                            }
                            #progress-icon-svg
                            {
                                margin-left: 5px;
                            }
                            .current-progress
                            {
                                .cancel-file
                                {
                                    margin-right: 5px;
                                    color: #3CD1C9;
                                    cursor: pointer;
                                }
                            }
                        }
                        &.file-upload-error
                        {
                            .img-name-holder
                            {
                                .file-name
                                {
                                    color: #888888 !important;
                                }
                            }
                            .cross-size-holder
                            {
                                .file-size,
                                .current-progress
                                {
                                    color: #f54a4f;
                                }
                                .delete-icon
                                {
                                    .ui-icon
                                    {
                                        svg
                                        {
                                            g
                                            {
                                                path
                                                {
                                                    fill: #f54a4f;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        &.file-uploading
                        {
                            padding-right: 8px;
                        }
                    }
                    table
                    {
                        tr
                        {
                            td
                            {
                                &:nth-child(1)
                                {
                                    width: 30px;
                                }
                                .file-name
                                {
                                    color: #3CD1B5;
                                }
                            }
                        }
                    }

                }
            }
        }
    }
}
