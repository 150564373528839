@import "../../../styles/variables";


@import "../../../styles/variables";


.ci-loading
{
    top             : 0;
    left            : 0;
    width           : 100%;
    height          : 100vh;
    z-index         : 10000;
    display         : flex;
    justify-content : center;
    
    &.center {
        align-items     : center;
    }
    
    &.top {
        align-items     : flex-top;
        top: 20px;
    }

    .background
    {
        position   : absolute;
        width      : 100%;
        height     : 100vh;
        background : black;
        opacity    : 0;
    }

    .loading-block
    {
        @keyframes rotate
        {
            from {transform: rotate(0deg);}
            to {transform: rotate(360deg);}
        }

        width                     : 40px;
        height                    : 40px;
        animation-name            : rotate;
        animation-duration        : 0.4s;
        animation-iteration-count : infinite;
        animation-timing-function : linear;

        .ui-icon
        {
            width  : 40px;
            height : 40px;

            svg
            {
                fill: $GREEN;
            }
        }
    }

    .text-block
    {
        position   : absolute;
        padding-left: 230px;
    }
}
