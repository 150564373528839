@import "../../../../styles/variables";

.view-webhook-route
{
    .header-section
    {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin: 20px 0 20px;
        .action-btn-holder
        {
            display: flex;
            .action-btn
            {
                color: $FONT_COLOR;
                border: none;
                background-color: rgba(216, 216, 216, 0.10);
                border-radius: 8px;
                margin-left: 10px;
                padding: 8px 12px;
                display: inline-flex;
                align-items: center;
                min-width: auto;

                .ui-icon
                {
                    height: 16px;
                    width: 16px;
                    margin-right: 10px;
                    svg
                    {
                        fill: $FONT_COLOR!important;
                    }
                }
                &:hover{
                    color:  $GREEN;
                    svg{
                        fill: $GREEN !important;
                        g
                        {
                            g
                            {
                                fill: $GREEN !important;
                            }
                        }
                    }
                    &.delete-btn
                    {
                        g{
                            fill: $GREEN !important;
                            stroke: $GREEN !important;

                        }
                    }
                }
            }
        }

    }
    .status-nav
    {
        list-style: none;
        display: flex;
        align-items: center;

        .status-item
        {
            border: 1px solid $GREY_LIGHT3;
            background-color: unset;
            color: $FONT_LIGHT;
            border-radius: 16px;
            padding: 4px 8px;
            margin-right: 10px;

        }
        .status-label
        {
            color: #18171D;
            border: none;
            background-color: orange;
            border-radius: 18px;
            margin-left: 10px;
            padding: 8px 12px;
            display: inline-flex;
            align-items: center;
            min-width: auto;
            cursor: initial;
            &.ENABLED
            {
                background-color: #7AE173;
            }

            &.DISABLED
            {
                background-color: #F54A4F;
            }
        }
    }
    .nav-block{
        display: inline-flex;
        justify-content: space-evenly;
        color: $GREY_LIGHT3;
        margin-top: 25px;

        svg{
            fill: $GREY_LIGHT3;
        }

        >div{
            margin-right: 25px;
            margin-bottom: 20px;
            flex: 1;
            white-space: nowrap;

            > div{
                display: flex;
            }

            h2{
                font-size: $FONT_SIZE_19;
            }

            .ui-icon
            {
                width: 17px;
                height: 18px;
                margin-right: 10px;
                svg{
                    width: 17px;
                    height: 18px;
                }

            }
            h2
            {
                font-weight: normal;
            }

            .log-holder{
                .deactive {
                    path {
                        fill: #888888;
                    }
                }
                &:hover {
                    path {
                        fill: rgb(207, 207, 207);
                    }
                }
            }

            &:hover,
            &.selected{
                color: $FONT_COLOR;
                cursor: pointer;
                .ui-icon {
                    svg{
                        fill: $FONT_COLOR;
                    g{

                        &.white-fill
                        {
                            fill: $FONT_COLOR;

                        }
                    }
                    }
                }
            }
        }
    }
    &.view-webhook-route-block {
        padding: 0 0 100px !important;
        .content-inner-block{
            padding-left: 44px;
            padding-right: 44px;
            background: #2A2A36;
            padding-top: 20px;
            padding-bottom: 25px;
            .header-section {
                margin-top:0;
            }
        }
        .tabs-block {
            padding-right: 44px;
            padding-left: 44px;
            /*background: #353540;*/
            .extended-table.have-table-icons {
                margin-top: 40px;
            }
        }
    }
}
