@import "../../../styles/variables";
.dashboard-route
{
    margin        : 0 auto;
    padding-top   : 24px;
    text-align    : center;
    padding-left  : 24px;
    padding-right : 24px;

    .primary-box-heading,
    .bottom-block,
    .dashboard-heading{
        margin-right: 18px;
    }

    .dashboard-heading
    {
        overflow: hidden;
        margin-bottom: 20px;
        border-bottom: 1px solid $GREY_LIGHT3;
        h1
        {
            font-size: $FONT_SIZE_23;
            margin-bottom : 10px;
            display: inline-block;
            float: left;
            line-height: 33px;
        }
        h3
        {
            font-size: $FONT_SIZE_19;
            margin-top : 6px;
            display: inline-block;
            float: right;
            line-height: 23px;
            font-style: italic;
            margin-right: 5px;
        }
    }
    .tasks-block
    {
        .task
        {
            border: 1px solid #18171D;
            background-color: #353540;
            display: block;
            height: 100%;
            text-align: center;
            h3
            {
                font-size: $FONT_SIZE_19;
                line-height: 25px;
                letter-spacing: 0.5;
                color: $FONT_COLOR;
                margin-bottom: 5px;
            }
            .copy
            {
                color: $GREY;
                max-width:220px;
                margin: 0 auto 20px;
                font-size: 14px;
                line-height: 25px;
                letter-spacing: 0.5;
            }
        }
        .create-product,
        .primary-box
        {
            margin-bottom: 5px;
            text-align: left;
            $CONTAINER_WIDTH: 280px;

            li
            {
                display: inline-block;
                list-style-type: none;
                margin-right: 9px;
                margin-bottom: 15px;
                min-width: $CONTAINER_WIDTH;
                max-width: $CONTAINER_WIDTH;
                height: 323px;
                vertical-align: top;
            }

            .first-product
            {
                .task
                {
                    padding: 30px 20px 20px;
                    .icon
                    {
                        width: 134px;
                        height: 102px;
                        margin-bottom: 25px;
                    }
                }
                &.disabled{
                    height: auto;
                }
            }
            .new-product
            {
                .task
                {
                    background-color: transparent;
                    border: 2px dashed $GREY_LIGHT3;
                    display: flex;
                    align-items: center;
                    .new-product-holder
                    {
                        margin: 0 auto;
                        .icon
                        {
                            width: 44px;
                            height: 44px;
                        }
                        h3
                        {
                            font-size: $FONT_SIZE_19;
                            line-height: 22px;
                            letter-spacing: 0.5;
                            margin-top: 46px;
                        }
                    }
                }
                .ui-icon{
                    width: unset;
                    margin-bottom: 25px;
                    svg{
                        #Line-3{
                               stroke: $FONT_COLOR;
                            }
                        }
                    }
                &:hover{
                    border-color: $FONT_LIGHT;
                    background-color: $GREEN;
                    h3
                    {
                        color: $FONT_LIGHT;
                    }
                    .ui-icon
                    {
                        svg
                        {
                            #Line-3
                            {
                                   stroke: $BG_LIGHT;
                            }
                        }
                    }
                }
            }
            .customer-data
            {
                position: relative;
                .task
                {
                    padding: 0;

                    .task-image, .default-img
                    {
                        height: 181px;
                        width: $CONTAINER_WIDTH - 2;
                        background: $BG_WHITE;
                        display: flex;
                        align-items: center;
                    }
                    .default-img{
                        background-image: linear-gradient(-180deg, #353540 0%, #18171D 100%);
                        padding-top: 0px;
                        justify-content: center;
                        .ui-icon{
                            width: 70px;
                            height: 70px;
                        }
                        svg{
                            width: 68px;
                            height: 69px;
                            fill: $GREY_LIGHT3;
                        }
                    }

                    .task-image
                    {
                        .icon{
                          position: absolute;
                          width: 100%;
                          height: 100%;
                          max-height: 160px;
                          background-size: contain;
                          background-position: center;
                          background-repeat: no-repeat;
                          background-color: white;
                        }
                    }

                    .task-detail
                    {
                        padding: 15px 20px;
                        h3
                        {
                            font-size: $FONT_SIZE_19;
                            line-height: 22px;
                            letter-spacing: 0.5;
                            text-align: left;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            display: block;
                            overflow: hidden;
                            width: fit-content;
                            max-width: 100%;
                        }
                        .content
                        {
                            font-size: 14px;
                            line-height: 22px;
                            text-align: left;
                            .title
                            {
                                min-width: 95px;
                                display: inline-block;
                                color: $FONT_LIGHT;
                            }
                            .value
                            {
                                color: $FONT_COLOR;
                                .last-modified-value div
                                {
                                    display: inline;
                                }
                            }
                        }
                    }

                    &:hover
                    {
                        border: 1px solid $GREEN;
                    }
                }
            }
        }
        .primary-box-heading
        {
            font-size: $FONT_SIZE_23;
            line-height: 28px;
            font-weight: normal;
            text-align: left;
            margin-bottom: 20px;
            border-top: 1px solid $GREY_LIGHT3;
            padding-top: 10px;
        }
        .primary-box
        {
            li
            {
                cursor: pointer;
                min-width: 205px;
                height: 215px;
                border: 1px solid transparent;

                &:hover{
                    border: 1px solid $GREEN;
                }
            }
            .task
            {
                padding: 35px 20px;
                .icon
                {
                    max-width: 100px;
                    max-height: 120px;
                    margin-bottom: 15px;
                }
                h3
                {
                    max-width: 140px;
                    margin: 0 auto;
                    margin-top: 20px;
                }
                .ui-icon{
                    width: 76px;
                }
            }
        }
    }
    .bottom-block
    {
        border-top: 1px solid $BG_LIGHT;
        padding-top: 40px;
        .copy
        {
            color: $GREY;
            font-size: 14px;
            line-height: 25px;
            letter-spacing: 0.5;
            text-align: left;
        }
    }

    .variant-holder
    {
        width: 33px;
        height: 33px;
        border-radius: 6px;
        position: absolute;
        right: 15px;
        bottom: 15px;
        background: rgba(216, 216, 216, 0.10);
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover
        {
            #Create-Variant-1
            {
                stroke: $GREEN;
            }
        }
        .ui-icon
        {
            width: 17px;
            height: 17px;
        }

        .variant-count
        {
            position: absolute;
            width: 20px;
            height: 20px;
            background: #04adc3;
            border-radius: 34px;
            top: -10px;
            left: -10px;
            border: 1px solid $FONT_COLOR;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 12px;
        }
    }

    #variant-table {
        width: 94.1%;
        right: 48px;
        top: 44px;
    }
}
