@import "../../../styles/variables";

.main-content .search-row,
.header .private-view .search-row
{
    margin: 0 40px 0 0;
    width: 100%;
    .search-input
    {
        width: 265px;
        max-width: 850px;
        border: 1px solid $GREY_LIGHT3;
        float: left;
        -webkit-transition: all .5s ease;
       -moz-transition: all .5s ease;
       transition: all .5s ease;
    }
    &.focused
    {
        .search-input
        {
            width: 100%;
        }
    }
    .search-bar
    {
        height          : 40px;
        font-size       : 14px;
        display         : flex;
        justify-content : space-between;
        align-items     : center;
        padding-right   : 0;

        .ui-icon
        {
            margin-top : 5px;
            margin-left: 10px;
            width      : 25px;
            height     : 25px;

            svg
            {
                fill: $GREY;
            }
        }

        input
        {
            font-size      : 14px;
            font-weight    : 400;
            font-family    : 'Roboto', sans-serif;
            margin-left    : 5px;
            margin-bottom  : 0;
            border         : none;
            background     : none;
            color          : $FONT_COLOR;
            width          : 100%;
            outline        : none;
            min-height     : 40px;

            &::placeholder
            {
                color          : $GREY_LIGHT3;
                text-transform : none;
            }
        }
    }

    .search-results
    {
        position    : absolute;
        color       : black;
        background  : white;
        border-top  : 1px solid $GREY;
        // padding-top : 8px;
        font-size   : 14px;

        .search-results-scroll
        {
            max-height: 404px;
            .ps__thumb-y
            {
                background-color: $GREY_LIGHT3 !important;
            }
            .ps__thumb-x
            {
                display: none;
            }
        }

        table
        {
            tbody tr
            {
                &:not(.footer-row)
                {
                    cursor: pointer;
                    &.focused,
                    &:hover
                    {
                        background: $GREY_FOCUSED;
                    }
                }
                &.existing-variants
                {
                    cursor: default !important;
                    background: WHITE !important;
                    text-transform : none;
                    td
                    {
                        color : $GREY_LIGHT3 !important;
                        .ui-icon.item-icon
                        {
                            cursor: default;
                        }
                        div.text-block,
                        .two-lines
                        {
                            color : $GREY_LIGHT3 !important;
                        }
                    }
                }
            }

            th, td
            {
                font-size: $FONT_SIZE_DEFAULT;
                padding: 15px 5px;
                text-align: left;

                &:nth-child(1)
                {
                    padding-left : 20px;
                    width        : 80px;
                }

                &:nth-child(2)
                {
                    width        : 209px;
                    max-width    : 209px;
                }

                &:nth-child(3)
                {
                    width        : 70px;
                }

                &:nth-child(4)
                {
                    width        : 196px;
                }

                &:nth-child(5)
                {
                    width        : 115px;
                }

                &:nth-child(6)
                {
                    padding-right : 20px;
                    width         : 98px;
                }

                .ui-icon.item-icon
                {
                    margin-left: 8px;
                    svg
                    {
                        path
                        {
                            stroke: #000;
                        }
                    }
                }
                .two-lines {
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    white-space: initial;
                    color: $FONT_BLACK !important;

                    p { line-height: 16px; }
                }
                .text-block
                {
                    overflow      : hidden;
                    text-overflow : ellipsis;
                    white-space   : nowrap;
                    color: $FONT_BLACK !important;
                }
                .type-icon
                {
                    margin-left: 2px;
                }
            }
        }
    }

    &.active
    {
        .ui-icon
        {
            svg
            {
                fill: white;
            }
        }
    }

    &.focused
    {
        .search-input{
            background: white;
        }
        .ui-icon
        {
            svg
            {
                fill: black;
            }
        }

        input
        {
            min-width: 743px;
            color: black;

            &::placeholder
            {
                color: $GREY_LIGHT;
            }
        }
    }
    .search-results {
        z-index: 99;
    }
}

.type-container {
    .rc-tooltip-arrow {
        border-width: 6px 8px 0 !important;
        border-top-color: #222 !important;
    }
    .rc-tooltip-inner {
        color: #FFF;
        background-color: #222;
        max-width: 300px;
        border: 1px solid #222;
        padding: 5px;
        line-height: 15px;
        min-height: unset;
    }
}
