@import "../../../../styles/variables";

.groups-settings-route
{
  padding-left  : $SITE_MARGINS;
  padding-right : $SITE_MARGINS;
  .main-block{
    width: 100%;
  }
  .heading-block{
    max-width: 100%;
    width: 100%;
    overflow: hidden;
    margin-bottom: 30px;
    h1{
      display: inline-block;
    }
  }

}
