@import "../../../styles/variables";


.integration
{
    position    : relative;
    display     : inline-block;
    margin-left : 40px;
    .last {
        margin-left: 30px;
    }
    .custom_trigger{
        /*width: 30px;
        height: 30px;
        position: relative;*/
        /*a
        {
            width: 32px;
            height: 32px;
            display: block;
            border-radius: 100%;
            overflow: hidden;
            min-height: auto;
            .profile-image
            {
                height              : 32px;
                width               : 32px;
                background-position : 50%;
                background-size     : cover;
                background-repeat   : no-repeat;
            }
        }*/
    }
    &.open
    {
        .custom_trigger
        {
            
            a
            {
                color: $GREEN;


                .ui-icon
                {
                    svg
                    {
                        fill: $GREEN;
                    }
                }
            }
        }

        .sub-integration
        {
            display: block;
        }
    }
    a
    {
        color: $GREEN;
        min-height: 43px;
        display: block;
        .ui-icon
        {
            g {
                color: $FONT_COLOR;
            }
        }
        &:hover
        {
            color: $FONT_COLOR;
            .ui-icon
            {
                polygon
                {
                    fill: $FONT_COLOR;
                }
            }

        }
        &.disabled
        {
            pointer-events: none;
            color          : $GREY;
            border-color   : $GREY;

            svg
            {
                fill: $GREY !important;
            }

            &:hover, &:focus
            {
                background : none;
                color      : $GREY;

                svg
                {
                    fill: $GREY !important;
                }
            }
            .option {
                .ui-icon {
                    opacity: 0.50;
                }
                .label {
                    color : $FONT_LIGHT;
                }
            }
        }
    }
    .menu-vendor-label {
        padding: 15px 8px 0px 8px !important;
    }
    .trigger
    {
        position : relative;
        cursor   : pointer;

        .label
        {
            font-size     : $FONT_SIZE_16;
            padding-right : 22px;
        }

        .ui-icon
        {
            width    : 14px;
            position : absolute;
            top      : 2px;
            right    : 0;
        }
    }

    .sub-integration
    {
        right       : -40px;
        z-index     : 999;
        display     : none;
        position    : absolute;
        background  : $BG_DARK;
        margin      : 0px 40px 0 0;
        padding     : 0px 15px 10px;
        text-align  : left;
        /*white-space : nowrap;*/
        min-width: 256px;
        max-width: 256px;

        .simple-label {
            color: #888;
        }
        .simple-label-bold {
            text-transform: uppercase;
            font-weight: bold;
            color: #888;
        }
        .option
        {
            display     : flex;
            align-items : center;
            padding     : 15px 8px;

            .ui-icon
            {
                width        : 18px;
                height       : 18px;
                margin-right : 12px;
            }
        }
        .final-entry {
            padding-right: 0px;
            display: inline-block;
            .ui-link {
                margin: 0;
                margin-left: 5px;
                display: inline;
            }
        }
       .border-bottom
        {
            border-bottom : 1px solid #888;
        }
    }
    .muted-text{
        color : $FONT_LIGHT;
    }
}
