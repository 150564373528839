@import "../../../../../../styles/variables";

.new-webhook-form-block
{

  .heading-holder
  {
    display: flex;
    align-items: baseline;
    h1{
        margin-right: 10px;
        line-height: 20px !important;
        margin-bottom: 0px !important;
    }
  }

  h1{
    line-height: 20px;
  }

  .flex-inputs
  {
    display: flex;
    justify-content: space-between;

    > div
    {
      width: 233px;
      flex: 1;
      select,
      input[type="text"]{
        width: 233px;
      }
    }
    div:nth-child(1)
    {
      margin-right: 10px;
    }
  }
  .eventTypes {
    border: 1px solid #979797;
    border-radius: 8px;
    justify-content: center;
    padding: 10px;
    margin-top: 20px;
    label {
      margin-bottom: 0;
    }
          .muted-text
      {
        margin-top: 0;
      }
  }

  label + .label_value{
    margin-left: 10px
  }

  label{
    margin-top: 20px
  }
  input[name="email"]
  {
    width:476px;
  }
  hr{
    border-color: #979797;
    margin: 30px 0;
  }

  .button-sections{
    display: flex;
    align-items: space-between;
    /*justify-content: flex-end;*/
    justify-content: space-around;
    button,
    > div,
    input[type="submit"] {
      margin-left: 30px;
    }
    > div{
       top: -8px;
    }
    button, input[type="submit"]  {
      padding: 7px 0;
      width: 138px;
      max-height: 32px;
      display: flex;
      align-items: center;
      justify-content:center;
      .label
      {
        margin-left: 25px;
      }
      .spin-block
        {
            margin: 0 0 0 5px;
            .spinner
            {
                width: 20px;
                height: 20px;
                svg,
                .ui-icon
                 {
                    margin: 0;
                    width: 20px;
                    height: 20px;
                }
            }
        }
    }
  }
  .bottom-section
  {
    border: 1px solid #979797;
    border-radius: 8px;
    margin-top: 20px;
    max-width: fit-content;
    display: flex;
    justify-content: space-between;
    .status-block,
      .mode-block
    {
        display: flex;
        justify-content: space-around;
        .item-label
        {
            max-width: 250px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 10px;
            .label-heading
            {
              max-width: fit-content;
              margin-right: 10px;
            }
            .status-holder,
            .mode-holder
            {
                flex: 1;
            }
            .status-holder,
            .mode-holder
            {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 5px;
                input
                {
                    height: auto;
                }
                label {
                    border-radius: 50%;
                    margin-right: 8px;
                    margin-top: 0;
                    width: 15px;
                    height: 15px;
                }
                span {
                    vertical-align: top;
                    margin-top: 2px;
                    margin-right: 10px;
                    display: inline-block;
                }
            }
        }
    }
  }
}
