@import "../../../../../styles/_variables.scss";


.new-product-route .documents-block,
.new-component-manual-route .documents-block
{

    margin-top: 25px;

    .list
    {
        margin-bottom : 40px;

        &.empty
        {
            display: none;
        }

        table
        {
            margin-bottom: 40px;

            &.empty
            {
                display: none;
            }

            tr
            {
                span.upload-status{
                    color: $GREEN;
                }
                &:nth-child(odd)
                {

                    td
                    {

                        &:nth-child(1)
                        {
                            width: 275px;
                            span
                            {
                                &.current-progress
                                {
                                    right: 33px;
                                    position: absolute;
                                    text-transform: unset;
                                }
                                &.cancel-file
                                {
                                    color: #3CD1BF !important;
                                    cursor: pointer;
                                }
                            }
                        }

                        &:nth-child(2)
                        {
                            text-transform: uppercase;
                            color: $GREY;
                            width: 45px;
                        }

                        &:nth-child(3)
                        {
                            text-transform: uppercase;
                            color: $GREY;
                            width: 55px;
                        }

                        &:nth-child(4)
                        {
                            width: 18px;

                            div
                            {
                                width: 18px;
                                height: 18px;
                            }
                        }
                    }
                }

                &:nth-child(even)
                {
                    border-color: transparent;

                    td
                    {
                        padding-top : 10px;
                        text-align  : left;

                        label
                        {
                            font-size: 11px;
                        }

                        > div
                        {
                            display: flex;
                            justify-content: space-between;

                            > div
                            {
                                width: 33%;
                                padding-left: 35px;
                            }
                        }
                    }
                }
                &.file-upload-error
                {
                    border-bottom: 1px solid #f54a4f;
                    td
                    {
                        color: #f54a4f !important;
                        input,
                        select
                       {
                        background: #f54a4f;
                       }
                       label
                        {
                            color:#f54a4f;
                        }
                        .ui-icon
                        {
                            svg
                            {
                                fill: #f54a4f;
                            }
                        }
                    }
                }
            }
        }
    }
}
