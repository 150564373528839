.ellipsis-tooltip {
  margin-top: -10px !important; 
  
  .rc-tooltip-arrow {
    border-width: 6px 8px 0 !important;
    border-top-color: #222 !important; 
  }
  .rc-tooltip-inner {
    padding: 5px;
    background-color: #222 !important;
    border: 1px solid #222 !important;
    color: #FFFFFF;
    font-size: 14px;
    border-radius: 0px !important;
    line-height: 15px;
    min-height: unset; 
  }
}
.heading {
  .rc-tooltip-inner {
    text-transform: uppercase;
    font-weight: 700; 
  }
}