.new-warning-modal {
	&.warning-modal-custom {
		.modal {
			width:400px!important; 
			padding: 28px 44px!important;
			height: auto;
			.modal-content {
				.warning-text {
					p {
						margin-bottom: 0!important;
					}
				}
				.bottom-section {
					margin-top: 30px!important;
					padding-top: 48px!important;
				}
			}
		}
	}
}
