.co-erp-options {
  .MuiInputBase-adornedEnd {
    padding: 0;

    &:before {
      display: none;
    }

    .MuiInputAdornment-root {
      button {
        background: transparent;

        border-radius: 0;
        padding: 0;
      }
    }
  }
}
