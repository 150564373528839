.integrations-table
{
    .help-icon-class
    {
        border-right: 1px solid #888888 !important;
        .text-block {
            .link {
                display: flex;
                align-items: center;
                justify-content: center;
                .ui-icon {
                    width: 14px;
                    height: 14px;
                }
            }
        }
    }
    .action
    {
        .link
        {
            color: #3CD1BF;
            &.disable
            {
                color: #888888;
            }
        }
    }
    .ui-modal-box {
        .modal {
            max-width: 480px;
            width: 100%;
            padding: 40px 60px;
            .modal-content {
                .valispace-form-block {
                    h1 {
                        text-align: center;
                        margin-bottom: 20px;
                    }
                    label {
                        margin-bottom: 10px;
                    }
                    input {
                        margin-bottom: 20px;
                    }
                }
            }
        }
    }
}
