@import "../../../../../../../styles/variables";
.show-users {
  position: absolute;
  top: -170%;
  right: 30px;
  width: 235px;
  min-width: auto;
  background-color: #18171D;
  z-index: 9;
  .ps__rail-y:hover .ps__thumb-y {
    width: 6px!important;
    background-color: rgba(170, 170, 170, 0.7)!important;
  }
  .custom-mandatory-scroll {
    min-height: 120px;
    padding: 20px 10px 20px 20px;
      &.dco-popover-1 {
        max-height: 460px;
      }
      &.dco-popover-2 {
        max-height: 410px;
      }
      &.dco-popover-3 {
        max-height: 360px;
      }
      &.dco-popover-4 {
        max-height: 310px;
      }
      &.dco-popover-5 {
        max-height: 260px;
      }
      &.dco-popover-6 {
        max-height: 210px;
      }
      &.popover-1 {
        max-height: 360px;
      }
      &.popover-2 {
        max-height: 310px;
      }
      &.popover-3 {
        max-height: 260px;
      }
      &.popover-4 {
        max-height: 210px;
      }
  }
  .content-block {
    min-width: auto!important;
    margin: 0 auto 10px!important;
    h4 {
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 10px;
      text-align: left;
    }
    .includers-list {
      .template-user {
        display: flex;
        align-items: center;
        padding: 8px 0;
        border-top: 0.5px solid #888888;
        &:last-child {
          border-bottom: 0.5px solid #888888;
        }
        .user-avatar-holder {
          height: 30px;
          .face-avatar,
          .user-profile-img {
            display: inline-flex;
            width: 28px;
            height: 29px;
            margin-right: 10px;
            img {
              width: 100%;
              height: auto;
              border-radius: 50%;
            }
            svg {
              height: 29px;
              width: 28px;
            }
          }
          span:not(.hidden) { display: flex; }

        }
      }
    }
  }
}
