@import "../../../../styles/variables";

.configuration-settings-route
{
    .main-block
    {
        .content-block
        {
            h1
            {
                font-size: 26px;
                margin-bottom: 30px;
            }
        }
    }
    .main-block
    {
        width: 100%;
        .sub-heading
        {   padding-bottom: 10px;
            border-bottom : 1px solid grey;
        }
    }
}
