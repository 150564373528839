@import "../../../../styles/variables";

.user-settings-route
{
    .main-block
    {
        .content-block
        {
            .form-block
            {
                .info-block
                {
                    .passwords-block
                    {
                        margin-bottom: 40px;
                        padding-top: 10px;

                        .password-btn
                        {
                            color: $GREY;
                            font-size: 11px;
                            font-style: italic;
                            cursor: pointer;
                            user-select: none;
                            &:hover
                            {
                                color: $GREEN;
                            }
                        }

                        .fields
                        {
                            display: none;
                            margin-top: 25px;
                            margin-left: 25px;
                        }

                        &.open
                        {
                            margin-bottom: 0;

                            .fields
                            {
                                display: block;
                            }
                        }
                    }

                    .oauth2-block {
                        h3 {
                            margin-bottom: 12px;
                        }
                        .oauth2-row
                        {
                            .oauth2-label
                            {
                                text-align: center;
                                display: flex;
                                align-items: center;

                                .ui-icon
                                {
                                    width: 25px;
                                    height: 25px;
                                    margin-right: 10px;
                                    /*background: $BG_LIGHT3;*/
                                    padding: 5px;
                                    border-radius: 4px;
                                    svg
                                    {
                                        max-width: 15px;
                                        max-height: 15px;
                                    }
                                }

                            }

                            .oauth2-switch
                            {
                                display: inline-block;
                                min-width: 70px;
                                vertical-align: middle;
                                text-align: right;
                            }
                        }

                        .oauth2-error
                        {
                            height: 35px;
                            width: available;

                            .oauth2-error-msg
                            {
                                width: 100%;
                                height: 20px;
                                font-size: 12px;
                                font-style: italic;
                                padding: 2px 8px;
                                margin-top: 8px;
                                text-align: right;
                                color: #F54A4F;
                                vertical-align: middle;
                            }
                        }
                    }

                    .names-block
                    {
                        display: flex;
                        justify-content: space-between;

                        div
                        {
                            flex: 1;
                        }

                        div:nth-child(1)
                        {
                            margin-right: 25px;
                        }
                    }

                    .two-fa
                    {
                        display: flex;
                        align-items: center;
                        margin-bottom: 25px;
                        label
                        {
                            margin-bottom: 0;
                            margin-right: 25px;
                        }
                        &.disable
                        {
                            color: $GREY;
                            .ui-toggle-btn.selected
                            {
                                background: $GREY;
                            }
                        }
                    }
                }

                .right-block
                {
                    position: relative;
                }
            }
        }
    }

    &.saving
    {
        pointer-events: none;
    }
}

.user-settings-route
{
    .modal
    {
        width: 600px;
        padding: 0;
    }
    .qr-code-wrapper
    {
        background: #fff;
        color: #000;
        border-radius: 3px;
        .qr-header
        {
            padding: 20px 30px;
            padding-bottom: 15px;
            border-bottom: 1px solid #ccc;
            h2
            {
                font-size: 20px;
                font-weight: bold;
            }
        }
        .qr-body
        {
            padding: 30px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            border-bottom: 1px solid #ccc;
            .icons-holder {
                width: 50px;
                height: 50px;
                margin-right: 15px;
                img
                {
                    width: 50px;
                    height: 50px;
                }
            }
            .body-content {
                flex: 1;
                h3
                {
                    font-size: 18px;
                    font-weight: bold;
                    margin-bottom: 10px;
                }
            }
        }
        .qr-code-holder
        {
            padding: 10px 30px 0;
            border-bottom: 1px solid #ccc;
            .content-holder
            {
                text-align: center;
                margin-bottom: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                border: 1px solid #ccc;
                padding: 10px;
                border-radius: 3px;
                h4
                {
                    margin-bottom: 15px;
                }
                p
                {
                    word-break: break-all;
                }
            }
            .qr-code
            {
                display: flex;
                align-items: center;
                justify-content: center;
                max-width: 185px;
                margin: 0 auto;
                svg
                {
                    min-height: 195px;
                    path
                    {
                        transform: scale(3.6);
                    }
                }
            }
        }
        .buttons-holder
        {
            padding: 15px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            button
            {
                &:first-child
                {
                    margin-right: 10px;
                }
            }
        }
    }
}
