@import "../../../../styles/variables";

.simple-table{
    *{
        font-size: 14px;
        pointer-events: auto;
    }

    table
    {
        div.text-block
        {
            padding: 10px 9px;
            overflow      : hidden;
            text-overflow : ellipsis;
            white-space   : nowrap;
        }
        thead
        {
            th
            {
                padding: 0;
            }
        }
        tbody{
            *{
                user-select: auto;
            }

            tr{
                td
                {
                    color: $FONT_COLOR;
                    .text-block{
                        color: $FONT_COLOR;
                    }
                    padding: 0;
                    &:last-child{
                        text-align: unset;
                    }
                }
                &.disabled{
                    &:not(.con-holder)
                    {
                        cursor: default;
                        pointer-events: none;
                    }
                    .con-holder
                    {
                        cursor: pointer;
                        pointer-events: default;
                    }
                }
                &:hover
                {
                    &:not(.disabled)
                    {
                        color: $GREEN;
                        cursor: pointer;
                        background-color: $GREY_HOVER;
                    }
                }
                &.no-data
                {
                    td
                    {
                        padding: 10px 9px;
                        text-align: left;
                        padding-left: 10px !important;
                        width: 100%;
                        font-style: italic;
                        color: $GREY;
                    }

                    &:hover
                    {
                        color: unset;
                        cursor: default;
                        background-color: unset;
                    }
                }
            }
        }
    }
}
