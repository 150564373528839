.integrations-table
{
    .ui-modal-box
    {
        .modal
        {
            .modal-content
            {
                .valispace-form-block
                {
                    p{
                        color: #F54A4F;
                        text-align: center;
                        margin-bottom: 10px;
                        font-size: 16px;
                    }
                    .btn-holder {
                        width: 100%;
                        display: flex;

                        .btn-container{
                            button{
                                width: 100px;
                                padding: 6px 12px;
                                &:nth-child(2){
                                    margin-left: 10px;
                                    min-width: 83px;
                                }
                                &.disable {
                                    color: #888888;
                                    border-color: #888888;
                                    pointer-events: none;
                                    &:hover {
                                        color: none;
                                        background: none;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
