@import "../../../../styles/_variables.scss";

.diff-tool-container
{
    background: #2A2A36;
    .diff-tool
    {
        .diff-update-holder {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .tree-child-assembly {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                width: 100%;
            }
            .spinner-wrapper
            {
                margin-top: 0;
                margin-left: 10px;
                border-radius: 3px;
                background: #3C84CD;
                .spinner
                {
                    width: 25px;
                    height: 25px;
                    fill: #044e45;
                    .ui-icon
                    {
                        svg
                        {
                          width: 25px;
                          height: 25px;
                          fill: chartreuse;
                        }
                    }
                }
            }
        }
        .ref-des-check
        {
            padding: 1px 4px 1px 0;
            border-radius: 2px;
            font-size: 12px;
            &.remove
            {
                color: #fff;
                padding: 1px 4px;
                background: #F54A4F;
                text-decoration: line-through;
            }
            &.add
            {
                color: #fff;
                padding: 1px 4px;
                background: #7ae173;
            }
        }
        .comma-span
        {
            margin: 0px 2px;
        }
    }
    .main-diff-wrapper {
        display: flex;
        justify-content: space-between;
        .diff-holder {
            flex:1;
            background: #353540;
            max-width: calc(100% - 310px);
            .diff-header{
                background: #2A2A36;
            }
            .tiles-wrapper{
                background: #2A2A36;
                max-width: 100%;
                margin: 0;
                padding-bottom: 15px;
                .tiles-section-scroll {
                    display: flex;
                    align-items: stretch;
                    width: 100%;
                }
                .tile-box {
                    &:first-child {
                        margin-left: 0;
                    }
                }
            }
            #rev-history-table{
                left: 24px;
                .header-block {
                    .compare-rev-btn {
                        .ui-icon {
                            width: 15px;
                            height: 15px;
                        }
                    }
                }
            }
        }
        .right-search-menu {
            flex:1;
            width: 100% !important;
            max-width: 310px;
            min-width: 310px;
            .diff-side-bar-scroll {
               max-height: 100vh;
            }
        }
    }
    .revision-to-be-compare {
        display: flex;
        align-items: center;
        padding: 5px 44px 5px 24px;
        justify-content: space-between;
        background: #D9EAD9;
        #btn-cancle {
            color: #fff;
            &:hover {
                color: #17171F;
                background: #3CD1B5;
                border-color: #3CD1B5;
            }
        }
        .revision-holder {
            flex: 1;
            display: flex;
            align-items: center;
            .ui-icon {
                width: 15px;
                height: 17px;
                &.rev-link-icon {
                    margin: 0 10px 0 0;
                }
                svg {
                    fill: #18171C;
                    &:hover {
                        fill: #14957D;
                    }
                }
                &.active
                {
                    svg
                    {
                        fill: #14957D;
                    }
                }
            }

            .default
            {
                margin-left: 0px;
                .time-span,
                .date-span {
                    color: #fff;
                    font-size: 12px;
                }
            }
            .compare-revision {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                .revision-col {
                    text-align: left;
                    border: 1px solid #88c685;
                    padding: 5px;
                    border-radius: 8px;
                    background: #bddebc;
                    .revision {
                        grid-area: Rev1;
                        display: flex;
                        justify-content:left;
                        align-items:left;
                        .revision-value {
                            color: #18171d;
                            font-weight: 600;

                        }
                        .revision-label {
                            color: #18171d;
                            font-weight: 600;
                            min-width: 64px;
                        }
                        .rev-link-icon
                        {
                            svg
                            {
                                g
                                {
                                    fill: #18171d;
                                }
                            }
                            &:hover
                            {
                                svg
                                {
                                    g
                                    {
                                        fill: #14957D;
                                    }
                                }
                            }
                        }
                    }
                    .modified-date {
                        display: flex;
                        justify-content:left;
                        align-items:left;
                        margin-left: 25px;
                        .modified-label {
                            color: $GREY_LIGHT3;
                            font-weight: 500;
                            margin-right: 5px;
                            min-width: 64px;
                            display: none;
                        }
                    }
                    &.modified-col {
                        display: flex;
                        padding: 0;
                        .modified-update {
                            padding: 5px;
                        }
                        .icon-holder {
                            border-left: 1px solid #88c685;
                            display: flex;
                            align-items: center;
                            padding: 0 8px;
                            margin-left: 5px;
                            .open-link-holder {
                                height: 17px;
                                .ui-icon {
                                    margin: 0;
                                    width: 20px;
                                    svg{
                                        &:hover{
                                            .line-stroke{
                                                stroke: $GREEN;
                                            }
                                            .round-fill{
                                                fill: $GREEN;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .double-headed-arrow {
                    display: flex;
                    justify-content:center;
                    align-items:center;
                    .ui-icon.arrow
                    {
                        width: 20px;
                        height: 17px;
                        display: flex;
                        align-items: center;
                        cursor: default;
                        margin: 0 10px;
                        svg
                        {
                            height: 25px;
                        }
                    }
                }
            }
        }
    }
    .top-name-heading {
        display: inline-block;
        margin: 0 44px 5px 38px;
        padding: 0 5px;
        font-size: 23px;
        margin-top: 15px;
    }
    .documents-view-block,
    .sourcing-row-mode,
    .assembly-tab-view-common
     {
        .extended-table {
            #extended-table-data,
            #rolled-up-table
             {
                tbody{
                    tr{
                        &.update {
                            background: #d4e8fc;
                            color: #18171d;
                            .date-span,
                            .link,
                            a{
                                color: #18171d;
                            }
                            &:hover {
                                background: #d4e8fcd9 !important;
                                color: #18171d;
                            }
                            .link {
                                color: #14957D;
                                &:hover
                                {
                                    color: #18171d;
                                }
                            }
                        }
                        &.remove {
                            background: #fdd2d2;
                            position: relative;
                            color: #18171d;
                            .date-span,
                            .link,
                            a{
                                color: #18171d;
                            }
                            &:hover {
                                background: #f9c5c5 !important;
                                // background: rgb(253 210 210 / 85%);
                                color: #18171d;
                            }
                            .link {
                                color: #14957D;
                                &:hover
                                {
                                    color: #18171d;
                                }
                            }
                            td{
                                position: relative;
                                &:before {
                                    content: '';
                                    position: absolute;
                                    height: 1px;
                                    width: 100%;
                                    background: #000;
                                    top: 47%;
                                    z-index: 1;
                                    left: 0;
                                    right: 0;
                                }
                            }
                        }
                        &.add {
                            background: #d8ead8;
                            color: #18171d;
                            .date-span,
                            .link,
                            a{
                                color: #18171d;
                            }
                            &:hover {
                                color: #18171d;
                                background: #e4fbe4 !important;
                            }
                            .link {
                                color: #14957D;
                                &:hover
                                {
                                    color: #18171d;
                                }
                            }
                        }


                        &.update,&.remove,&.add
                        {
                            .diff-update-holder
                            {
                                .tree-child-assembly
                                {
                                    .ui-icon
                                    {
                                        svg
                                        {
                                            fill: #000;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

            }
        }

        .diff-tool
        {
            height: auto;
        }
        .diff-update-section
        {
            display: flex;
            align-items: center;
            .update-tag
            {
                padding: 1px 4px;
                border-radius: 2px;
                margin-right: 5px;
                font-size: 12px;
                color: #fff;
            }
            .remove
            {
                background: #F54A4F;
                text-decoration: line-through;
                .modified
                {
                    svg
                    {
                        g
                        {
                            g
                            {
                                fill: white;
                            }
                        }
                    }
                }
            }
            .update
            {
                background: #52A2F3;
            }
            .add
            {
                background: #7AE173;
            }
            .is-modified
            {
                padding-right: 9px;
            }
        }
            .diff-tag-container
            {
                border-radius: 2px;
                margin-left: 10px;
                background: #3C84CD;
                padding-right: 3px;
                .update-tag
                {
                    margin: 3px 0 3px 3px;
                }
            }
    }

    .diff-tool-active-section
    {
        -webkit-box-shadow: 0px 0px 8px 0px rgba(82,162,243,1);
        -moz-box-shadow: 0px 0px 8px 0px rgba(82,162,243,1);
        box-shadow: 0px 0px 8px 0px rgba(82,162,243,1);
        border-radius: 4px !important;
    }
    .fade-transition{
        transition: box-shadow 0.5s linear;
    }

    .ps__thumb-y {
        background-color: #aaa !important;
    }
}

.product.view,
.product.revision,
.component.view,
.component.revision,
.product.diff,
.component.diff
{
   .routes
   {
        padding-bottom: 0;
   }
   .main-content {
    max-height: inherit;
   }
}


