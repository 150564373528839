@import "../../../../../styles/variables";

.webhook-add-modal
{
  .ui-modal-box
  {
    color: $FONT_COLOR;
    .modal
    {
      max-width: 564px;
      margin: 0 auto;
      position: relative;
      padding: 0;
      height: auto;
      .modal-content
      {
        padding: 32px 41px;
        height: 100%;
        position: initial;
        h2 {
          text-align: center;
          font-size: 32px;
          line-height: 36px;
          max-width: 302px;
          margin: 0 auto 20px;
        }
        .ui-message {
          height: auto;
        }
      }
    }
  }
}
