@import "../../../styles/variables";

.sidebar-view  {
    // min-height: 75vh;
    border-right: 1px solid #000;
    background: $BG_LIGHT;
    position: relative;
    .scrollarea {
        background: $BG_LIGHT4;
    }
    .nav-list {
        overflow: hidden;
    }

    .scrollarea .scrollbar-container{
        opacity: 1;
        z-index: 2;
    }
    .scrollarea .scrollbar-container.active, .scrollarea .scrollbar-container:hover{
        background: transparent;
        opacity: 1 !important;
    }
    .scrollarea:hover .scrollbar-container {
        opacity: 1 !important;
    }
    .scrollarea .scrollbar-container.vertical .scrollbar{
        background: $BG_LIGHT3;
    }

    .sidebar-expand {
        position: absolute;
        right: -7px;
        z-index: 2;
        width: 15px;
        height: 100%;
        cursor: ew-resize;
        .border{
            border-right: 1px solid transparent;
            transition: border-right 1s;
            height: 100%;
            right: 6px;
            text-decoration: none;
        }
        &:hover, &:active{
            .border{
                border-right: 1px solid $GREEN;
                right: 6px;
                height: 100%;
            }
        }
    }
    .nav-list.expanded-products{
        background: $BG_LIGHT4;
    }
    .nav-list {
        background: $BG_LIGHT2;
        min-height: 35vh;
        height: 100%;
        .list-bottom {
            position: absolute;
            width: 100%;
            border-top: 1px solid $BG_LIGHT3;
            background: $BG_LIGHT2;
            &.last-one {
                bottom: 140px;
            }
            &.last {
                bottom: 70px;
            }
            &.end-last {
                bottom: 0px;
            }
        }
    }
    .side-nav-holder{
        background: $BG_LIGHT4;
    }
    ul {
        padding: 0;
        margin: 0;
        li {
            &.active {
                a {
                    color: $GREEN;
                    .ui-icon {
                        svg path{
                            stroke:$GREEN;
                        }
                    }
                }
            }
            &.item-tab {
                position: relative;
                > a.close-tab{
                    min-height: 71px;
                    width: 100%;
                    display: -webkit-inline-box;
                }
                &:hover,
                &.active {
                    .product-nav{
                        color: $GREEN;
                        svg path{
                            stroke:$GREEN;
                        }
                    }
                    .product-nav:hover{
                        cursor: pointer;
                    }
                }

                .product-nav {
                    position: absolute;
                    top: 26px;
                    left: 44px;
                    font-size: 14px;
                    font-family: 'Roboto', sans-serif;
                    font-weight: 700;
                    vertical-align: middle;
                    line-height: normal;
                    cursor: pointer;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    &:hover,
                    &.active {
                        color: $GREEN;
                        svg path{
                            stroke:$GREEN;
                        }
                    }
                    .nav-icon {
                        width: 35px;
                        height: 20px;
                        padding: 0 7px 0 0;
                        float: left;
                    }
                }
            }
            a {
                border-bottom: 1px solid $BG_LIGHT3;
                font-size: 14px;
                // line-height: 20px;
                vertical-align: middle;
                line-height: normal;
                color: $FONT_COLOR;
                max-height: 71px;
                // padding: 26px 44px;
                padding: 26px 0 26px 44px;
                display: block;
                font-family: 'Roboto', sans-serif;
                background: $BG_LIGHT2;

                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;


                &:hover {
                    color:$GREEN;
                    svg path{
                        stroke:$GREEN;
                    }
                }
                .nav-icon {
                    width: 35px;
                    height: 20px;
                    padding: 0 7px 0 0;
                    float: left;
                }
                .nav-text{
                    vertical-align: middle;
                    line-height: normal;
                    font-weight: 700;
                }
            }
            .product-type-block {
                padding-right: 0;
                display: inline-block;
            }
        }
        .release-tab {
            svg {
                g {
                    fill: #FFF;
                }
                path {
                    stroke: none;
                }
            }
            &.active {
                .sideNavLinks {
                    svg {
                        g {
                            fill: $GREEN;
                        }
                        path {
                            stroke: none;
                        }
                    }
                }
            }
            .sideNavLinks {
                &:hover {
                    svg {
                        g {
                            fill: $GREEN;
                        }
                        path {
                            stroke: none;
                        }
                    }
                }
            }
        }
    }

    &.narrow{
        width: 55px !important;
        min-width: 55px !important;
        max-width: 55px !important;

        .expanded-products{
            background: $BG_LIGHT2 !important;
        }
        .nav-text{
            display: none;
        }
        ul li a{
            padding: 26px 0 26px 18px;
        }
        .product-nav{
            left: 18px !important;
        }
        .item-assembly-view{
            display: none;
        }
        .list-bottom{
            border-top: none;
            position: inherit;
            &.last-one{
                bottom: auto;
            }
        }
    }
}
