//
// Resend Email Modal Styles

@import "../../../../../../styles/variables";

$avatarSize: 28px;
$uiIconSize: 20px;

.resend-email {
  .modal {
    width: 564px;
    padding: 28px 44px;

    h1, p{
      margin-bottom: 20px;
    }

    p {
      width: 60%;
    }

    .includers {
      display: flex;

      * {
        display: flex;
      }
      
      .approver-checkbox,
      .notifier-checkbox {
        align-items: center;
        margin-left: 15px;

        input, label {
          width: 15px;
          height: 15px;
        }
        label{
          margin-left: -15px;
        }

        span {
          padding-left: 7px;
        }
      }
    }

    .includers-list {
      max-height: 195px;
      padding-right: 7px;
      margin-top: 15px;
      overflow-y: scroll;

      /* Firefox */
      scrollbar-width: auto;
      scrollbar-color: $GREY_LIGHT3 $BG_LIGHT;

      /* Chrome, Edge, and Safari */
      &::-webkit-scrollbar {
        width: 11px;
      }

      &::-webkit-scrollbar-track {
        background: $BG_LIGHT;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $GREY_LIGHT3;
        border-radius: 10px;
        border: 3px solid $BG_LIGHT;
      }
      // ----------------------------

      .item {
        display: flex;
        align-items: center;
        padding: 7px 0;
        border-bottom: 1px solid $FONT_LIGHT;

        &:first-child {
          border-top: 1px solid $FONT_LIGHT;
        }

        > * {
          display: inline-flex;
        }

        .user-avatar-holder {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 33px;
          height: 33px;

          span:not(.hidden) { display: flex; }

          .user-profile-img:not(.hidden) {
            width: $avatarSize;
            height: $avatarSize;
            border-radius: 50%;
            overflow: hidden;

            img {
              width: 100%;
              overflow: hidden;
            }
          }

          .ui-icon:hover { cursor: auto; }
        }

        .name {
          width: calc(51% - 33px);
          padding-left: 7px;
        }

        .email {
          width: 46%;
          padding-right: 5px;

          a {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }

        .ui-icon {
          width: $uiIconSize;
          height: $uiIconSize;

          &.disabled { cursor: auto; }

          svg {
            width: $uiIconSize;
            height: $uiIconSize;
          }
        }

        .face-avatar {
          .ui-icon {
            width: $avatarSize;
            height: $avatarSize;
            border-radius: 50%;
            overflow: hidden;

            svg {
              width: $avatarSize;
              height: $avatarSize;
            }
          }
        }
      }

    }

    .includers-cc {
      display: flex;
      flex-wrap: wrap;
      margin-top: 30px;

      .cc-title {
        display: block;
        width: 100%;
        padding-bottom: 7px;
      }

      input {
        width: 76%;
        height: 30px;
        padding: 4px 8px;

        &:not(:focus) {
          color: $FONT_LIGHT;
          background-color: $BG_LIGHT;
          border: 1px solid #79787d;
        }
      }

      button {
        width: calc(24% - 10px);
        height: 30px;
        padding: 0;
        margin-left: 10px;
      }
    }

    .bottom-section {
      display: flex;
      flex-direction: row-reverse;
      width: 100%;
      padding-top: 35px;

      button {
        width: 138px;
        height: 30px;
        padding: 0;

        &:not(:last-child) {
          margin-left: 10px;
        }
      }
    }

    .email-sent-block {
      text-align: center;
      .ui-icon {
        width: 90px;
        height: 68px;
        margin: 12px 0 25px;
      }

      p { width: 100%; }
    }
  }
}

.emailed {
  .modal {
    width: 506px;
    min-height: 374px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
