.export-notification-modal{
	position: fixed;
    bottom: 20px;
    right: 25px;
	filter: drop-shadow(0px 2px 2.5px rgba(0,0,0,0.5));
	background-color: #000000;
	min-height: 75px;
	width:100%;
	max-width: 400px;
	margin-left: auto;
	z-index: 9;
	.notification-holder{
		.notification-heading{
			display: flex;
			align-items:center;
			justify-content:space-between;
			font-size: 14px;
			letter-spacing: 0px;
			font-weight: 500;
			padding: 10px 17px;
			.modal-close{
				.ui-icon{
					height: 13px;
					width:13px;
				}
			}
		}
		.divider{
			border-bottom: 1px solid #575759;
		}
		.notification-loading, {
			padding: 10px 17px;
			display: flex;
			align-items:center;
			justify-content: space-between;

			.notification-loading-area, .notification-progress-area, .notification-download{
				display: flex;
			}
			.notification-loading-area{
				.notification-download
				{
					.notification-icon
					{
						padding-right: 7px;
						svg
						{
							width: 14px;
							height: 14px;
							g
							{
								g
								{
									stroke:#807f7f;
								}
							}
						}
					}
					.notification-files
					{
						color: #807f7f;
					}
				}
			}
			.notification-progress-area{
				.notification-progress-icon{
					display: flex;
					svg{
						height: 17px;
						width:17px;
					}
				}
				.notification-progress-time{
					display: flex;
					.notification-progress{
						font-style: italic;
						color: #888888;
						padding-right: 7px;
						.cancel-link{
							color:#3CD1BF;
							cursor: pointer;
						}
					}
					.notification-loader{
						@keyframes rotate
						{
							from {transform: rotate(0deg);}
							to {transform: rotate(360deg);}
						}
						margin : 0 auto;
						width                     : 18px;
						height                    : 18px;
						animation-name            : rotate;
						animation-duration        : 0.4s;
						animation-iteration-count : infinite;
						animation-timing-function : linear;

						.ui-icon
						{
							width  : 15px;
							height : 15px;

							svg
							{
								height: 18px;
								width: 18px;
								fill: #3CD1BF;
							}
						}
					}
				}
			}
		}
	}
}
