.vendor-wrapper
{
    .ui-icon
    {
        margin-left: 0!important;
        svg
        {
            g
            {
                transform: none;
                fill:  transparent;
                stroke:  unset;
            }
        }
    }

    .vendor-label
    {
        cursor: pointer;
        position: initial!important;
        display: inline-flex;
        min-height: 24px;
        background-color: #04ADC3;

        span
        {
            color: #000;
        }


        &:hover
        {
            -webkit-box-shadow:inset 0px 0px 0px 2px #01D6F2;
            -moz-box-shadow:inset 0px 0px 0px 2px #01D6F2;
            box-shadow:inset 0px 0px 0px 2px #01D6F2;
        }
    }

    .disabled
    {
        cursor: default;
        background-color: #B3B3B3;
        opacity: 0.8;
        &:hover
        {
            -webkit-box-shadow: unset;
            -moz-box-shadow: unset;
            box-shadow: unset;
        }
    }

    .not-editable
    {
        cursor: default;
        &:hover
        {
            -webkit-box-shadow: unset;
            -moz-box-shadow: unset;
            box-shadow: unset;
        }
    }

}

.truncate-name
{
    padding-top: 15px!important;
}

body
{
    .rc-tooltip
    {
        &.error
        {
            &.type-dark
            {
                .rc-tooltip-arrow
                {
                    border-right-color: #000!important;
                }
                .rc-tooltip-inner
                {
                    background-color: #000!important;
                    border-color: #000!important;
                    color: #fff!important;
                }
            }
        }
    }
}
