@import "../../../styles/variables";

.invite-user-modal
{
    .ui-modal-box {
        .modal {
            max-width: 485px;
            margin: 0 auto;
            background-color: $FONT_COLOR;
            position: relative;
            padding: 0;
            height: auto;
            min-height: 600px;
            .modal-content {
                padding: 42px;
                color: black;
                height: 100%;
                position: initial;
                h2 {
                    text-align: center;
                    font-size: 32px;
                    line-height: 36px;
                    max-width: 302px;
                    margin: 0 auto 20px;
                }
                .ui-message {
                    height: auto;
                }
                .names-block {
                    margin-bottom: 15px;
                    .input-holder {
                        input {
                            border:1px solid #CCCCCC;
                            color: black;
                            padding: 10px 20px 0;
                            height: 45px;
                            background-color: transparent;
                            font-size: 14px;
                            line-height: 16px;
                            text-align: left;
                            transition: all 0.3s;
                            &.invalid {
                                background: transparent;
                            }
                            &:focus{
                                outline: none;
                            }
                            &:focus + .label-text,
                            &:valid + .label-text {
                              font-size: 7px;
                              letter-spacing: 1px;
                              -moz-transform: translateY(-42px);
                              -ms-transform: translateY(-42px);
                              -webkit-transform: translateY(-42px);
                              transform: translateY(-42px);
                            }
                        }
                        input[type="text"].invalid{
                            border-color: #F54A4F;
                        }
                        .label-text {
                          color: #999999;
                          cursor: text;
                          font-size: $FONT_SIZE_DEFAULT;
                          padding: 0 20px;
                           letter-spacing: 1px;
                          text-transform: uppercase;
                          -moz-transform: translateY(-30px);
                          -ms-transform: translateY(-30px);
                          -webkit-transform: translateY(-30px);
                          transform: translateY(-30px);
                          transition: all 0.3s;
                          text-align: left;
                          display: inline-block;
                        }
                        .ui-message {
                            position: absolute;
                            right: 0;
                            top: 0;
                            margin: 0;
                            color: #F54A4F;
                            padding: 5px;
                            text-align: right;
                        }
                        label {
                            margin-bottom: 0;
                        }
                    }
                }
                .submit-block
                {
                    margin-bottom: 30px;
                    input {
                        width: 180px;
                        height: 40px;
                        margin: 0 auto;
                        font-size: 16px;
                        line-height: 19px;
                        letter-spacing: 0.57;
                        color: $GREEN;
                        &:focus, &:hover {
                            color: $FONT_LIGHT;
                            background: $GREEN;
                        }
                        &.disabled {
                            color: #B3B3B3;
                            border-color: #B3B3B3;
                        }
                    }
                }
                .area {
                    max-height: 215px;
                }
                .table-block {
                    table {
                        tbody {
                            tr {
                                border-top: 1px solid #CCCCCC;
                                border-bottom: 1px solid #CCCCCC;
                                td {
                                    font-size: $FONT_SIZE_DEFAULT;
                                    line-height: 15px;
                                    color: #999999;
                                    font-weight: 600;
                                    padding: 13px;
                                }
                            }
                        }
                    }
                }
                .close-btn {
                    position: absolute;
                    bottom: 20px;
                    left: 20px;
                    text-decoration: underline;
                    color: #999999;
                    font-size: 14px;
                    line-height: 16px;
                }
            }
            .buttons.modal-close {
                right: -21px;
                top: -35px;
            }
        }
    }
}
