@import "../../../../../../styles/variables";

.assembly-tab-view-common,
.assembly-tab-edit-common{
    .rolled-up-cost-cell{
        display: flex;
        justify-content: space-between;
    }
    .price-value{
        min-width: 70px;
        display: inline-block;
        vertical-align: top;
    }
    .warningEl
    {
        float: left;
    }
    select, input[type='text']{
        height: 35px;
    }

    /*//toDo: need to add in accurate place*/
    .disable-qty-input {
        background-color: #4A4A54;
            color: $FONT_COLOR;
            font-size: 14px;
            pointer-events: none;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            border-color: transparent;
    }
}
