@import "../../styles/variables";

.hidden {
    display: none;
}
.hidden-imp {
    display: none !important;
}

.body {
    overflow: hidden;
}
.two-col {
    display: flex;
    overflow: hidden;
    .routes{
        padding-bottom: 100px;
    }
    .main-content {
        width: 100%;
        height: 100%;
        .ps--active-x > .ps__rail-x,
        .ps--active-y > .ps__rail-y{
            opacity:9;
         }
        .ps__rail-x:hover,
        .ps__rail-y:hover,
        .ps__rail-x:focus,
        .ps__rail-y:focus{
            background: transparent;
        }
        .ps__rail-y:hover > .ps__thumb-y,
        .ps__rail-y:focus > .ps__thumb-y{
            background-color: #18171D;
            width:9px;
        }
        .ps__thumb-y{
            background-color: #18171D;
            opacity:9;
        }
    }

    .onboarding-in-progress{
        .modal
        {
            width: 675px;
            height: 303px;
            background-color: $FONT_COLOR;
            color: #000000;
            text-align: center;

            h1{
                margin-top: 40px;
                font-size: 32px;
            }
            p{
                color: $FONT_LIGHT;
                font-size: 16px;
            }
            .loading-block
            {
                @keyframes rotate
                {
                    from {transform: rotate(0deg);}
                    to {transform: rotate(360deg);}
                }
                margin : 0 auto;
                width                     : 40px;
                height                    : 40px;
                animation-name            : rotate;
                animation-duration        : 0.4s;
                animation-iteration-count : infinite;
                animation-timing-function : linear;

                .ui-icon
                {
                    width  : 40px;
                    height : 40px;

                    svg
                    {
                        fill: $GREEN;
                    }
                }
            }
        }
    }
}
.app{
    &.product,
    &.component{
        &.view,
        &.revision,
        &.edit{
            .main-content{
                background: $BG_LIGHT2;
            }
        }
    }
}

.export.app.default {
    .routes {
        min-height: 100%;
    }
}