@import "../../../../../styles/variables";

.webhook-list-form-block
{
    .extended-table table tr td
    {
        &:nth-child(2)
        {
            .text-block
            {
                overflow: inherit;
                .status-nav
                {
                    list-style: none;
                    display: flex;
                    align-items: center;

                    .status-item
                    {
                        border: 1px solid $GREY_LIGHT3;
                        background-color: unset;
                        color: $FONT_LIGHT;
                        border-radius: 16px;
                        padding: 4px 8px;
                        margin-right: 10px;

                    }
                    .status-label
                    {
                        color: #18171D;
                        border: none;
                        background-color: orange;
                        border-radius: 18px;
                        padding: 8px 12px;
                        display: inline-flex;
                        align-items: center;
                        min-width: auto;
                        cursor: initial;
                        &.ENABLED
                        {
                            background-color: #7AE173;
                        }

                        &.DISABLED
                        {
                            background-color: #F54A4F;
                        }
                    }
                }
            }
        }
    }
}

